import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';

import BarGraph from '../BarGraph';

const DashboardCollaborationsPerTypeCountPage = ({ companyId }) => {
    const [error, setError] = useState('');
       const [collaborationRequest, setCollaborationRequest] = useState(null);
    const [collaborations, setCollaborations] = useState([]);
   
    const [openPressReleaseCollaborations, setOpenPressReleaseCollaborations] = useState(0);
    const [openArticleCollaborations, setOpenArticleCollaborations] = useState(0);
    const [openBlogpostCollaborations, setOpenBlogpostCollaborations] = useState(0);
    const [openUsecaseCollaborations, setOpenUsecaseCollaborations] = useState(0);

    const [openCorporatetextCollaborations, setOpenCorporatetextCollaborations] = useState(0);

    const { fetchWithAuth } = useAuth();

    const { t } = useTranslation();
    const navigate = useNavigate();

useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    const fetchCounts = async () => {
        try {
            const countsEndpoint = `/collaboration/collaborations/${companyId}/counts?userId=${storedUserId}`;
            const response = await fetchWithAuth(countsEndpoint);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();

           setOpenPressReleaseCollaborations(data.openPressReleaseCollaborations);
           setOpenArticleCollaborations(data.openArticleCollaborations);

           setOpenBlogpostCollaborations(data.openBlogpostCollaborations);
           setOpenUsecaseCollaborations(data.openUsecaseCollaborations);

           setOpenCorporatetextCollaborations(data.openCorporatetextCollaborations);


        } catch (error) {
            setError('Failed to load data. Please try again later.');
        }
    };

    fetchCounts();
}, [companyId, navigate]);


    const handleEditClick = (collaborationRequest) => {
        navigate(`/app/app/collaborate/${collaborationRequest.onModel}/${collaborationRequest.contentId}`, { state: { companyId } });
    };

   const graphData = [
        { name: t('your_open_press_releases'), value: openPressReleaseCollaborations },
        { name: t('your_open_articles'), value: openArticleCollaborations },
        { name: t('your_open_blogposts'), value: openBlogpostCollaborations },
        { name: t('your_open_usecases'), value: openUsecaseCollaborations },

        { name: t('your_open_corporatetexts'), value: openCorporatetextCollaborations },
    ]; 

    return (
        <div>
            <div className="dashboard-content-top">
                <div>{t('your_open_collaboration_by_category')}</div>
                <div><NavLink to="collaborate">{t('open_by_category_link')}</NavLink></div>
            </div>
            <div className="dashboard-content-table">
                      <div className="dashboard-stats">
        <div className="bargraph"><BarGraph data={graphData} /></div>

                </div>
            </div>
        </div>
    );
};

export default DashboardCollaborationsPerTypeCountPage;
