import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ArticleButtons = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

 const handleCreateClick = () => {
        navigate('app/articles', { state: { addNew: true } });
    };

    const handleListViewClick = () => {
        navigate('app/articles', { state: { reset: true } });
    };


    return (
        <>
<button onClick={handleCreateClick}>{t('create_article')}</button>
            <button onClick={handleListViewClick}>{t('view_articles')}</button>
            

        </>
    );
};

export default ArticleButtons;
