import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const BlogpostModal = ({ isOpen, onClose, blogpost }) => {
    const [copySuccess, setCopySuccess] = useState('');
    const { t } = useTranslation();

    if (!isOpen) return null;

    const renderBlogpostText = () => {
        const paragraphs = blogpost.blogpostText.split('\n');
        return paragraphs.map((paragraph, index) => (
            <p key={index} className="press-release-text">{paragraph}<br></br></p>

        ));
    };

   
const copyToClipboard = text => {
    navigator.clipboard.writeText(text)
        .then(() => {
            setCopySuccess(t('copy_success'));
            setTimeout(() => setCopySuccess(''), 3000);
        })
        .catch(err => {
            setCopySuccess(t('copy_error'));
            setTimeout(() => setCopySuccess(''), 3000);
        });
};

    const handleCopyHeadline = () => copyToClipboard(blogpost.headline);
    const handleCopyContent = () => copyToClipboard(blogpost.blogpostText);
     const handleCopyComplete = () => copyToClipboard(
        `Headline: ${blogpost.headline}\n\n` +
        `Content:\n${blogpost.blogpostText}\n\n`
    );





    return (
        <div className="press-modal">
            <div className="modal-press-content">
                <div className="modal-button-bar">
             <button className="save" onClick={handleCopyHeadline}>{t('copy_headline')}</button>
                    <button className="save" onClick={handleCopyContent}>{t('copy_content')}</button>
                    <button className="save" onClick={handleCopyComplete}>{t('copy_complete')}</button>
                      <button className="close-press-button" onClick={onClose}>&times;</button>
                </div>
                {copySuccess && <div className="copy-success-message">{copySuccess}</div>}

                <div className="modal-scrollable-content">
                    <div className="modal-header">
                        <h1>{blogpost.headline}</h1>
                    </div>
                    <div className="modal-body">
 <p><br></br></p>
{renderBlogpostText()}             
       </div>
                   
                </div>
            </div>
        </div>
    );
};

export default BlogpostModal;
