import React, { useState, useEffect } from 'react';
import CompanyDescriptionFunction from './companyDescriptionFunction';
import CompanyDescriptionTranslate from './companyDescriptionTranslate';
import CompanyContent from './companyContent';
import DescriptionPage from './descriptionPage';
import axios from '../axios.js';
import { useTranslation } from 'react-i18next';

const CompanyDescriptionPage = ({ existingCompany, setCompanyData, onToggleSubscription }) => {
    const [company, setCompany] = useState(existingCompany || {});
    // Determine the initial editMode state based on existingCompany's descriptionText
    const [editMode, setEditMode] = useState(!existingCompany?.content?.description?.descriptionText);
    const [isTranslating, setIsTranslating] = useState(false);
    const [translateMode, setTranslateMode] = useState(false);
    const [isSaved, setIsSaved] = useState(!!existingCompany?.content?.description?.descriptionText);
    const { t } = useTranslation();

    useEffect(() => {
        if (!company || Object.keys(company).length === 0) {
            fetchInitialCompanyData();
        }
    }, []);

    useEffect(() => {
        if (existingCompany) {
            setCompany(existingCompany);
            const hasDescriptionText = !!existingCompany.content?.description?.descriptionText;
            setIsSaved(hasDescriptionText);
            // Update editMode based on the presence of descriptionText
            setEditMode(!hasDescriptionText);
        }
    }, [existingCompany]);

    const fetchInitialCompanyData = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                return;
            }
            const response = await axios.get('/companies/get-company', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setCompany(response.data);
            const hasDescriptionText = !!response.data.content?.description?.descriptionText;
            setIsSaved(hasDescriptionText);
            setEditMode(!hasDescriptionText);
        } catch (error) {
        }
    };

 const onCompanyUpdate = (updatedData) => {
    setCompanyData(updatedData);
    setIsSaved(true);
    setEditMode(false);
setTranslateMode(false);
  };

    const handleChange = (event) => {
        const { name, value } = event.target;
        let updatedCompany = { ...company };
        const keys = name.split('.');
        let currentLevel = updatedCompany;
        for (let i = 0; i < keys.length - 1; i++) {
            if (!currentLevel[keys[i]]) {
                currentLevel[keys[i]] = {};
            }
            currentLevel = currentLevel[keys[i]];
        }
        currentLevel[keys[keys.length - 1]] = value;
        setCompany(updatedCompany);
    };


const handleDescriptionTextUpdate = (newText) => {
    const userId = localStorage.getItem('userId');

    if (!userId) {
        return;
    }

    setCompany(prevCompany => ({
        ...prevCompany,
        content: {
            ...prevCompany.content,
            description: {
                ...prevCompany.content.description,
                descriptionText: newText,
                creator: userId
            }
        }
    }));

    setIsSaved(true);
    setEditMode(false);
    setTranslateMode(false);
};



const handleTranslationSave = (language, translatedText) => {
    const updatedCompany = { ...company };
    updatedCompany.content.description.descriptionTranslations = updatedCompany.content.description.descriptionTranslations || [];
    const translationIndex = updatedCompany.content.description.descriptionTranslations.findIndex(t => t.language === language);
    if (translationIndex > -1) {
        updatedCompany.content.description.descriptionTranslations[translationIndex].translation = translatedText;
    } else {
        updatedCompany.content.description.descriptionTranslations.push({ language, translation: translatedText });
    }
    setCompany(updatedCompany);
};


const handleEditClick = () => {
    setEditMode(true);
    setIsSaved(false);
    setTranslateMode(false);
};

const handleTranslateClick = () => {
    setTranslateMode(true);
    setIsSaved(false);
setEditMode(false);
};

const handleEditTranslation = (translation, index, newTranslationText) => {
    const updatedCompany = { ...company };
    if (updatedCompany.content && updatedCompany.content.description.descriptionTranslations) {
        if (index >= 0 && index < updatedCompany.content.description.descriptionTranslations.length) {
            updatedCompany.content.description.descriptionTranslations[index].translation = newTranslationText;
        }
    }
    setCompany(updatedCompany);
};


    const handleDeleteTranslation = async (translationId, index) => {
 if (window.confirm(t('confirm_delete_description_translation'))) 
        try {
            const companyId = company._id;
            

const token = localStorage.getItem('token');
        await axios.delete(`/content/delete/${companyId}/description-translation/${translationId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
            

            const updatedTranslations = [...company.content.description.descriptionTranslations];
            updatedTranslations.splice(index, 1);
            setCompany({ ...company, content: { ...company.content, descriptionTranslations: updatedTranslations } });
        } catch (error) {
        }
    };



 return (
        <div className="content-container">
            {!editMode && !translateMode && (
                <DescriptionPage 
                    onToggleSubscription={onToggleSubscription}
                    descriptionText={company.content?.description?.descriptionText} 
                    translations={company.content?.description?.descriptionTranslations || []} 
                    onUpdate={handleDescriptionTextUpdate} 
                    onEditClick={handleEditClick}
                    onTranslateClick={handleTranslateClick} 
                    onEditTranslation={handleEditTranslation} 
                    onDeleteTranslation={handleDeleteTranslation} 
                />
            )}

            {editMode && (
                <>
                    <CompanyDescriptionFunction company={company} handleChange={handleChange} />
                    <CompanyContent existingCompany={company} onCompanyUpdate={onCompanyUpdate} isEditing={true} />
                </>
            )}

            {translateMode && (
                <>
                    <CompanyDescriptionTranslate 
                        company={company} 
                        handleChange={handleChange} 
                        handleTranslationSave={handleTranslationSave} 
                    />
                    <CompanyContent existingCompany={company} onCompanyUpdate={onCompanyUpdate} isTranslating={isTranslating} />
                </>
            )}
        </div>
    );
};

export default CompanyDescriptionPage;