import React, { useState, useEffect } from 'react';
import ProductCounter from './ProductCounter'; 
import BuzzBeatNews from './BuzzBeatNews'; 
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

function BuzzBeatProductPage() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/assets/images/backgrounds/image${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

return (
    <>
      <div className="buzzbeat-other-page" style={{ backgroundImage: `url(${backgroundImage})` }}></div>

      <div className="content-section-homepage">

        <div className="grid-layout">
        
  <div className="grid-content-item col-six center"><h1>{t('headline_product1')}</h1><h2>{t('sub_headline_product1')}</h2></div>
          <div className="grid-content-item col-six"><p>{t('text_product1')}<br></br><br></br></p></div>

   </div>
      </div>


      <div className="content-section-homepage">

        <div className="grid-layout">

  <div className="grid-content-item col-six center"><h1>{t('headline_product2')}</h1></div>


  <div className="grid-content-item col-six center"><h2>{t('sub_headline_product2')}</h2></div>

        
<div className="grid-content-item col-two center">
    <div className="screen-container">
        <div className="screenshot">
            <img src="../../assets/images/screenshots/create_corporatetext.png" alt="Screenshot"/>
        </div>
        <img src="../../assets/images/screen.png" alt="Screen" className="screen-image"/>
    </div>
</div>

<div className="grid-content-item col-two center">
    <div className="screen-container">
        <div className="screenshot">
            <img src="../../assets/images/screenshots/edit_content.png" alt="Screenshot"/>
        </div>
        <img src="../../assets/images/screen.png" alt="Screen" className="screen-image"/>
    </div>
</div>


<div className="grid-content-item col-two center">
    <div className="screen-container">
        <div className="screenshot">
            <img src="../../assets/images/screenshots/overview_list_blogposts.png" alt="Screenshot"/>
        </div>
        <img src="../../assets/images/screen.png" alt="Screen" className="screen-image"/>
    </div>
</div>



<div className="grid-content-item col-two center">
   <p>{t('create_content_product')}</p>
</div>

<div className="grid-content-item col-two center">
   <p>{t('collaborate_product')}</p>
</div>


<div className="grid-content-item col-two center">
   <p>{t('overview_content_product')}</p>
</div>

   </div>
      </div>


 

 <div className="content-section-homepage">

        <div className="grid-layout">

  <div className="grid-content-item col-six center"><h1>{t('collaborate_with_team')}</h1></div>


  <div className="grid-content-item col-six center"><h2>{t('collaborate_with_team_sub')}</h2></div>

        
<div className="grid-content-item col-two center">
    <div className="screen-container">
        <div className="screenshot">
            <img src="../../assets/images/screenshots/start_collaboration.png" alt="Screenshot"/>
        </div>
        <img src="../../assets/images/screen.png" alt="Screen" className="screen-image"/>
    </div>
</div>

<div className="grid-content-item col-two center">
    <div className="screen-container">
        <div className="screenshot">
            <img src="../../assets/images/screenshots/workon_collaborate.png" alt="Screenshot"/>
        </div>
        <img src="../../assets/images/screen.png" alt="Screen" className="screen-image"/>
    </div>
</div>


<div className="grid-content-item col-two center">
    <div className="screen-container">
        <div className="screenshot">
            <img src="../../assets/images/screenshots/manage_collaboration.png" alt="Screenshot"/>
        </div>
        <img src="../../assets/images/screen.png" alt="Screen" className="screen-image"/>
    </div>
</div>



<div className="grid-content-item col-two center">
   <p>{t('collaborate_feature1')}</p>
</div>

<div className="grid-content-item col-two center">
   <p>{t('collaborate_feature2')}</p>
</div>


<div className="grid-content-item col-two center">
   <p>{t('collaborate_feature3')}</p>
</div>

   </div>
      </div>



 <div className="content-section-homepage">

        <div className="grid-layout">

  <div className="grid-content-item col-six center"><h1>{t('approval_headline')}</h1></div>

  <div className="grid-content-item col-six center"><h2>{t('approval_subheadline')}</h2></div>

   
<div className="grid-content-item col-two center">
    <div className="screen-container">
        <div className="screenshot">
            <img src="../../assets/images/screenshots/create_approval.png" alt="Screenshot"/>
        </div>
        <img src="../../assets/images/screen.png" alt="Screen" className="screen-image"/>
    </div>
</div>

<div className="grid-content-item col-two center">
    <div className="screen-container">
        <div className="screenshot">
            <img src="../../assets/images/screenshots/review_apprvoals.png" alt="Screenshot"/>
        </div>
        <img src="../../assets/images/screen.png" alt="Screen" className="screen-image"/>
    </div>
</div>


<div className="grid-content-item col-two center">
    <div className="screen-container">
        <div className="screenshot">
            <img src="../../assets/images/screenshots/manage_approvals.png" alt="Screenshot"/>
        </div>
        <img src="../../assets/images/screen.png" alt="Screen" className="screen-image"/>
    </div>
</div>



<div className="grid-content-item col-two center">
   <p>{t('approval_feature1')}</p>
</div>

<div className="grid-content-item col-two center">
   <p>{t('approval_feature2')}</p>
</div>


<div className="grid-content-item col-two center">
   <p>{t('approval_feature3')}</p>
</div>

   </div>
      </div>




<div className="content-section-homepage">

        <div className="grid-layout">

  <div className="grid-content-item col-six center"><h1>{t('some_more_headline')}</h1><p><br></br></p></div>
   
<div className="grid-content-item col-two center">
    <div className="screen-container">
        <div className="screenshot">
            <img src="../../assets/images/screenshots/manage_overview.png" alt="Screenshot"/>
        </div>
        <img src="../../assets/images/screen.png" alt="Screen" className="screen-image"/>
    </div>
</div>

<div className="grid-content-item col-two center">
    <div className="screen-container">
        <div className="screenshot">
            <img src="../../assets/images/screenshots/translation.png" alt="Screenshot"/>
        </div>
        <img src="../../assets/images/screen.png" alt="Screen" className="screen-image"/>
    </div>
</div>


<div className="grid-content-item col-two center">
    <div className="screen-container">
        <div className="screenshot">
            <img src="../../assets/images/screenshots/create_description.png" alt="Screenshot"/>
        </div>
        <img src="../../assets/images/screen.png" alt="Screen" className="screen-image"/>
    </div>
</div>



<div className="grid-content-item col-two center">
   <p>{t('some_more_feature1')}</p>
</div>

<div className="grid-content-item col-two center">
   <p>{t('some_more_feature2')}</p>
</div>


<div className="grid-content-item col-two center">
   <p>{t('some_more_feature3')}</p>
</div>

   </div>
      </div>

 <div className="content-section-homepage">

  <div className="grid-content-item col-six center"><h1>{t('convinced')}</h1></div>
<div className="grid-content-item col-six center"><Link to="/register"><button className="action">{t('get_started_button')}</button></Link></div>

      </div>


     
    </>
  );
}

export default BuzzBeatProductPage;
