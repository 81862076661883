import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PressReleaseModal = ({ isOpen, onClose, pressRelease }) => {
    const [copySuccess, setCopySuccess] = useState('');
    const { t } = useTranslation();

    if (!isOpen) return null;

    const headline = pressRelease.headline;
    const content = pressRelease.pressReleaseText;
    const contact = pressRelease.contact;
    const date = pressRelease.date;

    const renderPressReleaseText = () => {
        const paragraphs = pressRelease.pressReleaseText.split('\n');
        return paragraphs.map((paragraph, index) => (
            <p key={index} className="press-release-text">{paragraph}<br></br></p>

        ));
    };

   
const copyToClipboard = text => {
    navigator.clipboard.writeText(text)
        .then(() => {
            setCopySuccess(t('copy_success'));
            setTimeout(() => setCopySuccess(''), 3000);
        })
        .catch(err => {
            setCopySuccess(t('copy_error'));
            setTimeout(() => setCopySuccess(''), 3000);
        });
};

    const handleCopyHeadline = () => copyToClipboard(headline);
    const handleCopyContent = () => copyToClipboard(content);
    const handleCopyContact = () => copyToClipboard(pressRelease.contact);
    const handleCopyDate = () => copyToClipboard(pressRelease.date);
    const handleCopyComplete = () => copyToClipboard(
        `Headline: ${headline}\n\n` +
        `Date: ${pressRelease.date}\n\n` +
        `Content:\n${content}\n\n` +
        `Contact: ${pressRelease.contact}`
    );



const dateObject = new Date(pressRelease.date);

    const dateOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZoneName: 'short',
        timeZone: 'Europe/Paris',
    };

    const formattedDate = dateObject.toLocaleString('de-DE', dateOptions);


    return (
        <div className="press-modal">
            <div className="modal-press-content">
                <div className="modal-button-bar">
            <button className="save" onClick={handleCopyHeadline}>{t('copy_headline')}</button>
                    <button className="save" onClick={handleCopyContent}>{t('copy_content')}</button>
                    <button className="save" onClick={handleCopyContact}>{t('copy_contact')}</button>
                    <button className="save" onClick={handleCopyDate}>{t('copy_date')}</button>
                    <button className="save" onClick={handleCopyComplete}>{t('copy_complete')}</button>
                    <button className="close-press-button" onClick={onClose}>&times;</button>
                </div>
                {copySuccess && <div className="copy-success-message">{copySuccess}</div>}

                <div className="modal-scrollable-content">
                    <div className="modal-header">
                        <h1>{pressRelease.headline}</h1>
                    </div>
                    <div className="modal-body">
 <p className="press-release-date">{formattedDate}</p>
                        <br />

                {renderPressReleaseText()}             
       </div>
                <div className="modal-footer">
                        <h1>Contact</h1>
                        <p className="press-release-contact">{pressRelease.contact}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PressReleaseModal;
