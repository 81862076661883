import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext'; 

const VisionPage = ({
    visionText, 
    translations = [], 
    onUpdate, 
    onEditClick, 
    onTranslateClick, 
    onEditTranslation, 
    onDeleteTranslation,
    onToggleSubscription 
}) => {
    const [editMode, setEditMode] = useState(false);
    const [editedText, setEditedText] = useState(visionText || '');
    const [translateMode, setTranslateMode] = useState(false);
    const [translatedText, setTranslatedText] = useState(visionText || '');
    const [showCopiedMessage, setShowCopiedMessage] = useState(false);
    const { t } = useTranslation();
    const { fetchWithAuth, subscriptionLevel } = useAuth();
    const freeAccess = ['free'].includes(subscriptionLevel);
    const starterAccess = ['starter'].includes(subscriptionLevel);
    const proAccess = ['pro'].includes(subscriptionLevel);
    const enterpriseAccess = ['enterprise'].includes(subscriptionLevel);

    const handleEdit = () => {
        setEditMode(true);
    };

    const handleTranslate = () => {
        setTranslateMode(true);
    };

    const handleSave = () => {
        onUpdate(editedText);
        setEditMode(false);
	setTranslateMode(false);
    };

    const handleChange = (e) => {
        setEditedText(e.target.value);
        setTranslatedText(e.target.value);
    };

    const handleCopyToClipboard = (text, index) => {
        navigator.clipboard.writeText(text).then(() => {
            setShowCopiedMessage(true);
            setTimeout(() => setShowCopiedMessage(false), 2000);
        });
    };

   return (
        <>
            

<div className="content-container-column col-two">

<div className="content-box col-two row-eight">
                <h1>{t('your_vision')}:</h1>
  <p><br></br></p>
  <p className="big">{visionText}</p>
</div>
</div>

<div className="content-container-column col-one">

<div className="content-button-box col-two row-one">
         <>
  {!visionText &&
<button type="submit" className="save" onClick={onEditClick}>{t('generate_button')}</button>
}

{visionText &&
<>
     <button type="submit" className="save" onClick={onEditClick}>{t('edit_button')}</button>
   {(proAccess || enterpriseAccess) && (
  <button type="submit" className="save" onClick={onTranslateClick}>{t('translate_button')}</button>
)}
   {(freeAccess || starterAccess) && (

 <button type="submit" className="save disabledButton" onClick={onToggleSubscription}>{t('translate_button')}</button>
)}

</>   

}
{showCopiedMessage && <span>Copied!</span>}
                   <button className="save" onClick={() => handleCopyToClipboard(visionText)}>
                       {t('copy')}
                    </button>

</>
</div>
   {(proAccess || enterpriseAccess) && translations.length > 0 && (

<div className="content-box col-two row-two">
                <div className="translations-section">
                    <div className="translation-header">
                      <div className="first-smaller">  <h2>{t('language')}</h2></div>
                       <div className="second-smaller"> <h2>{t('translation')}</h2></div>
			<div className="third-smaller"></div>
                    </div>
                    {translations.map((translation, index) => (
                        <div key={index} className="translation-row">
                           <div className="first-smaller"> <p>{translation.language}</p></div>
                            <div className="second-smaller shorten"><p>{translation.translation}</p></div>
                            <div className="third-smaller icons"><button type="img" onClick={() => handleCopyToClipboard(translation.translation, index)}>
                                <img src="../../assets/images/copy.png" alt={t('copy')} />
                            </button>
                            <button type="img" onClick={() => onDeleteTranslation(translation._id, index)}>
                                <img src="../../assets/images/delete.png" alt={t('delete')} />
                            </button>
</div>
                        </div>
                    ))}
                </div>
            </div>
)}
<div className="content-box col-two row-four"><h2>{t('good_to_know')}</h2><p className="small">{t('vision_intro_text')}</p>
</div>



</div>
        </>
    );
};

export default VisionPage;
