import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';
 
import ApprovalRequestOverview from './ApprovalRequestOverview';

import LoadingIndicator from '../LoadingIndicator';

const GiveApprovalsPage = ({ companyId }) => {
    const { fetchWithAuth } = useAuth();
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [openApprovals, setOpenApprovals] = useState([]);
    const [allApprovals, setAllApprovals] = useState([]);
    const [myApprovals, setMyApprovals] = useState([]);
    const [activeTab, setActiveTab] = useState('openApprovals');
    const [creatorApprovals, setCreatorApprovals] = useState([]); 
    const [activeApprovalId, setActiveApprovalId] = useState(null);
    const [approvalRequest, setApprovalRequest] = useState(null);
    const [approvals, setApprovals] = useState([]);
     const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();
    const navigate = useNavigate();

  useEffect(() => {

        fetchData();
    }, [companyId, activeTab, fetchWithAuth]);



    
    const fetchData = async () => {
const storedUserId = localStorage.getItem('userId');

  setIsLoading(true);

        try {
            let endpoint = `/approval/approvals/${companyId}?type=${activeTab}`;
            if (activeTab === 'user' || activeTab === 'creator') {
                endpoint += `&userId=${storedUserId}`;
            }

const response = await fetchWithAuth(endpoint)

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setApprovals(data);
    setIsLoading(false);

        } catch (error) {
            setError('Failed to load data. Please try again later.');
        }
    };



    const handleSendApprovalRequest = async (contentId) => {
        try {
            const response = await fetchWithAuth(`/approval/send-approval-notifications/${contentId}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            });
           if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setSuccessMessage(t('approval_sent_success'));
            setTimeout(() => {
                fetchData();
                setSuccessMessage('');
            }, 800);
        } catch (e) {
            setError(t('approval_sent_error'));
        }
    };

    const handleReminder = async (approverEmail, contentId) => {
        try {
            const response = await fetchWithAuth(`/approval/send-reminder/${contentId}`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ email: approverEmail })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setSuccessMessage(t('approval_reminder_success'));
            setTimeout(() => {
                fetchData();
                setSuccessMessage('');
            }, 800);
        } catch (e) {
            setError(t('approval_reminder_error'));
        }
    };

    const handleEditClick = (approvalRequest) => {
        navigate(`/app/app/approval/${approvalRequest.onModel}/${approvalRequest.contentId}`, { state: { companyId } });
    };

    const renderTabContent = () => {
        return approvals.map((approval, index) => (
            <ApprovalRequestOverview
                key={index}
                approvalRequest={approval}
		fetchData={fetchData}
                handleReminder={() => handleReminder(approval.email, approval.contentId)}
                handleSendApprovalRequest={() => handleSendApprovalRequest(approval.contentId)}
                isActive={activeApprovalId === approval._id}
                toggleActive={() => setActiveApprovalId(activeApprovalId === approval._id ? null : approval._id)}
                handleEditClick={() => handleEditClick(approval)}
            />
        ));
    };

if (isLoading) {
    return <div> <LoadingIndicator />
</div>;
  }

    return (
<div className="content-twocol-container">
       
<div className="content-box">
            <div className="tab-content">
<div className="table">
                        <div className="table-header">
                            <div className="header-approval"></div>
                            <div className="header-approval">{t('content')}</div>
                          <div className="header-approval">{t('type')}</div>
                            <div className="header-approval">{t('request_sent')}</div>
                            <div className="header-approval">{t('approvals')}</div> 
                            <div className="header-approval"></div>
                        </div>
</div>
                {renderTabContent()}
            </div>
        </div>


<div className="content-container-column">

<div className="content-button-box row-three">
            <div className="tab-controls">
                <button onClick={() => setActiveTab('open')} className={activeTab === 'open' ? 'active' : ''}>{t('open')}</button>
                <button onClick={() => setActiveTab('all')} className={activeTab === 'all' ? 'active' : ''}>{t('all')}</button>
                <button onClick={() => setActiveTab('user')} className={activeTab === 'user' ? 'active' : ''}>{t('to_approve')}</button>
                <button onClick={() => setActiveTab('creator')} className={activeTab === 'creator' ? 'active' : ''}>{t('my_approvals')}</button>
            </div>
</div>

{successMessage && <div className="content-box blue-background"> {successMessage}</div>}

</div>
</div>
    );
};

export default GiveApprovalsPage;
