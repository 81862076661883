import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import axios from '../axios.js';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';
 
const DashboardTeamMembers = ({ companyId }) => {
    const [users, setUsers] = useState([]);
     const { fetchWithAuth } = useAuth();

    const { t } = useTranslation();

    useEffect(() => {
        if (!companyId) {
            return;
        }
        fetchUsers();
    }, [companyId]);

   const fetchUsers = async () => {
        try {
            const response = await fetchWithAuth(`/companies/${companyId}/users`, {
                method: 'GET'
            });
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const data = await response.json();
            setUsers(data);
        } catch (error) {
        }
    };



      return (
        <div>
<div className="dashboard-content-top">
                <div>{t('your_team')}</div>
                <div><NavLink to="add-users">{t('manage_your_team')}</NavLink></div>
            </div>

            {users.map(user => (
                <div key={user._id}>
                     
                    	  <div className="dashboard-two">
                           	<div>{user.name} {user.surname}</div>
				<div>{user.email}</div>
			  </div>
	
                </div>
            ))}
       </div>
    );
};

export default DashboardTeamMembers;
