const GA_MEASUREMENT_ID = 'G-YRPMR9BVNS'; // Your Google Analytics Measurement ID

export const initializeGoogleAnalytics = (consent) => {
  if (consent) {
    window.dataLayer = window.dataLayer || []; 
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', GA_MEASUREMENT_ID);
  } else {
    window['ga-disable-' + GA_MEASUREMENT_ID] = true;
  }
};

