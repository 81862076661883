import axios from '../../axios.js';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../AuthContext'; 

const BlogpostList = ({ blogposts, handleEditBlogpost, users, onPressView, onToggleSubscription }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const { fetchWithAuth, subscriptionLevel } = useAuth();
  const [currentBlogpostIndex, setCurrentBlogpostIndex] = useState(null);
  const { t } = useTranslation();

const formatDate = (dateString) => {
    if (!dateString) return 'No Date Provided';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

const getComparable = (item, key, users) => {
 if (key === 'date' || key === 'creationDate') { // Assuming 'creationDate' is the correct property
    const date = new Date(item[key]);
    return isNaN(date.getTime()) ? 0 : date.getTime();
  }
  if (key === 'contact') {
    const user = users.find(u => u.email === item.contact);
    return user ? user.email : '';
  }
  return item[key];
};

const sortedBlogposts = React.useMemo(() => {
  let sortableItems = [...blogposts];
  if (sortConfig.key !== null) {
    sortableItems.sort((a, b) => {
      const aValue = getComparable(a, sortConfig.key, users);
      const bValue = getComparable(b, sortConfig.key, users);
      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }
  return sortableItems;
}, [blogposts, sortConfig, users]); 

  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '↑' : '↓';
    }
    return '↕'; 
  };

  const requestSort = (key) => {
    setSortConfig(currentSortConfig => {
      let direction = 'ascending';
      if (currentSortConfig.key === key && currentSortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      return { key, direction };
    });
  };

  useEffect(() => {
  }, [sortConfig, sortedBlogposts]);


 
  return (
   <>
                       <div className="dashboard-createdcontent-table">
 
  {sortedBlogposts && sortedBlogposts.map((blogpost, index) => (

<React.Fragment key={`blogpost-${blogpost._id}-${index}`}>

  <div className="dashboard-createdcontent-item">
            <div>{blogpost.headline || 'Untitled blogpost'}</div>
<div className="icons">  <button onClick={() => onPressView(blogpost._id)} title={t('view')}><img src="../../assets/images/view.png" alt={t('view')} /></button>
  <button onClick={() => handleEditBlogpost(blogpost._id)} title={t('edit')} ><img src="../../assets/images/edit.png" alt={t('edit')} /></button></div>
</div>

          </React.Fragment>
))}
</div>
</>
  );
};

export default BlogpostList;
