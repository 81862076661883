import React, { useState, useEffect } from 'react';
import CompanyMissionFunction from './companyMissionFunction';
import CompanyMissionTranslate from './companyMissionTranslate';
import CompanyContent from './companyContent';
import MissionPage from './missionPage';
import axios from '../axios.js';
import { useTranslation } from 'react-i18next';

const CompanyMissionPage = ({ existingCompany, setCompanyData, onToggleSubscription }) => {
    const [company, setCompany] = useState(existingCompany || {});
    const [editMode, setEditMode] = useState(!existingCompany?.content?.mission?.missionText);
    const [isTranslating, setIsTranslating] = useState(false);
    const [translateMode, setTranslateMode] = useState(false);
    const [isSaved, setIsSaved] = useState(!!existingCompany?.content?.mission?.missionText);
    const { t } = useTranslation();

    useEffect(() => {
        if (!company || Object.keys(company).length === 0) {
            fetchInitialCompanyData();
        }
    }, []);

    useEffect(() => {
        if (existingCompany) {
            setCompany(existingCompany);
            const hasMissionText = !!existingCompany?.content?.mission?.missionText;
            setIsSaved(hasMissionText);
            // Update editMode based on the presence of missionText
            setEditMode(!hasMissionText);
        }
    }, [existingCompany]);

    const fetchInitialCompanyData = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                return;
            }
            const response = await axios.get('/companies/get-company', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setCompany(response.data);
            const hasMissionText = !!response.data.content?.mission?.missionText;
            setIsSaved(hasMissionText);
            setEditMode(!hasMissionText);
        } catch (error) {
        }
    };

 const onCompanyUpdate = (updatedData) => {
    setCompanyData(updatedData);
    setIsSaved(true);
    setEditMode(false);
setTranslateMode(false);
  };

    const handleChange = (event) => {
        const { name, value } = event.target;
        let updatedCompany = { ...company };
        const keys = name.split('.');
        let currentLevel = updatedCompany;
        for (let i = 0; i < keys.length - 1; i++) {
            if (!currentLevel[keys[i]]) {
                currentLevel[keys[i]] = {};
            }
            currentLevel = currentLevel[keys[i]];
        }
        currentLevel[keys[keys.length - 1]] = value;
        setCompany(updatedCompany);
    };


const handleMissionTextUpdate = (newText) => {
    const userId = localStorage.getItem('userId');

    if (!userId) {
        return;
    }

    setCompany(prevCompany => ({
        ...prevCompany,
        content: {
            ...prevCompany.content,
            mission: {
                ...prevCompany.content.mission,
                missionText: newText,
                creator: userId
            }
        }
    }));

    setIsSaved(true);
    setEditMode(false);
    setTranslateMode(false);
};



const handleTranslationSave = (language, translatedText) => {
    const updatedCompany = { ...company };
    updatedCompany.content.mission.missionTranslations = updatedCompany.content.mission.missionTranslations || [];
    const translationIndex = updatedCompany.content.mission.missionTranslations.findIndex(t => t.language === language);
    if (translationIndex > -1) {
        updatedCompany.content.mission.missionTranslations[translationIndex].translation = translatedText;
    } else {
        updatedCompany.content.mission.missionTranslations.push({ language, translation: translatedText });
    }
    setCompany(updatedCompany);
};


const handleEditClick = () => {
    setEditMode(true);
    setIsSaved(false);
    setTranslateMode(false);
};

const handleTranslateClick = () => {
    setTranslateMode(true);
    setIsSaved(false);
setEditMode(false);
};

const handleEditTranslation = (translation, index, newTranslationText) => {
    const updatedCompany = { ...company };
    if (updatedCompany.content && updatedCompany.content.mission.missionTranslations) {
        if (index >= 0 && index < updatedCompany.content.mission.missionTranslations.length) {
            updatedCompany.content.mission.missionTranslations[index].translation = newTranslationText;
        }
    }
    setCompany(updatedCompany);
};


    const handleDeleteTranslation = async (translationId, index) => {
 if (window.confirm(t('confirm_delete_mission_translation'))) 
        try {
            const companyId = company._id;
            

const token = localStorage.getItem('token');
        await axios.delete(`/content/delete/${companyId}/mission-translation/${translationId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
            

            const updatedTranslations = [...company.content.mission.missionTranslations];
            updatedTranslations.splice(index, 1);
            setCompany({ ...company, content: { ...company.content, missionTranslations: updatedTranslations } });
        } catch (error) {
        }
    };



return (
    <div className="content-container">
        {!editMode && !translateMode && (
            <MissionPage 
                onToggleSubscription={onToggleSubscription}
                missionText={company.content?.mission?.missionText} 
                translations={company.content?.mission?.missionTranslations || []} 
                onUpdate={handleMissionTextUpdate} 
                onEditClick={handleEditClick}
                onTranslateClick={handleTranslateClick} 
                onEditTranslation={handleEditTranslation} 
                onDeleteTranslation={handleDeleteTranslation} 
            />
        )}

        {editMode && (
            <>
                <CompanyMissionFunction company={company} handleChange={handleChange} />
                <CompanyContent existingCompany={company} onCompanyUpdate={onCompanyUpdate} isEditing={editMode} />
            </>
        )}

        {translateMode && (
            <>
                <CompanyMissionTranslate 
                    company={company} 
                    handleChange={handleChange} 
                    handleTranslationSave={handleTranslationSave} 
                />
                <CompanyContent existingCompany={company} onCompanyUpdate={onCompanyUpdate} isTranslating={isTranslating} />
            </>
        )}
    </div>
);
};

export default CompanyMissionPage;