import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Text } from 'recharts';

const Gauge = ({ value, total }) => {
  const data = [
    { name: 'Remaining', value: total - value },
{ name: 'Open', value: value },
      ];
 
  const COLORS = ['#0028ff', '#FFA500'];

  const score = total > 0 ? Math.round(((total - value) / total) * 100) : 0;

  return (
    <ResponsiveContainer width="100%" height={150}>
      <PieChart
 margin={{ top: -130, right: 0, bottom: -250, left: 0 }} >
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          startAngle={180}
          endAngle={0}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
          labelLine={false}
          label={({ cx, cy }) => (
            <Text x={cx} y={cy} fill="#000" textAnchor="middle" dominantBaseline="central">
              {`${score}/100`}
            </Text>
          )}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default Gauge;
