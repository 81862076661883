import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext'; 
import axios from '../axios.js';
 
import NewUserForm from './NewUserForm';
import UserManagementPage from './UserManagementPage';
import { useTranslation } from 'react-i18next';

const CompanyForm = ({ existingCompany }) => {
    const initialState = {
        contactPersons: []
    };
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [company, setCompany] = useState(initialState);
    const [activeTab, setActiveTab] = useState('userList');
    const [thisCompanyId, setThisCompanyId] = useState('');
    const { t } = useTranslation();
    const { fetchWithAuth } = useAuth();

    useEffect(() => {
        const storedCompanyId = localStorage.getItem('buzzBeatCompanyId');
        if (storedCompanyId) {
            setThisCompanyId(storedCompanyId);
        } else {
        }
    }, []);

    useEffect(() => {
        if (!existingCompany) {
            fetchCompanyData();
        } else {
            setCompany(existingCompany);
        }
    }, [existingCompany]);

    const fetchCompanyData = async () => {
        try {
            const response = await fetchWithAuth('/companies/get-company', {
                method: 'GET'
            });
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const data = await response.json();
            setCompany(data);
        } catch (error) {
        }
    };

const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split('.');

    if (keys.length > 1) {
        const [mainKey, subKey] = keys;
        setCompany(prevState => ({
            ...prevState,
            [mainKey]: {
                ...prevState[mainKey],
                [subKey]: value
            }
        }));
    } else {
        setCompany(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
};

    const handleContactPersonChange = (index, e) => {
        const updatedContactPersons = [...company.contactPersons];
        updatedContactPersons[index] = {
            ...updatedContactPersons[index],
            [e.target.name]: e.target.value
        };
        setCompany({ ...company, contactPersons: updatedContactPersons });
    };

 const addContactPerson = () => {
        setCompany({
            ...company,
            contactPersons: [
                ...company.contactPersons,
                { name: '', surname: '', email: '', role: '', phoneNumber: '' }
            ]
        });
    };

const renderTabContent = () => {
    switch (activeTab) {
        case 'userList':
            return thisCompanyId ? <UserManagementPage companyId={thisCompanyId} onSuccess={handleSuccessMessage} onError={handleErrorMessage} /> : <div>{t('loading')}</div>;
        case 'addUser':
            return thisCompanyId ? <NewUserForm companyId={thisCompanyId} onSuccess={handleSuccessMessage} onError={handleErrorMessage} /> : <div>{t('loading')}</div>;
        default:
            return <div>Invalid tab</div>;
    }
};



    const hasBulletsContent = company?.description?.descriptionBullets;

    useEffect(() => {
        let successTimeout = null;
        if (successMessage) {
            successTimeout = setTimeout(() => {
                setSuccessMessage('');
            }, 2000);
        }

        return () => {
            if (successTimeout) {
                clearTimeout(successTimeout);
            }
        };
    }, [successMessage]);

    useEffect(() => {
        let errorTimeout = null;
        if (errorMessage) {
            errorTimeout = setTimeout(() => {
                setErrorMessage('');
            }, 2000);
        }

        return () => {
            if (errorTimeout) {
                clearTimeout(errorTimeout);
            }
        };
    }, [errorMessage]);

  const handleSuccessMessage = (message) => {
        setSuccessMessage(message);
    };

    const handleErrorMessage = (message) => {
        setErrorMessage(message);
    };


   return (
     <div class="content-twocol-container">
             <div className="content-box">
{renderTabContent()}</div>
      


<div className="content-container-column">


<div className="content-box row-two">

 <div className="tab-controls">

 <button onClick={() => setActiveTab('userList')} className={activeTab === 'userList' ? 'active' : ''}>{t('user_list_button')}</button>
 <button onClick={() => setActiveTab('addUser')} className={activeTab === 'addUser' ? 'active' : ''}>{t('add_user_button')}</button>

</div>
</div>

<div className="content-box row-two">
  {successMessage && <div className="success-message">{successMessage}</div>}
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
             </div>


         
            </div>


</div>
    );
};

export default CompanyForm;