import React, { useState, useEffect } from 'react';
import CompanyVisionFunction from './companyVisionFunction';
import CompanyVisionTranslate from './companyVisionTranslate';
import CompanyContent from './companyContent';
import VisionPage from './visionPage';
import axios from '../axios.js';
import { useTranslation } from 'react-i18next';

const CompanyVisionPage = ({ existingCompany, setCompanyData, onToggleSubscription }) => {
    const [company, setCompany] = useState(existingCompany || {});
    // Initialize editMode based on the existing vision text
    const [editMode, setEditMode] = useState(!existingCompany?.content?.vision?.visionText);
    const [isTranslating, setIsTranslating] = useState(false);
    const [translateMode, setTranslateMode] = useState(false);
    const [isSaved, setIsSaved] = useState(!!existingCompany?.content?.vision?.visionText);
    const { t } = useTranslation();

    useEffect(() => {
        if (!company || Object.keys(company).length === 0) {
            fetchInitialCompanyData();
        }
    }, []);

    useEffect(() => {
        if (existingCompany) {
            setCompany(existingCompany);
            const hasVisionText = !!existingCompany?.content?.vision?.visionText;
            setIsSaved(hasVisionText);
            setEditMode(!hasVisionText);
        }
    }, [existingCompany]);

    const fetchInitialCompanyData = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                return;
            }
            const response = await axios.get('/companies/get-company', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setCompany(response.data);
            const hasVisionText = !!response.data.content?.vision?.visionText;
            setIsSaved(hasVisionText);
            setEditMode(!hasVisionText);
        } catch (error) {
        }
    };

 const onCompanyUpdate = (updatedData) => {
    setCompanyData(updatedData);
    setIsSaved(true);
    setEditMode(false);
setTranslateMode(false);
  };

    const handleChange = (event) => {
        const { name, value } = event.target;
        let updatedCompany = { ...company };
        const keys = name.split('.');
        let currentLevel = updatedCompany;
        for (let i = 0; i < keys.length - 1; i++) {
            if (!currentLevel[keys[i]]) {
                currentLevel[keys[i]] = {};
            }
            currentLevel = currentLevel[keys[i]];
        }
        currentLevel[keys[keys.length - 1]] = value;
        setCompany(updatedCompany);
    };


const handleVisionTextUpdate = (newText) => {
    const userId = localStorage.getItem('userId');

    if (!userId) {
        return;
    }

    setCompany(prevCompany => ({
        ...prevCompany,
        content: {
            ...prevCompany.content,
            vision: {
                ...prevCompany.content.vision,
                visionText: newText,
                creator: userId
            }
        }
    }));

    setIsSaved(true);
    setEditMode(false);
    setTranslateMode(false);
};



const handleTranslationSave = (language, translatedText) => {
    const updatedCompany = { ...company };
    updatedCompany.content.vision.visionTranslations = updatedCompany.content.vision.visionTranslations || [];
    const translationIndex = updatedCompany.content.vision.visionTranslations.findIndex(t => t.language === language);
    if (translationIndex > -1) {
        updatedCompany.content.vision.visionTranslations[translationIndex].translation = translatedText;
    } else {
        updatedCompany.content.vision.visionTranslations.push({ language, translation: translatedText });
    }
    setCompany(updatedCompany);
};


const handleEditClick = () => {
    setEditMode(true);
    setIsSaved(false);
    setTranslateMode(false);
};

const handleTranslateClick = () => {
    setTranslateMode(true);
    setIsSaved(false);
setEditMode(false);
};

const handleEditTranslation = (translation, index, newTranslationText) => {
    const updatedCompany = { ...company };
    if (updatedCompany.content && updatedCompany.content.vision.visionTranslations) {
        if (index >= 0 && index < updatedCompany.content.vision.visionTranslations.length) {
            updatedCompany.content.vision.visionTranslations[index].translation = newTranslationText;
        }
    }
    setCompany(updatedCompany);
};


    const handleDeleteTranslation = async (translationId, index) => {
 if (window.confirm(t('confirm_delete_vision_translation'))) 
        try {
            const companyId = company._id;
            

const token = localStorage.getItem('token');
        await axios.delete(`/content/delete/${companyId}/vision-translation/${translationId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
            

            const updatedTranslations = [...company.content.vision.visionTranslations];
            updatedTranslations.splice(index, 1);
            setCompany({ ...company, content: { ...company.content, visionTranslations: updatedTranslations } });
        } catch (error) {
        }
    };



  return (
        <div className="content-container">
            {!editMode && !translateMode && (
                <VisionPage 
                    onToggleSubscription={onToggleSubscription}
                    visionText={company.content?.vision?.visionText} 
                    translations={company.content?.vision?.visionTranslations || []} 
                    onUpdate={handleVisionTextUpdate} 
                    onEditClick={handleEditClick}
                    onTranslateClick={handleTranslateClick} 
                    onEditTranslation={handleEditTranslation} 
                    onDeleteTranslation={handleDeleteTranslation} 
                />
            )}

            {editMode && (
                <>
                    <CompanyVisionFunction company={company} handleChange={handleChange} />
                    <CompanyContent existingCompany={company} onCompanyUpdate={onCompanyUpdate} />
                </>
            )}

            {translateMode && (
                <>
                    <CompanyVisionTranslate 
                        company={company} 
                        handleChange={handleChange} 
                        handleTranslationSave={handleTranslationSave} 
                    />
                    <CompanyContent existingCompany={company} onCompanyUpdate={onCompanyUpdate} isTranslating={isTranslating} />
                </>
            )}
        </div>
    );
};

export default CompanyVisionPage;