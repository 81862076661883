import React, { useState, useEffect } from 'react';
import axios from '../axios.js';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
 
const PaymentSuccessPage = () => {
    const { t } = useTranslation();
 
    return (
        <div>
            <h2>{t('payment_successful')}</h2>
            <p>{t('payment_successful_setup')}</p>
        </div>
    );
};

export default PaymentSuccessPage;
