import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';

const CollaborationRequestComments = ({ collaborationRequest, handleReminder, handleSendCollaborationRequest, handleEditClick }) => {
    const [currentUserId, setCurrentUserId] = useState('');
   const { t } = useTranslation();
    const { fetchWithAuth } = useAuth();
 
  useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        setCurrentUserId(storedUserId);
    }, []);


    if (!collaborationRequest) {
        return <div></div>;
    }



    return (
        <div>
<h3>{t('comments')}</h3>
<p><br></br></p>
{collaborationRequest.comments.map((comment, index) => (
 <div key={index}>
            <div className="table-comments">
                                                 
  <div className="table-row-comments">{comment.comment}</div>
  <div className="table-row-comments">{comment.commenterEmail || (comment.commenterId && comment.commenterId.name)}</div>
  <div className="table-row-comments">Posted on {new Date(comment.date).toLocaleDateString()}</div>
</div>
</div>
 ))}

                
        </div>
    );
};

export default CollaborationRequestComments;
