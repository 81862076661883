import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ProductCounter from './ProductCounter'; 
import BuzzBeatNews from './BuzzBeatNews'; 

function BuzzBeatContactPage() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { t } = useTranslation();

 const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = { name, email, message };

    try {
      const response = await fetch('/public/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (data.success) {
        alert(t('message_success'));

      } else {
        alert('Failed to send message. Please try again.');
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }
  };


  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/assets/images/backgrounds/image${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);




  return (
    <>
      <div className="buzzbeat-other-page" style={{ backgroundImage: `url(${backgroundImage})` }}></div>



      <div className="content-section-homepage">

        <div className="grid-layout">
        
  <div className="grid-content-item col-six center"><h1>{t('get_in_touch')}</h1>
          <h2>{t('contact_subhead1')}<br></br>{t('contact_subhead2')}
<br></br><br></br></h2>
</div>
  <div className="grid-content-item col-six">
<p><form onSubmit={handleSubmit}>
        <label>
          <input type="text" value={name} placeholder={t('name')} onChange={(e) => setName(e.target.value)} required />
        </label>
        <label>
          <input type="email" value={email} placeholder={t('email')} onChange={(e) => setEmail(e.target.value)} required />
        </label>
        <label>
          
          <textarea value={message} placeholder={t('message')} onChange={(e) => setMessage(e.target.value)} required />
        </label>
        <button type="submit" className="save">{t('send_message_button')}</button>
      </form>


</p></div>

   </div>
      </div>


      


     
    </>
  );
}

export default BuzzBeatContactPage;
