import React, { useEffect, useState, useCallback } from 'react';
import '../../Spinner.css';
import { useTranslation } from 'react-i18next';
import axios from '../../axios.js';
import { useHistory } from 'react-router-dom';
 
const CompanyArticleTranslate = ({ existingCompany, articleId, users, formatDate, onCancel }) => {
    const [articles, setArticles] = useState([]);
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState(existingCompany || { content: { articles: [articleId] } });
    const [articleText, setArticleText] = useState('');
    const [headline, setHeadline] = useState('');
    const [textTranslation, setTextTranslation] = useState('');
    const [headlineTranslation, setHeadlineTranslation] = useState('');
    const [translationLanguageSelected, setTranslationLanguageSelected] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

useEffect(() => {
        fetchCompanyData();
    }, []);

const fetchCompanyData = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }
const response = await axios.get('/companies/get-company', {
            headers: { Authorization: `Bearer ${token}` }
        });

       
        const articlesResponse = await axios.get('/content/articles/get-for-company', {
            headers: { Authorization: `Bearer ${token}` }
        });

        const filteredArticles = articlesResponse.data.filter(article => article._id === articleId);

           if (filteredArticles.length > 0) {
                setArticleText(filteredArticles[0].articleText || '');
                setHeadline(filteredArticles[0].headline || '');
        }

        setCompany(response.data);
        setArticles(filteredArticles);
       
    } catch (error) {
    }
};


const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }

        let updatedCompany = {...company};

        // Identify the article we are working with
        const articleIndex = updatedCompany.content.articles.findIndex(pr => pr._id === articleId);

        if (articleIndex !== -1) {
            let article = updatedCompany.content.articles[articleIndex];

            const translationIndex = article.articleTranslations.findIndex(translation => translation.language === textTranslation);

            if (translationIndex !== -1) {
                article.articleTranslations[translationIndex] = {
                    ...article.articleTranslations[translationIndex],
                    translatedArticleHeadline: headlineTranslation,
                    translatedArticleText: textTranslation
                };
            } else {
                article.articleTranslations.push({
                    language: translationLanguageSelected,
                    translatedArticleHeadline: headlineTranslation,
                    translatedArticleText: textTranslation
                });
            }

            // Update the article in the array
            updatedCompany.content.articles[articleIndex] = article;
        } else {
            // Handle case for adding a new article if needed
        }

        // Perform the update operation with Axios
        const response = await axios.put(`/companies/${updatedCompany._id}`, updatedCompany, {
            headers: { Authorization: `Bearer ${token}` }
        });

        setCompany(response.data);
        setSuccessMessage('Saved successfully.');
        setTimeout(() => {
            setSuccessMessage('');
        }, 2000);
        fetchCompanyData();
    } catch (error) {
    }
};


 const processArticleTranslation = useCallback(async () => {
        if (isLoading) return;
        setIsLoading(true);

        setTranslationLanguageSelected(textTranslation);

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setIsLoading(false);
                return;
            }

            const requestBody = {
                headline: headline,
                text: articleText,
                targetLanguage: textTranslation
            };

            const response = await axios.post('/content/translate-articles', requestBody, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.data) {
                setHeadlineTranslation(response.data.translatedHeadline || '');
                setTextTranslation(response.data.translatedText || '');
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }, [isLoading, textTranslation, headlineTranslation, company?.content, setHeadlineTranslation, setTextTranslation, setTranslationLanguageSelected]);



  return (

<>
 {isLoading && <div className="spinner-overlay"><div className="spinner"></div></div>}

<div className="content-container">

<div className="content-container-column col-two">

<div className="content-box col-two row-eight">

               <div className="inner-content-container">


{headlineTranslation && (
         <>
 <div className="inner-content-box col-five row-one">
                <textarea rows={16} className="content-box-main-headline"
 name="textTranslation" value={headlineTranslation} onChange={e => setHeadlineTranslation(e.target.value)} />
   </div>

 <div className="inner-content-box col-five row-eleven">
 <textarea placeholder={t('translate_instructions')}
                rows={16} className="content-box-main-textarea"
 name="textTranslation" value={textTranslation} onChange={e => setTextTranslation(e.target.value)} />
   </div>
</>
)}

{!headlineTranslation && (
         <>
 <div className="inner-content-box col-five row-twelve">
 <textarea placeholder={t('translate_instructions')}
                rows={16} className="content-box-main-textarea"
 name="textTranslation" value={textTranslation} onChange={e => setTextTranslation(e.target.value)} />
   </div>
</>
)}


<div className="inner-content-box">
<button className="editor-button" onClick={processArticleTranslation} disabled={isLoading}>
                        {t('translate_button')}
                    </button>
</div>
        </div>
       </div>
</div>
{successMessage && <div className="content-box blue-background"> {successMessage}</div>}


{!successMessage && (
<div className="content-container-column col-one">
 <div className="content-button-box row-one">
                    <button className="save" onClick={handleSubmit}>{t('save_button')}</button>
                    <button className="save" onClick={onCancel}>{t('cancel_button')}</button>
                </div>
 


    <div className="content-box row-six">
<h2>{t('original')}</h2>
	<h2>{headline}</h2>
	<p className="small">{articleText}</p>
</div>
</div>
)}
            


</div>
</>
    );
};

export default CompanyArticleTranslate;
