import React, { useState, useEffect } from 'react';
import axios from '../axios.js';
import { useTranslation } from 'react-i18next';

const BuzzBeatSubscriptionPage = ({ companyId }) => {
    const [plans, setPlans] = useState([]);
    const [isYearly, setIsYearly] = useState(true);
    const [selectedCurrency, setSelectedCurrency] = useState('EUR');
    const [currencies, setCurrencies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { t } = useTranslation();

    const planValue = {
        free: 0,
        starter: 1,
        pro: 2,
        enterprise: 3
    };

    const fetchPlanDetails = async () => {
        try {
            setLoading(true);
            const response = await axios.get('/subscription/plan-details');
            setPlans(response.data);

            const allCurrencies = response.data.flatMap(plan => 
                plan.prices.map(price => price.currency_code)
            );
            const uniqueCurrencies = Array.from(new Set(allCurrencies));
            setCurrencies(uniqueCurrencies);

            if(uniqueCurrencies.length > 0 && !selectedCurrency) {
                setSelectedCurrency(uniqueCurrencies[0]);
            }

            setLoading(false);
        } catch (err) {
            setError(err.response?.data || 'Error fetching plans');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPlanDetails();
    }, [companyId]);

    const getPrice = (plan) => {
        const billingCycle = isYearly ? 'year' : 'month'; 
        const priceInfo = plan.prices.find(price => 
            price.period_unit.startsWith(billingCycle) && price.currency_code === selectedCurrency
        );
        return priceInfo ? `${priceInfo.price / 100} ${priceInfo.currency_code}` : t('na');
    };

    const extractPlanName = (planId) => {
        const planNameRegex = /BuzzBeat-(.*?)(?:-|$)/;
        const match = planId.match(planNameRegex);
        return match && match[1] ? match[1].toLowerCase() : null;
    };

    const sortPlans = (a, b) => {
        const planNameA = extractPlanName(a.id);
        const planNameB = extractPlanName(b.id);

        if (!planNameA || !planNameB) {
            return !planNameA ? 1 : -1;
        }

        const aValue = planValue[planNameA];
        const bValue = planValue[planNameB];

        return aValue - bValue;
    };

    return (
        <>
            <div className="toggle-container">
                <span className={isYearly ? 'inactive' : 'active'} onClick={() => setIsYearly(false)}>{t('monthly')}</span>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={isYearly}
                        onChange={() => setIsYearly(!isYearly)}
                    />
                    <span className="slider round"></span>
                </label>
                <span className={isYearly ? 'active' : 'inactive'} onClick={() => setIsYearly(true)}>{t('yearly')}</span>
            </div>

            {currencies.length > 0 && (
                <div>
                    <select
                        id="currency-selector"
                        value={selectedCurrency}
                        onChange={(e) => setSelectedCurrency(e.target.value)}
                    >
                        {currencies.map((currency) => (
                            <option key={currency} value={currency}>
                                {currency}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {loading && <p>{t('loading')}</p>}
            {error && <p className="error">{error}</p>}
            {!loading && !error && (
                <div className="plans-container">
                    {plans
                        .filter(plan => extractPlanName(plan.id) !== 'free')
                        .sort(sortPlans)
                        .map(plan => (
                            <div key={plan.id} className="plan">
                                <h2>{plan.name}</h2>
                                <p>{t('price')}: {getPrice(plan)}</p>
                            </div>
                        ))}
                </div>
            )}
        </>
    );
};

export default BuzzBeatSubscriptionPage;
