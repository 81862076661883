import React, { useEffect, useState, useCallback } from 'react';
import '../../Spinner.css';
import { useTranslation } from 'react-i18next';
import axios from '../../axios.js';
import { useHistory } from 'react-router-dom';

const CompanyDashboardBlogpostFunction = ({ existingCompany, articleId, users, formatDate, onCancel }) => {
    const [articles, setArticles] = useState([]);
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState(existingCompany || { content: { articles: [articleId] } });
    const [articleBullets, setBlogpostBullets] = useState('');
    const [articleText, setBlogpostText] = useState('');
    const [articleQuotes, setBlogpostQuotes] = useState('');
    const [articleFacts, setBlogpostFacts] = useState('');
    const [headline, setHeadline] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

 useEffect(() => {
        fetchCompanyData();
    }, []);

const fetchCompanyData = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }
        const response = await axios.get('/companies/get-company', {
            headers: { Authorization: `Bearer ${token}` }
        });
        const articlesResponse = await axios.get('/content/articles/get-for-company', {
            headers: { Authorization: `Bearer ${token}` }
        });

        const filteredArticles = articlesResponse.data.filter(article => article._id === articleId);

           if (filteredArticles.length > 0) {
          setBlogpostBullets(filteredArticles[0].articleBullets || '');
                setBlogpostText(filteredArticles[0].articleText || '');
                setBlogpostQuotes(filteredArticles[0].articleQuotes || '');
                setBlogpostFacts(filteredArticles[0].articleFacts || '');
                setHeadline(filteredArticles[0].headline || '');

        }

        setCompany(response.data);
        setArticles(filteredArticles);
   

    } catch (error) {
    }
};


const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }

        let updatedCompany = {...company};

        // Check if articleId is null to determine if we are adding a new article or updating an existing one
        if (!articleId) {
            const userId = localStorage.getItem('userId');
            const newBlogpost = {
                articleBullets,
                articleText,
                articleQuotes,
                articleFacts,
                headline,
                articleTranslations: [],
                creator: userId,
                creationDate: new Date()
            };

            updatedCompany.content.articles = [...(updatedCompany.content?.articles || []), newBlogpost];

        } else {

            const articleIndex = updatedCompany.content.articles.findIndex(pr => pr._id === articleId);
            if (articleIndex !== -1) {
                updatedCompany.content.articles[articleIndex] = {
                    ...updatedCompany.content.articles[articleIndex],
                    articleBullets,
                    articleText,
                    articleQuotes,
                    articleFacts,
			headline,
                };
            }
        }

        const response = await axios.put(`/companies/${updatedCompany._id}`, updatedCompany, {
            headers: { Authorization: `Bearer ${token}` }
        });

        setCompany(response.data);

        setSuccessMessage('Saved successfully.');
setTimeout(() => {
            setSuccessMessage('');
        }, 2000);
        fetchCompanyData();
    } catch (error) {
    }
};




  return (
<>


<textarea className="content-box-main-headline" name="headline" value={headline} onChange={(e) => setHeadline(e.target.value)} placeholder="article Headline" rows="3" cols="50" />
<textarea className="content-box-main-textarea" value={articleText} onChange={(e) => setBlogpostText(e.target.value)} placeholder="article Text" rows="10" cols="50" />


       


 <div className="content-box three-one">
                    <button className="save" onClick={handleSubmit}>{t('save_button')}</button>
                    <button className="save" onClick={onCancel}>{t('cancel_button')}</button>
                </div>
 
{successMessage && <div className="content-box blue-background"> {successMessage}</div>}




</>
    );
};

export default CompanyDashboardBlogpostFunction;
