import React, { useState, useEffect } from 'react';
import axios from '../axios.js';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ForgotPasswordModal from './ForgotPasswordModal';
import ResendConfirmationModal from './ResendConfirmationModal';

const Login = ({ setIsLoggedIn, setCompanyData }) => {
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
   const [showResendModal, setShowResendModal] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');
   const navigate = useNavigate();
   const { t } = useTranslation();
   const [backgroundImage, setBackgroundImage] = useState('');
   const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/assets/images/backgrounds/image${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

const handleLogin = async (event) => {
    event.preventDefault();
    try {
        const response = await axios.post('/registerlogin/login', { email, password });
        if (response.data && response.data.token) {
            // Storing token, user ID, and email in localStorage
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('userId', response.data.user.id);
            localStorage.setItem('userEmail', email);

            setIsLoggedIn(true);
            setCompanyData(response.data.company);

            const companyId = response.data.company?._id;
            if (companyId) {
                localStorage.setItem('buzzBeatCompanyId', companyId);
                navigate('/app/app');
            } else {
                setErrorMessage(t('company_id_not_found'));
            }
        } else {
            setErrorMessage(t('login_failed_no_data'));
        }
    } catch (error) {
        if (error.response) {
            setErrorMessage(error.response.data.msg || t('login_failed'));
        } else {
            setErrorMessage(t('login_failed'));
        }
    }
};





  const handleOpenForgotPasswordModal = () => {
    setShowForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setShowForgotPasswordModal(false);
  };

  const handleOpenResendModal = () => {
    setShowResendModal(true);
  };

  const handleCloseResendModal = () => {
    setShowResendModal(false);
  };

    return (
<>
      <div className="buzzbeat-starting-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="overlay-content" style={{ top: `${offsetY * 0.3}px` }}>


    <div className="login-container">
      <div className="login-form">
        <h1>{t('login')}</h1>
               {errorMessage && <><br></br><p className="error-message">{errorMessage}</p></>}
<br></br>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder={t('your_email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder={t('password')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="save">{t('login_button')}</button>
        </form>
        <div className="additional-options">
          <button onClick={handleOpenForgotPasswordModal}>{t('forgot_password')}</button>
          <button onClick={handleOpenResendModal}>{t('resend_confirmation_email')}</button>
        </div>
        {showResendModal && <ResendConfirmationModal onClose={() => setShowResendModal(false)} />}
        {showForgotPasswordModal && <ForgotPasswordModal onClose={() => setShowForgotPasswordModal(false)} />}
      </div>
    </div>
        </div>
      </div>


      

</>
    );
};

export default Login;
