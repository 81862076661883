import React, { useEffect, useState, useCallback } from 'react';
import '../../Spinner.css';
import { useTranslation } from 'react-i18next';
import axios from '../../axios.js';
import { useHistory } from 'react-router-dom';

const CompanyCorporatetextTranslate = ({ existingCompany, corporatetextId, users, formatDate, onCancel }) => {

    const [corporatetexts, setCorporatetexts] = useState([]);
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState(existingCompany || { content: { corporatetexts: [corporatetextId] } });
    const [corporatetextText, setCorporatetextText] = useState('');
    const [headline, setHeadline] = useState('');
    const [textTranslation, setTextTranslation] = useState('');
    const [headlineTranslation, setHeadlineTranslation] = useState('');
    const [translationLanguageSelected, setTranslationLanguageSelected] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

 useEffect(() => {
        fetchCompanyData();
    }, []);

const fetchCompanyData = async () => {
    try {
        const token = localStorage.getItem('token');

        if (!token) {
            return;
        }
const response = await axios.get('/companies/get-company', {
            headers: { Authorization: `Bearer ${token}` }
        });

       
        const corporatetextsResponse = await axios.get('/content/corporatetexts/get-for-company', {
            headers: { Authorization: `Bearer ${token}` }
        });

        const filteredCorporatetexts = corporatetextsResponse.data.filter(corporatetext => corporatetext._id === corporatetextId);

           if (filteredCorporatetexts.length > 0) {
                setCorporatetextText(filteredCorporatetexts[0].corporatetextText || '');
                setHeadline(filteredCorporatetexts[0].headline || '');
     }

        setCompany(response.data);
        setCorporatetexts(filteredCorporatetexts);

    } catch (error) {
    }
};


const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }

        let updatedCompany = {...company};

        const corporatetextIndex = updatedCompany.content.corporatetexts.findIndex(pr => pr._id === corporatetextId);

        if (corporatetextIndex !== -1) {
            let corporatetext = updatedCompany.content.corporatetexts[corporatetextIndex];

            const translationIndex = corporatetext.corporatetextTranslations.findIndex(translation => translation.language === textTranslation);

            if (translationIndex !== -1) {
                corporatetext.corporatetextTranslations[translationIndex] = {
                    ...corporatetext.corporatetextTranslations[translationIndex],
                    translatedCorporatetextHeadline: headlineTranslation,
                    translatedCorporatetextText: textTranslation
                };
            } else {
                corporatetext.corporatetextTranslations.push({
                    language: translationLanguageSelected,
                    translatedCorporatetextHeadline: headlineTranslation,
                    translatedCorporatetextText: textTranslation
                });
            }

            updatedCompany.content.corporatetexts[corporatetextIndex] = corporatetext;
        } else {
        }

        // Perform the update operation with Axios
        const response = await axios.put(`/companies/${updatedCompany._id}`, updatedCompany, {
            headers: { Authorization: `Bearer ${token}` }
        });

        setCompany(response.data);
        setSuccessMessage(t('save_success'));
        setTimeout(() => {
            setSuccessMessage('');
        }, 2000);
        fetchCompanyData();
    } catch (error) {
    }
};


 const processCorporatetextTranslation = useCallback(async () => {
        if (isLoading) return;
        setIsLoading(true);

        setTranslationLanguageSelected(textTranslation);

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setIsLoading(false);
                return;
            }

            const requestBody = {
                headline: headline,
                text: corporatetextText,
                targetLanguage: textTranslation
            };

            const response = await axios.post('/content/translate-corporatetexts', requestBody, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.data) {
                setHeadlineTranslation(response.data.translatedHeadline || '');
                setTextTranslation(response.data.translatedText || '');
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }, [isLoading, textTranslation, headlineTranslation, company?.content, setHeadlineTranslation, setTextTranslation, setTranslationLanguageSelected]);



  return (
<>
            {isLoading && <div className="spinner-overlay"><div className="spinner"></div></div>}

<div className="content-container">

<div className="content-container-column col-two">
	<div className="content-box row-eight">

             <div className="inner-content-container">

 {headlineTranslation && ( 
<>
 <div className="inner-content-box col-five row-one">
                <textarea rows={16} className="content-box-main-headline" name="textTranslation" value={headlineTranslation} onChange={e => setHeadlineTranslation(e.target.value)} />
   </div>

 <div className="inner-content-box col-five row-eleven">
 <textarea placeholder={t('translate_instructions')}
                rows={16} className="content-box-main-textarea" name="textTranslation" value={textTranslation} onChange={e => setTextTranslation(e.target.value)} />
   </div>
</>
)}


 {!headlineTranslation && ( 
 <div className="inner-content-box col-five row-twelve">
 <textarea placeholder={t('translate_instructions')}
                rows={16} className="content-box-main-textarea" name="textTranslation" value={textTranslation} onChange={e => setTextTranslation(e.target.value)} />
   </div>
)}

<div className="inner-content-box">
<button className="editor-button" onClick={processCorporatetextTranslation} disabled={isLoading}>
                        {t('translate_button')}
                    </button>
</div>
        </div>
       </div>
</div>

{successMessage && <div className="content-box blue-background"> {successMessage}</div>}

{!successMessage && (
<div className="content-container-column col-one">

 <div className="content-button-box row-one">
                    <button className="save" onClick={handleSubmit}>{t('save_button')}</button>
                    <button className="save" onClick={onCancel}>{t('cancel_button')}</button>
                </div>
 
      
<div className="content-box row-six">
<h2>{t('original')}</h2>	
<h1>{headline}</h1>
<p><br></br></p>
	<p>{corporatetextText}</p>
</div>

</div>
)}
            

</div>
</>
    );
};

export default CompanyCorporatetextTranslate;
