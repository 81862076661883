
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate, Routes, Route, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Register from '../usermanagement/registerPage';
import Login from '../usermanagement/loginPage';
import LanguageSwitcher from '../LanguageSwitcher';
import CompleteRegistration from '../usermanagement/completeRegistration';
import ResetPasswordPage from '../usermanagement/ResetPasswordPage';
import PublicContentReviewPage from '../approval/PublicContentReviewPage';
import BuzzBeatProductPage from './BuzzBeatProductPage';
import BuzzBeatStartingPage from './BuzzBeatStartingPage'; 
import BuzzBeatPricingPage from './BuzzBeatPricingPage';
import BuzzBeatFooterPage from './BuzzBeatFooterPage';
import BuzzBeatImprint from './BuzzBeatImprint';
import BuzzBeatPrivacy from './BuzzBeatPrivacy';
import BuzzBeatTermsConditions from './BuzzBeatTermsConditions';
import BuzzBeatContactPage from './BuzzBeatContactPage';
import BuzzBeatJobs from './BuzzBeatJobsPage';
import '../i18n';
import '../HomePage.css';

function HomePage({ setIsLoggedIn, isLoggedIn, openCookieConsentManager }) {
  const [companyData, setCompanyData] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('language') || navigator.language.split('-')[0]);
  const [headerClass, setHeaderClass] = useState('headerbar-homepage');
  const [logoSrc, setLogoSrc] = useState("../../assets/images/buzzbeat-logo-white.png");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [headerMobileClass, setMobileHeaderClass] = useState('mobile-headerbar-homepage');
  const [mobileMenuToggleClass, setMobileMenuToggleClass] = useState('mobile-menu-toggle');
  const [backgroundImage, setBackgroundImage] = useState('');

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

useEffect(() => {
  if (isMobileMenuOpen) {
    // Prevent scrolling
    document.body.style.overflow = 'hidden';
  } else {
    // Enable scrolling
    document.body.style.overflow = 'unset';
  }

  // Cleanup function to reset the overflow style
  return () => {
    document.body.style.overflow = 'unset';
  };
}, [isMobileMenuOpen]);

const closeMenu = () => {
  setIsMobileMenuOpen(false);
};

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [currentLanguage, i18n]);

  useEffect(() => {
    localStorage.setItem('isLoggedIn', isLoggedIn);
  }, [isLoggedIn]);

  const handleLoginSuccess = (userData, companyInfo) => {
    setIsLoggedIn(true);
    setCompanyData(companyInfo);
    navigate('/app');
  };

  const changeLanguage = (language) => {
    setCurrentLanguage(language);
    localStorage.setItem('language', language);
    i18n.changeLanguage(language);
  };

useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/assets/images/backgrounds/image${randomIndex}.jpg`;
    setBackgroundImage(imagePath);
  }, []);


useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 0;
      setHeaderClass(show ? 'headerbar-homepage scrolled' : 'headerbar-homepage');
      setMobileHeaderClass(show ? 'mobile-headerbar-homepage scrolled' : 'mobile-headerbar-homepage');
      setMobileMenuToggleClass(show ? 'mobile-menu-toggle scrolled' : 'mobile-menu-toggle');

      setLogoSrc(show ? "../../assets/images/buzzbeat-logo.png" : "../../assets/images/buzzbeat-logo-white.png");
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

 const isActivePath = (path) => {
    return location.pathname === path;
  };


  if (isLoggedIn === undefined) {
    return null;
  }


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


 return (
<>

<div className={headerMobileClass}></div>
<div className={mobileMenuToggleClass}>
          <button onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? t('close') : t('menu')}
          </button>
</div>
          {isMobileMenuOpen && (
            <div className="mobile-menu-container center" style={{ backgroundImage: `url(${backgroundImage})` }}>

	     <div> <Link to="/features"><button onClick={closeMenu} className={isActivePath('/features') ? 'active' : ''}>{t('product')}</button></Link></div>
             <div> <Link to="/pricing"><button onClick={closeMenu} className={isActivePath('/pricing') ? 'active' : ''}>{t('pricing')}</button></Link></div>
             <div> <Link to="/contact"><button onClick={closeMenu} className={isActivePath('/contact') ? 'active' : ''}>{t('contact')}</button></Link></div>
             <div> <Link to="/jobs"><button onClick={closeMenu} className={isActivePath('/jobs') ? 'active' : ''}>{t('jobs')}</button></Link></div>
             <div></div>
{!isLoggedIn &&
                <>
                  <div><Link to="login"><button onClick={closeMenu}>{t('login_button')}</button></Link></div>
                  <div><Link to="register"><button onClick={closeMenu}>{t('register_button')}</button></Link></div>
                </>
              }
              {isLoggedIn &&
<>
		<div></div>
                <div><Link to="/app/app"><button onClick={closeMenu}>{t('dashboard')}</button></Link></div>
</>
              }
	     <div></div>
              <div><LanguageSwitcher currentLanguage={localStorage.getItem('language')} changeLanguage={(language) => i18n.changeLanguage(language)} /></div>
         

            </div>
          )}

  <div className="logo-mobile-homepage">
       <Link to="/"><img src={logoSrc} onClick={closeMenu} alt={t('buzzbeat_logo')}></img></Link>
      </div>

      <div className={headerClass}>




<div className="headerbar-homepage-container">

  <div className="logo-homepage middle col-two">
       <Link to="/"><img src={logoSrc} alt={t('buzzbeat_logo')}></img></Link>
      </div>

<div></div>
<div></div>
<div></div>
<div></div>
<div><Link to="/features"><button className={isActivePath('/features') ? 'active' : ''}>{t('product')}</button></Link> </div>
<div> <Link to="/pricing"><button className={isActivePath('/pricing') ? 'active' : ''} >{t('pricing')}</button></Link> </div>
<div> <Link to="/contact"><button className={isActivePath('/contact') ? 'active' : ''} >{t('contact')}</button></Link> </div>
<div> <Link to="/jobs"><button className={isActivePath('/jobs') ? 'active' : ''} >{t('jobs')}</button></Link> </div>

<div></div>
<div></div>

{!isLoggedIn && 
<>
	<div className="col-two"><LanguageSwitcher currentLanguage={currentLanguage} changeLanguage={changeLanguage} /></div>
	<div><Link to="login"><button>{t('login_button')}</button></Link></div>
        <div><Link to="register"><button>{t('register_button')}</button></Link></div>
</>
}

{isLoggedIn && 
<>	
	<div></div>
	<div className="col-two"><LanguageSwitcher currentLanguage={currentLanguage} changeLanguage={changeLanguage} /></div>
	<div><Link to="/app/app"><button>{t('dashboard')}</button></Link></div>
</>
}

</div>
</div>
      
      <ScrollToTop />

        <Routes>
 	  <Route index element={<BuzzBeatStartingPage />} />
          <Route path="/pricing" element={<BuzzBeatPricingPage />} />
          <Route path="/complete-registration/:token" element={<CompleteRegistration />} />
          <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
          <Route path="login" element={<Login setIsLoggedIn={setIsLoggedIn} setCompanyData={setCompanyData} />} />
          <Route path="register" element={<Register />} />
<Route path="/:contentType/:contentId" element={<PublicContentReviewPage />} />
<Route path="/features" element={<BuzzBeatProductPage />} />
<Route path="/imprint" element={<BuzzBeatImprint />} />
<Route path="/privacy" element={<BuzzBeatPrivacy />} />
<Route path="/termsconditions" element={<BuzzBeatTermsConditions />} />
<Route path="/contact" element={<BuzzBeatContactPage />} />
<Route path="/jobs" element={<BuzzBeatJobs />} />



        </Routes>


<div className="bottombar-homepage">

<BuzzBeatFooterPage openCookieConsentManager={openCookieConsentManager} />

</div>

</>
  );
}

export default HomePage;



