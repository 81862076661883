import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext'; 

const ApprovalRequestDashboardOverview = ({ 
    approvalRequest, 
    handleReminder, 
    handleSendApprovalRequest, 
    isActive,
    toggleActive,
    handleEditClick
}) => {
    const [currentUserId, setCurrentUserId] = useState('');
const { t } = useTranslation();
    const { fetchWithAuth } = useAuth();

  useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        setCurrentUserId(storedUserId);
    }, []);

     const handleToggleClick = () => {
        toggleActive(approvalRequest._id);
    };


 	

const countApprovalStatuses = () => {
    const allApprovers = [...approvalRequest.internalApprovers, ...approvalRequest.externalApprovers];
    const total = allApprovers.length;
    const approvedCount = allApprovers.filter(approver => approver.approvalStatus === 'Approved').length;
    return `${approvedCount} of ${total}`;
};


 useEffect(() => {
    const autoUpdateApprovalStatus = async () => {
      if (approvalRequest && approvalRequest._id) {
        await updateOverallStatus(approvalRequest._id);
      }
    };

    autoUpdateApprovalStatus();
  }, [approvalRequest]);

const updateOverallStatus = async (approvalRequestId) => {
    try {
        const response = await fetchWithAuth(`/approval/update-overall-status/${approvalRequestId}`, { method: 'PUT' });
        if (response.ok) {
            const data = await response.json();
        } else {
            throw new Error('Failed to update overall approval status.');
        }
    } catch (error) {
    }
};

const getModelDisplayName = (model) => {
    const modelDisplayNames = {
        pressreleases: t('press_release'),
        articles: t('article'),
	blogposts: t('blogpost'),
	usecases: t('usecase'),

corporatetexts: t('corporatetext'),
    };

    return modelDisplayNames[model] || model;
};



const updateApprovalStatus = async (approverId, status) => {
        try {
            const response = await fetchWithAuth(`/approval/updateStatus/${approverId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ approvalStatus: status }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
        }
    };

     const isCurrentUserAnInternalApprover = () => {
        return approvalRequest.internalApprovers.some(approver => approver.userId._id === currentUserId);
    };

  const isCurrentUserTheCreator = () => {
    return approvalRequest.creator && approvalRequest.creator._id === currentUserId;
  };

     return (
        <>
              {approvalRequest && (
                <>
                       <div className="dashboard-content-item">
  <div>
    {approvalRequest && (
                 <span className={`status-circle ${approvalRequest.overallApprovalStatus === 'Approved' ? 'approved' : 'not-approved'}`}></span>
            )}
</div>

                            <div><p>{approvalRequest.contentHeadline}</p></div>
                            <div><p>{getModelDisplayName(approvalRequest.onModel)}</p></div>
     			    <div><button className="toggle-translations-btn" onClick={() => handleEditClick(approvalRequest)}>{t('view')}</button>
</div>
                        </div>
                    

                   

                </>
            )}
        </>
    );
};

export default ApprovalRequestDashboardOverview;
