import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';
 
const CollaborationRequestOverview = ({ 
    collaborationRequest, 
    handleReminder, 
    handleSendCollaborationRequest, 
    isActive,
    toggleActive,
handleEditClick,
fetchData
}) => {
    const [currentUserId, setCurrentUserId] = useState('');
const { t } = useTranslation();
    const { fetchWithAuth } = useAuth();

  useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        setCurrentUserId(storedUserId);
    }, []);

     const handleToggleClick = () => {
        toggleActive(collaborationRequest._id);
    };


  useEffect(() => {
    const autoUpdateCollaborationStatus = async () => {
      if (collaborationRequest && collaborationRequest._id) {
        await updateOverallStatus(collaborationRequest._id);
      }
    };

    autoUpdateCollaborationStatus();
  }, [collaborationRequest]);	

const countCollaborationStatuses = () => {
    const allCollaborators = [...collaborationRequest.internalCollaborators, ...collaborationRequest.externalCollaborators];
    const total = allCollaborators.length;
    const collaboratedCount = allCollaborators.filter(collaborator => collaborator.collaborationStatus === 'Collaborated').length;
    return `${collaboratedCount} of ${total}`;
};



const updateOverallStatus = async (collaborationRequestId) => {
    try {
        const response = await fetchWithAuth(`/collaboration/update-overall-status/${collaborationRequestId}`, { method: 'PUT' });
        if (response.ok) {
            const data = await response.json();
        } else {
            throw new Error('Failed to update overall collaboration status.');
        }
    } catch (error) {
    }
};

const getModelDisplayName = (model) => {
    const modelDisplayNames = {
        pressreleases: t('press_release'),
        articles: t('article'),
	blogposts: t('blogpost'),

	usecases: t('usecase'),
corporatetexts: t('corporatetext'),
    };

    return modelDisplayNames[model] || model;
};



const updateCollaborationStatus = async (collaboratorId, status) => {
    try {
        const response = await fetchWithAuth(`/collaboration/updateStatus/${collaboratorId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ collaborationStatus: status }),
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok. Status: ${response.status}`);
        }

        fetchData();
    } catch (error) {
      
        alert('Failed to update collaboration status. Please try again.');
    }
};

     const isCurrentUserAnInternalCollaborator = () => {
        return collaborationRequest.internalCollaborators.some(collaborator => collaborator.userId._id === currentUserId);
    };

  const isCurrentUserTheCreator = () => {
    return collaborationRequest.creator && collaborationRequest.creator._id === currentUserId;
  };

     return (
        <>
              {collaborationRequest && (
                <div>
                       <div className="table-row">


    {collaborationRequest && (
 <div className="row-collaboration">
            <div className="collaboration-status">
                <span className={`status-circle ${collaborationRequest.overallCollaborationStatus === 'Collaborated' ? 'collaborated' : 'not-collaborated'}`}></span>
            </div>
        </div>
    )}

                            <div className="row-collaboration"><p>{collaborationRequest.contentHeadline}</p></div>
                            <div className="row-collaboration"><p>{getModelDisplayName(collaborationRequest.onModel)}</p></div>
                            <div className="row-collaboration"><p>{collaborationRequest.collaborationSentDate ? new Date(collaborationRequest.collaborationSentDate).toLocaleDateString() : 'Not Sent'}</p></div>
        		    <div className="row-collaboration"><p>{countCollaborationStatuses()}</p></div>
     			    <div className="row-collaboration"><button className="toggle-translations-btn" onClick={handleToggleClick}>{isActive ? t('hide') : t('show')}</button> <button className="toggle-translations-btn" onClick={() => handleEditClick(collaborationRequest)}>{t('view')}</button>
</div>
                        </div>

                   

             {isActive && (
    			<div className="detailed-info">
       				

          {isActive && isCurrentUserTheCreator() ? (

<>



            				<div className="creator-info">
 						<span>{t('type')}: {getModelDisplayName(collaborationRequest.onModel)}</span>
                				<span>{t('created_by')}: {collaborationRequest.creator?.name} {collaborationRequest.creator?.surname}</span>
						<span>{t('overall_collaboration_status')}: {collaborationRequest.overallCollaborationStatus}</span>
            				</div>
        		


        <div className="collaborators-section">
            <h4>{t('internal_collaborators')}:</h4>
            <ul className="collaborators-list">
                {collaborationRequest.internalCollaborators.map((collaborator, index) => (
                                        <li key={index} className={collaborator.collaborationStatus.toLowerCase()}>
                                            <span>{collaborator.userId?.name} {collaborator.userId?.surname} </span>
                                            <span>{collaborator.collaborationStatus} </span>
                                            {collaborator.collaborationDate && <span>{t('changed')}: {new Date(collaborator.collaborationDate).toLocaleDateString()} </span>}
                                           {collaborationRequest.collaborationSentDate && collaborator.collaborationStatus !== "Collaborated" && (
    <button className="tiny_button" onClick={() => handleReminder(collaborator.userId.email)}>
        {t('send_reminder')}
    </button>
)}

 					{collaborator.userId._id === currentUserId && collaborator.collaborationStatus !== "Collaborated" && ( 
                                                <select 
                                                    value={collaborator.collaborationStatus}
                                                    onChange={(e) => updateCollaborationStatus(collaborator._id, e.target.value)}
                                                >
                                                            <option value="Pending">{t('still_working')}</option>
						     <option value="rewrite">{t('rewrite_it')}</option>
                                                    <option value="Commented">{t('commented')}</option>
							<option value="Collaborated">{t('done')}</option>     
                                                </select>
                                            )}
                                        </li>
                                    ))}
            </ul>
        </div>

  	
 </>
    ) : (<>

<div className="creator-info">
                				<span>{t('created_by')}: </span><span>{collaborationRequest.creator?.name} {collaborationRequest.creator?.surname}</span><br></br>
            				</div>

 <div className="collaborators-section">
            <ul className="collaborators-list">
                {collaborationRequest.internalCollaborators.map((collaborator, index) => (
                                      collaborator.userId._id === currentUserId && collaborator.collaborationStatus !== "Collaborated" ? (

				  <li key={index} className={collaborator.collaborationStatus.toLowerCase()}>
                                            <span>{collaborator.userId?.name} {collaborator.userId?.surname}</span>
                                            <span>{collaborator.collaborationStatus}</span>
                                            {collaborator.collaborationDate && <span>{t('changed')}: {new Date(collaborator.collaborationDate).toLocaleDateString()}</span>}
                                         

 			
                                                <select 
                                                    value={collaborator.collaborationStatus}
                                                    onChange={(e) => updateCollaborationStatus(collaborator._id, e.target.value)}
                                                >
                                                            <option value="Pending">{t('still_working')}</option>
						     <option value="rewrite">{t('rewrite_it')}</option>
                                                    <option value="Commented">{t('commented')}</option>
							<option value="Collaborated">{t('done')}</option>       
                                                </select>
                                            
                                        </li>
) : ( collaborator.userId._id === currentUserId && (
				<li key={index} className={collaborator.collaborationStatus.toLowerCase()}>
                                            <span>{collaborator.userId.name} {collaborator.userId.surname}</span>
                                            <span>{collaborator.collaborationStatus}</span>
                                            {collaborator.collaborationDate && <span>{t('changed')}: {new Date(collaborator.collaborationDate).toLocaleDateString()}</span>}
                                         {collaborator.collaborationStatus}

 			
                                                                                           
                                        </li>
))
                                    ))}
            </ul>
        </div>



</>)
  
}

        <div className="comments-section">
            <h4>{t('comments')}:</h4>
            <ul className="comments-list">
                {collaborationRequest.comments.map((comment, index) => (
                    <li key={index}>
                        <span>{comment.comment}</span>
                        <span>{comment.commenterEmail || comment.commenterId?.name}</span>
                        <span>Posted on {new Date(comment.date).toLocaleDateString()}</span>
                    </li>
                ))}
            </ul>
        </div>           



        {!collaborationRequest.collaborationSentDate && (
            <button className="editor-button" onClick={handleSendCollaborationRequest}>
                {t('send_collaboration_request')}
            </button>
        )}
    </div>
)}

                </div>
            )}
        </>
    );
};

export default CollaborationRequestOverview;
