import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../BuzzBeatFooterPage.css';

function BuzzBeatFooterPage({ openCookieConsentManager }) {
const { t } = useTranslation();

  return (
    <footer className="buzzbeat-footer">
      <div className="footer-content">
        <div className="footer-section col-three">
          <h1>{t('ultimate_tool')}</h1>
        </div>
      
                 <div className="footer-section col-three">
          <ul>
            <li><Link to="/">{t('home')}</Link></li>
	     <li><Link to="/contact">{t('contact')}</Link></li>
	     <li><Link to="/jobs">{t('jobs')}</Link></li>
            <li><Link to="/imprint">{t('imprint')}</Link></li>
            <li><Link to="/privacy">{t('data_privacy')}</Link></li>
             <li><Link to="/termsconditions">{t('terms_conditions')}</Link></li>
<li><a href="#" onClick={(e) => {e.preventDefault(); openCookieConsentManager();}}>{t('cookie_policy')}</a>
</li>
          </ul>
        </div>
      </div>
        <div className="footer-section col-three">
        <p>&copy; {new Date().getFullYear()} {t('buzzbeat')}. {t('all_rights_reserved')}.</p>
      </div>
    </footer>
  );
}

export default BuzzBeatFooterPage;
