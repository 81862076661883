import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate, NavLink } from 'react-router-dom';
import axios from '../../axios.js';

import CompanyDashboardArticleFunction from './companyDashboardArticleFunction';
import DashboardArticleList from './DashboardArticleList';

import ArticleModal from './articleViewModal';
import LoadingIndicator from '../../LoadingIndicator';

const DashboardArticles = ({ existingCompany, onApprove, onCollaborate, onToggleSubscription }) => {
    const [company, setCompany] = useState(existingCompany || { content: { articles: [] } });
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [articleId, setArticleId] = useState([]);
    const [articles, setArticles] = useState([]);
    const [users, setUsers] = useState([]);
    const { t } = useTranslation();
    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [isTranslationModalOpen, setIsTranslationModalOpen] = useState(false);
    const [editingArticleId, setEditingArticleId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isArticleFunctionModalOpen, setIsArticleFunctionModalOpen] = useState(false);

 useEffect(() => {
        fetchCompanyData();
    }, []);

useEffect(() => {
  if (articles.length > 0) {
  }
}, [articles]);

 useEffect(() => {
        const fetchUsers = async () => {
            const token = localStorage.getItem('token');
            const companyID = company?._id;
            if (token && companyID) {
                try {
                    const response = await axios.get(`/content/get-for-company/${companyID}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setUsers(response.data || []);
                } catch (error) {
                }
            }
        };

        fetchUsers();
    }, [company]); 


const handleEditArticle = (articleId) => {
  resetState();
  setEditingArticleId(articleId);
  handleOpenArticleFunctionModal();
};





const resetState = () => {
    setIsAddingNew(false);
    setEditingIndex(-1);
    setArticleId([]);
    setEditingArticleId(null);
    fetchCompanyData(); 
};






const fetchCompanyData = async () => {
  try {
    setIsLoading(true);

    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    if (!token) {
      return;
    }

    const response = await axios.get('/companies/get-company', {
      headers: { Authorization: `Bearer ${token}` }
    });

    const articlesResponse = await axios.get('/content/articles/get-for-company', {
      headers: { Authorization: `Bearer ${token}` }
    });

     const sortedArticles = articlesResponse.data.sort((a, b) => {
      return new Date(b.creationDate) - new Date(a.creationDate);
    });

     const filteredAndLimitedArticles = sortedArticles.filter(article => article.creator === userId).slice(0, 3);

    setCompany(response.data);
    setArticles(filteredAndLimitedArticles);
    setIsLoading(false);

  } catch (error) {
    setIsLoading(false);
  }
};


    const onCompanyUpdate = (updatedCompany) => {
        setCompany(updatedCompany);
    };




    const handleFinishAddingOrEditing = () => {
        handleCloseArticleFunctionModal();
        resetState();
fetchCompanyData();
    };

    const handleCancelAddingOrEditing = () => {
        handleCloseArticleFunctionModal();

        resetState();
fetchCompanyData();
    };






  const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };


const handleCopyArticle = (data) => {
  let formattedData;

  if (typeof data === 'number') {

    const article = articles[data];
    formattedData = `
${article.headline || 'Untitled article'}

${article.articleText || ''}

Contact:
${article.contact || 'No contact information'}
`;
  } else if (typeof data === 'object' && data.translatedArticleHeadline) {

    formattedData = `
Headline: ${data.translatedArticleHeadline || 'Untitled Translation'}
Text: ${data.translatedArticleText || ''}
`;
  } else {
    return;
  }

  navigator.clipboard.writeText(formattedData)
    .then(() => alert("Details copied!"))
    .catch(err => console.error("Failed to copy: ", err));
};

const handleViewArticle = (articleId) => {
    const selectedArticle = company.content.articles.find(article => article._id === articleId);
    if (selectedArticle) {
        setSelectedArticle(selectedArticle);
        setIsModalOpen(true);
        setIsTranslationModalOpen(false);
    } else {
    }
};


    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedArticle(null);
    };


 const handleOpenArticleFunctionModal = () => {
        setIsArticleFunctionModalOpen(true);
    };

    const handleCloseArticleFunctionModal = () => {
        setIsArticleFunctionModalOpen(false);
    };





 let articleFunctionModalContent = isArticleFunctionModalOpen ? (
<div className="modal-backdrop-edit">
	<div className="modal-edit-content">
        <CompanyDashboardArticleFunction
            company={company}
            onFinish={handleFinishAddingOrEditing}
            articleId={editingArticleId}
            isAddingNew={isAddingNew}
            users={users}
            formatDate={formatDate}
            onCancel={handleCancelAddingOrEditing}
        />
</div>
</div>
    ) : null;


let content = (
            <DashboardArticleList 
    	    	onToggleSubscription={onToggleSubscription}

                articles={articles} 
                handleEditArticle={handleEditArticle}
                onCopy={handleCopyArticle} 
                onPressView={handleViewArticle}
                users={users}
            />
    
    );


if (isLoading) {
    return <div> <LoadingIndicator />
</div>;
  }

    return (
        <>
            {articleFunctionModalContent}

 <div className="dashboard-content-top">
                <div>{t('articles')}</div>
		<div> <NavLink to="articles" className={({ isActive }) => isActive ? 'active' : ''}>{t('view_all')}</NavLink></div> 
	    </div>

     <ArticleModal 
                isOpen={isModalOpen} 
                onClose={handleCloseModal} 
                article={selectedArticle} 
            />

   


            {content}
        </>
    );
};

export default DashboardArticles;