import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';
 
import Gauge from '../Gauge'; 

const DashboardCollaborationsCountPage = ({ companyId }) => {
    const [error, setError] = useState('');
    const [openCollaborations, setOpenCollaborations] = useState([]);
    const [allCollaborations, setAllCollaborations] = useState([]);
    const [myCollaborations, setMyCollaborations] = useState([]);
    const [creatorCollaborations, setCreatorCollaborations] = useState([]);
    const [activeCollaborationId, setActiveCollaborationId] = useState(null);
    const [collaborationRequest, setCollaborationRequest] = useState(null);
    const [collaborations, setCollaborations] = useState([]);
    const [totalCollaborations, setTotalCollaborations] = useState(0);
    const [myCollaborationsCount, setMyCollaborationsCount] = useState(0);
    const [openCollaborationsCount, setOpenCollaborationsCount] = useState(0);
    const [allOpenCollaborationsCount, setAllOpenCollaborationsCount] = useState(0);
    const { fetchWithAuth } = useAuth();

    const { t } = useTranslation();
    const navigate = useNavigate();

useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    const fetchCounts = async () => {
        try {
            const countsEndpoint = `/collaboration/collaborations/${companyId}/counts?userId=${storedUserId}`;
            const response = await fetchWithAuth(countsEndpoint);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();

            setTotalCollaborations(data.totalCollaborations);
            setMyCollaborationsCount(data.userRelatedCollaborations);
            setOpenCollaborationsCount(data.openCollaborations);
	    setAllOpenCollaborationsCount(data.allOpenCollaborations);

        } catch (error) {
            setError('Failed to load data. Please try again later.');
        }
    };

    fetchCounts();
}, [companyId, navigate]);


    const handleEditClick = (collaborationRequest) => {
        navigate(`/app/app/collaborate/${collaborationRequest.onModel}/${collaborationRequest.contentId}`, { state: { companyId } });
    };

   

    return (
        <div>
            <div className="dashboard-content-top">
                <div>{t('collaboration_score')}</div>
                <div><NavLink to="collaborate">{t('improve_your_score')}</NavLink></div>
            </div>
            <div className="dashboard-content-table">
                                       <p>{t('your_open_collaborations')}: {openCollaborationsCount}</p>

                <div className="dashboard-stats">
        <div className="gauge"><Gauge value={openCollaborationsCount} total={allOpenCollaborationsCount} /></div>

                </div>
            </div>
        </div>
    );
};

export default DashboardCollaborationsCountPage;
