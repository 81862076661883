import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate, NavLink } from 'react-router-dom';
import axios from '../../axios.js';

import CompanyDashboardPressReleaseFunction from './companyDashboardPressreleaseFunction';
import DashboardPressReleaseList from './DashboardPressreleaseList';
import PressReleaseModal from './pressReleaseViewModal';
import LoadingIndicator from '../../LoadingIndicator';


const DashboardPressReleases = ({ existingCompany, onApprove, onCollaborate, onToggleSubscription }) => {
    const [company, setCompany] = useState(existingCompany || { content: { pressReleases: [] } });
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [pressReleaseId, setPressReleaseId] = useState([]);
    const [pressReleases, setPressReleases] = useState([]);
    const [users, setUsers] = useState([]);
    const { t } = useTranslation();
    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPressRelease, setSelectedPressRelease] = useState(null);
    const [isTranslationModalOpen, setIsTranslationModalOpen] = useState(false);
    const [editingPressReleaseId, setEditingPressReleaseId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isPressReleaseFunctionModalOpen, setIsPressReleaseFunctionModalOpen] = useState(false);

useEffect(() => {
        fetchCompanyData();
    }, []);

useEffect(() => {
  if (pressReleases.length > 0) {
  }
}, [pressReleases]);

 useEffect(() => {
        const fetchUsers = async () => {
            const token = localStorage.getItem('token');
            const companyID = company?._id;
            if (token && companyID) {
                try {
                    const response = await axios.get(`/content/get-for-company/${companyID}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setUsers(response.data || []);
                } catch (error) {
                }
            }
        };

        fetchUsers();
    }, [company]); 


const handleEditPressRelease = (pressReleaseId) => {
  resetState();
  setEditingPressReleaseId(pressReleaseId);
  handleOpenPressReleaseFunctionModal();
};

const resetState = () => {
    setIsAddingNew(false);
    setEditingIndex(-1);
    setPressReleaseId([]);
    setEditingPressReleaseId(null);
    fetchCompanyData(); 
};

const fetchCompanyData = async () => {
  try {
    setIsLoading(true);

    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    if (!token) {
      return;
    }

    const response = await axios.get('/companies/get-company', {
      headers: { Authorization: `Bearer ${token}` }
    });

    const pressReleasesResponse = await axios.get('/content/press-releases/get-for-company', {
      headers: { Authorization: `Bearer ${token}` }
    });

    const sortedPressReleases = pressReleasesResponse.data.sort((a, b) => {
      return new Date(b.creationDate) - new Date(a.creationDate);
    });

    const filteredAndLimitedPressReleases = sortedPressReleases.filter(pressRelease => pressRelease.creator === userId).slice(0, 3);

    setCompany(response.data);
    setPressReleases(filteredAndLimitedPressReleases);
    setIsLoading(false);

  } catch (error) {
    setIsLoading(false);
  }
};

const onCompanyUpdate = (updatedCompany) => {
        setCompany(updatedCompany);
    };


 const handleFinishAddingOrEditing = () => {
        handleClosePressReleaseFunctionModal();
        resetState();
        fetchCompanyData();
    };

    const handleCancelAddingOrEditing = () => {
        handleClosePressReleaseFunctionModal();
        resetState();
        fetchCompanyData();
    };


  const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };


const handleCopyPressRelease = (data) => {
  let formattedData;

  if (typeof data === 'number') {

    const pressRelease = pressReleases[data];
    formattedData = `
${pressRelease.headline || 'Untitled pressRelease'}

${pressRelease.pressReleaseText || ''}

Contact:
${pressRelease.contact || 'No contact information'}
`;
  } else if (typeof data === 'object' && data.translatedPressReleaseHeadline) {

    formattedData = `
Headline: ${data.translatedPressReleaseHeadline || 'Untitled Translation'}
Text: ${data.translatedPressReleaseText || ''}
`;
  } else {
    return;
  }

  navigator.clipboard.writeText(formattedData)
    .then(() => alert("Details copied!"))
    .catch(err => console.error("Failed to copy: ", err));
};

const handleViewPressRelease = (pressReleaseId) => {
    const selectedPressRelease = company.content.pressReleases.find(pressRelease => pressRelease._id === pressReleaseId);
    if (selectedPressRelease) {
        setSelectedPressRelease(selectedPressRelease);
        setIsModalOpen(true);
        setIsTranslationModalOpen(false);
    } else {
    }
};


    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedPressRelease(null);
    };


 const handleOpenPressReleaseFunctionModal = () => {
        setIsPressReleaseFunctionModalOpen(true);
    };

    const handleClosePressReleaseFunctionModal = () => {
        setIsPressReleaseFunctionModalOpen(false);
    };


 let pressReleaseFunctionModalContent = isPressReleaseFunctionModalOpen ? (
<div className="modal-backdrop-edit">
	<div className="modal-edit-content">
        <CompanyDashboardPressReleaseFunction
            company={company}
            onFinish={handleFinishAddingOrEditing}
            pressReleaseId={editingPressReleaseId}
            isAddingNew={isAddingNew}
            users={users}
            formatDate={formatDate}
            onCancel={handleCancelAddingOrEditing}
        />
</div>
</div>
    ) : null;


let content = (
            <DashboardPressReleaseList 
    	    	onToggleSubscription={onToggleSubscription}
                pressReleases={pressReleases} 
                handleEditPressRelease={handleEditPressRelease}
                onCopy={handleCopyPressRelease} 
                onPressView={handleViewPressRelease}
                users={users}
            />
    
    );


if (isLoading) {
    return <div> <LoadingIndicator />
</div>;
  }

    return (
        <>
            {pressReleaseFunctionModalContent}

 <div className="dashboard-content-top">
                <div>{t('press_releases')}</div>
		<div> <NavLink to="press-release" className={({ isActive }) => isActive ? 'active' : ''}>{t('view_all')}</NavLink></div> 
	    </div>

     <PressReleaseModal 
                isOpen={isModalOpen} 
                onClose={handleCloseModal} 
                pressRelease={selectedPressRelease} 
            />

   


            {content}
        </>
    );
};

export default DashboardPressReleases;