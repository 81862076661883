import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../axios.js';

import CompanyUsecaseFunction from './companyUsecaseFunction';
import UsecaseList from './UsecaseList';
import CompanyUsecaseTranslate from './companyUsecaseTranslate';

import UsecaseModal from './usecaseViewModal';
import UsecaseTranslationModal from './usecaseTranslationViewModal';

import LoadingIndicator from '../../LoadingIndicator';


const CompanyUsecasePage = ({ existingCompany, onApprove, onCollaborate, onToggleSubscription }) => {
    const [company, setCompany] = useState(existingCompany || { content: { usecases: [] } });
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [usecaseId, setUsecaseId] = useState([]);
    const [translatingId, setTranslatingId] = useState(null);
    const [usecases, setUsecases] = useState([]);
    const [users, setUsers] = useState([]);
    const { t } = useTranslation();
    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUsecase, setSelectedUsecase] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const [headlineTranslation, setHeadlineTranslation] = useState('');
    const [textTranslation, setTextTranslation] = useState('');
    const [isTranslationModalOpen, setIsTranslationModalOpen] = useState(false);
    const [selectedTranslation, setSelectedTranslation] = useState(null);
    const [editingUsecaseId, setEditingUsecaseId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


 const handleTranslationLanguageSelected = (language) => {
        setSelectedLanguage(language); 
     };

 const handleUsecaseApproval = (index) => {
    if (usecases[index]) {
        const contentId = usecases[index]._id;
        onApprove(contentId, 'usecases');
    } else {
    }
};


 const handleUsecaseCollaborate = (index) => {
    if (usecases[index]) {
        const contentId = usecases[index]._id;
        onCollaborate(contentId, 'usecases');
    } else {
    }
};



    useEffect(() => {
    if (location.state?.addNew) {
        addNewUsecase();
    } else if (location.state?.reset) {
        resetState();
    }
}, [location]);


 useEffect(() => {
        fetchCompanyData();
    }, []);

useEffect(() => {
  if (usecases.length > 0) {
  }
}, [usecases]);

 useEffect(() => {
        const fetchUsers = async () => {
            const token = localStorage.getItem('token');
            const companyID = company?._id;
            if (token && companyID) {
                try {
                    const response = await axios.get(`/content/get-for-company/${companyID}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setUsers(response.data || []);
                } catch (error) {
                }
            }
        };

        fetchUsers();
    }, [company]); 


const handleEditUsecase = (usecaseId) => {
  resetState();
  setEditingUsecaseId(usecaseId);
};


const handleTranslateUsecase = (usecaseId) => {
  resetState();
     setTranslatingId(usecaseId);

};

const resetState = () => {
    setIsAddingNew(false);
    setEditingIndex(-1);
    setUsecaseId([]);
    setTranslatingId(null); 
    setEditingUsecaseId(null);
    fetchCompanyData(); 
};






const fetchCompanyData = async () => {
    try {
  setIsLoading(true);

        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        if (!token) {
            return;
        }
        const response = await axios.get('/companies/get-company', {
            headers: { Authorization: `Bearer ${token}` }
        });
        const usecasesResponse = await axios.get('/content/usecases/get-for-company', {
            headers: { Authorization: `Bearer ${token}` }
       });

        // Filter usecases where the 'creator' field matches the logged-in user's ID
        const filteredUsecases = usecasesResponse.data.filter(usecase => usecase.creator === userId);

        setCompany(response.data);
        setUsecases(filteredUsecases);
  setIsLoading(false);

    } catch (error) {
    }
};


    const onCompanyUpdate = (updatedCompany) => {
        setCompany(updatedCompany);
    };

const addNewUsecase = () => {
        resetState();
        setIsAddingNew(true);
        setEditingUsecaseId(false);
        setTranslatingId(null);
};




    const handleFinishAddingOrEditing = () => {
        resetState();
fetchCompanyData();
    };

    const handleCancelAddingOrEditing = () => {
        resetState();
fetchCompanyData();
    };


const handleDeleteUsecase = async (data) => {
  if (typeof data === 'number') {
    const usecaseToDelete = usecases[data];
    const usecaseId = usecaseToDelete._id;
    if (usecaseId && window.confirm(t('confirm_delete_usecase'))) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`/content/usecases/${usecaseId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const updatedUsecases = usecases.filter((_, i) => i !== data);
        setUsecases(updatedUsecases);
        setSuccessMessage(t('usecase_deleted_success'));
      } catch (error) {
        alert(t('usecase_delete_failed'));
      }
    }
  } else if (typeof data === 'object' && data.hasOwnProperty('usecaseIndex') && data.hasOwnProperty('translationIndex')) {
        const { usecaseIndex, translationIndex } = data;

        if (window.confirm(t('confirm_delete_translation'))) {
            try {
                const usecaseId = usecases[usecaseIndex]._id;
                const translationId = usecases[usecaseIndex].usecaseTranslations[translationIndex]._id;
                const token = localStorage.getItem('token');

                await axios.delete(`/content/usecases/${usecaseId}/translations/${translationId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                const updatedUsecases = [...usecases];
                updatedUsecases[usecaseIndex].usecaseTranslations.splice(translationIndex, 1);
                setUsecases(updatedUsecases);
                setSuccessMessage(t('translation_deleted_success'));
            } catch (error) {
            }
        }
    }
};



  const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };


const handleCopyUsecase = (data) => {
  let formattedData;

  if (typeof data === 'number') {

    const usecase = usecases[data];
    formattedData = `
${usecase.headline || 'Untitled usecase'}

${usecase.usecaseText || ''}

Contact:
${usecase.contact || 'No contact information'}
`;
  } else if (typeof data === 'object' && data.translatedUsecaseHeadline) {

    formattedData = `
Headline: ${data.translatedUsecaseHeadline || 'Untitled Translation'}
Text: ${data.translatedUsecaseText || ''}
`;
  } else {
    return;
  }

  navigator.clipboard.writeText(formattedData)
    .then(() => alert("Details copied!"))
    .catch(err => console.error("Failed to copy: ", err));
};

const handleViewUsecase = (usecaseId) => {
    const selectedUsecase = company.content.usecases.find(usecase => usecase._id === usecaseId);
    if (selectedUsecase) {
        setSelectedUsecase(selectedUsecase);
        setIsModalOpen(true);
        setIsTranslationModalOpen(false);
    } else {
    }
};


    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedUsecase(null);
    };

const handleViewTranslation = (translation) => {
    setSelectedTranslation(translation);
    const relatedUsecase = usecases.find(pr => pr.usecaseTranslations.some(t => t._id === translation._id));
    
    if (relatedUsecase) {
        setSelectedUsecase(relatedUsecase);
    } else {
        return;
    }

    setIsTranslationModalOpen(true);
    setIsModalOpen(false);
};

    const handleCloseTranslationModal = () => {
        setIsTranslationModalOpen(false);
        setSelectedTranslation(null);
    };

let content;
if (translatingId !== null) {
    content = 
       <CompanyUsecaseTranslate
       setHeadlineTranslation={setHeadlineTranslation}
    textTranslation={textTranslation}
    setTextTranslation={setTextTranslation}
                onTranslationLanguageSelected={handleTranslationLanguageSelected}
onCancel={handleCancelAddingOrEditing}
        usecaseId={translatingId} 
/>;

    } else if (isAddingNew || editingUsecaseId !== null) {
        content = 
    <CompanyUsecaseFunction 
        company={company} 
        onFinish={handleFinishAddingOrEditing} 
        usecaseId={editingUsecaseId} 
        isAddingNew={isAddingNew} 
        users={users} 
	formatDate={formatDate}
	onCancel={handleCancelAddingOrEditing}
    />;
} else {
    content = (
<div className="content-list-container">
            <UsecaseList 
    	    	onToggleSubscription={onToggleSubscription}

                usecases={usecases} 
                handleEditUsecase={handleEditUsecase}
                onTranslate={handleTranslateUsecase} 
                onCopy={handleCopyUsecase} 
                onDelete={handleDeleteUsecase} 
                onPressView={handleViewUsecase}
                onPressViewTranslation={handleViewTranslation}
                users={users}
                onPressApprove={handleUsecaseApproval}
                onPressCollaborate={handleUsecaseCollaborate}
            />
        </div>
    );
}

if (isLoading) {
    return <div> <LoadingIndicator />
</div>;
  }

    return (
        <>
     <UsecaseModal 
                isOpen={isModalOpen} 
                onClose={handleCloseModal} 
                usecase={selectedUsecase} 
            />

     <UsecaseTranslationModal
    isOpen={isTranslationModalOpen}
    onClose={handleCloseTranslationModal}
    usecase={selectedUsecase}
    translation={selectedTranslation}
/>


            {content}
        </>
    );
};

export default CompanyUsecasePage;