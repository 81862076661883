import React, { useState, useEffect } from 'react';
import axios from '../axios.js';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ResetPasswordPage = () => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [passwordStrengthMessage, setPasswordStrengthMessage] = useState([]);
    const [message, setMessage] = useState('');
    const { t } = useTranslation();
    const [backgroundImage, setBackgroundImage] = useState('');
    const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/assets/images/backgrounds/image${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

    const validatePassword = (password) => {
        let messages = [];
        if (!/.{8,}/.test(password)) {
            messages.push(t('password_length_error'));
        }
        if (!/[A-Z]/.test(password)) {
            messages.push(t('password_uppercase_error'));
        }
        if (!/[a-z]/.test(password)) {
            messages.push(t('password_lowercase_error'));
        }
        if (!/[0-9]/.test(password)) {
            messages.push(t('password_number_error'));
        }
        if (!/[^A-Za-z0-9]/.test(password)) {
            messages.push(t('password_special_char_error'));
        }
        setPasswordStrengthMessage(messages);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        validatePassword(e.target.value);
    };

    const handleResetPassword = async () => {
        try {
            const response = await axios.post('/registerlogin/reset-password', { token, password });
            setMessage(response.data.message);
        } catch (error) {
            setMessage(error.response?.data?.message || 'An error occurred.');
        }
    };

    return (

      <div className="buzzbeat-starting-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="overlay-content" style={{ top: `${offsetY * 0.3}px` }}>

        <div className="login-container">
            <div className="login-form">
                <h1>{t('reset_password_headline')}</h1>
                <input
                    type="password"
                    placeholder={t('new_password')}
                    value={password}
                    onChange={handlePasswordChange}
                    required
                />
                <ul className="password-strength-messages">
                    {passwordStrengthMessage.map((msg, index) => (
                        <li key={index}>{msg}</li>
                    ))}
                </ul>
                <button onClick={handleResetPassword} className="glowing">{t('reset_password_button')}</button>
                {message && <p className={message.includes('success') ? "success-message" : "error-message"}>{message}</p>}
            </div>
        </div>
      </div>
        </div>
    );
};

export default ResetPasswordPage;
