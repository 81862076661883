import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';

const ApprovalRequestDisplay = ({ approvalRequest, handleReminder, handleSendApprovalRequest, handleEditClick, fetchData }) => {
    const [currentUserId, setCurrentUserId] = useState('');
   const { t } = useTranslation();
    const { fetchWithAuth } = useAuth(); 

  useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        setCurrentUserId(storedUserId);
    }, []);


    if (!approvalRequest) {
        return <div></div>;
    }

const updateApprovalStatus = async (approverId, status) => {
        try {
            const response = await fetchWithAuth(`/approval/updateStatus/${approverId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ approvalStatus: status }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

	fetchData(); 
        } catch (error) {
        }
    };

    const isCurrentUserAnInternalApprover = () => {
        return approvalRequest.internalApprovers.some(approver => approver.userId._id === currentUserId);
    };

  const isCurrentUserTheCreator = () => {
    return approvalRequest.creator && approvalRequest.creator._id === currentUserId;
  };

  return (
        <div>
              {approvalRequest && (
                <div>
                   

                   

            
    			<div className="detailed-info">
       				

          {isCurrentUserTheCreator() ? (

<>



            				<div className="creator-info">
                				<span>{t('created_by')}: {approvalRequest.creator?.name} {approvalRequest.creator?.surname}</span>
						<span>{t('overall_approval_status')}: {approvalRequest.overallApprovalStatus}</span>
            				</div>
        		


        <div className="approvers-section">
            <h4>{t('internal_approvers')}:</h4>
            <ul className="approvers-list">
                {approvalRequest.internalApprovers.map((approver, index) => (
                                        <li key={index} className={approver.approvalStatus.toLowerCase()}>
                                            <span>{approver.userId?.name} {approver.userId?.surname}</span>
                                            <span>{approver.approvalStatus}</span>
                                            {approver.approvalDate && <span>{t('changed')}: {new Date(approver.approvalDate).toLocaleDateString()}</span>}
                                          {approvalRequest.approvalSentDate && approver.approvalStatus !== "Approved" && (
    <button className="tiny_button" onClick={() => handleReminder(approver.userId.email)}>
        {t('send_reminder')}
    </button>
)}

 					{approver.userId._id === currentUserId && approver.approvalStatus !== "Approved" && ( 
                                                <select 
                                                    value={approver.approvalStatus}
                                                    onChange={(e) => updateApprovalStatus(approver._id, e.target.value)}
                                                >
                                                    <option value="Pending">{t('pending')}</option>
						     <option value="Review">{t('review_again')}</option>
                                                    <option value="Commented">{t('commented')}</option>
							<option value="Approved">{t('approve')}</option>
						    <option value="Not Approved">{t('not_approve')}</option>
                                                    
                                                </select>
                                            )}
                                        </li>
                                    ))}
            </ul>
        </div>

        <div className="approvers-section">
            <h4>{t('external_approver')}:</h4>
             <ul className="approvers-list">
                        {approvalRequest.externalApprovers.map((approver, index) => (
                                        <li key={index} className={approver.approvalStatus.toLowerCase()}>
                                <span>{approver.email}</span>
                                <span>{approver.approvalStatus}</span>
                                            {approver.approvalDate && <span>{t('changed')}: {new Date(approver.approvalDate).toLocaleDateString()}</span>}
                                {approvalRequest.approvalSentDate && approver.approvalStatus !== "Approved" && (
                                    <button className="tiny_button" onClick={() => handleReminder(approver.email)}>
                                        {t('send_reminder')}
                                    </button>
                                )}
                            </li>
                        ))}
                    </ul>
        </div>
 </>
    ) : (<>

<div className="creator-info">
                				<span>{t('created_by')}: </span><span>{approvalRequest.creator?.name} {approvalRequest.creator?.surname}</span><br></br>
            				</div>

 <div className="approvers-section">
            <ul className="approvers-list">
                {approvalRequest.internalApprovers.map((approver, index) => (
                                      approver.userId._id === currentUserId && approver.approvalStatus !== "Approved" ? (

				  <li key={index} className={approver.approvalStatus.toLowerCase()}>
                                            <span>{approver.userId?.name} {approver.userId?.surname}</span>
                                            <span>{approver.approvalStatus}</span>
                                            {approver.approvalDate && <span>{t('changed')}: {new Date(approver.approvalDate).toLocaleDateString()}</span>}
                                         

 			
                                                <select 
                                                    value={approver.approvalStatus}
                                                    onChange={(e) => updateApprovalStatus(approver._id, e.target.value)}
                                                >
                                                    <option value="Pending">{t('pending')}</option>
						     <option value="Review">{t('review_again')}</option>
                                                    <option value="Commented">{t('commented')}</option>
							<option value="Approved">{t('approve')}</option>
						    <option value="Not Approved">{t('not_approve')}</option>
                                                    
                                                </select>
                                            
                                        </li>
) : ( approver.userId._id === currentUserId && (
				<li key={index} className={approver.approvalStatus.toLowerCase()}>
                                            <span>{approver.userId.name} {approver.userId.surname}</span>
                                            <span>{approver.approvalStatus}</span>
                                            {approver.approvalDate && <span>{t('changed')}: {new Date(approver.approvalDate).toLocaleDateString()}</span>}
                                         {approver.approvalStatus}

 			
                                                                                           
                                        </li>
)

)




))}
            </ul>
        </div>



</>)
  
}

        <div className="comments-section">
            <h4>{t('comments')}:</h4>
            <ul className="comments-list">
                {approvalRequest.comments.map((comment, index) => (
                    <li key={index}>
                        <span>{comment.comment}</span>
                        <span>{comment.commenterEmail || comment.commenterId?.name}</span>
                        <span>Posted on {new Date(comment.date).toLocaleDateString()}</span>
                    </li>
                ))}
            </ul>
        </div>           



        {!approvalRequest.approvalSentDate && isCurrentUserTheCreator() && (
            <button className="tiny_button" onClick={handleSendApprovalRequest}>
                {t('send_approval_request')}
            </button>
        )}
    </div>


                </div>
            )}
        </div>
    );
};

export default ApprovalRequestDisplay;

