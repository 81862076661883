import React, { useEffect, useState, useCallback } from 'react';
import '../../Spinner.css';
import { useTranslation } from 'react-i18next';
import axios from '../../axios.js';
import { useHistory } from 'react-router-dom';

const CompanyDashboardCorporatetextFunction = ({ existingCompany, corporatetextId, users, formatDate, onCancel }) => {

    const [corporatetexts, setCorporatetexts] = useState([]);
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState(existingCompany || { content: { corporatetexts: [corporatetextId] } });
    const [corporatetextBullets, setCorporatetextBullets] = useState('');
    const [corporatetextText, setCorporatetextText] = useState('');
    const [corporatetextQuotes, setCorporatetextQuotes] = useState('');
    const [corporatetextFacts, setCorporatetextFacts] = useState('');
    const [headline, setHeadline] = useState('');


const [successMessage, setSuccessMessage] = useState('');

 useEffect(() => {
        fetchCompanyData();
    }, []);

const fetchCompanyData = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }
        const response = await axios.get('/companies/get-company', {
            headers: { Authorization: `Bearer ${token}` }
        });
        const corporatetextsResponse = await axios.get('/content/corporatetexts/get-for-company', {
            headers: { Authorization: `Bearer ${token}` }
        });

        const filteredCorporatetexts = corporatetextsResponse.data.filter(corporatetext => corporatetext._id === corporatetextId);

           if (filteredCorporatetexts.length > 0) {
          setCorporatetextBullets(filteredCorporatetexts[0].corporatetextBullets || '');
                setCorporatetextText(filteredCorporatetexts[0].corporatetextText || '');
                setCorporatetextQuotes(filteredCorporatetexts[0].corporatetextQuotes || '');
                setCorporatetextFacts(filteredCorporatetexts[0].corporatetextFacts || '');
                setHeadline(filteredCorporatetexts[0].headline || '');

        }

        setCompany(response.data);
        setCorporatetexts(filteredCorporatetexts);
   

    } catch (error) {
    }
};


const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }

        let updatedCompany = {...company};

        if (!corporatetextId) {
            const userId = localStorage.getItem('userId');
            const newCorporatetext = {
                corporatetextBullets,
                corporatetextText,
                corporatetextQuotes,
                corporatetextFacts,
                headline,
                corporatetextTranslations: [],
                creator: userId,
                creationDate: new Date()
            };

            updatedCompany.content.corporatetexts = [...(updatedCompany.content?.corporatetexts || []), newCorporatetext];

        } else {

            const corporatetextIndex = updatedCompany.content.corporatetexts.findIndex(pr => pr._id === corporatetextId);
            if (corporatetextIndex !== -1) {
                updatedCompany.content.corporatetexts[corporatetextIndex] = {
                    ...updatedCompany.content.corporatetexts[corporatetextIndex],
                    corporatetextBullets,
                    corporatetextText,
                    corporatetextQuotes,
                    corporatetextFacts,
			headline,
                };
            }
        }

        const response = await axios.put(`/companies/${updatedCompany._id}`, updatedCompany, {
            headers: { Authorization: `Bearer ${token}` }
        });

        setCompany(response.data);

        setSuccessMessage(t('save_success'));
setTimeout(() => {
            setSuccessMessage('');
        }, 2000);
        fetchCompanyData();
    } catch (error) {
    }
};




  return (
<>


<textarea className="content-box-main-headline" name="headline" value={headline} onChange={(e) => setHeadline(e.target.value)} placeholder={t('corporatetext_headline')} rows="3" cols="50" />
<textarea className="content-box-main-textarea" value={corporatetextText} onChange={(e) => setCorporatetextText(e.target.value)} placeholder={t('corporatetext_text')} rows="10" cols="50" />


       


 <div className="content-box three-one">
                    <button className="save" onClick={handleSubmit}>{t('save_button')}</button>
                    <button className="save" onClick={onCancel}>{t('cancel_button')}</button>
                </div>
 
{successMessage && <div className="content-box blue-background"> {successMessage}</div>}




</>
    );
};

export default CompanyDashboardCorporatetextFunction;
