import React, { useEffect, useState, useCallback } from 'react';
import '../../Spinner.css';
import { useTranslation } from 'react-i18next';
import axios from '../../axios.js';
import { useHistory } from 'react-router-dom';

const CompanyPressReleaseTranslate = ({ existingCompany, pressReleaseId, users, formatDate, onCancel }) => {

    const [pressReleases, setPressReleases] = useState([]);
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState(existingCompany || { content: { pressreleases: [pressReleaseId] } });
    const [pressReleaseText, setPressReleaseText] = useState('');
    const [headline, setHeadline] = useState('');
    const [textTranslation, setTextTranslation] = useState('');
    const [headlineTranslation, setHeadlineTranslation] = useState('');
    const [translationLanguageSelected, setTranslationLanguageSelected] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

useEffect(() => {
        fetchCompanyData();
    }, []);

const fetchCompanyData = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }
const response = await axios.get('/companies/get-company', {
            headers: { Authorization: `Bearer ${token}` }
        });

       
        const pressReleasesResponse = await axios.get('/content/press-releases/get-for-company', {
            headers: { Authorization: `Bearer ${token}` }
        });

        const filteredPressReleases = pressReleasesResponse.data.filter(pressRelease => pressRelease._id === pressReleaseId);

           if (filteredPressReleases.length > 0) {
                setPressReleaseText(filteredPressReleases[0].pressReleaseText || '');
                setHeadline(filteredPressReleases[0].headline || '');
        }

        setCompany(response.data);
        setPressReleases(filteredPressReleases);
      } catch (error) {
    }
};


const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }

        let updatedCompany = {...company};

        const pressReleaseIndex = updatedCompany.content.pressreleases.findIndex(pr => pr._id === pressReleaseId);

        if (pressReleaseIndex !== -1) {
            let pressRelease = updatedCompany.content.pressreleases[pressReleaseIndex];

            const translationIndex = pressRelease.pressReleaseTranslations.findIndex(translation => translation.language === textTranslation);

            if (translationIndex !== -1) {
                pressRelease.pressReleaseTranslations[translationIndex] = {
                    ...pressRelease.pressReleaseTranslations[translationIndex],
                    translatedPressReleaseHeadline: headlineTranslation,
                    translatedPressReleaseText: textTranslation
                };
            } else {
                pressRelease.pressReleaseTranslations.push({
                    language: translationLanguageSelected,
                    translatedPressReleaseHeadline: headlineTranslation,
                    translatedPressReleaseText: textTranslation
                });
            }

            updatedCompany.content.pressreleases[pressReleaseIndex] = pressRelease;
        } else {
        }

        // Perform the update operation with Axios
        const response = await axios.put(`/companies/${updatedCompany._id}`, updatedCompany, {
            headers: { Authorization: `Bearer ${token}` }
        });

        setCompany(response.data);
        setSuccessMessage(t('save_success'));
        setTimeout(() => {
            setSuccessMessage('');
        }, 2000);
        fetchCompanyData();
    } catch (error) {
    }
};


 const processPressReleaseTranslation = useCallback(async () => {
        if (isLoading) return;
        setIsLoading(true);

        setTranslationLanguageSelected(textTranslation);

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setIsLoading(false);
                return;
            }

            const requestBody = {
                headline: headline,
                text: pressReleaseText,
                targetLanguage: textTranslation
            };

            const response = await axios.post('/content/translate-pressRelease', requestBody, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.data) {
                setHeadlineTranslation(response.data.translatedHeadline || '');
                setTextTranslation(response.data.translatedText || '');
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }, [isLoading, textTranslation, company?.content, headlineTranslation, setHeadlineTranslation, setTextTranslation, setTranslationLanguageSelected]);



  return (
<>
            {isLoading && <div className="spinner-overlay"><div className="spinner"></div></div>}

<div className="content-container">

<div className="content-container-column col-two">
	<div className="content-box row-eight">

             <div className="inner-content-container">
 {headlineTranslation && (        
<>
 <div className="inner-content-box col-five row-one">
 <textarea rows={16} className="content-box-main-headline"
 name="textTranslation" value={headlineTranslation} onChange={e => setHeadlineTranslation(e.target.value)} />
   </div>


 <div className="inner-content-box col-five row-eleven">
 <textarea placeholder={t('translate_instructions')}
                rows={16} className="content-box-main-textarea"
 name="textTranslation" value={textTranslation} onChange={e => setTextTranslation(e.target.value)} />
   </div>
</>
)}

{!headlineTranslation && (        
<>
 <div className="inner-content-box col-five row-twelve">
 <textarea placeholder={t('translate_instructions')}
                rows={16} className="content-box-main-textarea"
 name="textTranslation" value={textTranslation} onChange={e => setTextTranslation(e.target.value)} />
   </div>
</>
)}



<div className="inner-content-box">
<button className="editor-button" onClick={processPressReleaseTranslation} disabled={isLoading}>
                        {t('translate_button')}
                    </button>
</div>
        </div>
       </div>
</div>


{successMessage && <div className="content-box blue-background"> {successMessage}</div>}

{!successMessage && (
<div className="content-container-column col-one">
 <div className="content-button-box row-one">
                    <button className="save" onClick={handleSubmit}>{t('save_button')}</button>
                    <button className="save" onClick={onCancel}>{t('cancel_button')}</button>
                </div>
 


               
<div className="content-box row-six">
<h2>{t('original')}</h2>
	<h2>{headline}</h2>
	<p className="small">{pressReleaseText}</p>
</div>
</div>
)}

</div>
</>
    );
};

export default CompanyPressReleaseTranslate;
