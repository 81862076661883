import React from 'react';
import { useTranslation } from 'react-i18next';
import SubscriptionPlansPage from './SubscriptionPlansPage';
 
const SubscriptionModal = ({ show, onClose, children, companyId }) => {
    const { t } = useTranslation();
 
  if (!show) {
    return null;
  }

  return (
    <div className="modal-backdrop-full">
      <div className="modal-content">
        {children} <h1>{t('oops')}</h1>
  <p>{t('oops_subscription_text1')} {t('oops_subscription_text2')}  <a href="https://www.buzzbeat.io/pricing" className="tiny_button" target="_blank" rel="noopener noreferrer">
{t('learn_more')}</a></p>
<p><br></br></p>
	<SubscriptionPlansPage companyId={companyId} />
<br></br>
        <button className="save" onClick={onClose}>{t('close')}</button>
      </div>
    </div>
  );
};

export default SubscriptionModal;
