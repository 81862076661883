import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import App from './App.js';
import HomePage from './public/HomePage.js';
import LandingPages from './landingpages/LandingPages.js';

import { AuthProvider } from './AuthContext';
import CookieConsentManager from './cookies/CookieConsentManager.js';
import initializeTagManager from './cookies/googleTagManager.js';
import { initializeGoogleAnalytics } from './cookies/initializeGoogleAnalytics.js';
import axios from './axios.js'; 

const GF_CONSENT_KEY = 'user_gf_consent';

function BuzzBeat() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [showBanner, setShowBanner] = useState(false);
  const [isGATrackingAllowed, setIsGATrackingAllowed] = useState(() => JSON.parse(localStorage.getItem('user_ga_consent')) ?? null);
  const [isGTMTrackingAllowed, setIsGTMTrackingAllowed] = useState(() => JSON.parse(localStorage.getItem('user_gtm_consent')) ?? null);
  const [isGFAllowed, setIsGFAllowed] = useState(() => JSON.parse(localStorage.getItem(GF_CONSENT_KEY)) ?? null);

  const checkTokenValidity = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    try {
      const { exp } = JSON.parse(atob(token.split('.')[1]));
      return exp * 1000 > new Date().getTime();
    } catch (error) {
      return false;
    }
  };

  const className = isGFAllowed ? 'roboto' : 'alternate';


  useEffect(() => {
  }, [className]);

  useEffect(() => {
    setIsLoggedIn(checkTokenValidity());

    const handleStorageChange = () => {
      setIsLoggedIn(checkTokenValidity());
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  useEffect(() => {
    if (isGATrackingAllowed !== null && isGTMTrackingAllowed !== null) {
      initializeTagManager(isGTMTrackingAllowed);
      initializeGoogleAnalytics(isGATrackingAllowed);
    }
  }, [isGATrackingAllowed, isGTMTrackingAllowed]);

  const handleGAConsentChange = (consent) => {
    setIsGATrackingAllowed(consent);
  };

  const handleGTMConsentChange = (consent) => {
    setIsGTMTrackingAllowed(consent);
  };

  const openCookieConsentManager = () => setShowBanner(true);



  return (
    <Router>
      <AuthProvider>
        <CookieConsentManager
          showBanner={showBanner}
          setShowBanner={setShowBanner}
          onGAConsentChange={handleGAConsentChange}
          onGTMConsentChange={handleGTMConsentChange}
        />
    <div className={className}>
          <Routes>
            <Route path="/*" element={<HomePage setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} openCookieConsentManager={openCookieConsentManager} />} />
            <Route path="/app/*" element={<App isLoggedIn={isLoggedIn} openCookieConsentManager={openCookieConsentManager} />} />
            <Route path="/buzzbeat/*" element={<LandingPages openCookieConsentManager={openCookieConsentManager} />} />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}


export default BuzzBeat;
