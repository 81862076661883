import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const COOKIE_CONSENT_KEY = 'user_cookie_consent';
const GTM_CONSENT_KEY = 'user_gtm_consent';
const GA_CONSENT_KEY = 'user_ga_consent';
const GF_CONSENT_KEY = 'user_gf_consent'; 

const CookieConsentManager = ({ showBanner, setShowBanner, onGAConsentChange, onGTMConsentChange }) => {
  const [gtmConsent, setGTMConsent] = useState(() => JSON.parse(localStorage.getItem(GTM_CONSENT_KEY) || 'false'));
  const [gaConsent, setGAConsent] = useState(() => JSON.parse(localStorage.getItem(GA_CONSENT_KEY) || 'false'));
  const [gfConsent, setGFConsent] = useState(() => JSON.parse(localStorage.getItem(GF_CONSENT_KEY) || 'false'));
  const [settingsView, setSettingsView] = useState(false);

useEffect(() => {
  const consent = localStorage.getItem(COOKIE_CONSENT_KEY);
  if (!consent) {
    setShowBanner(true);
    setGTMConsent(JSON.parse(localStorage.getItem(GTM_CONSENT_KEY) || 'false'));
    setGAConsent(JSON.parse(localStorage.getItem(GA_CONSENT_KEY) || 'false'));
    setGFConsent(JSON.parse(localStorage.getItem(GF_CONSENT_KEY) || 'false'));
  } else {
    setShowBanner(false);
  }
}, [setShowBanner]);



  const setConsent = (cookieConsent, gtmConsentStatus, gaConsentStatus, gfConsentStatus) => {
    localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify(cookieConsent));
    localStorage.setItem(GTM_CONSENT_KEY, JSON.stringify(gtmConsentStatus));
    localStorage.setItem(GA_CONSENT_KEY, JSON.stringify(gaConsentStatus));
    localStorage.setItem(GF_CONSENT_KEY, JSON.stringify(gfConsentStatus));
    setShowBanner(!cookieConsent);
    onGTMConsentChange?.(gtmConsentStatus);
    onGAConsentChange?.(gaConsentStatus);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const handleAcceptAll = () => {
    setConsent(true, true, true, true);
  setShowBanner(false);
    refreshPage();
  };

  const handleManageSettings = () => {
    setSettingsView(true);
  };

const handleSaveSettings = () => {
  setConsent(true, gtmConsent, gaConsent, gfConsent);
  onGTMConsentChange(gtmConsent);
  onGAConsentChange(gaConsent);
  setShowBanner(false);
    refreshPage();
};


  const handleGTMConsentToggle = (event) => {
    setGTMConsent(event.target.checked);
  };

  const handleGAConsentToggle = (event) => {
    setGAConsent(event.target.checked);
  };

  const handleGFConsentToggle = (event) => {
    setGFConsent(event.target.checked);
  };


const { t } = useTranslation();



  if (!showBanner) {
    return null;
  }

  return (
    <div className="cookie-consent-overlay">
      <div className="cookie-consent-modal">
        {settingsView ? (
          <>
<div className="cookies-grid">
           <div className="col-two"><h1><strong>{t('cookie_17')}</strong></h1></div>
<div className="col-two left"><p><strong>{t('cookie_9')}</strong></p></div>
<div className="left"><input type="checkbox" checked={gfConsent} onChange={handleGFConsentToggle} /></div>
     <div className="left"><p>{t('cookie_18')}</p></div>
              <div className="col-two left"><p><br></br></p></div>


<div className="col-two left"><p><strong>{t('cookie_11')}</strong></p></div>
<div className="left"><input type="checkbox" checked={gaConsent} onChange={handleGAConsentToggle} /></div>
        <div className="left"><p>{t('cookie_20')}</p></div> 

<div className="left"><input type="checkbox" checked={gtmConsent} onChange={handleGTMConsentToggle} /></div>
 <div className="left"><p>{t('cookie_19')}</p></div>    
       
</div>
            <div className="actions">
              <button onClick={handleSaveSettings}>{t('cookie_21')}</button>
              <button onClick={handleAcceptAll}>{t('cookie_16')}</button>
            </div>
          </>
        ) : (
          <>
<div className="left">



<h1>{t('cookie_1')} 🚀🌟🍪</h1> <p>{t('cookie_2')} 🤖 {t('cookie_3')} 🎨 {t('cookie_4')} 🧠 {t('cookie_5')}</p> 
<br></br>
<h1><strong>{t('cookie_6')}</strong> {t('cookie_22')}</h1>

<p><strong>{t('cookie_7')}</strong>{t('cookie_8')}<br></br>

<strong>{t('cookie_9')}</strong>{t('cookie_10')}
<br></br>
<strong>{t('cookie_11')}</strong>{t('cookie_12')}
<br></br><br></br>
{t('cookie_13')} 🍪 {t('cookie_14')}</p>
</div>
            <div className="actions">
              <button className="manage-consent" onClick={handleManageSettings}>{t('cookie_15')}</button>
              <button onClick={handleAcceptAll}>{t('cookie_16')}</button>

            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CookieConsentManager;
