import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom'; 
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';

import CollaborationRequestDashboardOverview from './CollaborationRequestDashboardOverview';

const DashboardCollaborationsPage = ({ companyId }) => {
    const [error, setError] = useState('');
    const [openCollaborations, setOpenCollaborations] = useState([]);
    const [allCollaborations, setAllCollaborations] = useState([]);
    const [myCollaborations, setMyCollaborations] = useState([]);
 const [activeTab, setActiveTab] = useState('user');
    const [creatorCollaborations, setCreatorCollaborations] = useState([]); 
    const [activeCollaborationId, setActiveCollaborationId] = useState(null);
    const [collaborationRequest, setCollaborationRequest] = useState(null);
    const [collaborations, setCollaborations] = useState([]);
     const { fetchWithAuth } = useAuth();

    const { t } = useTranslation();
    const navigate = useNavigate();

useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    const fetchData = async () => {
        try {
            let endpoint = `/collaboration/collaborations/${companyId}?type=${activeTab}`;
            if (activeTab === 'user' || activeTab === 'creator') {
                endpoint += `&userId=${storedUserId}`;
            }

            const response = await fetchWithAuth(endpoint);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setCollaborations(data);
        } catch (error) {
            setError('Failed to load data. Please try again later.');
        }
    };

    fetchData();
}, [companyId, activeTab]);


   

    const handleEditClick = (collaborationRequest) => {
        navigate(`/app/app/collaborate/${collaborationRequest.onModel}/${collaborationRequest.contentId}`, { state: { companyId } });
    };

   const renderTabContent = () => {

    const latestCollaborations = collaborations.slice(-4);
    return latestCollaborations.map((collaboration, index) => (
        <CollaborationRequestDashboardOverview
            key={index}
            collaborationRequest={collaboration}
            isActive={activeCollaborationId === collaboration._id}
            toggleActive={() => setActiveCollaborationId(activeCollaborationId === collaboration._id ? null : collaboration._id)}
            handleEditClick={() => handleEditClick(collaboration)}
        />
    ));
};


    return (
        <div>
            <div className="dashboard-content-top">
                <div>{t('some_open_collaborations')}</div>
		<div> <NavLink to="collaborate" className={({ isActive }) => isActive ? 'active' : ''}>{t('view_all')}</NavLink></div> 
	    </div>



            <div className="dashboard-content-table">
                {renderTabContent()}
          </div>
        </div>
    );
};

export default DashboardCollaborationsPage;
