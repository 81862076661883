import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../axios.js';

import CompanyCorporatetextFunction from './companyCorporatetextFunction';
import CorporatetextList from './CorporatetextList';
import CompanyCorporatetextTranslate from './companyCorporatetextTranslate';

import CorporatetextModal from './corporatetextViewModal';
import CorporatetextTranslationModal from './corporatetextTranslationViewModal';

import LoadingIndicator from '../../LoadingIndicator';


const CompanyCorporatetextPage = ({ existingCompany, onApprove, onCollaborate, onToggleSubscription }) => {
    const [company, setCompany] = useState(existingCompany || { content: { corporatetexts: [] } });
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [corporatetextId, setCorporatetextId] = useState([]);
    const [translatingId, setTranslatingId] = useState(null);
    const [corporatetexts, setCorporatetexts] = useState([]);
    const [users, setUsers] = useState([]);
    const { t } = useTranslation();
    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCorporatetext, setSelectedCorporatetext] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const [headlineTranslation, setHeadlineTranslation] = useState('');
    const [textTranslation, setTextTranslation] = useState('');
    const [isTranslationModalOpen, setIsTranslationModalOpen] = useState(false);
    const [selectedTranslation, setSelectedTranslation] = useState(null);
    const [editingCorporatetextId, setEditingCorporatetextId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


 const handleTranslationLanguageSelected = (language) => {
        setSelectedLanguage(language); 
     };

 const handleCorporatetextApproval = (index) => {
    if (corporatetexts[index]) {
        const contentId = corporatetexts[index]._id;
        onApprove(contentId, 'corporatetexts');
    } else {
    }
};


 const handleCorporatetextCollaborate = (index) => {
    if (corporatetexts[index]) {
        const contentId = corporatetexts[index]._id;
        onCollaborate(contentId, 'corporatetexts');
    } else {
    }
};



    useEffect(() => {
    if (location.state?.addNew) {
        addNewCorporatetext();
    } else if (location.state?.reset) {
        resetState();
    }
}, [location]);


 useEffect(() => {
        fetchCompanyData();
    }, []);

useEffect(() => {
  if (corporatetexts.length > 0) {
  }
}, [corporatetexts]);

 useEffect(() => {
        const fetchUsers = async () => {
            const token = localStorage.getItem('token');
            const companyID = company?._id;
            if (token && companyID) {
                try {
                    const response = await axios.get(`/content/get-for-company/${companyID}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setUsers(response.data || []);
                } catch (error) {
                }
            }
        };

        fetchUsers();
    }, [company]); 


const handleEditCorporatetext = (corporatetextId) => {
  resetState();
  setEditingCorporatetextId(corporatetextId);
};


const handleTranslateCorporatetext = (corporatetextId) => {
  resetState();
     setTranslatingId(corporatetextId);

};

const resetState = () => {
    setIsAddingNew(false);
    setEditingIndex(-1);
    setCorporatetextId([]);
    setTranslatingId(null); 
    setEditingCorporatetextId(null);
    fetchCompanyData(); 
};






const fetchCompanyData = async () => {
    try {
  setIsLoading(true);

        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        if (!token) {
            return;
        }
        const response = await axios.get('/companies/get-company', {
            headers: { Authorization: `Bearer ${token}` }
        });
        const corporatetextsResponse = await axios.get('/content/corporatetexts/get-for-company', {
            headers: { Authorization: `Bearer ${token}` }
       });

        // Filter corporatetexts where the 'creator' field matches the logged-in user's ID
        const filteredCorporatetexts = corporatetextsResponse.data.filter(corporatetext => corporatetext.creator === userId);

        setCompany(response.data);
        setCorporatetexts(filteredCorporatetexts);
  setIsLoading(false);

    } catch (error) {
    }
};


    const onCompanyUpdate = (updatedCompany) => {
        setCompany(updatedCompany);
    };

const addNewCorporatetext = () => {
        resetState();
        setIsAddingNew(true);
        setEditingCorporatetextId(false);
        setTranslatingId(null);
};




    const handleFinishAddingOrEditing = () => {
        resetState();
fetchCompanyData();
    };

    const handleCancelAddingOrEditing = () => {
        resetState();
fetchCompanyData();
    };


const handleDeleteCorporatetext = async (data) => {
  if (typeof data === 'number') {
    const corporatetextToDelete = corporatetexts[data];
    const corporatetextId = corporatetextToDelete._id;
    if (corporatetextId && window.confirm(t('confirm_delete_corporatetext'))) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`/content/corporatetexts/${corporatetextId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const updatedCorporatetexts = corporatetexts.filter((_, i) => i !== data);
        setCorporatetexts(updatedCorporatetexts);
        setSuccessMessage(t('corporatetext_deleted_success'));
      } catch (error) {
        alert(t('corporatetext_delete_failed'));
      }
    }
  } else if (typeof data === 'object' && data.hasOwnProperty('corporatetextIndex') && data.hasOwnProperty('translationIndex')) {
        const { corporatetextIndex, translationIndex } = data;

        if (window.confirm(t('confirm_delete_translation'))) {
            try {
                const corporatetextId = corporatetexts[corporatetextIndex]._id;
                const translationId = corporatetexts[corporatetextIndex].corporatetextTranslations[translationIndex]._id;
                const token = localStorage.getItem('token');

                await axios.delete(`/content/corporatetexts/${corporatetextId}/translations/${translationId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                const updatedCorporatetexts = [...corporatetexts];
                updatedCorporatetexts[corporatetextIndex].corporatetextTranslations.splice(translationIndex, 1);
                setCorporatetexts(updatedCorporatetexts);
                setSuccessMessage(t('translation_deleted_success'));
            } catch (error) {
            }
        }
    }
};



  const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };


const handleCopyCorporatetext = (data) => {
  let formattedData;

  if (typeof data === 'number') {

    const corporatetext = corporatetexts[data];
    formattedData = `
${corporatetext.headline || 'Untitled corporatetext'}

${corporatetext.corporatetextText || ''}

Contact:
${corporatetext.contact || 'No contact information'}
`;
  } else if (typeof data === 'object' && data.translatedCorporatetextHeadline) {

    formattedData = `
Headline: ${data.translatedCorporatetextHeadline || 'Untitled Translation'}
Text: ${data.translatedCorporatetextText || ''}
`;
  } else {
    return;
  }

  navigator.clipboard.writeText(formattedData)
    .then(() => alert("Details copied!"))
    .catch(err => console.error("Failed to copy: ", err));
};

const handleViewCorporatetext = (corporatetextId) => {
    const selectedCorporatetext = company.content.corporatetexts.find(corporatetext => corporatetext._id === corporatetextId);
    if (selectedCorporatetext) {
        setSelectedCorporatetext(selectedCorporatetext);
        setIsModalOpen(true);
        setIsTranslationModalOpen(false);
    } else {
    }
};


    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedCorporatetext(null);
    };

const handleViewTranslation = (translation) => {
    setSelectedTranslation(translation);
    const relatedCorporatetext = corporatetexts.find(pr => pr.corporatetextTranslations.some(t => t._id === translation._id));
    
    if (relatedCorporatetext) {
        setSelectedCorporatetext(relatedCorporatetext);
    } else {
        return;
    }

    setIsTranslationModalOpen(true);
    setIsModalOpen(false);
};

    const handleCloseTranslationModal = () => {
        setIsTranslationModalOpen(false);
        setSelectedTranslation(null);
    };

let content;
if (translatingId !== null) {
    content = 
       <CompanyCorporatetextTranslate
       setHeadlineTranslation={setHeadlineTranslation}
    textTranslation={textTranslation}
    setTextTranslation={setTextTranslation}
                onTranslationLanguageSelected={handleTranslationLanguageSelected}
onCancel={handleCancelAddingOrEditing}
        corporatetextId={translatingId} 
/>;

    } else if (isAddingNew || editingCorporatetextId !== null) {
        content = 
    <CompanyCorporatetextFunction 
        company={company} 
        onFinish={handleFinishAddingOrEditing} 
        corporatetextId={editingCorporatetextId} 
        isAddingNew={isAddingNew} 
        users={users} 
	formatDate={formatDate}
	onCancel={handleCancelAddingOrEditing}
    />;
} else {
    content = (
<div className="content-list-container">
            <CorporatetextList 
    	    	onToggleSubscription={onToggleSubscription}
                corporatetexts={corporatetexts} 
                handleEditCorporatetext={handleEditCorporatetext}
                onTranslate={handleTranslateCorporatetext} 
                onCopy={handleCopyCorporatetext} 
                onDelete={handleDeleteCorporatetext} 
                onPressView={handleViewCorporatetext}
                onPressViewTranslation={handleViewTranslation}
                users={users}
                onPressApprove={handleCorporatetextApproval}
                onPressCollaborate={handleCorporatetextCollaborate}
            />
        </div>
    );
}

if (isLoading) {
    return <div> <LoadingIndicator />
</div>;
  }

    return (
        <>
     <CorporatetextModal 
                isOpen={isModalOpen} 
                onClose={handleCloseModal} 
                corporatetext={selectedCorporatetext} 
            />

     <CorporatetextTranslationModal
    isOpen={isTranslationModalOpen}
    onClose={handleCloseTranslationModal}
    corporatetext={selectedCorporatetext}
    translation={selectedTranslation}
/>

            {content}
        </>
    );
};

export default CompanyCorporatetextPage;