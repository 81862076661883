import React, { useState } from 'react';
import axios from '../axios.js';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const ForgotPasswordModal = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation();

const handleForgotPassword = async () => {
    const currentLanguage = i18n.language;
    const data = {
        email: email, 
        language: currentLanguage,
    };

    try {
        await axios.post('/registerlogin/forgot-password', data);
        setMessage(t('password_reset_success_message'));
        setIsError(false); // Ensure 'setIsError' is a state setter function defined in your component
    } catch (error) {
        setMessage(error.response?.data?.message || t('error_message'));
        setIsError(true);
    }
};


    const handleChange = (e) => {
        setEmail(e.target.value);
        if (message) {
            setMessage('');
            setIsError(false);
        }
    };

    return (
        <div className="modal-backdrop-full">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>X</button>
                <h1>{t('forgot_password')}</h1><br></br>
                <input
                    type="email"
                    placeholder={t('your_email')}
                    value={email}
                    onChange={handleChange}
                    required
                />
                <button onClick={handleForgotPassword} className="save">{t('reset_password_button')}</button>
                {message && <p className={isError ? 'error-message' : 'success-message'}>{message}</p>}
            </div>
        </div>
    );
};

export default ForgotPasswordModal;
