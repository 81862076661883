import React, { useState, useEffect } from 'react';
import axios from './axios.js';
import { useTranslation } from 'react-i18next';
import './Dashboard.css';
 
import DashboardApprovalPage from './approval/dashboardApprovalPage';
import DashboardApprovalsCountPage from './approval/DashboardApprovalsCountPage';
import DashboardApprovalsCountPerTypePage from './approval/dashboardApprovalsPerTypeCountPage';

import DashboardCollaborationPage from './collaboration/dashboardCollaborationPage';
import DashboardCollaborationsCountPage from './collaboration/DashboardCollaborationsCountPage';
import DashboardCollaborationsCountPerTypePage from './collaboration/dashboardCollaborationsPerTypeCountPage';

import DashboardTeamMembers from './companymanagement/dashboardTeamMembers';


const DashboardPage = ({ companyId }) => {
 
  const { t } = useTranslation();

  return (
    <div className="dashboard-container">
      <div className="square"> <DashboardApprovalPage companyId={companyId} /></div>
        <div className="square"><DashboardApprovalsCountPage companyId={companyId} /></div>
      <div className="square"><DashboardApprovalsCountPerTypePage companyId={companyId} /></div>

      <div className="square"> <DashboardCollaborationPage companyId={companyId} /></div>
      <div className="square"><DashboardCollaborationsCountPage companyId={companyId} /></div>
      <div className="square"><DashboardCollaborationsCountPerTypePage companyId={companyId} /></div>

<div className="square"><DashboardTeamMembers companyId={companyId} /></div>
    </div>
  );
};

export default DashboardPage;
