import React, { useState, useCallback } from 'react';
import CompanyDetailsPage from '../companymanagement/companyDetailsPage';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const SetupPage = () => {
  const [companyData, setCompanyData] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
 
  const handleSuccess = useCallback(() => {
   
      navigate('/app/app');
   
   }, [navigate]);

  return (
    <div>
      <CompanyDetailsPage existingCompany={companyData} onSuccess={handleSuccess} />
    </div>
  );
};

export default SetupPage;
