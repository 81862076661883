import React, { useState, useEffect } from 'react';
import axios from './axios.js';
import { useTranslation } from 'react-i18next';
import './Dashboard.css';
 
import DashboardVision from './content/DashboardVision';
import DashboardCorporatetextsFree from './content/corporatetexts/DashboardCorporatetextsFree';
import SubscriptionPlansPage from './subscription/SubscriptionPlansPage';
import DashboardWelcome from './subscription/DashboardWelcome';
import BuzzBeatModal from './BuzzBeatModal';
import SubscriptionProPlansPage from './subscription/SubscriptionProPlansPage';




const DashboardPage = ({ companyId }) => {
   const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="dashboard-container">

   <BuzzBeatModal isOpen={isModalOpen} onClose={handleCloseModal}>
        
      
    <SubscriptionProPlansPage companyId={companyId} />  

      </BuzzBeatModal>


<div className="square row-three"><DashboardWelcome companyId={companyId} /></div>  
<div className="square row-two"><DashboardCorporatetextsFree companyId={companyId} /></div>  
<div className="square row-two"><DashboardVision companyId={companyId} /></div>         
     <div className="square col-two"><SubscriptionPlansPage companyId={companyId} /></div>       

    </div>
  );
};

export default DashboardPage;
