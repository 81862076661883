import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
 
const ArticleModal = ({ isOpen, onClose, article }) => {
    const [copySuccess, setCopySuccess] = useState('');
    const { t } = useTranslation();

    if (!isOpen) return null;

   const renderArticleText = () => {
    if (!article.articleText) return null;
    const paragraphs = article.articleText.split('\n');
    return paragraphs.map((paragraph, index) => (
        <p key={index} className="press-release-text">{paragraph}<br></br></p>
    ));
};


   
const copyToClipboard = text => {
    navigator.clipboard.writeText(text)
        .then(() => {
            setCopySuccess("Copied to clipboard!");
            setTimeout(() => setCopySuccess(''), 3000);
        })
        .catch(err => {
            setCopySuccess("Failed to copy!");
            setTimeout(() => setCopySuccess(''), 3000);
        });
};

    const handleCopyHeadline = () => copyToClipboard(article.headline);
    const handleCopyContent = () => copyToClipboard(article.articleText);
    const handleCopyComplete = () => copyToClipboard(
        `Headline: ${article.headline}\n\n` +
        `Content:\n${article.articleText}\n\n` 
    );


const dateObject = new Date(article.date);

    const dateOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZoneName: 'short',
        timeZone: 'Europe/Paris',
    };

    const formattedDate = dateObject.toLocaleString('de-DE', dateOptions);


    return (
        <div className="press-modal">
            <div className="modal-press-content">
                <div className="modal-button-bar">
          <button className="save" onClick={handleCopyHeadline}>{t('copy_headline')}</button>
                    <button className="save" onClick={handleCopyContent}>{t('copy_content')}</button>
                    <button className="save" onClick={handleCopyComplete}>{t('copy_complete')}</button>
                      <button className="close-press-button" onClick={onClose}>&times;</button>
                </div>
                {copySuccess && <div className="copy-success-message">{copySuccess}</div>}

                <div className="modal-scrollable-content">
                    <div className="modal-header">
                        <h1>{article.headline}</h1>
                    </div>
                    <div className="modal-body">
                
{renderArticleText()}             
       </div>
                  
                </div>
            </div>
        </div>
    );
};

export default ArticleModal;
