import React, { useEffect, useState } from 'react';
import axios from '../axios.js';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

const ProductCounter = () => {
  const { t } = useTranslation();

    const [counts, setCounts] = useState({
        totalCompanies: 100,
        totalUsers: 100,
        totalContent: 100,
    });

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.2, 
    });

    useEffect(() => {
        const fetchCounts = async () => {
            try {
                const response = await axios.get('/public/counts');
                setCounts(response.data);
            } catch (error) {
            }
        };

        fetchCounts();
    }, []); 

    return (
        <div className="counter-container" ref={ref}>
            <div className="counter">
                <h1>
                    {inView ? <CountUp end={counts.totalCompanies} duration={2.75} /> : "0"}
                </h1>
                <p>{t('satisfied_customers')}</p>
            </div>
            <div className="counter">
               <h1>
                   {inView ? <CountUp end={counts.totalUsers} duration={2.75} /> : "0"}
               </h1>
                <p>{t('happy_users')}</p>
            </div>
            <div className="counter">
               <h1>
                   {inView ? <CountUp end={counts.totalContent} duration={2.75} /> : "0"}
               </h1>
                <p>{t('contents_created')}</p>
            </div>
        </div>
    );
};
 
export default ProductCounter;
