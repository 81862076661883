import React, { useState } from 'react';
import ResendConfirmationLink from './resendConfirmationLink';
 
const ResendConfirmationModal = ({ onClose }) => {
    return (
        <div className="modal-backdrop-full">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>X</button>
                <ResendConfirmationLink />
            </div>
        </div>
    );
};

export default ResendConfirmationModal;
