import React from 'react';
 
const parseText = (inputText) => {
  const paragraphs = inputText.split('\n\n\n\n');
  const formattedParagraphs = paragraphs.map((paragraph, pIndex) => {
    const lines = paragraph.split('\n').map((line, lIndex) => {
      if (line.startsWith('# ')) {
        return <h2 key={`h2-${pIndex}-${lIndex}`}>{line.substring(2)}</h2>;
      }
      const linkMatch = line.match(/\[([^\]]+)\]\(([^)]+)\)/);
      if (linkMatch) {
        const text = linkMatch[1];
        const url = linkMatch[2];
        return <a href={url} key={`a-${pIndex}-${lIndex}`}>{text}</a>;
      }
      return line;
    });

    const combinedLines = lines.reduce((acc, curr, index) => {
      acc.push(curr);
      if (index !== lines.length - 1) acc.push(<br key={`br-${pIndex}-${index}`} />);
      return acc;
    }, []);

    if (lines.length === 1 && React.isValidElement(lines[0])) {
      return lines[0]; // Directly return the element if it's a single headline or link
    } else {
      return <p key={`p-${pIndex}`}>{combinedLines}</p>;
    }
  });

  return formattedParagraphs;
};

const FormattedText = ({ text }) => {
  const safeText = text || '';

  const formattedText = parseText(safeText);

  return <p>{formattedText}</p>;
};

export default FormattedText;
