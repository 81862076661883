import React, { useEffect, useState, useCallback } from 'react';
import '../../Spinner.css';
import { useTranslation } from 'react-i18next';
import axios from '../../axios.js';
import { useHistory } from 'react-router-dom';

const CompanyDashboardUsecaseFunction = ({ existingCompany, usecaseId, users, formatDate, onCancel }) => {

    const [usecases, setUsecases] = useState([]);
    const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState(existingCompany || { content: { usecases: [usecaseId] } });
const [usecaseBullets, setUsecaseBullets] = useState('');
    const [usecaseText, setUsecaseText] = useState('');
    const [usecaseQuotes, setUsecaseQuotes] = useState('');
    const [usecaseFacts, setUsecaseFacts] = useState('');
    const [headline, setHeadline] = useState('');


const [successMessage, setSuccessMessage] = useState('');

 useEffect(() => {
        fetchCompanyData();
    }, []);

const fetchCompanyData = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }
        const response = await axios.get('/companies/get-company', {
            headers: { Authorization: `Bearer ${token}` }
        });
        const usecasesResponse = await axios.get('/content/usecases/get-for-company', {
            headers: { Authorization: `Bearer ${token}` }
        });

        const filteredUsecases = usecasesResponse.data.filter(usecase => usecase._id === usecaseId);

           if (filteredUsecases.length > 0) {
          setUsecaseBullets(filteredUsecases[0].usecaseBullets || '');
                setUsecaseText(filteredUsecases[0].usecaseText || '');
                setUsecaseQuotes(filteredUsecases[0].usecaseQuotes || '');
                setUsecaseFacts(filteredUsecases[0].usecaseFacts || '');
                setHeadline(filteredUsecases[0].headline || '');

        }

        setCompany(response.data);
        setUsecases(filteredUsecases);
   

    } catch (error) {
    }
};


const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }

        let updatedCompany = {...company};

        if (!usecaseId) {
            const userId = localStorage.getItem('userId');
            const newUsecase = {
                usecaseBullets,
                usecaseText,
                usecaseQuotes,
                usecaseFacts,
                headline,
                usecaseTranslations: [],
                creator: userId,
                creationDate: new Date()
            };

            updatedCompany.content.usecases = [...(updatedCompany.content?.usecases || []), newUsecase];

        } else {

            const usecaseIndex = updatedCompany.content.usecases.findIndex(pr => pr._id === usecaseId);
            if (usecaseIndex !== -1) {
                updatedCompany.content.usecases[usecaseIndex] = {
                    ...updatedCompany.content.usecases[usecaseIndex],
                    usecaseBullets,
                    usecaseText,
                    usecaseQuotes,
                    usecaseFacts,
			headline,
                };
            }
        }

        const response = await axios.put(`/companies/${updatedCompany._id}`, updatedCompany, {
            headers: { Authorization: `Bearer ${token}` }
        });

        setCompany(response.data);

        setSuccessMessage('Saved successfully.');
setTimeout(() => {
            setSuccessMessage('');
        }, 2000);
        fetchCompanyData();
    } catch (error) {
    }
};




  return (
<>


<textarea className="content-box-main-headline" name="headline" value={headline} onChange={(e) => setHeadline(e.target.value)} placeholder={t('usecase_headline')} rows="3" cols="50" />
<textarea className="content-box-main-textarea" value={usecaseText} onChange={(e) => setUsecaseText(e.target.value)} placeholder={t('usecase_text')} rows="10" cols="50" />


       


 <div className="content-box three-one">
                    <button className="save" onClick={handleSubmit}>{t('save_button')}</button>
                    <button className="save" onClick={onCancel}>{t('cancel_button')}</button>
                </div>
 
{successMessage && <div className="content-box blue-background"> {successMessage}</div>}




</>
    );
};

export default CompanyDashboardUsecaseFunction;
