import React, { useEffect, useState, useCallback } from 'react';
import '../../Spinner.css';
import { useTranslation } from 'react-i18next';
import axios from '../../axios.js';
import { useHistory } from 'react-router-dom';

const CompanyUsecaseTranslate = ({ existingCompany, usecaseId, users, formatDate, onCancel }) => {

    const [usecases, setUsecases] = useState([]);
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState(existingCompany || { content: { usecases: [usecaseId] } });
    const [usecaseText, setUsecaseText] = useState('');
    const [headline, setHeadline] = useState('');
    const [textTranslation, setTextTranslation] = useState('');
    const [headlineTranslation, setHeadlineTranslation] = useState('');
    const [translationLanguageSelected, setTranslationLanguageSelected] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

 useEffect(() => {
        fetchCompanyData();
    }, []);

const fetchCompanyData = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }
const response = await axios.get('/companies/get-company', {
            headers: { Authorization: `Bearer ${token}` }
        });

       
        const usecasesResponse = await axios.get('/content/usecases/get-for-company', {
            headers: { Authorization: `Bearer ${token}` }
        });

        const filteredUsecases = usecasesResponse.data.filter(usecase => usecase._id === usecaseId);
           if (filteredUsecases.length > 0) {
                setUsecaseText(filteredUsecases[0].usecaseText || '');
                setHeadline(filteredUsecases[0].headline || '');

        }

        setCompany(response.data);
        setUsecases(filteredUsecases);
    } catch (error) {
    }
};


const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }

        let updatedCompany = {...company};

        const usecaseIndex = updatedCompany.content.usecases.findIndex(pr => pr._id === usecaseId);

        if (usecaseIndex !== -1) {
            let usecase = updatedCompany.content.usecases[usecaseIndex];

            const translationIndex = usecase.usecaseTranslations.findIndex(translation => translation.language === textTranslation);

            if (translationIndex !== -1) {
                usecase.usecaseTranslations[translationIndex] = {
                    ...usecase.usecaseTranslations[translationIndex],
                    translatedUsecaseHeadline: headlineTranslation,
                    translatedUsecaseText: textTranslation
                };
            } else {
                usecase.usecaseTranslations.push({
                    language: translationLanguageSelected,
                    translatedUsecaseHeadline: headlineTranslation,
                    translatedUsecaseText: textTranslation
                });
            }

            updatedCompany.content.usecases[usecaseIndex] = usecase;
        } else {
        }

        const response = await axios.put(`/companies/${updatedCompany._id}`, updatedCompany, {
            headers: { Authorization: `Bearer ${token}` }
        });

        setCompany(response.data);
        setSuccessMessage('Saved successfully.');
        setTimeout(() => {
            setSuccessMessage('');
        }, 2000);
        fetchCompanyData();
    } catch (error) {
    }
};


 const processUsecaseTranslation = useCallback(async () => {
        if (isLoading) return;
        setIsLoading(true);

        setTranslationLanguageSelected(textTranslation);

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setIsLoading(false);
                return;
            }

            const requestBody = {
                headline: headline,
                text: usecaseText,
                targetLanguage: textTranslation
            };

            const response = await axios.post('/content/translate-usecases', requestBody, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.data) {
                setHeadlineTranslation(response.data.translatedHeadline || '');
                setTextTranslation(response.data.translatedText || '');
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }, [isLoading, textTranslation, headlineTranslation, company?.content, setHeadlineTranslation, setTextTranslation, setTranslationLanguageSelected]);



  return (
<>
            {isLoading && <div className="spinner-overlay"><div className="spinner"></div></div>}

<div className="content-container">

<div className="content-container-column col-two">

<div className="content-box col-two row-eight">
               <div className="inner-content-container">
         
{headlineTranslation && (  
<>
 <div className="inner-content-box col-five row-one">
                <textarea rows={16} className="content-box-main-headline"
 name="textTranslation" value={headlineTranslation} onChange={e => setHeadlineTranslation(e.target.value)} />
   </div>

 <div className="inner-content-box col-five row-eleven">
 <textarea placeholder={t('translate_instructions')}
                rows={16} className="content-box-main-textarea"
 name="textTranslation" value={textTranslation} onChange={e => setTextTranslation(e.target.value)} />
   </div>
</>
)}


{!headlineTranslation && (  
<>
 <div className="inner-content-box col-five row-twelve">
 <textarea placeholder={t('translate_instructions')}
                rows={16} className="content-box-main-textarea"
 name="textTranslation" value={textTranslation} onChange={e => setTextTranslation(e.target.value)} />
   </div>
</>
)}

<div className="inner-content-box">
<button className="editor-button" onClick={processUsecaseTranslation} disabled={isLoading}>
                        {t('translate_button')}
                    </button>
</div>
        </div>
       </div>
</div>
{successMessage && <div className="content-box blue-background"> {successMessage}</div>}

{!successMessage && (
<div className="content-container-column col-one">

 <div className="content-box row-one">
                    <button className="save" onClick={handleSubmit}>{t('save_button')}</button>
                    <button className="save" onClick={onCancel}>{t('cancel_button')}</button>
                </div>
 


<div className="content-box row-six">
	<h1>{headline}</h1>
<p><br></br></p>
	<p className="small">{usecaseText}</p>
</div>


</div>
)}
            

</div>
</>
    );
};

export default CompanyUsecaseTranslate;
