import React, { useState, useCallback } from 'react';
import axios from '../axios.js';
import '../Spinner.css';
import { useTranslation } from 'react-i18next';

const CompanyVisionTranslate = ({ company, handleChange, handleTranslationSave }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const [translation, setTranslation] = useState(company.content?.vision?.visionTranslations.language);
    const [successMessage, setSuccessMessage] = useState('');

const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = localStorage.getItem('userId');
    const updatedCompany = { ...company };

    if (updatedCompany.content?.vision) {
        updatedCompany.content.vision.creator = userId;
    }

   try {
        const response = await axios.put(`/companies/${updatedCompany._id}`, updatedCompany);
        setSuccessMessage(t('translation_update_success'));
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
    }
};


const handleRefresh = () => {
        window.location.reload();
    };

    const processVisionOpenAI = useCallback(async () => {
        if (isLoading) return;
        setIsLoading(true);

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setIsLoading(false);
                return;
            }

            const response = await axios.post('/content/translate-vision-text', {
                visionText: company.content.vision.visionText,
                targetLanguage: translation,
            }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            if (response.data && response.data.translatedText) {
                setTranslation(response.data.translatedText);
                handleTranslationSave(translation, response.data.translatedText);
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }, [isLoading, translation, company.content.vision.visionText, handleTranslationSave]);



  return (
    
<>
    {isLoading && <div className="spinner-overlay"><div className="spinner"></div></div>}



<div className="content-container-column col-two">
 <div className="content-box row-eight">	
               <div className="inner-content-container">

 <div className="inner-content-box col-five row-eleven">
            <textarea name="translation" className="content-box-main-textarea" placeholder={t('choose_language')} value={translation} onChange={e => setTranslation(e.target.value)} />
   </div>


<div className="inner-content-box col-two">

            <button className="editor-button" onClick={processVisionOpenAI} disabled={isLoading}>
                        {t('translate')}
            </button>
        </div>

</div>
</div>
    
      </div>     

<div className="content-container-column col-one">

 <div className="content-button-box row-one">


          <button className="save" type="submit" onClick={handleSubmit}>{t('save_button')}</button>
            <button className="save"  type="button" onClick={handleRefresh}>{t('cancel_button')}</button>

</div>

{successMessage && <div className="content-box blue-background"> {successMessage}</div>}

  
 <div className="content-box row-five">
<h2>{t('your_vision')}</h2>
<p>{company.content?.vision?.visionText || ''}</p>
        </div>
   </div>


</>
);
};

export default CompanyVisionTranslate;
