import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const BarGraph = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={150}>
      <BarChart
        data={data}
        margin={{
          top: 20, right: 0, left: 0, bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <Tooltip />
        <Bar dataKey="value" fill="#FFA500" barSize={40} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarGraph;
