import React, { createContext, useContext, useState } from 'react';
 
const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const showModal = (content) => {
    setModalContent(content);
    setIsVisible(true);
  };

  const hideModal = () => {
    setIsVisible(false);
  };

  return (
    <ModalContext.Provider value={{ showModal, hideModal, modalContent, isVisible }}>
      {children}
    </ModalContext.Provider>
  );
};
