import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';

import Gauge from '../Gauge'; 

const DashboardApprovalsCountPage = ({ companyId }) => {
    const [error, setError] = useState('');
    const [openApprovals, setOpenApprovals] = useState([]);
    const [allApprovals, setAllApprovals] = useState([]);
    const [myApprovals, setMyApprovals] = useState([]);
    const [creatorApprovals, setCreatorApprovals] = useState([]);
    const [activeApprovalId, setActiveApprovalId] = useState(null);
    const [approvalRequest, setApprovalRequest] = useState(null);
    const [approvals, setApprovals] = useState([]);
    const [totalApprovals, setTotalApprovals] = useState(0);
    const [myApprovalsCount, setMyApprovalsCount] = useState(0);
    const [openApprovalsCount, setOpenApprovalsCount] = useState(0);
    const [allOpenApprovalsCount, setAllOpenApprovalsCount] = useState(0);
    const { fetchWithAuth } = useAuth();
    const { t } = useTranslation();
    const navigate = useNavigate();

useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    const fetchCounts = async () => {
        try {
            const countsEndpoint = `/approval/approvals/${companyId}/counts?userId=${storedUserId}`;
            const response = await fetchWithAuth(countsEndpoint);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();

            setTotalApprovals(data.totalApprovals);
            setMyApprovalsCount(data.userRelatedApprovals);
            setOpenApprovalsCount(data.openApprovals);
	    setAllOpenApprovalsCount(data.allOpenApprovals);

          } catch (error) {
                setError(`Failed to load data. Please try again later. Error: ${error.message}`);
             }
        };

        fetchCounts();
    }, [companyId, navigate]);


    const handleEditClick = (approvalRequest) => {
        navigate(`/app/app/give-approval/${approvalRequest.onModel}/${approvalRequest.contentId}`, { state: { companyId } });
    };

   

    return (
        <div>
            <div className="dashboard-content-top">
                <div>{t('approval_score')}</div>
                <div><NavLink to="give-approval">{t('improve_your_score')}</NavLink></div>
            </div>
            <div className="dashboard-content-table">
                                       <p>{t('your_open_approvals')}: {openApprovalsCount}</p>

                <div className="dashboard-stats">
        <div className="gauge"><Gauge value={openApprovalsCount} total={allOpenApprovalsCount} /></div>

                </div>
            </div>
        </div>
    );
};

export default DashboardApprovalsCountPage;
