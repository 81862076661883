import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate, NavLink } from 'react-router-dom';
import axios from '../../axios.js';

import CompanyDashboardBlogpostFunction from './companyDashboardBlogpostFunction';
import DashboardBlogpostList from './DashboardBlogpostList';
import BlogpostModal from './blogpostViewModal';
import LoadingIndicator from '../../LoadingIndicator';


const DashboardBlogposts = ({ existingCompany, onApprove, onCollaborate, onToggleSubscription }) => {
    const [company, setCompany] = useState(existingCompany || { content: { blogposts: [] } });
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [blogpostId, setBlogpostId] = useState([]);
    const [blogposts, setBlogposts] = useState([]);
    const [users, setUsers] = useState([]);
    const { t } = useTranslation();
    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBlogpost, setSelectedBlogpost] = useState(null);
    const [isTranslationModalOpen, setIsTranslationModalOpen] = useState(false);
    const [editingBlogpostId, setEditingBlogpostId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isBlogpostFunctionModalOpen, setIsBlogpostFunctionModalOpen] = useState(false);

useEffect(() => {
        fetchCompanyData();
    }, []);

useEffect(() => {
  if (blogposts.length > 0) {
  }
}, [blogposts]);

 useEffect(() => {
        const fetchUsers = async () => {
            const token = localStorage.getItem('token');
            const companyID = company?._id;
            if (token && companyID) {
                try {
                    const response = await axios.get(`/content/get-for-company/${companyID}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setUsers(response.data || []);
                } catch (error) {
                }
            }
        };

        fetchUsers();
    }, [company]); 


const handleEditBlogpost = (blogpostId) => {
  resetState();
  setEditingBlogpostId(blogpostId);
  handleOpenBlogpostFunctionModal();
};





const resetState = () => {
    setIsAddingNew(false);
    setEditingIndex(-1);
    setBlogpostId([]);
    setEditingBlogpostId(null);
    fetchCompanyData(); 
};






const fetchCompanyData = async () => {
  try {
    setIsLoading(true);

    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    if (!token) {
      return;
    }

    const response = await axios.get('/companies/get-company', {
      headers: { Authorization: `Bearer ${token}` }
    });

    const blogpostsResponse = await axios.get('/content/blogposts/get-for-company', {
      headers: { Authorization: `Bearer ${token}` }
    });

    const sortedBlogposts = blogpostsResponse.data.sort((a, b) => {
      return new Date(b.creationDate) - new Date(a.creationDate);
    });

    const filteredAndLimitedBlogposts = sortedBlogposts.filter(blogpost => blogpost.creator === userId).slice(0, 3);

    setCompany(response.data);
    setBlogposts(filteredAndLimitedBlogposts);
    setIsLoading(false);

  } catch (error) {
    setIsLoading(false);
  }
};

const onCompanyUpdate = (updatedCompany) => {
        setCompany(updatedCompany);
    };


const handleFinishAddingOrEditing = () => {
        handleCloseBlogpostFunctionModal();
        resetState();
fetchCompanyData();
    };

    const handleCancelAddingOrEditing = () => {
        handleCloseBlogpostFunctionModal();

        resetState();
fetchCompanyData();
    };

  const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };


const handleCopyBlogpost = (data) => {
  let formattedData;

  if (typeof data === 'number') {

    const blogpost = blogposts[data];
    formattedData = `
${blogpost.headline || 'Untitled blogpost'}

${blogpost.blogpostText || ''}

Contact:
${blogpost.contact || 'No contact information'}
`;
  } else if (typeof data === 'object' && data.translatedBlogpostHeadline) {

    formattedData = `
Headline: ${data.translatedBlogpostHeadline || 'Untitled Translation'}
Text: ${data.translatedBlogpostText || ''}
`;
  } else {
    return;
  }

  navigator.clipboard.writeText(formattedData)
    .then(() => alert("Details copied!"))
    .catch(err => console.error("Failed to copy: ", err));
};

const handleViewBlogpost = (blogpostId) => {
    const selectedBlogpost = company.content.blogposts.find(blogpost => blogpost._id === blogpostId);
    if (selectedBlogpost) {
        setSelectedBlogpost(selectedBlogpost);
        setIsModalOpen(true);
        setIsTranslationModalOpen(false);
    } else {
    }
};


    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedBlogpost(null);
    };


 const handleOpenBlogpostFunctionModal = () => {
        setIsBlogpostFunctionModalOpen(true);
    };

    const handleCloseBlogpostFunctionModal = () => {
        setIsBlogpostFunctionModalOpen(false);
    };





 let blogpostFunctionModalContent = isBlogpostFunctionModalOpen ? (
<div className="modal-backdrop-edit">
	<div className="modal-edit-content">
        <CompanyDashboardBlogpostFunction
            company={company}
            onFinish={handleFinishAddingOrEditing}
            blogpostId={editingBlogpostId}
            isAddingNew={isAddingNew}
            users={users}
            formatDate={formatDate}
            onCancel={handleCancelAddingOrEditing}
        />
</div>
</div>
    ) : null;


let content = (
            <DashboardBlogpostList 
    	    	onToggleSubscription={onToggleSubscription}

                blogposts={blogposts} 
                handleEditBlogpost={handleEditBlogpost}
                onCopy={handleCopyBlogpost} 
                onPressView={handleViewBlogpost}
                users={users}
            />
    
    );


if (isLoading) {
    return <div> <LoadingIndicator />
</div>;
  }

    return (
        <>
            {blogpostFunctionModalContent}

 <div className="dashboard-content-top">
                <div>{t('blogposts')}</div>
		<div> <NavLink to="blogposts" className={({ isActive }) => isActive ? 'active' : ''}>{t('view_all')}</NavLink></div> 
	    </div>

     <BlogpostModal 
                isOpen={isModalOpen} 
                onClose={handleCloseModal} 
                blogpost={selectedBlogpost} 
            />

   


            {content}
        </>
    );
};

export default DashboardBlogposts;