import React, { useEffect, useState, useCallback } from 'react';
import '../../Spinner.css';
import { useTranslation } from 'react-i18next';
import axios from '../../axios.js';
import { useHistory } from 'react-router-dom';

const CompanyPressReleaseFunction = ({ existingCompany, pressReleaseId, users, formatDate, onCancel }) => {
    const [pressReleases, setPressReleases] = useState([]);
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState(existingCompany || { content: { pressreleases: [pressReleaseId] } });
    const [pressReleaseBullets, setPressReleaseBullets] = useState('');
    const [pressReleaseText, setPressReleaseText] = useState('');
    const [pressReleaseQuotes, setPressReleaseQuotes] = useState('');
    const [pressReleaseFacts, setPressReleaseFacts] = useState('');
    const [headline, setHeadline] = useState('');
    const [contact, setContact] = useState('');
    const [date, setDate] = useState('');
    const contextAI = company?.context?.contextCompany || '';
    const [successMessage, setSuccessMessage] = useState('');
const [errorMessage, setErrorMessage] = useState('');

 useEffect(() => {
        fetchCompanyData();
    }, []);

const fetchCompanyData = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }
        const response = await axios.get('/companies/get-company', {
            headers: { Authorization: `Bearer ${token}` }
        });
        const pressReleasesResponse = await axios.get('/content/press-releases/get-for-company', {
            headers: { Authorization: `Bearer ${token}` }
        });

        const filteredPressReleases = pressReleasesResponse.data.filter(pressRelease => pressRelease._id === pressReleaseId);

           if (filteredPressReleases.length > 0) {
          setPressReleaseBullets(filteredPressReleases[0].pressReleaseBullets || '');
                setPressReleaseText(filteredPressReleases[0].pressReleaseText || '');
                setPressReleaseQuotes(filteredPressReleases[0].pressReleaseQuotes || '');
                setPressReleaseFacts(filteredPressReleases[0].pressReleaseFacts || '');
                setHeadline(filteredPressReleases[0].headline || '');
		setContact(filteredPressReleases[0].contact || '');
		setDate(filteredPressReleases[0].date || '');


        }

        setCompany(response.data);
        setPressReleases(filteredPressReleases);
   

    } catch (error) {
    }
};


const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("handleSubmit triggered");

    try {

if (!pressReleaseText.trim()) {
    setErrorMessage(t('saving_error'));
    setTimeout(() => {
        setErrorMessage('');
    }, 2000);
    return;
}

        const token = localStorage.getItem('token');
        console.log("Retrieved token:", token);

        if (!token) {
            console.log("No token found, exiting the function.");
            return;
        }

        let updatedCompany = {...company};
        console.log("Current state of updatedCompany:", updatedCompany);

        // Ensure the content object exists
        updatedCompany.content = updatedCompany.content || {};
        // Ensure the pressreleases array exists
        updatedCompany.content.pressreleases = updatedCompany.content.pressreleases || [];

        if (!pressReleaseId) {
            console.log("Creating a new press release");
            const userId = localStorage.getItem('userId');
            console.log("Retrieved userId:", userId);

            const newPressRelease = {
                pressReleaseBullets,
                pressReleaseText,
                pressReleaseQuotes,
                pressReleaseFacts,
                headline,
                date,
                contact,
                multimedia_links: [],
                pressReleaseTranslations: [],
                creator: userId,
                creationDate: new Date()
            };
            console.log("New press release data:", newPressRelease);

            updatedCompany.content.pressreleases.push(newPressRelease);
            console.log("Updated company with new press release:", updatedCompany);
        } else {
            console.log("Updating an existing press release with ID:", pressReleaseId);
            const pressReleaseIndex = updatedCompany.content.pressreleases.findIndex(pr => pr._id === pressReleaseId);

            if (pressReleaseIndex !== -1) {
                updatedCompany.content.pressreleases[pressReleaseIndex] = {
                    ...updatedCompany.content.pressreleases[pressReleaseIndex],
                    pressReleaseBullets,
                    pressReleaseText,
                    pressReleaseQuotes,
                    pressReleaseFacts,
                    headline,
                    date,
                    contact
                };
                console.log("Updated press release data at index:", pressReleaseIndex);
            } else {
                console.log("Press release with the provided ID not found");
            }
        }

        console.log("Sending updated company data to the server:", updatedCompany);
        const response = await axios.put(`/companies/${updatedCompany._id}`, updatedCompany, {
            headers: { Authorization: `Bearer ${token}` }
        });
        console.log("Server response:", response);

        setCompany(response.data);
    setSuccessMessage(t('save_success'));
        setTimeout(() => {
            setSuccessMessage('');
        }, 2000);
        fetchCompanyData();
    } catch (error) {
        console.error("An error occurred in handleSubmit:", error);
    }
};



const processPressReleaseOpenAI = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    const token = localStorage.getItem('token');
    if (!token) {
        setIsLoading(false);
        return;
    }

   if (!pressReleaseText) {
        setIsLoading(false);
        return;
    }

    try {
        let bulletsResponse = await axios.post('/content/process-pressRelease-bullets', {
            pressReleaseBullets: pressReleaseText 
        }, { headers: { 'Authorization': `Bearer ${token}`,'Accept-Language': i18n.language } });

        let descriptionResponse = await axios.post('/content/process-pressRelease-text', {
            contextAI: contextAI,
            pressReleaseBullets: bulletsResponse.data.openAiResponse,              
            pressReleaseFacts: pressReleaseFacts,
            pressReleaseQuotes: pressReleaseQuotes
        }, { headers: { 'Authorization': `Bearer ${token}`,'Accept-Language': i18n.language } });

        if (bulletsResponse.data && descriptionResponse.data) {
            setPressReleaseBullets(bulletsResponse.data.openAiResponse);
            setPressReleaseText(descriptionResponse.data.openAiResponse);
        }

        if (descriptionResponse.data.openAiResponse) {
            const headlineResponse = await axios.post('/content/process-pressRelease-headline', {
                pressReleaseText: descriptionResponse.data.openAiResponse
            }, { headers: { 'Authorization': `Bearer ${token}`, 'Accept-Language': i18n.language } });


            if (headlineResponse.data) {
                setHeadline(headlineResponse.data.openAiResponse);
            } else {
            }
        }
    } catch (error) {
    } finally {
        setIsLoading(false);
    }
}, [
    isLoading, 
    pressReleaseText, 
    contextAI, 
    pressReleaseFacts, 
    pressReleaseQuotes, 
    i18n.language   
]);



  return (
<>
            {isLoading && <div className="spinner-overlay"><div className="spinner"></div></div>}

<div className="content-container">

<div className="content-container-column col-two">

<div className="content-box col-two row-eight">
               <div className="inner-content-container">
         
{
    headline && (

            <>

                        <div className="inner-content-box col-five row-two">

<textarea className="content-box-main-headline" name="headline" value={headline} onChange={(e) => setHeadline(e.target.value)} placeholder={t('pressrelease_headline')} rows="5" cols="50" />
  </div>

                        <div className="inner-content-box col-five row-eight">

            <textarea className="content-box-main-textarea" value={pressReleaseText} onChange={(e) => setPressReleaseText(e.target.value)} placeholder={t('pressrelease_text')} rows="5" cols="50" />
</div>
</>
)} 


{
    !headline && (
<>
 <div className="inner-content-box col-five row-ten">
            <textarea className="content-box-main-textarea" value={pressReleaseText} onChange={(e) => setPressReleaseText(e.target.value)} placeholder={t('pressrelease_instructions')} />
   </div>
</>
)}
<div className="inner-content-box col-two row-two">
            <textarea className="content-box-insert-textarea" value={pressReleaseQuotes} onChange={(e) => setPressReleaseQuotes(e.target.value)} placeholder={t('pr_quotes')} />
</div>

<div className="inner-content-box col-two row-two">
            <textarea className="content-box-insert-textarea" value={pressReleaseFacts} onChange={(e) => setPressReleaseFacts(e.target.value)} placeholder={t('pr_facts')} />
</div>

<div className="inner-content-box">
<button className="editor-button" onClick={processPressReleaseOpenAI} disabled={isLoading}>
                        {headline ? t('re_generate_button') : t('generate_button')}
                    </button>
</div>
        </div>
       </div>
</div>
{successMessage && <div className="content-box blue-background"> {successMessage}</div>}
{errorMessage && <div className="content-box blue-background"> {errorMessage}</div>}

{!successMessage && 
<div className="content-container-column col-one">

 <div className="content-button-box row-one">
                    <button className="save" onClick={handleSubmit}>{t('save_button')}</button>
                    <button className="save" onClick={onCancel}>{t('cancel_button')}</button>
                </div>


<div className="content-box row-two">     

<div className="input-section">
                    <p className="small">{t('contact_person')}:
                    <select
                        name="contact"
                        value={contact}
                        onChange={(e) => setContact(e.target.value)}
                    >
                        <option value="">{t('select_contact_person')}</option>
                        {users.map((user) => (
                            <option key={user._id} value={user.email}>
                                {user.name} {user.surname}
                            </option>
                        ))}
                    </select>
</p>
                </div>

<div className="input-section">
                    <p className="small">{t('dateline')}:
                   <input 
    type="date" 
    name="date" 
    value={date.split('T')[0]}
    onChange={(e) => setDate(e.target.value)}
/></p>
</div>
</div>
 

              <div className="content-box row-four"><h2>{t('good_to_know')}</h2><p className="small">{t('pressrelease_intro_text')}</p></div>
            
</div>
}

</div>
</>
    );
};

export default CompanyPressReleaseFunction;
