import React, { useState, useEffect } from 'react';
import axios from '../axios.js';
import NewUserForm from './NewUserForm';
import { useTranslation } from 'react-i18next';
 
const CompanyForm = ({ existingCompany }) => {
 const initialState = { name: '', };
const [formData, setFormData] = useState({
        name: '',
        surname: '',
        });

    const [company, setCompany] = useState(initialState);
    const [thisCompanyId, setThisCompanyId] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        const storedCompanyId = localStorage.getItem('buzzBeatCompanyId');
        if (storedCompanyId) {
            setThisCompanyId(storedCompanyId);
        }
    }, []);


  useEffect(() => {
        if (!existingCompany) {
            fetchCompanyData();
        } else {
            setCompany(existingCompany);
        }
    }, [existingCompany]);

    const fetchCompanyData = async () => {
         try {
            const token = localStorage.getItem('token');
            if (!token) {
                return;
            }
            const response = await axios.get('/companies/get-company', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setCompany(response.data);
        } catch (error) {
        }
    };

useEffect(() => {
    const fetchUserDetails = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return;
  }

  try {
    const response = await axios.get('/registerlogin/getUserDetails', {
      headers: { Authorization: `Bearer ${token}` }
    });
    const { name, surname, email, infos } = response.data;

    localStorage.setItem('userEmail', email);

    const updatedInfos = {
      roleInCompany: '',
      telephone: '',
      mobile: '',
      address: '',
      ...infos 
    };

    setFormData({ name, surname, email, infos: updatedInfos });
  } catch (error) {
  }
};


    fetchUserDetails();
}, []);


    return (
<>
  {t('welcome')} {formData.name} {t('from')} {company.name || ''}
</>
    );
};

export default CompanyForm;
