import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../axios.js';

import CompanyArticleFunction from './companyArticleFunction';
import ArticleList from './ArticleList';
import CompanyArticleTranslate from './companyArticleTranslate';

import ArticleModal from './articleViewModal';
import ArticleTranslationModal from './articleTranslationViewModal';

import LoadingIndicator from '../../LoadingIndicator';


const CompanyArticlePage = ({ existingCompany, onApprove, onCollaborate, onToggleSubscription }) => {
    const [company, setCompany] = useState(existingCompany || { content: { articles: [] } });
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [editingIndex, setEditingIndex] = useState(-1);
const [articleId, setArticleId] = useState([]);
const [translatingId, setTranslatingId] = useState(null);
    const [articles, setArticles] = useState([]);
    const [users, setUsers] = useState([]);
    const { t } = useTranslation();
    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState('');
 const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState(null);
const [selectedLanguage, setSelectedLanguage] = useState('en');
const [headlineTranslation, setHeadlineTranslation] = useState('');
const [textTranslation, setTextTranslation] = useState('');
  const [isTranslationModalOpen, setIsTranslationModalOpen] = useState(false);
    const [selectedTranslation, setSelectedTranslation] = useState(null);
    const [editingArticleId, setEditingArticleId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


 const handleTranslationLanguageSelected = (language) => {
        setSelectedLanguage(language); 
     };

 const handleArticleApproval = (index) => {
    if (articles[index]) {
        const contentId = articles[index]._id;
        onApprove(contentId, 'articles');
    } else {
    }
};


 const handleArticleCollaborate = (index) => {
    if (articles[index]) {
        const contentId = articles[index]._id;
        onCollaborate(contentId, 'articles');
    } else {
    }
};



    useEffect(() => {
    if (location.state?.addNew) {
        addNewArticle();
    } else if (location.state?.reset) {
        resetState();
    }
}, [location]);


 useEffect(() => {
        fetchCompanyData();
    }, []);

useEffect(() => {
  if (articles.length > 0) {
  }
}, [articles]);

 useEffect(() => {
        const fetchUsers = async () => {
            const token = localStorage.getItem('token');
            const companyID = company?._id;
            if (token && companyID) {
                try {
                    const response = await axios.get(`/content/get-for-company/${companyID}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setUsers(response.data || []);
                } catch (error) {
                }
            }
        };

        fetchUsers();
    }, [company]); 


const handleEditArticle = (articleId) => {
  resetState();
  setEditingArticleId(articleId);
};


const handleTranslateArticle = (articleId) => {
  resetState();
     setTranslatingId(articleId);

};

const resetState = () => {
    setIsAddingNew(false);
    setEditingIndex(-1);
    setArticleId([]);
    setTranslatingId(null); 
    setEditingArticleId(null);
    fetchCompanyData(); 
};






const fetchCompanyData = async () => {
    try {
  setIsLoading(true);

        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        if (!token) {
            return;
        }
        const response = await axios.get('/companies/get-company', {
            headers: { Authorization: `Bearer ${token}` }
        });
        const articlesResponse = await axios.get('/content/articles/get-for-company', {
            headers: { Authorization: `Bearer ${token}` }
       });

        // Filter articles where the 'creator' field matches the logged-in user's ID
        const filteredArticles = articlesResponse.data.filter(article => article.creator === userId);

        setCompany(response.data);
        setArticles(filteredArticles);
  setIsLoading(false);

    } catch (error) {
    }
};


    const onCompanyUpdate = (updatedCompany) => {
        setCompany(updatedCompany);
    };

const addNewArticle = () => {
        resetState();
        setIsAddingNew(true);
        setEditingArticleId(false);
        setTranslatingId(null);
};




    const handleFinishAddingOrEditing = () => {
        resetState();
fetchCompanyData();
    };

    const handleCancelAddingOrEditing = () => {
        resetState();
fetchCompanyData();
    };


const handleDeleteArticle = async (data) => {
  if (typeof data === 'number') {
    const articleToDelete = articles[data];
    const articleId = articleToDelete._id;
    if (articleId && window.confirm(t('confirm_delete_article'))) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`/content/articles/${articleId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const updatedArticles = articles.filter((_, i) => i !== data);
        setArticles(updatedArticles);
        setSuccessMessage(t('article_deleted_success'));
      } catch (error) {
        alert(t('article_delete_failed'));
      }
    }
  } else if (typeof data === 'object' && data.hasOwnProperty('articleIndex') && data.hasOwnProperty('translationIndex')) {
        const { articleIndex, translationIndex } = data;

        if (window.confirm(t('confirm_delete_translation'))) {
            try {
                const articleId = articles[articleIndex]._id;
                const translationId = articles[articleIndex].articleTranslations[translationIndex]._id;
                const token = localStorage.getItem('token');

                await axios.delete(`/content/articles/${articleId}/translations/${translationId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });

                const updatedArticles = [...articles];
                updatedArticles[articleIndex].articleTranslations.splice(translationIndex, 1);
                setArticles(updatedArticles);
                setSuccessMessage(t('translation_deleted_success'));
            } catch (error) {
            }
        }
    }
};



  const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };


const handleCopyArticle = (data) => {
  let formattedData;

  if (typeof data === 'number') {

    const article = articles[data];
    formattedData = `
${article.headline || 'Untitled article'}

${article.articleText || ''}

Contact:
${article.contact || 'No contact information'}
`;
  } else if (typeof data === 'object' && data.translatedArticleHeadline) {

    formattedData = `
Headline: ${data.translatedArticleHeadline || 'Untitled Translation'}
Text: ${data.translatedArticleText || ''}
`;
  } else {
    return;
  }

  navigator.clipboard.writeText(formattedData)
    .then(() => alert("Details copied!"))
    .catch(err => console.error("Failed to copy: ", err));
};

const handleViewArticle = (articleId) => {
    const selectedArticle = company.content.articles.find(article => article._id === articleId);
    if (selectedArticle) {
        setSelectedArticle(selectedArticle);
        setIsModalOpen(true);
        setIsTranslationModalOpen(false);
    } else {
    }
};


    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedArticle(null);
    };

const handleViewTranslation = (translation) => {
    setSelectedTranslation(translation);
    const relatedArticle = articles.find(pr => pr.articleTranslations.some(t => t._id === translation._id));
    
    if (relatedArticle) {
        setSelectedArticle(relatedArticle);
    } else {
        return;
    }

    setIsTranslationModalOpen(true);
    setIsModalOpen(false);
};

    const handleCloseTranslationModal = () => {
        setIsTranslationModalOpen(false);
        setSelectedTranslation(null);
    };

let content;
if (translatingId !== null) {
    content = 
       <CompanyArticleTranslate
       setHeadlineTranslation={setHeadlineTranslation}
    textTranslation={textTranslation}
    setTextTranslation={setTextTranslation}
                onTranslationLanguageSelected={handleTranslationLanguageSelected}
onCancel={handleCancelAddingOrEditing}
        articleId={translatingId} 
/>;

    } else if (isAddingNew || editingArticleId !== null) {
        content = 
    <CompanyArticleFunction 
        company={company} 
        onFinish={handleFinishAddingOrEditing} 
        articleId={editingArticleId} 
        isAddingNew={isAddingNew} 
        users={users} 
	formatDate={formatDate}
	onCancel={handleCancelAddingOrEditing}
    />;
} else {
    content = (

<div className="content-list-container">
            <ArticleList 
                onToggleSubscription={onToggleSubscription}
                articles={articles} 
                handleEditArticle={handleEditArticle}
                onTranslate={handleTranslateArticle} 
                onCopy={handleCopyArticle} 
                onDelete={handleDeleteArticle} 
                onPressView={handleViewArticle}
                onPressViewTranslation={handleViewTranslation}
                users={users}
                onPressApprove={handleArticleApproval}
                onPressCollaborate={handleArticleCollaborate}
            />
</div>
    );
}

if (isLoading) {
    return <div> <LoadingIndicator />
</div>;
  }

    return (
        <>

     <ArticleModal 
                isOpen={isModalOpen} 
                onClose={handleCloseModal} 
                article={selectedArticle} 
            />

    <ArticleTranslationModal
    isOpen={isTranslationModalOpen}
    onClose={handleCloseTranslationModal}
    article={selectedArticle}
    translation={selectedTranslation}
/>

            {content}
        </>
    );
};

export default CompanyArticlePage;