import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ArticleTranslationModal = ({ isOpen, onClose, article, translation }) => {
    const [copySuccess, setCopySuccess] = useState('');
    const { t } = useTranslation();

    if (!isOpen || !translation || !article) return null;

    const headline = translation.translatedArticleHeadline || article.headline;
    const content = translation.translatedArticleText || article.articleText;
    const contact = article.contact;
    const date = article.date;

    const renderArticleText = () => {
        const paragraphs = content.split('\n');
        return paragraphs.map((paragraph, index) => (
            <p key={index} className="press-release-text">{paragraph}<br /></p>
        ));
    };

    const copyToClipboard = text => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopySuccess(t('copy_success'));
                setTimeout(() => setCopySuccess(''), 3000);
            })
            .catch(err => {
                setCopySuccess(t('copy_error'));
                setTimeout(() => setCopySuccess(''), 3000);
            });
    };

    const handleCopyHeadline = () => copyToClipboard(headline);
    const handleCopyContent = () => copyToClipboard(content);
    const handleCopyComplete = () => copyToClipboard(
        `Headline: ${headline}\n\n` +
        `Content:\n${content}\n\n` 
    );

    const dateObject = new Date(article.date);
    const dateOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZoneName: 'short',
        timeZone: 'Europe/Paris',
    };
    const formattedDate = dateObject.toLocaleString('de-DE', dateOptions);

    return (
        <div className="press-modal">
            <div className="modal-press-content">
                <div className="modal-button-bar">
                    <button className="save" onClick={handleCopyHeadline}>{t('copy_headline')}</button>
                    <button className="save" onClick={handleCopyContent}>{t('copy_content')}</button>
                    <button className="save" onClick={handleCopyComplete}>{t('copy_complete')}</button>
                    <button className="close-press-button" onClick={onClose}>&times;</button>
                </div>
                {copySuccess && <div className="copy-success-message">{copySuccess}</div>}

                <div className="modal-scrollable-content">
                    <div className="modal-header">
                        <h1>{headline}</h1>
                    </div>
                    <div className="modal-body">
                        {renderArticleText()}
                    </div>
                   
                </div>
            </div>
        </div>
    );
};

export default ArticleTranslationModal;
