import React, { useEffect, useState, useCallback } from 'react';
import '../../Spinner.css';
import { useTranslation } from 'react-i18next';
import axios from '../../axios.js';
import { useHistory } from 'react-router-dom';

const CompanyBlogpostFunction = ({ existingCompany, blogpostId, users, formatDate, onCancel }) => {
    const [blogposts, setBlogposts] = useState([]);
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState(existingCompany || { content: { blogposts: [blogpostId] } });
    const [blogpostBullets, setBlogpostBullets] = useState('');
    const [blogpostText, setBlogpostText] = useState('');
    const [blogpostQuotes, setBlogpostQuotes] = useState('');
    const [blogpostFacts, setBlogpostFacts] = useState('');
    const [headline, setHeadline] = useState('');
    const contextAI = company?.context?.contextCompany || '';
    const finetuneAI = company?.context?.contextFinetune || '';
    const [successMessage, setSuccessMessage] = useState('');
const [errorMessage, setErrorMessage] = useState('');

 useEffect(() => {
        fetchCompanyData();
    }, []);

const fetchCompanyData = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }
        const response = await axios.get('/companies/get-company', {
            headers: { Authorization: `Bearer ${token}` }
        });
        const blogpostsResponse = await axios.get('/content/blogposts/get-for-company', {
            headers: { Authorization: `Bearer ${token}` }
        });

        const filteredBlogposts = blogpostsResponse.data.filter(blogpost => blogpost._id === blogpostId);

           if (filteredBlogposts.length > 0) {
          setBlogpostBullets(filteredBlogposts[0].blogpostBullets || '');
                setBlogpostText(filteredBlogposts[0].blogpostText || '');
                setBlogpostQuotes(filteredBlogposts[0].blogpostQuotes || '');
                setBlogpostFacts(filteredBlogposts[0].blogpostFacts || '');
                setHeadline(filteredBlogposts[0].headline || '');

        }

        setCompany(response.data);
        setBlogposts(filteredBlogposts);
   

    } catch (error) {
    }
};


const handleSubmit = async (e) => {
    e.preventDefault();

    try {

if (!blogpostText.trim()) {
    setErrorMessage(t('saving_error'));
    setTimeout(() => {
        setErrorMessage('');
    }, 2000);
    return;
}

        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }

        let updatedCompany = {...company};

        // Ensure the content object exists
        updatedCompany.content = updatedCompany.content || {};
        // Ensure the blogposts array exists
        updatedCompany.content.blogposts = updatedCompany.content.blogposts || [];

        if (!blogpostId) {
            const userId = localStorage.getItem('userId');
            const newBlogpost = {
                blogpostBullets,
                blogpostText,
                blogpostQuotes,
                blogpostFacts,
                headline,
                blogpostTranslations: [],
                creator: userId,
                creationDate: new Date()
            };

            updatedCompany.content.blogposts.push(newBlogpost);

        } else {
            const blogpostIndex = updatedCompany.content.blogposts.findIndex(blogpost => blogpost._id === blogpostId);
            if (blogpostIndex !== -1) {
                updatedCompany.content.blogposts[blogpostIndex] = {
                    ...updatedCompany.content.blogposts[blogpostIndex],
                    blogpostBullets,
                    blogpostText,
                    blogpostQuotes,
                    blogpostFacts,
                    headline,
                };
            }
        }

        const response = await axios.put(`/companies/${updatedCompany._id}`, updatedCompany, {
            headers: { Authorization: `Bearer ${token}` }
        });

        setCompany(response.data);

    setSuccessMessage(t('save_success'));
        setTimeout(() => {
            setSuccessMessage('');
        }, 2000);
        fetchCompanyData();
    } catch (error) {
    }
};


const processBlogpostOpenAI = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    const token = localStorage.getItem('token');
    if (!token) {
        setIsLoading(false);
        return;
    }

   if (!blogpostText) {
        setIsLoading(false);
        return;
    }

    try {
        let bulletsResponse = await axios.post('/content/process-blogposts-bullets', {
            blogpostBullets: blogpostText 
        }, { headers: { 'Authorization': `Bearer ${token}`,'Accept-Language': i18n.language } });

        let descriptionResponse = await axios.post('/content/process-blogposts-text', {
            contextAI: contextAI,
            blogpostBullets: bulletsResponse.data.openAiResponse,              
            blogpostFacts: blogpostFacts,
            blogpostQuotes: blogpostQuotes,
finetuneAI: finetuneAI
        }, { headers: { 'Authorization': `Bearer ${token}`,'Accept-Language': i18n.language } });

        if (bulletsResponse.data && descriptionResponse.data) {
            setBlogpostBullets(bulletsResponse.data.openAiResponse);
            setBlogpostText(descriptionResponse.data.openAiResponse);
        }

        if (descriptionResponse.data.openAiResponse) {
            const headlineResponse = await axios.post('/content/process-blogposts-headline', {
                blogpostText: descriptionResponse.data.openAiResponse,
finetuneAI: finetuneAI
            }, { headers: { 'Authorization': `Bearer ${token}`, 'Accept-Language': i18n.language } });


            if (headlineResponse.data) {
                setHeadline(headlineResponse.data.openAiResponse);
            } else {
            }
        }
    } catch (error) {
    } finally {
        setIsLoading(false);
    }
}, [
    isLoading, 
    blogpostText, 
    contextAI, 
    blogpostFacts, 
    blogpostQuotes, 
    i18n.language   
]);



  return (
<>
            {isLoading && <div className="spinner-overlay"><div className="spinner"></div></div>}

<div className="content-container">

<div className="content-container-column col-two">

<div className="content-box col-two row-eight">
               <div className="inner-content-container">
         
{headline && (

            <>

                        <div className="inner-content-box col-five row-two">

<textarea className="content-box-main-headline" name="headline" value={headline} onChange={(e) => setHeadline(e.target.value)} placeholder={t('blogpost_headline')} rows="5" cols="50" />
  </div>

                        <div className="inner-content-box col-five row-eight">

            <textarea className="content-box-main-textarea" value={blogpostText} onChange={(e) => setBlogpostText(e.target.value)} placeholder={t('blogpost_instructions')} rows="5" cols="50" />
</div>
</>
)}

{!headline && (
<>
 <div className="inner-content-box col-five row-ten">
            <textarea className="content-box-main-textarea" value={blogpostText} onChange={(e) => setBlogpostText(e.target.value)} placeholder={t('blogpost_instructions')} />
   </div>
</>
)}
<div className="inner-content-box col-four row-two">

            <textarea className="content-box-insert-textarea" value={blogpostFacts} onChange={(e) => setBlogpostFacts(e.target.value)} placeholder={t('blogpost_facts')} />
</div>

<div className="inner-content-box">
<button className="editor-button" onClick={processBlogpostOpenAI} disabled={isLoading}>
                        {headline ? t('re_generate_button') : t('generate_button')}
                    </button>
</div>
        </div>
       </div>
</div>
{successMessage && <div className="content-box blue-background"> {successMessage}</div>}
{errorMessage && <div className="content-box blue-background"> {errorMessage}</div>}


{!successMessage && ( 
<div className="content-container-column col-one">

               <div className="content-button-box row-one">
                    <button className="save" onClick={handleSubmit}>{t('save_button')}</button>
                    <button className="save" onClick={onCancel}>{t('cancel_button')}</button>
                </div>
 
<div className="content-box row-three"><h2>{t('good_to_know')}</h2><p className="small">{t('blogpost_intro_text')}</p></div>
</div>
)}
            


</div>
</>
    );
};

export default CompanyBlogpostFunction;
