import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SetupCheck = ({ isLoggedIn, updateSetupStatus }) => {
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://app.buzzbeat.io';
 
  useEffect(() => {
    const checkSetupComplete = async () => {
      if (isLoggedIn) {
        try {
          const token = localStorage.getItem('token');
          if (token) {
            const response = await fetch(`${baseURL}/registerlogin/check-setup-complete`, {
              headers: { Authorization: `Bearer ${token}` },
            });

            if (!response.ok) {
              throw new Error(`Network response was not ok, status: ${response.status}`);
            }

            const data = await response.json();
            updateSetupStatus(data.isSetupComplete);

            if (!data.isSetupComplete) {
              navigate('/app/app/setup');
            }
          } else {
          }
        } catch (error) {
        }
      } else {
      }
    };

    checkSetupComplete();
  }, [isLoggedIn, navigate, updateSetupStatus, baseURL]);

  return null;
};

export default SetupCheck;
