import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PressReleaseButtons = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleCreateClick = () => {
        navigate('app/press-release', { state: { addNew: true } });
    };

    const handleListViewClick = () => {
        navigate('app/press-release', { state: { reset: true } });
    };


    return (
        <>
<button onClick={handleCreateClick}>{t('create_press_release')}</button>
            <button onClick={handleListViewClick}>{t('view_press_releases')}</button>
            

        </>
    );
};

export default PressReleaseButtons;
