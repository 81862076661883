import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import './App.css';
import './buzzbeat-styles.css';
import { useAuth } from './AuthContext';
import { ModalProvider } from './ModalContext';
import './i18n';

import UserHeaderBar from './companymanagement/userHeaderBar';
import LanguageSwitcher from './LanguageSwitcher';

import CompanyDetailsPage from './companymanagement/companyDetailsPage';
import UserDetailsForm from './usermanagement/UserDetailsForm';
import AddUsers from './companymanagement/companyAddUsersPage';

import SetupPage from './firstCheck/SetupPage';
import SetupCheck from './firstCheck/setupCheck';

import CompanyVisionPage from './content/companyVisionPage';
import CompanyMissionPage from './content/companyMissionPage';
import CompanyDescriptionPage from './content/companyDescriptionPage';

import CompanyCorporatetextPage from './content/corporatetexts/companyCorporatetextPage';
import CorporatetextButtons from './content/corporatetexts/corporatetextButtons';

import CompanyPressReleasePage from './content/pressreleases/companyPressReleasePage';
import PressReleaseButtons from './content/pressreleases/PressReleaseButtons';

import CompanyArticlesPage from './content/articles/companyArticlePage';
import ArticlesButtons from './content/articles/articleButtons';

import CompanyBlogpostsPage from './content/blogposts/companyBlogpostPage';
import BlogpostsButtons from './content/blogposts/blogpostButtons';

import CompanyUsecasesPage from './content/usecases/companyUsecasePage';
import UsecasesButtons from './content/usecases/usecaseButtons';

import SubscriptionPage from './subscription/SubscriptionPage';
import PaymentSuccessPage from './subscription/PaymentSuccessPage';

import ApprovalPage from './approval/ApprovalPage';
import GiveApprovalPage from './approval/giveApprovalPage';

import CollaborationPage from './collaboration/CollaborationPage';
import GiveCollaborationPage from './collaboration/giveCollaborationPage';

import DashboardPageProEnterprise from './DashboardPageProEnterprise';
import DashboardPageStarter from './DashboardPageStarter';
import DashboardPageFree from './DashboardPageFree';

import SimpleModal from './subscription/subscriptionModal';

function App({ isLoggedIn }) {
  const [isSetupComplete, setIsSetupComplete] = useState(false);
  const [pressReleaseKey, setPressReleaseKey] = useState(0);
  const [articlesKey, setArticlesKey] = useState(0);
  const [blogpostsKey, setBlogpostsKey] = useState(0);
  const [usecasesKey, setUsecasesKey] = useState(0);
  const [corporatetextKey, setCorporatetextKey] = useState(0);

  const [companyData, setCompanyData] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const { t, i18n } = useTranslation();
   const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('language') || navigator.language.split('-')[0]);
const [hasToken, setHasToken] = useState(false);

  const [isPressReleaseActive, setIsPressReleaseActive] = useState(false);
  const [isArticlesActive, setIsArticlesActive] = useState(false);
  const [isBlogpostsActive, setIsBlogpostsActive] = useState(false);
  const [isUsecasesActive, setIsUsecasesActive] = useState(false);

  const [isCorporatetextActive, setIsCorporatetextActive] = useState(false);
const [showSubscription, setShowSubscription] = useState(false);
  const { fetchWithAuth, subscriptionLevel } = useAuth();

    const freeAccess = ['free'].includes(subscriptionLevel);
    const starterAccess = ['starter'].includes(subscriptionLevel);
    const proAccess = ['pro'].includes(subscriptionLevel);
    const enterpriseAccess = ['enterprise'].includes(subscriptionLevel);

  const navigate = useNavigate();
const companyId = localStorage.getItem('buzzBeatCompanyId');

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [currentLanguage, i18n]);
 
  useEffect(() => {
    const path = window.location.pathname;
    const tokenPattern = /^\/(reset-password|complete-registration)\/[A-Za-z0-9-_.]+$/;
    setHasToken(tokenPattern.test(path));
  }, [window.location.pathname]);




const handleLogout = () => {
  localStorage.removeItem('token');
  setIsDropdownOpen(false);
  navigate('/'); 
  window.location.reload(true);
  window.zE('messenger', 'logoutUser');

};


const handleApproval = (contentId, contentType) => {
    navigate(`/app/app/approval/${contentType}/${contentId}`, { state: { companyId, userId: localStorage.getItem('userId') } });
};

const handleCollaborate = (contentId, contentType) => {
    navigate(`/app/app/collaborate/${contentType}/${contentId}`, { state: { companyId, userId: localStorage.getItem('userId') } });
};


const toggleSubscriptionModal = () => {
  setShowSubscription(prevState => !prevState);
};



 const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
};

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isDropdownOpen && event.target.closest('.user') === null) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);


  const updateSetupStatus = (status) => {
    setIsSetupComplete(status);
  };

  const changeLanguage = (language) => {
    setCurrentLanguage(language);
    localStorage.setItem('language', language);
    i18n.changeLanguage(language);
  };

 useEffect(() => {
    const checkAuthentication = () => {
      const token = localStorage.getItem('token');
      const isTokenValid = () => {
        if (!token) return false;
        try {
          const { exp } = JSON.parse(atob(token.split('.')[1]));
          return exp * 1000 > new Date().getTime();
        } catch {
          return false;
        }
      };

      if (!isLoggedIn || !isTokenValid()) {
        navigate('/');
      }
    };

    checkAuthentication();
  }, [isLoggedIn, navigate]);


  useEffect(() => {
    if (isLoggedIn) {
      const currentPath = window.location.pathname;
      setIsPressReleaseActive(currentPath.includes('/app/press-release'));
      setIsCorporatetextActive(currentPath.includes('/app/corporatetext'));
    setIsArticlesActive(currentPath.includes('/app/articles'));
setIsBlogpostsActive(currentPath.includes('/app/blogposts'));
setIsUsecasesActive(currentPath.includes('/app/usecases'));

  }
}, [isLoggedIn, window.location.pathname]);


useEffect(() => {
  if (isLoggedIn) {
    const userId = localStorage.getItem('userId');
    const userEmail = localStorage.getItem('userEmail');

    const fetchZendeskJWT = () => {

      fetch('https://app.buzzbeat.io/zendesk/generate-jwt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: { id: userId, email: userEmail } }),
      })
      .then(response => {
        if (!response.ok) {
          // Extracting and logging the response text to understand the specific error message
          return response.text().then(text => {
            const error = `HTTP error while fetching JWT: Status ${response.status}, Body: ${text}`;
            throw new Error(error);
          });
        }
        return response.json();
      })
      .then(data => {
        const zendeskJWT = data.token;
        window.zE('messenger', 'loginUser', callback => {
          callback(zendeskJWT);
        });
      })
      .catch(error => {
      });
    };

    if (userId && userEmail) {
      fetchZendeskJWT();
    } else {
      setTimeout(fetchZendeskJWT, 3000);
    }
  }
}, [isLoggedIn]);






   if (!isLoggedIn) {
    return null; 
  }










  return (
<>
<SimpleModal companyId={companyId} show={showSubscription} onClose={() => setShowSubscription(false)}></SimpleModal>


    <ModalProvider>


    <div>
<>


  <div className="headerbar">
          <div className="welcome-header"><UserHeaderBar existingCompany={companyData} /></div>

</div>
	  <div className="logo">
  <NavLink to="/app/app">
    <img src="../../assets/images/buzzbeat-logo.png" alt="BuzzBeat Logo" />
  </NavLink>
</div>
</>
 {isLoggedIn && (
    <div className="language-switcher">
        <LanguageSwitcher 
          currentLanguage={currentLanguage} 
          changeLanguage={changeLanguage} 
        />
</div>
      )}
{isLoggedIn && ( <SetupCheck isLoggedIn={isLoggedIn} updateSetupStatus={updateSetupStatus} /> )}


   {isLoggedIn && !isSetupComplete && (
        <>
<div className="main-nav-background"></div>
</>
)}



{isLoggedIn && isSetupComplete && (
<>

<div className="main-nav-background">
     
 <div className="main-nav one">
        <NavLink to="app/vision" className={({ isActive }) => isActive ? 'active' : ''}>
          {t('vision')}
        </NavLink>
        <NavLink to="app/mission" className={({ isActive }) => isActive ? 'active' : ''}>
          {t('mission')}
        </NavLink>
 <NavLink to="app/description" className={({ isActive }) => isActive ? 'active' : ''}>
          {t('description')}
        </NavLink>

</div>

<div className="main-nav two">
<NavLink to="app/corporatetext" className={({ isActive }) => isActive ? 'active' : ''}>{t('corporatetexts')}</NavLink>
        {isCorporatetextActive && <CorporatetextButtons />}

{(starterAccess || proAccess || enterpriseAccess) && ( 
<>  

       <NavLink to="app/blogposts" className={({ isActive }) => isActive ? 'active' : ''}>{t('blogposts')}</NavLink>
	 {isBlogpostsActive && <BlogpostsButtons />}

<NavLink to="app/articles" className={({ isActive }) => isActive ? 'active' : ''}>{t('articles')}</NavLink>
 	{isArticlesActive && <ArticlesButtons />}

 <NavLink to={{ pathname: "app/press-release", state: { reset: true } }} className={({ isActive }) => isActive ? 'active' : ''}>{t('press_release')}</NavLink>
       {isPressReleaseActive && <PressReleaseButtons />}

<NavLink to="app/usecases" className={({ isActive }) => isActive ? 'active' : ''}>{t('usecases')}</NavLink>
	{isUsecasesActive && <UsecasesButtons />}


</>
)}

{(freeAccess) && (
<>  
 <button className="main-nav-disabled" onClick={toggleSubscriptionModal} title={t('articles')}> {t('articles')}</button>
  <button className="main-nav-disabled" onClick={toggleSubscriptionModal} title={t('blogposts')}> {t('blogposts')}</button>
  <button className="main-nav-disabled" onClick={toggleSubscriptionModal} title={t('press_release')}>{t('press_release')}</button>

  <button className="main-nav-disabled" onClick={toggleSubscriptionModal} title={t('usecases')}> {t('usecases')}</button>
</>
)}

</div>
{(proAccess || enterpriseAccess) && (

<div className="main-nav two">
      
 <NavLink to="app/collaborate" className={({ isActive }) => isActive ? 'active' : ''}>
   	 {t('collaborations')}
        </NavLink>

 <NavLink to="app/give-approval" className={({ isActive }) => isActive ? 'active' : ''}>
   	 {t('approvals')}
        </NavLink>



      </div>
)}

{(freeAccess || starterAccess) && (

<div className="main-nav two">
  <button className="main-nav-disabled" onClick={toggleSubscriptionModal} title={t('collaborations')}> {t('collaborations')}</button>
  <button className="main-nav-disabled" onClick={toggleSubscriptionModal} title={t('approvals')}>{t('approvals')}</button>
</div>
)}

    </div>
</>
    )}


{isLoggedIn && (
	<>
		<div className="user" onClick={toggleDropdown}>
  			<img src="/assets/images/user-buzzbeat-icon.png" alt="User Icon" />
</div>
                 <div className={`dropdown-content ${isDropdownOpen ? 'slide-in' : ''}`}>
        			<div className="dropdown-section">
<NavLink to="app/company-details" className={({ isActive }) => isActive ? 'active' : ''}>
      {t('company_details')}
    </NavLink>

{(freeAccess || starterAccess) && (
    <NavLink onClick={toggleSubscriptionModal} className="disabledButton">
{t('team')}
    </NavLink>
)}

{(proAccess || enterpriseAccess) && (
    <NavLink to="app/add-users" className={({ isActive }) => isActive ? 'active' : ''}>
{t('team')}
    </NavLink>
)}

    <NavLink to="app/user-details" className={({ isActive }) => isActive ? 'active' : ''}>
      {t('personal_settings')}
    </NavLink>
 <NavLink to="app/subscription" className={({ isActive }) => isActive ? 'active' : ''}>
      {t('subscription')}
    </NavLink>
               

        			</div>
        				<div className="dropdown-section-empty"></div>
        				<div className="dropdown-section-bottom">
<button onClick={handleLogout}>{t('logout')}</button>
<a href="/imprint" target="_blank" rel="noopener noreferrer">{t('imprint')}</a>
<a href="/privacy" target="_blank" rel="noopener noreferrer">{t('data_privacy')}</a>

</div>
      				</div>
	</>
)}




<div className="content-background">
  <div className="content">


        <Routes>

           {isLoggedIn && (
        <>

{(proAccess || enterpriseAccess) && (
<Route path="app/" element={<DashboardPageProEnterprise companyId={companyId} onToggleSubscription={toggleSubscriptionModal} />} />

)}


{(starterAccess) && (
<Route path="app/" element={<DashboardPageStarter companyId={companyId} onToggleSubscription={toggleSubscriptionModal} />} />

)}


{(freeAccess) && (
<Route path="app/" element={<DashboardPageFree companyId={companyId} onToggleSubscription={toggleSubscriptionModal} />} />

)}


 <Route path="app/vision" element={<CompanyVisionPage existingCompany={companyData} onToggleSubscription={toggleSubscriptionModal} />} />
<Route path="app/mission" element={<CompanyMissionPage existingCompany={companyData} onToggleSubscription={toggleSubscriptionModal} />} />
<Route path="app/description" element={<CompanyDescriptionPage existingCompany={companyData} onToggleSubscription={toggleSubscriptionModal} />} />


<Route path="app/corporatetext" element={<CompanyCorporatetextPage key={corporatetextKey} onToggleSubscription={toggleSubscriptionModal} existingCompany={companyData} onApprove={handleApproval} onCollaborate={handleCollaborate} showCreateCorporatetextButton={isCorporatetextActive} />} />


{(starterAccess || proAccess || enterpriseAccess) && ( 
<>  
<Route path="app/blogposts" element={<CompanyBlogpostsPage key={blogpostsKey} onToggleSubscription={toggleSubscriptionModal} existingCompany={companyData} onApprove={handleApproval} onCollaborate={handleCollaborate} showCreateBlogpostsButton={isBlogpostsActive} />} />

<Route path="app/articles" element={<CompanyArticlesPage key={articlesKey} onToggleSubscription={toggleSubscriptionModal} existingCompany={companyData} onApprove={handleApproval} onCollaborate={handleCollaborate} showCreateArticlesButton={isArticlesActive} />} />

<Route path="app/press-release" element={<CompanyPressReleasePage key={pressReleaseKey} onToggleSubscription={toggleSubscriptionModal} existingCompany={companyData} onApprove={handleApproval} onCollaborate={handleCollaborate}  showCreatePressReleaseButton={isPressReleaseActive} />} />

<Route path="app/usecases" element={<CompanyUsecasesPage key={usecasesKey} onToggleSubscription={toggleSubscriptionModal} existingCompany={companyData} onApprove={handleApproval} onCollaborate={handleCollaborate} showCreateUsecasesButton={isUsecasesActive} />} />
</>
)} 

          <Route path="app/company-details" element={<CompanyDetailsPage onToggleSubscription={toggleSubscriptionModal} existingCompany={companyData} />} />

{(proAccess || enterpriseAccess) && (   
<Route path="app/add-users" element={<AddUsers onToggleSubscription={toggleSubscriptionModal} existingCompany={companyData} />} />
)} 

          <Route path="app/user-details" element={<UserDetailsForm onToggleSubscription={toggleSubscriptionModal} />} />  
        <Route path="app/subscription" element={<SubscriptionPage companyId={companyId} />} />
        <Route path="app/payment-success" element={<PaymentSuccessPage companyId={companyId} />} />

{(proAccess || enterpriseAccess) && (  
<>
<Route path="app/approval/:contentType/:contentId" element={<ApprovalPage companyId={companyId} />} />
<Route path="app/give-approval/" element={<GiveApprovalPage companyId={companyId} />} />

<Route path="app/collaborate/:contentType/:contentId" element={<CollaborationPage companyId={companyId} />} />
<Route path="app/collaborate/" element={<GiveCollaborationPage companyId={companyId} />} />
</>
)}

        </>
      )}
      
      {isLoggedIn && !isSetupComplete && (
        <>
	  <Route path="app/setup" element={<SetupPage />} />
	</>
       )}
    </Routes>

  </div>
</div>



    </div>
    </ModalProvider>
</>
  );
}

export default App;
