import React from 'react';

const BuzzBeatModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <button className="save" onClick={onClose}>Close</button>
<p><br></br></p>

        {children}
      </div>
    </div>
  );
};

export default BuzzBeatModal;
