import TagManager from 'react-gtm-module';
 
const GTM_ID = 'GTM-WL66KSMM';

const initializeTagManager = (consent) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'consentUpdate',
    'gaConsent': consent
  });

  TagManager.initialize({ gtmId: GTM_ID });
};

export default initializeTagManager;
