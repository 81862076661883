import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../axios.js';
import { NavLink } from 'react-router-dom';


const DashboardVision = ({ existingCompany, setCompanyData }) => {
    const initialState = {
        vision: [{
            visionBullets: '',
            visionText: '',
            visionTranslations: [{ language: '', translation: '' }]
        }],
    };

    const [company, setCompany] = useState(initialState);
    const { t } = useTranslation();

    useEffect(() => {
        if (!existingCompany) {
            fetchCompanyData();
        } else {
            setCompany(existingCompany);
        }
    }, [existingCompany]);

    const fetchCompanyData = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                return;
            }
            const response = await axios.get('/companies/get-company', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setCompany(response.data);
        } catch (error) {
        }
    };

    const visionText = company.content?.vision?.visionText || '';

    return (
        <>
{visionText && (
<>
            <h2>{t('your_vision')}:</h2>
            <p><br /></p>
            <p className="big">{visionText}</p>
</>
)}

{!visionText && (
<>
             <button className="save"><NavLink to="vision">
          {t('create_vision_button_dashboard_free')}
        </NavLink></button>
</>
)}

        </>
    );
};

export default DashboardVision;
