import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(localStorage.getItem('token'));
    const [subscriptionLevel, setSubscriptionLevel] = useState('free');

    const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://app.buzzbeat.io';

    const fetchWithAuth = async (url, options = {}) => {
        const fullUrl = `${baseURL}${url}`; 
        return fetch(fullUrl, {
            ...options,
            headers: {
                ...options.headers,
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
        });
    };

const fetchSubscriptionLevel = useCallback(async () => {
    const companyId = localStorage.getItem('buzzBeatCompanyId');
    if (!companyId) {
        return;
    }

    const requestUrl = '/subscription/subscriptionlevel';

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ companyId }),
    };

    try {
        const response = await fetchWithAuth(requestUrl, requestOptions);

        if (!response.ok) {
            const errorBody = await response.text();
            throw new Error(`Request failed with status ${response.status}`);
        }

        const data = await response.json();
        setSubscriptionLevel(data.subscriptionLevel);
    } catch (error) {
    }
}, [authToken]);

    useEffect(() => {
        if (authToken) {
            fetchSubscriptionLevel();
        }
    }, [authToken, fetchSubscriptionLevel]);

    return (
        <AuthContext.Provider value={{ authToken, setAuthToken, subscriptionLevel, fetchWithAuth }}>
            {children}
        </AuthContext.Provider>
    );
};
