import React, { useState, useEffect } from 'react';
import axios from '../axios.js';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CompleteRegistration = () => {
    const { token } = useParams();
    const [userDetails, setUserDetails] = useState({
        name: '',
        surname: '',
        roleInCompany: '',
        telephone: '',
        mobile: '',
        address: ''
    });
    const [errors, setErrors] = useState({});

    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const { t } = useTranslation();
    const [passwordStrengthMessage, setPasswordStrengthMessage] = useState('');
    const [backgroundImage, setBackgroundImage] = useState('');
    const [offsetY, setOffsetY] = useState(0);
    const navigate = useNavigate();

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/assets/images/backgrounds/image${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get(`/companies/get-user-details/${token}`);
                if (response.data) {
                    setUserDetails(response.data);
                }
            } catch (error) {
            }
        };
        fetchUserDetails();
    
     const fetchRoles = async () => {
        try {
            const response = await axios.get(`/user/roles`);
            const roles = response.data;
            setUserDetails({ ...userDetails, roleOptions: roles });
        } catch (error) {
            console.error("Failed to fetch roles", error);
        }
    };
    fetchRoles();
}, [token]);


const validateForm = () => {
        let isValid = true;
        let errors = {};

        if (!userDetails.name) {
            isValid = false;
            errors.name = t('name_required_error');
        }

        if (!userDetails.surname) {
            isValid = false;
            errors.surname = t('surname_required_error');
        }

        if (!password) {
            isValid = false;
            errors.password = t('password_required_error');
        } else if (passwordStrengthMessage.length > 0) {
            isValid = false;
            errors.passwordStrength = passwordStrengthMessage.join(' ');
        }


        setErrors(errors);
        return isValid;
    };

     const handleCompleteRegistration = async () => {
        if (!validateForm()) {
            return;
        }

        try {
            const payload = {
                password,
                ...userDetails
            };

            const response = await axios.post(`/registerlogin/complete-registration/${token}`, payload);
            setMessage(t('complete_registration_success'));
        setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
        setMessage(error.response?.data?.msg || t('complete_registration_failed'));
    }
};

       const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserDetails({ ...userDetails, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

const validatePassword = (password) => {
    let messages = [];
    if (!/.{8,}/.test(password)) {
        messages.push(t('password_length_error'));
    }
    if (!/[A-Z]/.test(password)) {
        messages.push(t('password_uppercase_error'));
    }
    if (!/[a-z]/.test(password)) {
        messages.push(t('password_lowercase_error'));
    }
    if (!/[0-9]/.test(password)) {
        messages.push(t('password_number_error'));
    }
    if (!/[^A-Za-z0-9]/.test(password)) {
        messages.push(t('password_special_char_error'));
    }
    setPasswordStrengthMessage(messages);
};



    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        validatePassword(e.target.value);
    };

   return (
<>
 <div className="buzzbeat-other-page" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
            <div className="content-section-homepage">
                <div className="grid-layout">
                    <div className="grid-content-item col-six center"><h1>{t('complete_registration')}</h1></div>
                    <div className="grid-content-item col-six center">
                        {message && <><p className={message.includes('success') ? "success-message" : "error-message"}>{message}</p><br/></>}
                        <input type="text" name="name" placeholder={`${t('given_name')}*`} value={userDetails.name} onChange={handleInputChange} required />
                        {errors.name && <div className="error">{errors.name}</div>}
                        <input type="text" name="surname" placeholder={`${t('surname')}*`} value={userDetails.surname} onChange={handleInputChange} required />
                        {errors.surname && <div className="error">{errors.surname}</div>}
                        <input type="password" placeholder={`${t('password')}*`} value={password} onChange={handlePasswordChange} required />
                        {errors.password && <div className="error">{errors.password}</div>}
                        
<><ul className="password-strength-messages">
    {Array.isArray(passwordStrengthMessage) && passwordStrengthMessage.length > 0 &&
        passwordStrengthMessage.map((msg, index) => (
            <li key={index}>{msg}</li>
        ))
    }
</ul>
</>

               <select name="roleInCompany" value={userDetails.roleInCompany} onChange={handleInputChange} >
    <option value="" disabled>{t('role_in_company')}</option>
    {userDetails.roleOptions && userDetails.roleOptions.map((role) => (
        <option key={role} value={role}>{t(role)}</option>
    ))}
</select>

		
                <input type="text" name="telephone" placeholder={t('telephone')} value={userDetails.telephone} onChange={handleInputChange} />
                <input type="text" name="mobile" placeholder={t('mobile')} value={userDetails.mobile} onChange={handleInputChange} />
                <input type="text" name="address" placeholder={t('address')} value={userDetails.address} onChange={handleInputChange} />
<br></br><br></br>
                <button onClick={handleCompleteRegistration} className="save" type="submit">{t('complete_registration')}</button>
            </div>
        </div>

</div>
</>
    );
};

export default CompleteRegistration;
