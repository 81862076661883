import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../axios.js';

import { useAuth } from '../AuthContext';
 
const UserManagementPage = ({ companyId, onError, onSuccess }) => {
    const [users, setUsers] = useState([]);
    const [editingUserId, setEditingUserId] = useState(null);
    const [editedUserInfo, setEditedUserInfo] = useState({});
    const { t } = useTranslation();
    const { fetchWithAuth } = useAuth();
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        if (!companyId) {
            return;
        }
        fetchUsers();
    }, [companyId]);


 useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await fetchWithAuth('/user/roles', {
                    method: 'GET'
                });
                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
                const fetchedRoles = await response.json();
                setRoles(fetchedRoles);
            } catch (error) {
                console.error("Failed to fetch roles", error);
            }
        };

        fetchRoles();
    }, [fetchWithAuth]);


    const fetchUsers = async () => {
        try {
            const response = await fetchWithAuth(`/companies/${companyId}/users`, {
                method: 'GET'
            });
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            onError(t('load_users_error'));
        }
    };

    const handleDelete = async (userId) => {
        try {
            const response = await fetchWithAuth(`/companies/${companyId}/users/${userId}`, {
                method: 'DELETE'
            });
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            setUsers(users.filter(user => user._id !== userId));
            onSuccess(t('delete_user_success'));

        } catch (error) {
            onError(t('delete_user_error'));

        }
    };

    const confirmDelete = async (userId) => {
    if (window.confirm(t('confirm_user_deletion'))) {
            await handleDelete(userId);
        }
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedUserInfo(prev => ({ ...prev, [name]: value }));
    };

    const handleEdit = (user) => {
        setEditingUserId(user._id);
 setEditedUserInfo({
        name: user.name,
        surname: user.surname,
        email: user.email,
        roleInCompany: user.infos?.roleInCompany, 
        ...user.infos
    });
    };

    const handleCancelEdit = () => {
        setEditingUserId(null);
        setEditedUserInfo({});
    };

const handleSaveEdit = async () => {
    try {
        const updatedUserInfo = {
            name: editedUserInfo.name,
            surname: editedUserInfo.surname,
            email: editedUserInfo.email,
            infos: {
                roleInCompany: editedUserInfo.roleInCompany,
                telephone: editedUserInfo.telephone,
            },
        };

        const response = await fetchWithAuth(`/companies/${companyId}/users/${editingUserId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedUserInfo),
        });

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

        const data = await response.json();
        setUsers(users.map(user => user._id === editingUserId ? data : user));
        setEditingUserId(null);
        setEditedUserInfo({});
            onSuccess(t('save_user_edit_success'));

    } catch (error) {
            onError(t('save_user_edit_error'));
    }
};




 const handleResendInvitation = async (userId) => {
    try {
        const response = await fetchWithAuth(`/registerlogin/${userId}/resend-invitation`, {
            method: 'POST',
        });

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            onSuccess(t('reminder_sent_success'));
    } catch (error) {
            onError(t('reminder_sent_error'));
    }
};


    return (
        <div>
            <h1>{t('user_management')}</h1>
		<p><br></br></p>
            {users.map(user => (
                <div key={user._id}>
                    {editingUserId === user._id ? (
<>


			  <div className="companydetails-item">
				<div>{t('given_name')}:</div>
				<div>  <input type="text" name="name" value={editedUserInfo.name} onChange={handleEditChange} placeholder={t('given_name')} /></div>
			</div>

			  <div className="companydetails-item">
				<div>{t('surname')}:</div>
				<div> <input type="text" name="surname" value={editedUserInfo.surname} onChange={handleEditChange} placeholder={t('surname')} /></div>
			</div>

			  <div className="companydetails-item">
				<div>{t('email')}:</div>
				<div><input type="text" name="email" value={editedUserInfo.email} onChange={handleEditChange} placeholder={t('email')} /></div>
			</div>

			  <div className="companydetails-item">
				<div>{t('role_in_company')}</div>
				<div>
  					<select name="roleInCompany" value={editedUserInfo.roleInCompany || ''} onChange={handleEditChange}>                    
		    				<option value="">{t('select_role_in_company')}</option>
                            {roles.map((role) => (
                                <option key={role} value={role}>{t(role)}</option>
                            ))}
                        </select>
				</div>
			</div>

  <div className="companydetails-item"><div></div></div>

			  <div className="companydetails-item">
				<div></div>
   				<div><button className="editor-button" onClick={handleSaveEdit}>{t('save_button')}</button> <button className="save" onClick={handleCancelEdit}>{t('cancel_button')}</button></div>
			</div>
		

     </>

                    ) : (!editingUserId && (
                    
	
			  <div className="companydetails-item-four">
                           	<div>{user.name} {user.surname}</div>
				<div>{user.email}</div>
			  
			<div>{!user.isEmailConfirmed && (!user.invited || (user.invited && !user.accepted)) && (
   				<button className="toggle-translations-btn" onClick={() => handleResendInvitation(user._id)}>{t('resend_invitation')}</button> )}</div>

                           	<div className="icons"> <button type="img" onClick={() => handleEdit(user)}> <img src="../../assets/images/edit.png" alt="Edit" /></button></div>
                           		
                  		<div className="icons"><button type="img" onClick={() => confirmDelete(user._id)}><img src="../../assets/images/delete.png" alt="Delete" /></button></div>
			</div>
	

                   
                    ))}
                </div>
            ))}
        </div>
    );
};

export default UserManagementPage;
