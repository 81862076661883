import React, { useState, useEffect } from 'react';
import ProductCounter from './ProductCounter'; 
import BuzzBeatNews from './BuzzBeatNews'; 
import { useTranslation } from 'react-i18next';

function BuzzBeatJobsPage() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/assets/images/backgrounds/image${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);




  return (
    <>
      <div className="buzzbeat-other-page" style={{ backgroundImage: `url(${backgroundImage})` }}></div>



      <div className="content-section-homepage">

        <div className="grid-layout">
        
  <div className="grid-content-item col-six center"><h1>{t('headline_jobs')}</h1><h2>{t('sub_headline_jobs')}</h2></div>
          <div className="grid-content-item col-six"><p>{t('text1_jobs')}<br></br><br></br>{t('text2_jobs')}<br></br><br></br>{t('text2_jobs')}<br></br><br></br>{t('text4_jobs')}<br></br><br></br></p></div>

   </div>
      </div>


      <div className="content-section-homepage">

        <div className="grid-layout">

  <div className="grid-content-item col-six center"><h1>Branche Manager Europe (m/f/d) | Full time</h1></div>


  <div className="grid-content-item col-six center"><h2>STARTING DATE: IMMEDIATELY</h2></div>

        
  <div className="grid-content-item col-six left"><h2>Your tasks:</h2>
<p>- Creating a business plan on short and long term basis with the strategic goals<br></br>
- Developing and implementing key performance Indicators, budget and financial systems, and reports<br></br>
- Implementing the performance appraisal and competence development processes<br></br>
- Leading the commercial activity in the local business unit to optimize sales and business development for sustainable growth<br></br>
- Optimising operational capacity, efficiency and effectiveness<br></br>
- Ensuring that the company at any time is properly organized and staffed with qualified and motivated employees<br></br>
- Proposing a suitable compensation package for the employees of the company within approved guidelines and cost limits<br></br>
- Participating in internal and external quality audits and inspections and ensuring the co-operation of all local personnel towards such activities<br></br>
- Developing and maintaining a training system, encompassing learning modules, utilising other learning tools and techniques to achieve the best possible impact<br></br>
- Ensuring adherence related to regulatory issues<br></br>
- Liaising with our Insurer(s) and ensuring appropriate protection and mitigation against risks inherent or contracted in the business<br></br><br></br></p>
<h2>Your expertise and skills:</h2>
<p>- Bachelor's or Master's degree from a reputable institution<br></br>
- 5 years of working experience in similar role or responsibility<br></br>
- Excellent command in English, while Mandarin will be an advantage<br></br>
- Proven track record of successful leadership exposure and influence<br></br>
- Pleasant personality and good communication & presentation skills<br></br>
- Entrepreneurial mindset with positive attitude and high optimism to drive result<br></br><br></br></p>
<h2>Your benefits:</h2>
<p>- Great colleagues and many employee events (e.g. hiking tours, charity events, team dinners etc.)<br></br>
- Individual development and training opportunities (e.g. trainings, global Talent Management programs)<br></br>
- A broad range of benefits (e.g. health insurance, company pension scheme, etc.)</p></div>

   </div>
      </div>


 
   <div className="content-section-homepage">

        <div className="grid-layout">

  <div className="grid-content-item col-six center"><h1>Sales Executive (m/f/d) anywhere | Full time</h1></div>


  <div className="grid-content-item col-six center"><h2>STARTING DATE: IMMEDIATELY</h2></div>

        
  <div className="grid-content-item col-six left"><h2>Your tasks:</h2>
<p>- Comprehensively supporting and developing existing customers<br></br>
- Acquiring new customers to expand our customer portfolio<br></br>
- 
Independently developing and implementing marketing and sales measures<br></br>
- 
Performing potential and market analyses<br></br>
- 
Developing customer-specific offers<br></br>
- 
Processing transport tenders and preparing customer-specific quotations<br></br>
- 
Performing potential and market analyses<br></br>
- 
Keep the sales tools updated with all information about contacts, visits, quotes and deals closed during the week

<br></br><br></br></p>

<h2>Your expertise and skills:</h2>
<p>- Completed studies in the field of content creation or comparable qualifications
<br></br>
- Several years of sales experience in the communication and marketing industry
<br></br>
- Relevant market knowledge required
<br></br>
- Good knowledge of English (written and spoken)

<br></br><br></br></p>
<h2>Your benefits:</h2>
<p>- Great colleagues and many employee events (e.g. hiking tours, charity events, team dinners etc.)<br></br>
- Individual development and training opportunities (e.g. trainings, global Talent Management programs)<br></br>
- A broad range of benefits (e.g. health insurance, company pension scheme, etc.)</p></div>

   </div>
      </div>


   <div className="content-section-homepage">

        <div className="grid-layout">

  <div className="grid-content-item col-six center"><h1>Business Development (m/f/d) anywhere | Full time</h1></div>


  <div className="grid-content-item col-six center"><h2>STARTING DATE: IMMEDIATELY</h2></div>

        
  <div className="grid-content-item col-six left"><h2>Your tasks:</h2>
<p>- Develop and maintain customer portfolio and facilitate business growth<br></br>
- Prepare and implement marketing & sales approaches
<br></br>
- Grow sea freight & airfreight sectors, both export & import
<br></br>
- Represent our company to external customers
<br></br>
- Execute strategic sales plan incl. trade and route development
<br></br>
- Maintain a good image of the company and good relationships with customers and vendors incl. problem solving
<br></br>
- Close cooperation with our procurement & operations departments

<br></br><br></br></p>

<h2>Your expertise and skills:</h2>
<p>- Bachelor’s degree or an equivalent degree
<br></br>
- At least 5 years’ experience in a sales position or similar in communication, pr, marketing or similar
<br></br>
- Sufficient local market knowledge
<br></br>
- Good knowledge in MS Office suite
<br></br>
- Strong command of English
<br></br>
- Excellent communication, presentation and negotiation skills
<br></br>
- Team player with proactive work attitude and a entrepreneurial mindset
<br></br>
- Availability for traveling

<br></br><br></br></p>
<h2>Your benefits:</h2>
<p>- Great colleagues and many employee events (e.g. hiking tours, charity events, team dinners etc.)<br></br>
- Individual development and training opportunities (e.g. trainings, global Talent Management programs)<br></br>
- A broad range of benefits (e.g. health insurance, company pension scheme, etc.)</p></div>

   </div>
      </div>
  
    </>
  );
}

export default BuzzBeatJobsPage;
