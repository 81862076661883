import React, { useState, useEffect } from 'react';
import ProductCounter from './ProductCounter'; 
import BuzzBeatNews from './BuzzBeatNews'; 
import { useTranslation } from 'react-i18next';
 
function BuzzBeatTermsConditions() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/assets/images/backgrounds/image${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);




  return (
    <>
      <div className="buzzbeat-other-page" style={{ backgroundImage: `url(${backgroundImage})` }}></div>


<div className="content-section-homepage">

  <div className="grid-content-item col-six center"><h1>{t('terms_conditions')} BuzzBeat.io</h1></div>
<div className="grid-content-item col-six">


<h2>{t('purpose_headline')}</h2>
<p>
{t('purpose_1')}
<br></br><br></br>
{t('purpose_2')}
<br></br><br></br>
{t('purpose_3')}
<br></br><br></br>
{t('purpose_4')}
<br></br><br></br>
{t('purpose_5')}
<br></br><br></br></p>
</div>
</div>

<div className="content-section-homepage">
<div className="grid-content-item col-six">

<h2>{t('services_headline')}</h2>

<p>{t('services_1')}
<br></br><br></br></p>

</div>
</div>

<div className="content-section-homepage">
<div className="grid-content-item col-six">
<h2>{t('customer_duties_headline')}</h2>
<p>
{t('customer_duties_1')}
<br></br>
{t('customer_duties_1a')}
<br></br>
{t('customer_duties_1b')}
<br></br><br></br>
{t('customer_duties_2')}
<br></br><br></br>
{t('customer_duties_3')}
<br></br><br></br>
{t('customer_duties_4')}
<br></br><br></br></p>

</div>
</div>

<div className="content-section-homepage">
<div className="grid-content-item col-six">
<h2>{t('fees_headline')}</h2>
<p>
{t('fees_1')}<br></br><br></br>
{t('fees_2')}<br></br><br></br>
{t('fees_3')}<br></br><br></br>
{t('fees_4')}<br></br><br></br>
{t('fees_5')}<br></br><br></br>
{t('fees_6')}<br></br><br></br>
{t('fees_7')}<br></br><br></br></p>

</div>
</div>

<div className="content-section-homepage">
<div className="grid-content-item col-six">
<h2>{t('usage_headline')}</h2>
<p>
{t('usage_1')}<br></br><br></br>
{t('usage_2')}<br></br><br></br>
 {t('usage_3')}<br></br><br></br>
{t('usage_4')}<br></br><br></br>
{t('usage_5')}<br></br><br></br>
{t('usage_6')}<br></br><br></br></p>
</div>
</div>

<div className="content-section-homepage">
<div className="grid-content-item col-six">
<h2>{t('customer_data_headline')}</h2>
<p>
{t('customer_data_1')}<br></br><br></br>
{t('customer_data_2')}<br></br><br></br>
 {t('customer_data_3')}<br></br><br></br>
{t('customer_data_4')}
<br></br><br></br>
{t('customer_data_5')}
<br></br><br></br>
{t('customer_data_6')}
<br></br><br></br>
{t('customer_data_7')}<br></br><br></br></p>
</div>
</div>

<div className="content-section-homepage">
<div className="grid-content-item col-six">
<h2>{t('liabilities_headline')}</h2>
<p>
{t('liabilities_1')}
<br></br><br></br>
{t('liabilities_2')}
<br></br><br></br>
{t('liabilities_3')}
<br></br><br></br>
{t('liabilities_4')}
<br></br><br></br>
{t('liabilities_5')}
<br></br><br></br></p>
</div>
</div>

<div className="content-section-homepage">
<div className="grid-content-item col-six">
<h2>{t('non_disclosure_headline')}</h2>
<p>
{t('non_disclosure_1')}<br></br><br></br>
{t('non_disclosure_2')}<br></br><br></br>
</p>
</div>
</div>

<div className="content-section-homepage">
<div className="grid-content-item col-six">
<h2>{t('termination_headline')}</h2>
<p>
{t('termination_1')}<br></br><br></br>
{t('termination_2')}<br></br><br></br>
{t('termination_3')}<br></br><br></br></p>
</div>
</div>

<div className="content-section-homepage">
<div className="grid-content-item col-six">
<h2>{t('final_headline')}</h2>
<p>
{t('final_1')}<br></br><br></br>
{t('final_2')}<br></br><br></br>
{t('final_3')}<br></br><br></br></p>


</div>

      </div>

</>
);
}

export default BuzzBeatTermsConditions;



