import React, { useState, useEffect } from 'react';
import axios from '../axios.js';
import { useTranslation } from 'react-i18next';
import { useNavigate, NavLink } from 'react-router-dom';

const DashboardSubscription = ({ companyId }) => {
    const [plans, setPlans] = useState([]);
    const [currentSubscription, setCurrentSubscription] = useState(null);
    const [isYearly, setIsYearly] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const { t } = useTranslation();

 const fetchCurrentSubscription = async () => {
    try {
        const response = await axios.get(`/subscription/current/${companyId}`);
        if (response.data) {
            const subscriptionData = response.data;

            const itemPriceIds = subscriptionData.subscription_items.map(item => item.item_price_id);

            const mappedData = {
                planNames: itemPriceIds,
                price: `${subscriptionData.mrr / 100} ${subscriptionData.currency_code}`,
                creationDate: new Date(subscriptionData.created_at * 1000).toLocaleDateString(),
                nextBillingCycle: new Date(subscriptionData.next_billing_at * 1000).toLocaleDateString(),
		billingCycle: subscriptionData.billing_period_unit
            };
            setCurrentSubscription(mappedData);
        }
    } catch (err) {
    }
};



useEffect(() => {
    fetchCurrentSubscription();
}, [companyId]);






    return (
<>
 

{currentSubscription && (
<>
        <h2>{t('current_subscription')}</h2>
     	<p>{t('billing_cycle')}: {t('per')} {currentSubscription.billingCycle}</p>
        <p>{t('plan')}: {currentSubscription.planNames.join(', ')}</p>
        <p>{t('price')}: {currentSubscription.price}</p>
        
<p><br></br></p>
		<div> <NavLink to="subscription" className="blueButton">{t('get_access_to_all_features')}</NavLink></div> 


</>

)}


</>
    );
};

export default DashboardSubscription;

