import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext'; 
 
import CollaborationRequestOverview from './CollaborationRequestOverview';
import LoadingIndicator from '../LoadingIndicator';

const GiveCollaborationsPage = ({ companyId }) => {
const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState('');
    const [openCollaborations, setOpenCollaborations] = useState([]);
    const [allCollaborations, setAllCollaborations] = useState([]);
    const [myCollaborations, setMyCollaborations] = useState([]);
    const [activeTab, setActiveTab] = useState('openCollaborations');
    const [creatorCollaborations, setCreatorCollaborations] = useState([]); 
    const [activeCollaborationId, setActiveCollaborationId] = useState(null);
    const [collaborationRequest, setCollaborationRequest] = useState(null);
    const [collaborations, setCollaborations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { fetchWithAuth } = useAuth();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const fetchData = async (activeTab) => {
 setIsLoading(true);

        const storedUserId = localStorage.getItem('userId');
        try {
            let endpoint = `/collaboration/collaborations/${companyId}?type=${activeTab}`;
            if (activeTab === 'user' || activeTab === 'creator') {
                endpoint += `&userId=${storedUserId}`;
            }

            const response = await fetchWithAuth(endpoint);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setCollaborations(data);
    setIsLoading(false);

        } catch (error) {
            setError('Failed to load data. Please try again later.');
        }
    };


 useEffect(() => {
        fetchData(activeTab);
    }, [companyId, activeTab]);


    const handleSendCollaborationRequest = async (contentId) => {
        try {
            const response = await fetchWithAuth(`/collaboration/send-collaboration-notifications/${contentId}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (e) {
            setError('Failed to send collaboration notifications. Please try again later.');
        }
    };

    const handleReminder = async (collaboratorEmail, contentId) => {
        try {
            const response = await fetchWithAuth(`/collaboration/send-reminder/${contentId}`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ email: collaboratorEmail })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (e) {
        }
    };

    const handleEditClick = (collaborationRequest) => {
        navigate(`/app/app/collaborate/${collaborationRequest.onModel}/${collaborationRequest.contentId}`, { state: { companyId } });
    };

    const renderTabContent = () => {
        return collaborations.map((collaboration, index) => (
            <CollaborationRequestOverview
                key={index}
                collaborationRequest={collaboration}
                handleReminder={() => handleReminder(collaboration.email, collaboration.contentId)}
                handleSendCollaborationRequest={() => handleSendCollaborationRequest(collaboration.contentId)}
                isActive={activeCollaborationId === collaboration._id}
                toggleActive={() => setActiveCollaborationId(activeCollaborationId === collaboration._id ? null : collaboration._id)}
                handleEditClick={() => handleEditClick(collaboration)}
fetchData={fetchData} 
            />
        ));
    };

if (isLoading) {
    return <div><LoadingIndicator /> 
</div>;
  }


    return (
<div className="content-twocol-container">
       
<div className="content-box">


            <div className="tab-content">
<div className="table">
                        <div className="table-header">
                            <div className="header-collaboration"></div>

                            <div className="header-collaboration">{t('content')}</div>
                          <div className="header-collaboration">{t('type')}</div>
                            <div className="header-collaboration">{t('request_sent')}</div>
                            <div className="header-collaboration">{t('collaborated')}</div> 
                            <div className="header-collaboration"></div>
                        </div>
</div>
                {renderTabContent()}
            </div>
        </div>


<div className="content-container-column">

<div className="content-button-box row-four">
            <div className="tab-controls">
<h2>{t('filter')}</h2>
                <button onClick={() => setActiveTab('open')} className={activeTab === 'open' ? 'active' : ''}>{t('open')}</button>
                <button onClick={() => setActiveTab('all')} className={activeTab === 'all' ? 'active' : ''}>{t('all')}</button>
                <button onClick={() => setActiveTab('user')} className={activeTab === 'user' ? 'active' : ''}>{t('to_collaborate')}</button>
                <button onClick={() => setActiveTab('creator')} className={activeTab === 'creator' ? 'active' : ''}>{t('my_collaborations')}</button>
            </div>
</div>

{successMessage && <div className="content-box blue-background"> {successMessage}</div>}

</div>


</div>
    );
};

export default GiveCollaborationsPage;
