import React, { useEffect, useState, useCallback } from 'react';
import '../../Spinner.css';
import { useTranslation } from 'react-i18next';
import axios from '../../axios.js';
import { useHistory } from 'react-router-dom';

const CompanyDashboardPressReleaseFunction = ({ existingCompany, pressReleaseId, users, formatDate, onCancel }) => {

    const [pressReleases, setPressReleases] = useState([]);
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState(existingCompany || { content: { pressReleases: [pressReleaseId] } });
    const [pressReleaseBullets, setPressReleaseBullets] = useState('');
    const [pressReleaseText, setPressReleaseText] = useState('');
    const [pressReleaseQuotes, setPressReleaseQuotes] = useState('');
    const [pressReleaseFacts, setPressReleaseFacts] = useState('');
    const [headline, setHeadline] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

 useEffect(() => {
        fetchCompanyData();
    }, []);

const fetchCompanyData = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }
        const response = await axios.get('/companies/get-company', {
            headers: { Authorization: `Bearer ${token}` }
        });
        const pressReleasesResponse = await axios.get('/content/pressReleases/get-for-company', {
            headers: { Authorization: `Bearer ${token}` }
        });

        const filteredPressReleases = pressReleasesResponse.data.filter(pressRelease => pressRelease._id === pressReleaseId);

           if (filteredPressReleases.length > 0) {
          setPressReleaseBullets(filteredPressReleases[0].pressReleaseBullets || '');
                setPressReleaseText(filteredPressReleases[0].pressReleaseText || '');
                setPressReleaseQuotes(filteredPressReleases[0].pressReleaseQuotes || '');
                setPressReleaseFacts(filteredPressReleases[0].pressReleaseFacts || '');
                setHeadline(filteredPressReleases[0].headline || '');

        }

        setCompany(response.data);
        setPressReleases(filteredPressReleases);
   

    } catch (error) {
    }
};


const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }

        let updatedCompany = {...company};

        if (!pressReleaseId) {
            const userId = localStorage.getItem('userId');
            const newPressRelease = {
                pressReleaseBullets,
                pressReleaseText,
                pressReleaseQuotes,
                pressReleaseFacts,
                headline,
                pressReleaseTranslations: [],
                creator: userId,
                creationDate: new Date()
            };

            updatedCompany.content.pressReleases = [...(updatedCompany.content?.pressReleases || []), newPressRelease];

        } else {

            const pressReleaseIndex = updatedCompany.content.pressReleases.findIndex(pr => pr._id === pressReleaseId);
            if (pressReleaseIndex !== -1) {
                updatedCompany.content.pressReleases[pressReleaseIndex] = {
                    ...updatedCompany.content.pressReleases[pressReleaseIndex],
                    pressReleaseBullets,
                    pressReleaseText,
                    pressReleaseQuotes,
                    pressReleaseFacts,
			headline,
                };
            }
        }

        const response = await axios.put(`/companies/${updatedCompany._id}`, updatedCompany, {
            headers: { Authorization: `Bearer ${token}` }
        });

        setCompany(response.data);

           setSuccessMessage(t('save_success'));
           setTimeout(() => {
           setSuccessMessage('');
        }, 2000);
        fetchCompanyData();
    } catch (error) {
    }
};

  return (
<>


<textarea className="content-box-main-headline" name="headline" value={headline} onChange={(e) => setHeadline(e.target.value)} placeholder={t('press_release_headline')} rows="3" cols="50" />
<textarea className="content-box-main-textarea" value={pressReleaseText} onChange={(e) => setPressReleaseText(e.target.value)} placeholder={t('press_release_text')} rows="10" cols="50" />


       


 <div className="content-box three-one">
                    <button className="save" onClick={handleSubmit}>{t('save_button')}</button>
                    <button className="save" onClick={onCancel}>{t('cancel_button')}</button>
                </div>
 
{successMessage && <div className="content-box blue-background"> {successMessage}</div>}




</>
    );
};

export default CompanyDashboardPressReleaseFunction;
