import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route, Link } from 'react-router-dom';
import ProductCounter from './ProductCounter'; 
import BuzzBeatNews from './BuzzBeatNews'; 
import { useTranslation } from 'react-i18next';

function BuzzBeatStartingPage() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/assets/images/backgrounds/image${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className="buzzbeat-starting-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="overlay-content" style={{ top: `${offsetY * 0.3}px` }}>
         <h1>{t('headline_starting_page')}</h1>
<p>{t('subheadline_starting_page')}</p>
          <div className="buttons">
            <Link to="/register"><button className="btn">{t('get_started_button')}</button></Link>
            <Link to="/features"><button className="btn">{t('learn_more')}</button></Link>
          </div>
        </div>
      </div>



      <div className="content-section-homepage">

        <div className="grid-layout">
        
  <div className="grid-content-item col-six center"><h1>{t('headline1_starting')}


</h1><h2>{t('subheadline1_starting')}</h2></div>
          <div className="grid-content-item col-six"><p>{t('text1_starting')}<br></br><br></br></p></div>

          <div className="grid-item col-two center"><h2>{t('creation')}</h2>

<div className="grid-content-item col-two center">
    <div className="screen-container">
        <div className="screenshot">
            <img src="../../assets/images/screenshots/manage_overview.png" alt="Screenshot"/>
        </div>
        <img src="../../assets/images/screen.png" alt="Screen" className="screen-image"/>
    </div>
</div><p>{t('creation_starting_feature')}</p>

</div>

          <div className="grid-item col-two center"><h2>{t('collaboration')}</h2>
<div className="grid-content-item col-two center">
    <div className="screen-container">
        <div className="screenshot">
            <img src="../../assets/images/screenshots/start_collaboration.png" alt="Screenshot"/>
        </div>
        <img src="../../assets/images/screen.png" alt="Screen" className="screen-image"/>
    </div>
</div>
<p>{t('collaboration_starting_feature')}</p>
</div>

<div className="grid-item col-two center"><h2>{t('approval')}</h2>
<div className="grid-content-item col-two center">
    <div className="screen-container">
        <div className="screenshot">
            <img src="../../assets/images/screenshots/workon_collaborate.png" alt="Screenshot"/>
        </div>
        <img src="../../assets/images/screen.png" alt="Screen" className="screen-image"/>
    </div>
</div>
<p>{t('approval_starting_feature')}</p>
</div>

          <div className="grid-content-item col-six center"><br></br><Link to="/register"><button className="action">{t('get_started_button')}</button></Link><Link to="/features"><button className="action">{t('learn_more')}</button></Link></div>


        </div>
      </div>

 <div className="content-section-homepage">

  <div className="grid-content-item col-six center"><h1>{t('satisfied')}<br></br><br></br></h1></div>

<ProductCounter />
      </div>

       <div className="content-section-homepage">
        <div className="grid-layout center">
<div className="grid-content-item col-six center"><h1>{t('power_of_buzzbeat')}</h1></div>
          <div className="grid-content-item col-six"><p>{t('power_of_buzzbeat_text')}<br></br><br></br></p></div>

</div>
        <div className="grid-layout">

        <div className="grid-item col-one center">
 <div className="inner-grid-item center">
<div><h3>{t('create')}<br></br> {t('account')}</h3></div>

<div><img className="small-icon" src="../../assets/images/heart.png" /></div>
<div><p>{t('power1')}</p></div>
</div>
</div>

 <div className="grid-item col-one center">
<div className="inner-grid-item center">

<div><h3>{t('customize')}<br></br> {t('context')}</h3></div>

<div><img className="small-icon" src="../../assets/images/wrench.png" /></div>
<div><p>{t('power2')}</p></div>
</div>
</div>

 <div className="grid-item col-one center">
<div className="inner-grid-item center">


<div><h3>{t('invite')}<br></br> {t('team_starting')}</h3></div>

<div><img className="small-icon" src="../../assets/images/mate.png" /></div>
<div><p>{t('power3')}</p></div>
</div>
</div>

 <div className="grid-item col-one center">
<div className="inner-grid-item center">

<div><h3>{t('generate')}<br></br> {t('content_starting')}</h3></div>

<div><img className="small-icon" src="../../assets/images/generate.png" /></div>
<div><p>{t('power4')}</p></div>
</div>
</div>

 <div className="grid-item col-one center">
<div className="inner-grid-item center">
<div><h3>{t('collaborate_starting')}<br></br> {t('creativity')}</h3></div>

<div><img className="small-icon" src="../../assets/images/talk.png" /></div>
<div><p>{t('power5')}</p></div>
</div>

</div>

 <div className="grid-item col-one center">
<div className="inner-grid-item center">

<div><h3>{t('request')} <br></br>{t('approval_starting')}</h3></div>

<div><img className="small-icon" src="../../assets/images/perfection.png" /></div>
<div><p>{t('power6')}</p></div>
</div>

</div>

</div>

  <div className="grid-layout">
<div className="grid-content-item col-six center"><p><br></br>{t('power_statement')}<br></br><br></br></p>
<h2>{t('power_statement1')}</h2></div>
<div className="grid-content-item col-six center"><Link to="/register"><button className="action">{t('get_started_button')}</button></Link></div>
</div>

      </div>

<BuzzBeatNews />

 <div className="content-section-homepage">

  <div className="grid-content-item col-six center"><h1>{t('convinced')}</h1></div>
<div className="grid-content-item col-six center"><Link to="/register"><button className="action">{t('get_started_button')}</button></Link></div>

      </div>

     
    </>
  );
}

export default BuzzBeatStartingPage;
