import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';
 
const CollaborationRequestDisplay = ({ collaborationRequest, handleReminder, handleSendCollaborationRequest, handleEditClick, fetchData }) => {
    const [currentUserId, setCurrentUserId] = useState('');
   const { t } = useTranslation();
    const { fetchWithAuth } = useAuth();

  useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        setCurrentUserId(storedUserId);
    }, []);


    if (!collaborationRequest) {
        return <div></div>;
    }

const updateCollaborationStatus = async (collaboratorId, status) => {
    try {
        const response = await fetchWithAuth(`/collaboration/updateStatus/${collaboratorId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ collaborationStatus: status }),
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok. Status: ${response.status}`);
        }

        fetchData();
    } catch (error) {
            alert('Failed to update collaboration status. Please try again.');
    }
};


    const isCurrentUserAnInternalCollaborator = () => {
        return collaborationRequest.internalCollaborators.some(collaborator => collaborator.userId._id === currentUserId);
    };

  const isCurrentUserTheCreator = () => {
    return collaborationRequest.creator && collaborationRequest.creator._id === currentUserId;
  };

  return (
        <div>

              {collaborationRequest && (
                <div>
                   
		<div>
       				

          {isCurrentUserTheCreator() ? (
	<>
        	<div className="collaborators-section">
			<h2>{t('overall_collaboration_status')}: {collaborationRequest.overallCollaborationStatus}</h2>
			{collaborationRequest.collaborationSentDate && (
 			   <p className="small">{t('team_informed')}<br></br><br></br></p>
      			)}
        		<h2>{t('internal_collaborators')}:</h2>
				{!collaborationRequest.collaborationSentDate && (
           				<div> 
						<button className="tiny_button" onClick={handleSendCollaborationRequest}>
                					{t('send_collaboration_request')}
            					</button>
					<p><br></br></p>
					</div>
        			)}
            <ul className="collaborators-list">
                {collaborationRequest.internalCollaborators.map((collaborator, index) => (
                                        <li key={index} className={collaborator.collaborationStatus.toLowerCase()}>
                                            <span>{collaborator.userId?.name} {collaborator.userId?.surname}</span>
                                            <span> {collaborator.collaborationStatus}</span>
                                          {collaborationRequest.collaborationSentDate && collaborator.collaborationStatus !== "Collaborated" && (
    <button className="tiny_button" onClick={() => handleReminder(collaborator.userId.email)}>
        {t('send_reminder')}
    </button>
)}


 					{collaborator.userId._id === currentUserId && collaborator.collaborationStatus !== "Collaborated" && ( 

                                                <select 
                                                    value={collaborator.collaborationStatus}
                                                    onChange={(e) => updateCollaborationStatus(collaborator._id, e.target.value)}
                                                >
                                                    <option value="Pending">{t('still_working')}</option>
						     <option value="rewrite">{t('rewrite_it')}</option>
                                                    <option value="Commented">{t('commented')}</option>
							<option value="Collaborated">{t('done')}</option>
				                                                   
                                                </select>
                                            )}
                                        </li>
                                    ))}
            </ul>
        </div>

      
 </>
    ) : (<>

<div className="creator-info">
	<span>{t('created_by')}: {collaborationRequest.creator?.name} {collaborationRequest.creator?.surname}</span>
</div>

 <div className="collaborators-section">
            <ul className="collaborators-list">
                {collaborationRequest.internalCollaborators.map((collaborator, index) => (
                                      collaborator.userId._id === currentUserId && collaborator.collaborationStatus !== "Collaborated" ? (

				  <li key={index} className={collaborator.collaborationStatus.toLowerCase()}>
                                            <span>{collaborator.userId?.name} {collaborator.userId?.surname}</span>
                                            
                                            {collaborator.collaborationDate && <span>{t('changed')}: {new Date(collaborator.collaborationDate).toLocaleDateString()}</span>}
                                         

 			
                                                <select 
                                                    value={collaborator.collaborationStatus}
                                                    onChange={(e) => updateCollaborationStatus(collaborator._id, e.target.value)}
                                                >
                                                             <option value="Pending">{t('still_working')}</option>
						     <option value="rewrite">{t('rewrite_it')}</option>
                                                    <option value="Commented">{t('commented')}</option>
							<option value="Collaborated">{t('done')}</option>       
                                                </select>
                                            
                                        </li>
) : ( collaborator.userId._id === currentUserId && (

				<li key={index} className={collaborator.collaborationStatus.toLowerCase()}>
                                            <span>{collaborator.userId.name} {collaborator.userId.surname} - </span>
                                            <span>{collaborator.collaborationStatus}</span>
                                            {collaborator.collaborationDate && <span>{t('changed')}: {new Date(collaborator.collaborationDate).toLocaleDateString()}</span>}
                                         

 			
                                                                                           
                                        </li>
)

)




))}
            </ul>
        </div>



</>)
  
}

        



       
    </div>


                </div>

            )}
        </div>
    );
};

export default CollaborationRequestDisplay;

