import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';
 
const CollaborationRequestDashboardOverview = ({ 
    collaborationRequest, 
    handleReminder, 
    handleSendCollaborationRequest, 
    isActive,
    toggleActive,
handleEditClick
}) => {
    const [currentUserId, setCurrentUserId] = useState('');
const { t } = useTranslation();
    const { fetchWithAuth } = useAuth();

  useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        setCurrentUserId(storedUserId);
    }, []);

     const handleToggleClick = () => {
        toggleActive(collaborationRequest._id);
    };


 	

const countCollaborationStatuses = () => {
    const allCollaborators = [...collaborationRequest.internalCollaborators, ...collaborationRequest.externalCollaborators];
    const total = allCollaborators.length;
    const collaboratedCount = allCollaborators.filter(collaborator => collaborator.collaborationStatus === 'Collaborated').length;
    return `${collaboratedCount} of ${total}`;
};


 useEffect(() => {
    const autoUpdateCollaborationStatus = async () => {
      if (collaborationRequest && collaborationRequest._id) {
        await updateOverallStatus(collaborationRequest._id);
      }
    };

    autoUpdateCollaborationStatus();
  }, [collaborationRequest]);

const updateOverallStatus = async (collaborationRequestId) => {
    try {
        const response = await fetchWithAuth(`/collaboration/update-overall-status/${collaborationRequestId}`, { method: 'PUT' });
        if (response.ok) {
            const data = await response.json();
        } else {
            throw new Error('Failed to update overall collaboration status.');
        }
    } catch (error) {
    }
};

const getModelDisplayName = (model) => {
    const modelDisplayNames = {
        pressreleases: t('press_release'),
        articles: t('article'),
	blogposts: t('blogpost'),
	usecases: t('usecase'),

corporatetexts: t('corporatetext'),
    };

    return modelDisplayNames[model] || model;
};



const updateCollaborationStatus = async (collaboratorId, status) => {
        try {
            const response = await fetchWithAuth(`/collaboration/updateStatus/${collaboratorId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ collaborationStatus: status }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
        }
    };

     const isCurrentUserAnInternalCollaborator = () => {
        return collaborationRequest.internalCollaborators.some(collaborator => collaborator.userId._id === currentUserId);
    };

  const isCurrentUserTheCreator = () => {
    return collaborationRequest.creator && collaborationRequest.creator._id === currentUserId;
  };

     return (
        <>
              {collaborationRequest && (
                <>
                       <div className="dashboard-content-item">
  <div>
    {collaborationRequest && (
                 <span className={`status-circle ${collaborationRequest.overallCollaborationStatus === 'Collaborated' ? 'collaborated' : 'not-collaborated'}`}></span>
            )}
</div>

                            <div><p>{collaborationRequest.contentHeadline}</p></div>
                            <div><p>{getModelDisplayName(collaborationRequest.onModel)}</p></div>
     			    <div><button className="toggle-translations-btn" onClick={() => handleEditClick(collaborationRequest)}>{t('view')}</button>
</div>
                        </div>
                    

                   

                </>
            )}
        </>
    );
};

export default CollaborationRequestDashboardOverview;
