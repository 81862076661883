import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { diffWordsWithSpace } from 'diff';
import axios from '../axios.js'; 
import { useAuth } from '../AuthContext';
import i18n from 'i18next';

import CollaborationRequestDisplay from './CollaborationRequestDisplay'; 
import CollaborationRequestComments from './CollaborationRequestComments'; 
import LoadingIndicator from '../LoadingIndicator';

const CollaborationPage = () => {
    const { onModel, contentType, contentId } = useParams();
    const location = useLocation();
    const { companyId, userId } = location.state || {};
    const [contentData, setContentData] = useState(null);
    const [collaborators, setCollaborators] = useState([]);
    const [selectedCollaborator, setSelectedCollaborator] = useState('');
    const [selectedCollaborators, setSelectedCollaborators] = useState([]);
    const [customCollaboratorEmail, setCustomCollaboratorEmail] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [comment, setComment] = useState('');
    const [collaborationRequest, setCollaborationRequest] = useState(null);
    const [editableContent, setEditableContent] = useState('');
    const [editableHeadline, setEditableHeadline] = useState('');
    const [currentUserId, setCurrentUserId] = useState('');
const [formattedHeadline, setFormattedHeadline] = useState('');
const [formattedText, setFormattedText] = useState('');
const [changes, setChanges] = useState([]);
const [acceptedHeadlineChanges, setAcceptedHeadlineChanges] = useState([]);
const [acceptedTextChanges, setAcceptedTextChanges] = useState([]);
const [originalHeadline, setOriginalHeadline] = useState('');
const [originalText, setOriginalText] = useState('');
const [formattedOriginalHeadline, setFormattedOriginalHeadline] = useState('');
const [formattedOriginalText, setFormattedOriginalText] = useState('');
const [currentUserCollaborationStatus, setCurrentUserCollaborationStatus] = useState('');
    const { fetchWithAuth } = useAuth();

    const storedUserId = localStorage.getItem('userId');
const { t } = useTranslation();



 useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        setCurrentUserId(storedUserId);
    }, []);

  const fetchData = async () => {

 setIsLoading(true);
 setError('');

const findLatestUnreviewedChange = (changes) => {
  const unreviewedChanges = changes.filter(change => !change.reviewed);
  return unreviewedChanges[unreviewedChanges.length - 1]; // Get the last item
};


  const localCompanyId = localStorage.getItem('buzzBeatCompanyId') || companyId;

  if (!localCompanyId) {
    setIsLoading(false);
    return;
  }

  try {
    const contentResponse = await fetchWithAuth(`/collaboration/content/${contentType}/${localCompanyId}/${contentId}`);

    if (!contentResponse.ok) {
      throw new Error(`HTTP error! status: ${contentResponse.status}`);
    }
    const content = await contentResponse.json();
    setContentData(content);


setEditableContent(content[`${contentType}Text`] || '');
        setEditableHeadline(content.headline || '');

    let contentTextBasedOnType = '';
    switch (contentType) {
      case 'articles':
        contentTextBasedOnType = content.articleText || '';
        break;
      case 'pressreleases':
        contentTextBasedOnType = content.pressReleaseText || '';
        break;
      case 'blogposts':
        contentTextBasedOnType = content.blogpostText || '';

        break;
case 'usecases':
        contentTextBasedOnType = content.usecaseText || '';

        break;
      case 'corporatetexts':
        contentTextBasedOnType = content.corporatetextText || '';

        break;
      default:
        break;
    }
    

    const companyUsersResponse = await fetchWithAuth(`/collaboration/get-company-users/${localCompanyId}`);
    if (!companyUsersResponse.ok) {
      throw new Error(`HTTP error! status: ${companyUsersResponse.status}`);
    }
    const { companyUsers } = await companyUsersResponse.json();
    setCollaborators(companyUsers);

   const collaborationRequestResponse = await fetchWithAuth(`/collaboration/get-collaboration-request/${contentId}`);
  if (!collaborationRequestResponse.ok) {
    throw new Error(`HTTP error! status: ${collaborationRequestResponse.status}`);
  }
    const collaborationRequestData = await collaborationRequestResponse.json();
  setCollaborationRequest(collaborationRequestData);

const currentUserStatus = collaborationRequestData.internalCollaborators.find(collaborator => collaborator.userId._id === currentUserId)?.collaborationStatus;

setCurrentUserCollaborationStatus(currentUserStatus);



  setEditableHeadline(collaborationRequestData.collaborationContentHeadline);
  setEditableContent(collaborationRequestData.collaborationContentText);

setOriginalHeadline(collaborationRequestData.collaborationContentHeadlineOriginal || '');
setOriginalText(collaborationRequestData.collaborationContentTextOriginal || '');

  } catch (e) {
    setError(t('data_load_failed'));
  } finally {
    setIsLoading(false);
  }
};



useEffect(() => {
  fetchData();
}, [contentId, companyId, contentType]);





const handleCollaborationSubmit = async () => {
    let originalContent = null;

    try {
        const contentResponse = await fetchWithAuth(`/collaboration/content/${contentType}/${companyId}/${contentId}`);

        if (!contentResponse.ok) {
            throw new Error('Failed to fetch original content due to HTTP error.');
        }

        originalContent = await contentResponse.json();
    } catch (error) {
        setError('Failed to fetch original content. Please try again later.');
        return; 
    }

    const contentTypeToDbField = {
        articles: 'articleText',
        pressreleases: 'pressReleaseText',
        blogposts: 'blogpostText',
        corporatetexts: 'corporatetextText',
        usecases: 'usecaseText',
    };

    const contentTextFieldName = contentTypeToDbField[contentType] || '';

    const internalCollaboratorsData = selectedCollaborators.map(collaboratorId => ({
        userId: collaboratorId, 
    }));

    const collaborationData = {
        contentId,
        companyId,
        creatorId: userId,
        onModel: contentType,
        internalCollaborators: internalCollaboratorsData,
        collaborationContentHeadline: originalContent.headline || '',
        collaborationContentText: originalContent[contentTextFieldName] || '',
        collaborationContentHeadlineOriginal: originalContent.headline || '', 
        collaborationContentTextOriginal: originalContent[contentTextFieldName] || '', 
        ...(comment.trim() && { comment })
    };

    try {
        const response = await fetchWithAuth('/collaboration/send-collaboration', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(collaborationData)
        });

        if (!response.ok) {
            throw new Error('Failed to send collaboration request due to HTTP error.');
        }

        setComment('');
        fetchData(); 
    } catch (e) {
        setError(t('collaboration_send_failed')); 
    }
};


const handleAddCollaborator = async () => {
    if (selectedCollaborator) {
        const collaboratorDetails = collaborators.find(user => user._id === selectedCollaborator);
        if (collaboratorDetails && !selectedCollaborators.some(collaborator => collaborator._id === collaboratorDetails._id)) {
            const collaboratorToAdd = { ...collaboratorDetails };
            setSelectedCollaborators(prev => [...prev, collaboratorToAdd]);

            try {
                const response = await fetchWithAuth(`/collaboration/add-collaborator/${contentId}`, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({ userId: collaboratorToAdd._id }) 
                });
                if (!response.ok) {
                    throw new Error('Failed to add collaborator to the backend.');
                }
fetchData(); 
            } catch (error) {
            }
        }
        setSelectedCollaborator(''); 
    }
};



const selectedCollaboratorIds = selectedCollaborators
    .filter(collaborator => collaborator.userId && collaborator.userId._id)
    .map(collaborator => collaborator.userId._id.toString());

    const availableCollaborators = collaborators.filter(collaborator => 
    !selectedCollaborators.some(selected => selected._id === collaborator._id)
);



       const isReminderDue = (collaborationDate) => {
        const threeDaysAgo = new Date(new Date().setDate(new Date().getDate() - 3));
        return collaborationDate < threeDaysAgo;
    };


const handleSendCollaborationRequest = async () => {
    try {
        const currentLanguage = i18n.language;

        const response = await fetchWithAuth(`/collaboration/send-collaboration-notifications/${contentId}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ language: currentLanguage })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

    setCollaborationRequest(prev => ({ ...prev, collaborationSentDate: new Date().toISOString() }));

    } catch (e) {
        setError('Failed to send collaboration notifications. Please try again later.');
    }
};

const handleReminder = async (collaboratorEmail) => {
    try {

        const currentLanguage = i18n.language;

        const response = await fetchWithAuth(`/collaboration/send-reminder/${contentId}`, { 
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ email: collaboratorEmail, language: currentLanguage })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

    } catch (e) {
       
    }
};

const handleAddComment = async () => {
    if (!collaborationRequest || comment.trim() === "") {
        return;
    }

    try {
        const response = await fetchWithAuth('/collaboration/add-comment', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                collaborationRequestId: collaborationRequest._id,
                userId: userId,
                comment: comment.trim()
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        setComment('');
        fetchData();
    } catch (e) {
        setError('Failed to add comment. Please try again later.');
    }
};

const handleHeadlineChange = (e) => {
  setEditableHeadline(e.target.value);
};

const handleContentChange = (e) => {
  setEditableContent(e.target.value);
};


const saveChanges = async () => {
  const changes = {
    contentId,
    companyId,
    editorId: currentUserId,
    newText: editableContent,
    newHeadline: editableHeadline,
  };

  try {
    const response = await fetchWithAuth('/collaboration/collaborate-user-changes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(changes),
    });

    if (!response.ok) {
      throw new Error('Failed to save changes');
    }

    const result = await response.json();

  } catch (error) {

  }
};


const compareAndFormatDifferences = () => {
  const headlineDiffs = diffWordsWithSpace(originalHeadline, editableHeadline);
  const textDiffs = diffWordsWithSpace(originalText, editableContent);

  const formattedHeadline = formatDiffs(headlineDiffs);
  const formattedText = formatDiffs(textDiffs);

  setFormattedOriginalHeadline(formattedHeadline);
  setFormattedOriginalText(formattedText);
};


useEffect(() => {
  compareAndFormatDifferences();
}, [originalHeadline, originalText, editableHeadline, editableContent]);


const fetchUnreviewedChanges = async () => {
  try {
    const response = await fetchWithAuth(`/collaboration/fetch-unreviewed-changes/${contentId}`);

    if (!response.ok) {
      throw new Error(`Failed to fetch changes: Server responded with status ${response.status}`);
    }

    const changes = await response.json();

    if (changes && changes.length > 0) {

      processChangesForDisplay(originalHeadline, originalText, changes);
    } else {
    }
  } catch (error) {
  }
};

const processChangesForDisplay = (originalHeadline, originalText, changes) => {
  if (!originalHeadline || !originalText || !changes) {
    return;
  }

  let combinedFormattedHeadline = originalHeadline;
  let combinedFormattedText = originalText;

  changes.forEach((change, index) => {
    const color = `hsl(${(index * 40) % 360}, 70%, 60%)`;

    let headlineDiffs = diffWordsWithSpace(combinedFormattedHeadline, change.newHeadline);
    combinedFormattedHeadline = formatDiffs(headlineDiffs, color);

    let textDiffs = diffWordsWithSpace(combinedFormattedText, change.newText);
    combinedFormattedText = formatDiffs(textDiffs, color);
  });

combinedFormattedText = combinedFormattedText
    .split('\n\n\n\n')
    .join('');

  setFormattedHeadline(combinedFormattedHeadline);
  setFormattedText(combinedFormattedText);
};

const formatDiffs = (diffs) => {
  return diffs.map(part => {
    if (part.added) {
      return `<span class="span-added">${part.value}</span>`;
    } 
    else if (part.removed) {
      return `<span class="span-removed">${part.value}</span>`;
    } 

    else if (part.changed) {
      return `<span class="span-changed">${part.value}</span>`;
    }
    else {
      return `<span class="span-else">${part.value}</span>`;
    }
  }).join('');
};


const handleCollaborationFinalize = async () => {
  try {
    const response = await fetchWithAuth('/collaboration/finalize', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        contentId,
        companyId,
        contentType,
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const result = await response.json();
    fetchData();
  } catch (error) {
  }
};

const resetCollaborationStatus = async (contentId) => {
  try {
    const response = await fetchWithAuth(`/collaboration/resetCollaborationStatus/${contentId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    alert('Collaboration status reset successfully.');
    fetchData();
  } catch (error) {
    alert('Failed to reset approval status.');
  }
};


const removeCollaborator = (indexToRemove) => {
  setSelectedCollaborators(prevCollaborators =>
    prevCollaborators.filter((_, index) => index !== indexToRemove)
  );
};

useEffect(() => {
    const addCollaborator = async () => {
        if (!selectedCollaborator) return;

        const collaboratorDetails = collaborators.find(user => user._id === selectedCollaborator);
        if (!collaboratorDetails) return;

        const isAlreadySelected = selectedCollaborators.some(collaborator => collaborator._id === collaboratorDetails._id);
        if (isAlreadySelected) {
            alert('This collaborator has already been added.');
            setSelectedCollaborator(''); 
            return;
        }

        setSelectedCollaborators(prev => [...prev, collaboratorDetails]);
        setSelectedCollaborator(''); 
    };

    addCollaborator();
}, [selectedCollaborator, collaborators, selectedCollaborators]);

const handleSelectChange = async (e) => {
    const newSelectedCollaborator = e.target.value;
    setSelectedCollaborator(newSelectedCollaborator); // Update the local state

    if (!newSelectedCollaborator) return; // Exit if no collaborator is selected

    const collaboratorDetails = collaborators.find(user => user._id === newSelectedCollaborator);
    if (!collaboratorDetails) return;

    // Check if the collaborator is already selected
    const isAlreadySelected = selectedCollaborators.some(collaborator => collaborator._id === collaboratorDetails._id);
    if (isAlreadySelected) {
        alert('This collaborator has already been added.');
        setSelectedCollaborator(''); // Reset the selected collaborator to none
        return;
    }

    setSelectedCollaborators(prev => [...prev, collaboratorDetails]); // Add the new collaborator to the selected list
    setSelectedCollaborator(''); // Reset the selected collaborator to none after adding

    // Now, make the API call to add the collaborator to the backend
    try {
        const response = await fetchWithAuth(`/collaboration/add-collaborator/${contentId}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ userId: collaboratorDetails._id }) 
        });
        if (!response.ok) {
            throw new Error('Failed to add collaborator to the backend.');
        }
        fetchData(); // Reload the data to reflect the changes
    } catch (error) {
        console.error('Error adding collaborator:', error);
        // Optionally, handle errors (e.g., update the UI to show an error message)
    }
};


if (isLoading) {
    return 
	<div>    
		<LoadingIndicator /> 
	</div>;
  }

    return (
 <>

{collaborationRequest && (
<div className="content-three-container">
<div className="content-container-column">
<div className="content-box row-eight">
   <div className="inner-content-container">

  {collaborationRequest.internalCollaborators.some(collaborator => collaborator.userId._id === currentUserId && collaborator.collaborationStatus !== "Collaborated") ? (
    <>
      <div className="inner-content-box row-two col-five">
	<p className="sticky">Work here</p>
        <textarea
          className="content-box-main-headline"
          value={editableHeadline}
          onChange={handleHeadlineChange}
        />
      </div>
      <div className="inner-content-box row-ten col-five">
        <textarea
          className="content-box-main-textarea"
          value={editableContent}
          onChange={handleContentChange}
        />
      </div>
    </>
  ) : (
    <>
      <div className="inner-content-box row-two col-five">
        <textarea
          className="content-box-main-headline"
          value={editableHeadline}
          onChange={handleHeadlineChange}
          disabled={true}
        />
      </div>
      <div className="inner-content-box row-ten col-five">
        <textarea
          className="content-box-main-textarea"
          value={editableContent}
          onChange={handleContentChange}
          disabled={true}
        />
      </div>
    </>
  )}
</div>
</div>
 </div>


<div className="content-container-column">

<div className="content-box row-six">

<div className="sticky">
Review Changes here:
</div>

     <div className="reviewed-changes">
	

    <h2 className="headline" dangerouslySetInnerHTML={{ __html: formattedOriginalHeadline }} />
    <p className="small" dangerouslySetInnerHTML={{ __html: formattedOriginalText }} />
  </div>
   
</div>


<div className="content-box row-two">
            <h3>{t('comments')}:</h3>
            <ul className="comments-list">
                {collaborationRequest.comments.map((comment, index) => (
                    <li key={index}>
                        <span>{comment.comment}</span>
                        <span>{comment.commenterEmail || comment.commenterId?.name}</span>
                        <span>Posted on {new Date(comment.date).toLocaleDateString()}</span>
                    </li>
                ))}
            </ul>
        </div> 

</div>


 
<div className="content-container-column">


{currentUserId !== contentData.creator && (collaborationRequest && collaborationRequest.internalCollaborators.some(collaborator => collaborator.userId._id === currentUserId && collaborator.collaborationStatus !== "Collaborated") && (
<div className="content-button-box row-one">
 <button className="save" onClick={saveChanges}>{t('save_button')}</button>
 </div>
 ))}


{currentUserId === contentData.creator && (
<div className="content-button-box row-one">
		
{collaborationRequest && collaborationRequest.internalCollaborators.some(collaborator => collaborator.userId._id === currentUserId && collaborator.collaborationStatus !== "Collaborated") && (
    <button className="save" onClick={saveChanges}>{t('save_button')}</button>
			)}

{collaborationRequest && collaborationRequest.internalCollaborators.some(collaborator => collaborator.userId._id === currentUserId && collaborator.collaborationStatus == "Collaborated") && (
<>
			<button className="save" onClick={handleCollaborationFinalize}>{t('finalize_button')}</button>
    <button className="save" onClick={() => resetCollaborationStatus(contentId)}>{t('reset_statuses_button')}</button>
</>
)}

 	</div>
 )}


  <div className="content-comment-box row-three">
 <textarea className="comment-textarea" placeholder={t('you_can_leave_comment')} value={comment} onChange={(e) => setComment(e.target.value)} />
    <button className={collaborationRequest ? "tiny_button" : "save"} onClick={collaborationRequest ? handleAddComment : handleCollaborationSubmit}>
        {collaborationRequest ? t('comment') : t('create_collaboration_button')}
    </button> 


{currentUserId === contentData.creator && (
<>
<p><br></br></p>
               <select value={selectedCollaborator} onChange={handleSelectChange} id="collaborators-select">
    <option value="">{t('select_and_add_collaborator')}</option>
    {availableCollaborators.map((collaborator, index) => (
        <option key={index} value={collaborator._id}>{collaborator.name} {collaborator.surname}</option>
    ))}
</select>



</>
)}
</div>

  <div className="content-comment-box row-three">

<CollaborationRequestDisplay collaborationRequest={collaborationRequest} handleReminder={handleReminder} handleSendCollaborationRequest={handleSendCollaborationRequest} fetchData={fetchData} />
</div>
</div>
</div>
      )}



{!collaborationRequest && (
        <>
<div className="content-container">

<div className="content-container-column col-two">

 <div className="content-box row-eight">	
             
  <div className="inner-content-container">

 		<div className="inner-content-box col-five row-three">
			<h1>{t('steps_to_collaboration_intro')}</h1>
			<h3>{t('steps_to_collaboration_one')}</h3>
			<h3>{t('steps_to_collaboration_two')}</h3>
			<h3>{t('steps_to_collaboration_three')}</h3>
		</div>

 		<div className="inner-content-box col-five row-three">

	 		<h2>1. {t('add')} {t('team_members')}:</h2>
           		<select value={selectedCollaborator} onChange={(e) => setSelectedCollaborator(e.target.value)} id="collaborators-select">
    				<option value="">{t('select_a_collaborator')}</option>
    			    {availableCollaborators.map((collaborator, index) => (
        			<option key={index} value={collaborator._id}>{collaborator.name} {collaborator.surname}</option>
    			    ))}
			</select>

		</div>


	

	<div className="inner-content-box col-five row-three">

		{selectedCollaborators && selectedCollaborators.length > 0 && (
<>
			<h2>2. {t('add')} {t('comment')}:</h2>
         		<textarea placeholder={t('comment')} value={comment} onChange={(e) => setComment(e.target.value)} />
</>
    		)}

	</div>


<div className="inner-content-box col-five row-three">

		{comment && (
     			<button className="editor-button" onClick={collaborationRequest ? handleAddComment : handleCollaborationSubmit}>
        			3. {t('create_collaboration_button')}
      			</button> 
		)}
	</div>


	

</div>
</div>
</div>

<div className="content-container-column col-one">

	

	<div className="content-box row-six">


{selectedCollaborators && selectedCollaborators.length > 0 && (
<>
            <h2>{t('added_collaborators')}</h2>
            {selectedCollaborators.map((collaborator, index) => (
              <div key={index}>
                {collaborator.name} {collaborator.surname} <button className="tiny_button" onClick={() => removeCollaborator(index)}>{t('remove_button')}</button>
              </div>
            ))}
</>
 )}
</div>

</div>
</div>


        </>

      )}



 </>
  
    );
};

export default CollaborationPage;