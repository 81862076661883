import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate, NavLink } from 'react-router-dom';
import axios from '../../axios.js';

import CompanyDashboardCorporatetextFunction from './companyDashboardCorporatetextFunction';
import DashboardCorporatetextList from './DashboardCorporatetextList';

import CorporatetextModal from './corporatetextViewModal';
import LoadingIndicator from '../../LoadingIndicator';

const DashboardCorporatetexts = ({ existingCompany, onApprove, onCollaborate, onToggleSubscription }) => {
    const [company, setCompany] = useState(existingCompany || { content: { corporatetexts: [] } });
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [corporatetextId, setCorporatetextId] = useState([]);
    const [corporatetexts, setCorporatetexts] = useState([]);
    const [users, setUsers] = useState([]);
    const { t } = useTranslation();
    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCorporatetext, setSelectedCorporatetext] = useState(null);
    const [isTranslationModalOpen, setIsTranslationModalOpen] = useState(false);
    const [editingCorporatetextId, setEditingCorporatetextId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isCorporatetextFunctionModalOpen, setIsCorporatetextFunctionModalOpen] = useState(false);

useEffect(() => {
        fetchCompanyData();
    }, []);

useEffect(() => {
  if (corporatetexts.length > 0) {
  }
}, [corporatetexts]);

 useEffect(() => {
        const fetchUsers = async () => {
            const token = localStorage.getItem('token');
            const companyID = company?._id;
            if (token && companyID) {
                try {
                    const response = await axios.get(`/content/get-for-company/${companyID}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setUsers(response.data || []);
                } catch (error) {
                }
            }
        };

        fetchUsers();
    }, [company]); 


const handleEditCorporatetext = (corporatetextId) => {
  resetState();
  setEditingCorporatetextId(corporatetextId);
  handleOpenCorporatetextFunctionModal();
};





const resetState = () => {
    setIsAddingNew(false);
    setEditingIndex(-1);
    setCorporatetextId([]);
    setEditingCorporatetextId(null);
    fetchCompanyData(); 
};






const fetchCompanyData = async () => {
  try {
    setIsLoading(true);

    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    if (!token) {
      return;
    }

    const response = await axios.get('/companies/get-company', {
      headers: { Authorization: `Bearer ${token}` }
    });

    const corporatetextsResponse = await axios.get('/content/corporatetexts/get-for-company', {
      headers: { Authorization: `Bearer ${token}` }
    });

     const sortedCorporatetexts = corporatetextsResponse.data.sort((a, b) => {
      return new Date(b.creationDate) - new Date(a.creationDate);
    });

   const filteredAndLimitedCorporatetexts = sortedCorporatetexts.filter(corporatetext => corporatetext.creator === userId).slice(0, 3);

    setCompany(response.data);
    setCorporatetexts(filteredAndLimitedCorporatetexts);
    setIsLoading(false);

  } catch (error) {
    setIsLoading(false);
  }
};

    const onCompanyUpdate = (updatedCompany) => {
        setCompany(updatedCompany);
    };

 const handleFinishAddingOrEditing = () => {
        handleCloseCorporatetextFunctionModal();
        resetState();
        fetchCompanyData();
    };

    const handleCancelAddingOrEditing = () => {
        handleCloseCorporatetextFunctionModal();
        resetState();
        fetchCompanyData();
    };

  const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };


const handleCopyCorporatetext = (data) => {
  let formattedData;

  if (typeof data === 'number') {

    const corporatetext = corporatetexts[data];
    formattedData = `
${corporatetext.headline || 'Untitled corporatetext'}

${corporatetext.corporatetextText || ''}

Contact:
${corporatetext.contact || 'No contact information'}
`;
  } else if (typeof data === 'object' && data.translatedCorporatetextHeadline) {

    formattedData = `
Headline: ${data.translatedCorporatetextHeadline || 'Untitled Translation'}
Text: ${data.translatedCorporatetextText || ''}
`;
  } else {
    return;
  }

  navigator.clipboard.writeText(formattedData)
    .then(() => alert("Details copied!"))
    .catch(err => console.error("Failed to copy: ", err));
};

const handleViewCorporatetext = (corporatetextId) => {
    const selectedCorporatetext = company.content.corporatetexts.find(corporatetext => corporatetext._id === corporatetextId);
    if (selectedCorporatetext) {
        setSelectedCorporatetext(selectedCorporatetext);
        setIsModalOpen(true);
        setIsTranslationModalOpen(false);
    } else {
    }
};


    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedCorporatetext(null);
    };


 const handleOpenCorporatetextFunctionModal = () => {
        setIsCorporatetextFunctionModalOpen(true);
    };

    const handleCloseCorporatetextFunctionModal = () => {
        setIsCorporatetextFunctionModalOpen(false);
    };





 let corporatetextFunctionModalContent = isCorporatetextFunctionModalOpen ? (
<div className="modal-backdrop-edit">
	<div className="modal-edit-content">
        <CompanyDashboardCorporatetextFunction
            company={company}
            onFinish={handleFinishAddingOrEditing}
            corporatetextId={editingCorporatetextId}
            isAddingNew={isAddingNew}
            users={users}
            formatDate={formatDate}
            onCancel={handleCancelAddingOrEditing}
        />
</div>
</div>
    ) : null;


let content = (
            <DashboardCorporatetextList 
    	    	onToggleSubscription={onToggleSubscription}

                corporatetexts={corporatetexts} 
                handleEditCorporatetext={handleEditCorporatetext}
                onCopy={handleCopyCorporatetext} 
                onPressView={handleViewCorporatetext}
                users={users}
            />
    
    );


if (isLoading) {
    return <div> <LoadingIndicator />
</div>;
  }

    return (
        <>
            {corporatetextFunctionModalContent}

 <div className="dashboard-content-top">
                <div>{t('corporatetexts')}</div>
		<div> <NavLink to="corporatetexts" className={({ isActive }) => isActive ? 'active' : ''}>{t('view_all')}</NavLink></div> 
	    </div>

     <CorporatetextModal 
                isOpen={isModalOpen} 
                onClose={handleCloseModal} 
                corporatetext={selectedCorporatetext} 
            />

   


            {content}
        </>
    );
};

export default DashboardCorporatetexts;