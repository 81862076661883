import React, { useState, useEffect } from 'react';
import ProductCounter from './ProductCounter'; 
import BuzzBeatNews from './BuzzBeatNews'; 
import { Link } from 'react-router-dom';
import BuzzBeatSubscriptionPage from '../subscription/BuzzBeatSubscriptionPage'; 
import { useTranslation } from 'react-i18next';
import '../i18n';

function BuzzBeatPricingPage() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const [isAnnual, setIsAnnual] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/assets/images/backgrounds/image${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);




const features = [
{
    name: 'Free',
    price: '$20',
    features: {
      'feature1': true,
      'feature2': true,
      'feature3': true,
      'feature8': true,
      'feature5': false,
      'feature6': false,
      'feature4': false,
      'feature7': false,
'feature9': false,
'feature10': false,
'feature11': false,
'feature12': false,
'feature13': true,
'feature14': false,
    'feature15': "20",
      'feature16': "0",
      'feature17': "1",
'feature18': t('support_free'),
    }
  },

  {
    name: 'Starter',
    price: '$20',
    features: {
      'feature1': true,
      'feature2': true,
      'feature3': true,      
      'feature8': true,
      'feature5': true,
      'feature6': true,
      'feature4': true,
      'feature7': true,
'feature9': false,
'feature10': false,
'feature11': false,
'feature12': false,
'feature13': true,
'feature14': false,
    'feature15': "50",
      'feature16': "0",
'feature17': "1",
'feature18': t('support_starter'),
    }
  },
  {
    name: 'Pro',
    price: '$50',
    features: {
    'feature1': true,
      'feature2': true,
      'feature3': true,
      'feature8': true,
      'feature5': true,
      'feature6': true,
      'feature4': true,
      'feature7': true,
'feature9': true,
'feature10': true,
'feature11': true,
'feature12': true,
'feature13': true,
'feature14': true,
    'feature15': t('unlimited'),
      'feature16': "50",
'feature17': "5",
'feature18': t('support_pro'),

    }
  },
  {
    name: 'Enterprise',
    price: '$150',
   features: {
    'feature1': true,
      'feature2': true,
      'feature3': true,
      'feature8': true,
      'feature5': true,
      'feature6': true,
      'feature4': true,
      'feature7': true,
'feature9': true,
'feature10': true,
'feature11': true,
'feature12': true,
'feature13': true,
'feature14': true,
    'feature15': t('unlimited'),
      'feature16': t('unlimited'),
      'feature17': t('unlimited'),
'feature18': t('support_enterprise'),


    }
  }
];

 

  const togglePaymentFeature = () => {
    setIsAnnual(!isAnnual);
  };

  const getPrice = (price) => {
    if (isAnnual) {
      const monthlyPrice = parseInt(price.replace(/\D/g,''));
      const annualPrice = monthlyPrice * 12 * 0.5;
      return `$${Math.round(annualPrice)}/${t('year')}`;
    }
    return `${price}/${t('month')}`;
  };

return (
    <>
      <div className="buzzbeat-other-page" style={{ backgroundImage: `url(${backgroundImage})` }}></div>

      <div className="content-section-homepage">

        <div className="grid-layout">
        
  <div className="grid-content-item col-six center"><h1>{t('headline_pricing1')}</h1>
<h2>{t('sub_headline_pricing1')}</h2></div>
          <div className="grid-content-item col-six"><p>{t('pricing_text1')}
<br></br><br></br></p></div>

   </div>
      </div>


      <div className="content-section-homepage">

  <div className="grid-content-item col-six center"><h1>{t('headline_pricing2')}<br></br><br></br></h1></div>


        <div className="pricing-container">
         
          <div className="features-list">
<div></div>
<div></div>
            {Object.keys(features[0].features).map((featureKey, index) => (
              <div key={index} className={` verticle-center feature-features ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
                <span>{t(featureKey)}</span>
              </div>
            ))}
          </div>
        {features.map((feature, featureIndex) => (
  <div key={feature.name} className={`feature ${feature.name === 'Pro' ? 'highlighted' : ''}`}>
    <div><h1>{t(feature.name.toLowerCase())}</h1></div>
    <div>{feature.name === 'Pro' && <span className="bestseller-tag">{t('bestseller')}</span>}</div>
    {Object.keys(feature.features).map((featureKey, index) => (
      <div key={featureKey + index} className={`feature-checkmark ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}>
        <span>
          {typeof feature.features[featureKey] === 'boolean' ? (
            feature.features[featureKey] ? <span className="checkmark">✔</span> : ''
          ) : (
            <span style={{ fontSize: '15px', fontWeight: 300 }}>
              {feature.features[featureKey]}
            </span>
          )}
        </span>
      </div>
    ))}
  </div>
))}




        </div>

<div className="grid-content-item col-six center"><p><br></br></p><Link to="/register"><button className="action">{t('get_started_button')}</button></Link></div>


      </div>

 <div className="content-section-homepage">

  <div className="grid-content-item col-six center"><h1>{t('50_saving')}</h1></div>

<BuzzBeatSubscriptionPage />
<div className="grid-content-item col-six center"><p><br></br></p><Link to="/register"><button className="action">{t('get_started_button')}</button></Link></div>

      </div>

 <div className="content-section-homepage">

  <div className="grid-content-item col-six center"><h1>{t('satisfied')}<br></br><br></br></h1></div>

<ProductCounter />
      </div>


 <div className="content-section-homepage">

  <div className="grid-content-item col-six center"><h1>{t('convinced')}</h1></div>
<div className="grid-content-item col-six center"><Link to="/register"><button className="action">{t('get_started_button')}</button></Link></div>

      </div>




    </>
  );
}

export default BuzzBeatPricingPage;