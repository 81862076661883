import React, { useState, useEffect } from 'react';
import axios from '../axios.js';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18next';

const LandingRealEstatePageRegister = () => {
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [privacyAccepted, setPrivacyAccepted] = useState(false);
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();


    const isEmailValid = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleInitialRegister = async () => {
        if (!companyName) {
            setErrorMessage(t('company_name_required'));
            return;
        }

        if (!isEmailValid(email)) {
            setErrorMessage(t('invalid_email_error'));
            return;
        }

        if (!termsAccepted || !privacyAccepted) {
            setErrorMessage(t('terms_privacy_error'));
            return;
        }

          const currentLanguage = i18n.language;

        const payload = {
            companyName,
            email,
            language: currentLanguage, 
        };

        try {
            const response = await axios.post('/registerlogin/register', payload);
            setMessage(t('registration_success'));
            setErrorMessage('');
        } catch (error) {
            setErrorMessage(error.response?.data?.msg || t('registration_failed'));
            setMessage('');
        }
    };

    return (
<>

  <div className="login-landingpage-container">
            <div className="login-form left">
                {errorMessage && <><br></br><p className="error-message">{errorMessage}</p></>}
                {message && <><br></br><p className="success-message">{message}</p></>}
<br></br>
                <input
                    type="text"
                    placeholder={t('company_name')}
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                />
                <input
                    type="email"
                    placeholder={t('your_email')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <div>
                    <input
                        type="checkbox"
                        id="termsConditions"
                        checked={termsAccepted}
                        onChange={(e) => setTermsAccepted(e.target.checked)}
                    />
                    <label htmlFor="termsConditions">{t('accept_terms_and_conditions')} <a href="/termsconditions" target="_blank" rel="noopener noreferrer">
    {t('termsconditions_button')}
  </a>
</label>
                </div>
                <div>
                    <input
                        type="checkbox"
                        id="dataPrivacy"
                        checked={privacyAccepted}
                        onChange={(e) => setPrivacyAccepted(e.target.checked)}
                    />
                   <label htmlFor="dataPrivacy">
  {t('accept_data_privacy_terms')} 
  <a href="/privacy" target="_blank" rel="noopener noreferrer">
    {t('data_privacy_policy_button')}
  </a>
</label>

                </div>
<p><br></br></p>

                <button onClick={handleInitialRegister} className="save">{t('register_for_free_button')}</button>
            </div>
        </div>


   


      

</>
    );
};

export default LandingRealEstatePageRegister;
