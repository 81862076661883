import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route, Link } from 'react-router-dom';
import ProductCounter from '../public/ProductCounter'; 
import BuzzBeatNews from '../public/BuzzBeatNews'; 
import LandingSupplyChainPageRegister from '../usermanagement/registerSupplyChainLandingPage'; 

import { useTranslation } from 'react-i18next';

function SupplyChainLandingPage() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const totalImages = 3;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/assets/images/backgrounds/supplychain/supplychain0${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className="buzzbeat-landing-page-black"></div>

      <div className="buzzbeat-landing-page-background" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      <div className="buzzbeat-landing-page">

        <div className="overlay-content-landingpage" style={{ top: `${offsetY * 0.3}px` }}>
         <h1>{t('headline_landing_page_supply_chain')}</h1>
<p>{t('subheadline_landing_page_supply_chain')}</p>
          <div className="buttons">
                        <a href="#registerSection">
<button className="btn">{t('getstarted_button_landing_page')}</button></a>
          </div>
        </div>
      </div>


<div className="content-section-homepage">
        <div className="grid-layout">
 <div className="grid-content-item col-one center"></div>
  <div className="grid-content-item col-four center"><h1>{t('supply_chain_leader_intro_headline')}<br></br><br></br></h1></div>
<div className="grid-content-item col-two center">
<img src="../assets/images/backgrounds/supplychain/celebrating_success.jpg"></img></div>
<div className="grid-content-item col-four">
<p className="big">{t('supply_chain_leader_intro_text')}</p>
   </div>
</div>
</div>
    

 <div className="content-section-homepage">

  <div className="grid-content-item col-six center"><h1>{t('satisfied')}<br></br><br></br></h1></div>

<ProductCounter />
      </div>

 
      <div className="content-section-homepage" id="registerSection">
        <div className="grid-layout">

 <div className="grid-content-item col-one center"></div>
  <div className="grid-content-item col-four center"><h1>{t('register_your_supply_chain_leader')}<br></br><br></br></h1></div>
 <div className="grid-content-item col-one center"></div>
 <div className="grid-content-item col-one center"></div>
 <div className="grid-content-item col-four center"><LandingSupplyChainPageRegister /></div>
</div>
      </div>
     
    </>
  );
}

export default SupplyChainLandingPage;
