import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ProductCounter from './ProductCounter'; 
import BuzzBeatNews from './BuzzBeatNews'; 

function BuzzBeatImprint() {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);
const { t } = useTranslation();

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/assets/images/backgrounds/image${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);




  return (
    <>
      <div className="buzzbeat-other-page" style={{ backgroundImage: `url(${backgroundImage})` }}></div>


<div className="content-section-homepage">

  <div className="grid-content-item col-six center"><h1>{t('legal_info')}</h1></div>
<div className="grid-content-item col-six"><h2>{t('a_brand_of')}:<br></br></h2>

<p>
Appadise UG
<br></br>
Sierichstr. 168<br></br>
22299 {t('hamburg')}<br></br>
{t('germany')}<br></br>
<br></br>
{t('email')}: ben@buzzbeat.io<br></br>
<br></br>
{t('registry_court')}: {t('district_court')} {t('hamburg')}, HRB 183319<br></br>
{t('managing_director')}: Marc Schmitt<br></br>
<br></br>
{t('responsible')} i.S.d. § 18 II MStV: Marc Schmitt, Sierichstraße 168, 22299 {t('hamburg')}, {t('germany')}
<br></br><br></br>

<h1>{t('legal_notice')}</h1>
<h2>{t('copyright')}</h2>
{t('copyright_text')}
<br></br><br></br>
© {t('copyright')} Appadise UG, 2024
<br></br><br></br>
<h2>{t('links')}</h2>
{t('links_text')}
<br></br><br></br>
<h2>{t('online_dispute_resolution')}</h2>
{t('online_dispute_resolution_text')}<br></br>
<br></br>
{t('online_dispute_resolution_no')}
</p>
</div>

      </div>

</>
);
}

export default BuzzBeatImprint;



