import React, { useState, useEffect } from 'react';
import axios from '../axios.js';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';

const UserDetailsForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        email: '',
        password: '',
        infos: {
            roleInCompany: '',
            telephone: '',
            mobile: '',
            address: '',
        },
    });
    const [passwordStrengthMessage, setPasswordStrengthMessage] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { t } = useTranslation();
    const [roles, setRoles] = useState([]);
    const { fetchWithAuth, subscriptionLevel } = useAuth();

    useEffect(() => {
        const fetchUserDetails = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                return;
            }

            try {
                const response = await axios.get('/registerlogin/getUserDetails', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const { name, surname, email, infos } = response.data;

                const updatedInfos = {
                    roleInCompany: '',
                    telephone: '',
                    mobile: '',
                    address: '',
                    ...infos,
                };

                setFormData({ name, surname, email, infos: updatedInfos });
            } catch (error) {
            }
        };

        fetchUserDetails();

const fetchRoles = async () => {
    try {
        const response = await fetchWithAuth('/user/roles', {
            method: 'GET'
        });
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const fetchedRoles = await response.json();
        setRoles(fetchedRoles);
    } catch (error) {
        console.error("Failed to fetch roles:", error);
        setError(t('fetch_roles_error')); // Make sure you have a translation for this
    }
};


        fetchRoles();

    }, [fetchWithAuth]);

    const validatePassword = (password) => {
        let messages = [];
        if (!/.{8,}/.test(password)) {
            messages.push(t('password_length_error'));
        }
        if (!/[A-Z]/.test(password)) {
            messages.push(t('password_uppercase_error'));
        }
        if (!/[a-z]/.test(password)) {
            messages.push(t('password_lowercase_error'));
        }
        if (!/[0-9]/.test(password)) {
            messages.push(t('password_number_error'));
        }
        if (!/[^A-Za-z0-9]/.test(password)) {
            messages.push(t('password_special_char_error'));
        }
        setPasswordStrengthMessage(messages);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name in formData.infos) {
            setFormData({
                ...formData,
                infos: { ...formData.infos, [name]: value }
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }

        if (name === 'password') {
            validatePassword(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }

        try {
            const response = await axios.put('/registerlogin/updateDetails', formData, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setSuccess('Details updated successfully');
        } catch (error) {
            setError('Failed to update details');
        }
    };

  
    return (
<>

<div className="content-container">


			<div className="content-container-column col-two">
				 
	<div className="content-box row-eight">

     
  <div className="companydetails-item">
<div>{t('given_name')}:</div>
       <div><input type="text" name="name" value={formData.name} onChange={handleChange}/></div>
</div>
  <div className="companydetails-item">
<div>{t('surname')}:</div>
       <div>       <input
                type="text"
                name="surname"
                value={formData.surname}
                onChange={handleChange}
            /></div>
</div>
  <div className="companydetails-item">
<div>{t('your_email')}:</div>
          <div>    <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
            /></div>
</div>
  <div className="companydetails-item">
<div>{t('role_in_company')}:</div>
           <div>     <select name="roleInCompany" value={formData.infos.roleInCompany} onChange={handleChange}>
              
                            <option value="">{t('select_role_in_company')}</option>
                            {roles.map((role) => (
                                <option key={role} value={role}>{t(role)}</option>
                            ))}
                        </select>
</div>
</div>
  <div className="companydetails-item">
<div>{t('telephone')}:</div>
           <div>   <input
                type="text"
                name="telephone"
                value={formData.infos.telephone}
                onChange={handleChange}
            /></div>
</div>
  <div className="companydetails-item">
<div>{t('new_password')}:</div>
            <div>  <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
            /></div>
</div>
</div>

</div>

<div className="content-container-column col-one">
				 
	<div className="content-box row-one">
                    <button onClick={handleSubmit} className="save">{t('update_details_button')}</button>
	</div>

		{error && 
			 <div className="content-box row-two">
                    		<div className="error-message">{error}</div>
       			 </div>
       		}     

            {success && 
		<div className="content-box row-two">
			<div className="success-message">{success}</div>   
		</div>
            }


	<div className="content-box row-two">
   		{passwordStrengthMessage.map((msg, index) => (
	                <ul className="password-strength-messages">
                                <li key={index}>{msg}</li>
                       </ul>
    		))}
	</div>


     </div>
</div>
</>
    );
};

export default UserDetailsForm;
