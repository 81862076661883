import axios from '../../axios.js';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../AuthContext'; 

const UsecaseList = ({ usecases, handleEditUsecase, users, onPressView, onToggleSubscription }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const { fetchWithAuth, subscriptionLevel } = useAuth();

const [currentUsecaseIndex, setCurrentUsecaseIndex] = useState(null);


   const { t } = useTranslation();



const formatDate = (dateString) => {
    if (!dateString) return 'No Date Provided';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

const getComparable = (item, key, users) => {
 if (key === 'date' || key === 'creationDate') {
    const date = new Date(item[key]);
    return isNaN(date.getTime()) ? 0 : date.getTime();
  }
  if (key === 'contact') {
    const user = users.find(u => u.email === item.contact);
    return user ? user.email : '';
  }
  return item[key];
};

const sortedUsecases = React.useMemo(() => {
  let sortableItems = [...usecases];
  if (sortConfig.key !== null) {
    sortableItems.sort((a, b) => {
      const aValue = getComparable(a, sortConfig.key, users);
      const bValue = getComparable(b, sortConfig.key, users);
      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }
  return sortableItems;
}, [usecases, sortConfig, users]); 

  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '↑' : '↓';
    }
    return '↕'; 
  };

  const requestSort = (key) => {
    setSortConfig(currentSortConfig => {
      let direction = 'ascending';
      if (currentSortConfig.key === key && currentSortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      return { key, direction };
    });
  };

  useEffect(() => {
  }, [sortConfig, sortedUsecases]);


 
  return (
   <>
                       <div className="dashboard-createdcontent-table">
 
  {sortedUsecases && sortedUsecases.map((usecase, index) => (

<React.Fragment key={`usecase-${usecase._id}-${index}`}>

  <div className="dashboard-createdcontent-item">
            <div>{usecase.headline || 'Untitled usecase'}</div>
<div className="icons">  <button onClick={() => onPressView(usecase._id)} title={t('view')}><img src="../../assets/images/view.png" alt={t('view')} /></button>
  <button onClick={() => handleEditUsecase(usecase._id)} title={t('edit')} ><img src="../../assets/images/edit.png" alt={t('edit')} /></button></div>
</div>

          </React.Fragment>
))}
</div>
</>
  );
};

export default UsecaseList;
