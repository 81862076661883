import React, { useState, useEffect } from 'react';
import axios from '../axios.js';
import { useTranslation } from 'react-i18next';
import { useNavigate, NavLink } from 'react-router-dom';

const DashboardWelcome = ({ companyId }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const { t } = useTranslation();


    return (
<>
 
<div className="big-font">
<h1>{t('welcome_to_buzzbeat')}</h1>
<p><br></br></p>
<p>{t('welcome_to_buzzbeat_message')}</p>
</div>

</>
    );
};

export default DashboardWelcome;

