import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext'; 

import ApprovalRequestDashboardOverview from './ApprovalRequestDashboardOverview';
import LoadingIndicator from '../LoadingIndicator';

const DashboardApprovalsPage = ({ companyId }) => {
    const [error, setError] = useState('');
    const [openApprovals, setOpenApprovals] = useState([]);
    const [allApprovals, setAllApprovals] = useState([]);
    const [myApprovals, setMyApprovals] = useState([]);
    const [activeTab, setActiveTab] = useState('user');
    const [creatorApprovals, setCreatorApprovals] = useState([]); 
    const [activeApprovalId, setActiveApprovalId] = useState(null);
    const [approvalRequest, setApprovalRequest] = useState(null);
    const [approvals, setApprovals] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { fetchWithAuth } = useAuth();
    const { t } = useTranslation();
    const navigate = useNavigate();

useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    const fetchData = async () => {
  setIsLoading(true);

        try {
            let endpoint = `/approval/approvals/${companyId}?type=${activeTab}`;
            if (activeTab === 'user' || activeTab === 'creator') {
                endpoint += `&userId=${storedUserId}`;
            }

            const response = await fetchWithAuth(endpoint);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setApprovals(data);
    setIsLoading(false);

        } catch (error) {
            setError('Failed to load data. Please try again later.');
        }
    };

    fetchData();
}, [companyId, activeTab]);


   

    const handleEditClick = (approvalRequest) => {
        navigate(`/app/app/approval/${approvalRequest.onModel}/${approvalRequest.contentId}`, { state: { companyId } });
    };

   const renderTabContent = () => {

    const latestApprovals = approvals.slice(-4);
    return latestApprovals.map((approval, index) => (
        <ApprovalRequestDashboardOverview
            key={index}
            approvalRequest={approval}
            isActive={activeApprovalId === approval._id}
            toggleActive={() => setActiveApprovalId(activeApprovalId === approval._id ? null : approval._id)}
            handleEditClick={() => handleEditClick(approval)}
        />
    ));
};

if (isLoading) {
    return <div> <LoadingIndicator />
</div>;
  }

    return (
        <div>
            <div className="dashboard-content-top">
                <div>{t('some_open_approvals')}</div>
		<div> <NavLink to="give-approval" className={({ isActive }) => isActive ? 'active' : ''}>{t('view_all')}</NavLink></div> 
	    </div>



            <div className="dashboard-content-table">

                      

                {renderTabContent()}
          </div>
        </div>
    );
};

export default DashboardApprovalsPage;
