import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = ({ currentLanguage, changeLanguage }) => {
  return (
      <select value={currentLanguage} onChange={(e) => changeLanguage(e.target.value)}>
        <option value="en">English</option>
        <option value="de">Deutsch</option>
        <option value="fr">Fancais</option>
        <option value="es">Español</option>
        <option value="tr">Türkçe</option>
        <option value="cn">中国人</option>
	<option value="jp">日本語</option>
        <option value="ko">한국인</option>
        <option value="dk">Dansk</option>
 	<option value="et">Eesti Keel</option>
	<option value="nl">Nederlands</option>
	<option value="it">Italiano</option>
	<option value="no">Norsk</option>
	<option value="sv">Svenska</option>
	<option value="fn">Suomalainen</option>
	<option value="pt">Português</option>
	<option value="hi">हिंदी</option> 
 </select>
  );
};

export default LanguageSwitcher;
