import React, { useState, useEffect } from 'react';
import axios from '../axios.js';
import { useTranslation } from 'react-i18next';

const CompanyContent = ({ existingCompany, onCompanyUpdate , isTranslating }) => {
const initialState = {
    content: {
      mission: [{
        missionBullets: '',
        missionText: '',
        missionTranslations: [{ language: '', translation: '' }]
      }],
      vision: [{
        visionBullets: '',
        visionText: '',
        visionTranslations: [{ language: '', translation: '' }]
      }],
    description: [{
        descriptionBullets: '',
        descriptionText: '',
        descriptionTranslations: [{ language: '', translation: '' }]
      }],
    },
  };


    const [company, setCompany] = useState(initialState);
    const [successMessage, setSuccessMessage] = useState('');
    const { t } = useTranslation();

useEffect(() => {
    if (!existingCompany) {
        fetchCompanyData();
    } else {
        setCompany(existingCompany);
    }
}, [existingCompany]);

    const fetchCompanyData = async () => {
         try {
            const token = localStorage.getItem('token');
            if (!token) {
                return;
            }
            const response = await axios.get('/companies/get-company', {
                headers: { Authorization: `Bearer ${token}` }
            });
         setCompany(response.data);
        } catch (error) {
        }
    };

const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = localStorage.getItem('userId');
    const updatedCompany = { ...company };

    if (updatedCompany.content?.vision) {
        updatedCompany.content.vision.creator = userId;
    }

    if (updatedCompany.content?.mission) {
        updatedCompany.content.mission.creator = userId;
    }

 if (updatedCompany.content?.description) {
        updatedCompany.content.description.creator = userId;
    }

    try {
        const response = await axios.put(`/companies/${updatedCompany._id}`, updatedCompany);
    setSuccessMessage(t('save_success'));
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
    }
};


const handleRefresh = () => {
        window.location.reload();
    };

    const hasBulletsContent = company?.description?.descriptionBullets;

 return (
<></>
);
};

export default CompanyContent;
