import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';
 
const ApprovalRequestOverview = ({ 
    approvalRequest, 
    handleReminder, 
    handleSendApprovalRequest, 
    isActive,
    toggleActive,
handleEditClick,
fetchData
}) => {
    const [currentUserId, setCurrentUserId] = useState('');
const { t } = useTranslation();
    const { fetchWithAuth } = useAuth();

  useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        setCurrentUserId(storedUserId);
    }, []);

     const handleToggleClick = () => {
        toggleActive(approvalRequest._id);
    };

const countApprovalStatuses = () => {
    const allApprovers = [...approvalRequest.internalApprovers, ...approvalRequest.externalApprovers];
    const total = allApprovers.length;
    const approvedCount = allApprovers.filter(approver => approver.approvalStatus === 'Approved').length;
    return `${approvedCount} of ${total}`;
};


 useEffect(() => {
    const autoUpdateApprovalStatus = async () => {
      if (approvalRequest && approvalRequest._id) {
        await updateOverallStatus(approvalRequest._id);
      }
    };

    autoUpdateApprovalStatus();
  }, [approvalRequest]);

const updateOverallStatus = async (approvalRequestId) => {
    try {
        const response = await fetchWithAuth(`/approval/update-overall-status/${approvalRequestId}`, { method: 'PUT' });
        if (response.ok) {
            const data = await response.json();
        } else {
            throw new Error('Failed to update overall approval status.');
        }
    } catch (error) {
    }
};

const getModelDisplayName = (model) => {
    const modelDisplayNames = {
        pressreleases: t('press_release'),
        articles: t('article'),
	blogposts: t('blogpost'),
	usecases: t('usecase'),

corporatetexts: t('corporatetext'),
    };

    return modelDisplayNames[model] || model;
};



const updateApprovalStatus = async (approverId, status) => {
        try {
            const response = await fetchWithAuth(`/approval/updateStatus/${approverId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ approvalStatus: status }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
fetchData(); 
        } catch (error) {
        }
    };

const isCurrentUserAnInternalApprover = () => {
        return approvalRequest.internalApprovers.some(approver => approver.userId._id === currentUserId);
    };

  const isCurrentUserTheCreator = () => {
    return approvalRequest.creator && approvalRequest.creator._id === currentUserId;
  };

     return (
        <div>
              {approvalRequest && (
                <div>
                       <div className="table-row">
				<div>
   					 {approvalRequest && (
 						<div className="row-approval">
            						<div className="approval-status">
                					<span className={`status-circle ${approvalRequest.overallApprovalStatus === 'Approved' ? 'approved' : 'not-approved'}`}></span>
            						</div>
        					</div>
    					)}
				</div>

                            	<div className="row-approval"><p>{approvalRequest.contentHeadline}</p></div>
                            	<div className="row-approval"><p>{getModelDisplayName(approvalRequest.onModel)}</p></div>
                            	<div className="row-approval"><p>{approvalRequest.approvalSentDate ? new Date(approvalRequest.approvalSentDate).toLocaleDateString() : 'Not Sent'}</p></div>
        		    	<div className="row-approval"><p>{countApprovalStatuses()}</p></div>
     			    	<div className="row-approval"><button className="toggle-translations-btn" onClick={handleToggleClick}>{isActive ? t('hide') : t('show')}</button> <button className="toggle-translations-btn" onClick={() => handleEditClick(approvalRequest)}>{t('view')}</button>
</div>
                        </div>
                    

                   

             {isActive && (
    			<div className="detailed-info">
       				

          {isActive && isCurrentUserTheCreator() ? (

<>
            				<div className="creator-info">
 						<span>{t('approval_id')}: {approvalRequest._id}</span>
 						<span>{t('type')}: {getModelDisplayName(approvalRequest.onModel)}</span>
                				<span>{t('created_by')}: {approvalRequest.creator?.name} {approvalRequest.creator?.surname}</span>
						<span>{t('overall_approval_status')}: {approvalRequest.overallApprovalStatus}</span>
            				</div>
        		


        <div className="approvers-section">
            <h4>{t('internal_approvers')}:</h4>
            <ul className="approvers-list">
                {approvalRequest.internalApprovers.map((approver, index) => (
                                        <li key={index} className={approver.approvalStatus.toLowerCase()}>
                                            <span>{approver.userId?.name} {approver.userId?.surname}</span>
                                            <span>{approver.approvalStatus}</span>
                                            {approver.approvalDate && <span>{t('changed')}: {new Date(approver.approvalDate).toLocaleDateString()}</span>}
                                          {approvalRequest.approvalSentDate && approver.approvalStatus !== "Approved" && (
    <button className="send-reminder-btn" onClick={() => handleReminder(approver.userId.email)}>
        {t('send_reminder')}
    </button>
)}

 					{approver.userId._id === currentUserId && approver.approvalStatus !== "Approved" && ( 
                                                <select 
                                                    value={approver.approvalStatus}
                                                    onChange={(e) => updateApprovalStatus(approver._id, e.target.value)}
                                                >
                                                    <option value="Pending">{t('pending')}</option>
						     <option value="Review">{t('review_again')}</option>
                                                    <option value="Commented">{t('commented')}</option>
							<option value="Approved">{t('approve')}</option>
						    <option value="Not Approved">{t('not_approve')}</option>
                                                    
                                                </select>
                                            )}
                                        </li>
                                    ))}
            </ul>
        </div>

        <div className="approvers-section">
            <h4>{t('external_approver')}:</h4>
             <ul className="approvers-list">
                        {approvalRequest.externalApprovers.map((approver, index) => (
                                        <li key={index} className={approver.approvalStatus.toLowerCase()}>
                                <span>{approver.email}</span>
                                <span>{approver.approvalStatus}</span>
                                            {approver.approvalDate && <span>{t('changed')}: {new Date(approver.approvalDate).toLocaleDateString()}</span>}
                                {approvalRequest.approvalSentDate && approver.approvalStatus !== "Approved" && (
                                    <button className="send-reminder-btn" onClick={() => handleReminder(approver.email)}>
                                        {t('send_reminder')}
                                    </button>
                                )}
                            </li>
                        ))}
                    </ul>
        </div>
 </>
    ) : (<>

<div className="creator-info">
                				<span>{t('created_by')}: </span><span>{approvalRequest.creator?.name} {approvalRequest.creator?.surname}</span><br></br>
            				</div>

 <div className="approvers-section">
            <ul className="approvers-list">
                {approvalRequest.internalApprovers.map((approver, index) => (
                                      approver.userId._id === currentUserId && approver.approvalStatus !== "Approved" ? (

				  <li key={index} className={approver.approvalStatus.toLowerCase()}>
                                            <span>{approver.userId?.name} {approver.userId?.surname}</span>
                                            <span>{approver.approvalStatus}</span>
                                            {approver.approvalDate && <span>{t('changed')}: {new Date(approver.approvalDate).toLocaleDateString()}</span>}
                                         

 			
                                                <select 
                                                    value={approver.approvalStatus}
                                                    onChange={(e) => updateApprovalStatus(approver._id, e.target.value)}
                                                >
                                                    <option value="Pending">{t('pending')}</option>
						     <option value="Review">{t('review_again')}</option>
                                                    <option value="Commented">{t('commented')}</option>
							<option value="Approved">{t('approve')}</option>
						    <option value="Not Approved">{t('not_approve')}</option>
                                                    
                                                </select>
                                            
                                        </li>
) : ( approver.userId._id === currentUserId && (
				<li key={index} className={approver.approvalStatus.toLowerCase()}>
                                            <span>{approver.userId.name} {approver.userId.surname}</span>
                                            <span>{approver.approvalStatus}</span>
                                            {approver.approvalDate && <span>{t('changed')}: {new Date(approver.approvalDate).toLocaleDateString()}</span>}
                                         {approver.approvalStatus}

 			
                                                                                           
                                        </li>
))
                                    ))}
            </ul>
        </div>



</>)
  
}

        <div className="comments-section">
            <h4>{t('comments')}:</h4>
            <ul className="comments-list">
                {approvalRequest.comments.map((comment, index) => (
                    <li key={index}>
                        <span>{comment.comment}</span>
                        <span>{comment.commenterEmail || comment.commenterId?.name}</span>
                        <span>Posted on {new Date(comment.date).toLocaleDateString()}</span>
                    </li>
                ))}
            </ul>
        </div>           



        {!approvalRequest.approvalSentDate && (
            <button className="editor-button" onClick={handleSendApprovalRequest}>
                {t('send_approval_request')}
            </button>
        )}
    </div>
)}

                </div>
            )}
        </div>
    );
};

export default ApprovalRequestOverview;
