import React, { useState, useEffect } from 'react';
import axios from '../axios.js';
import { useTranslation } from 'react-i18next';

import SubscriptionPlansPage from './SubscriptionPlansPage';
import CurrentSubscriptionPage from './CurrentSubscriptionPage';

const SubscriptionPage = ({ companyId }) => {

    return (

		<div className="content-container">
			<div className="content-container-column col-two">
				 <div className="content-box row-eight center">	
					<SubscriptionPlansPage companyId={companyId} />
				</div>
			</div>

			<div className="content-container-column col-one">
				<CurrentSubscriptionPage companyId={companyId} />
			</div>
		</div>

    );
};

export default SubscriptionPage;

