import React, { useEffect, useState, useCallback } from 'react';
import '../../Spinner.css';
import { useTranslation } from 'react-i18next';
import axios from '../../axios.js';
import { useHistory } from 'react-router-dom';

const CompanyCorporatetextFunction = ({ existingCompany, corporatetextId, users, formatDate, onCancel }) => {
    const [corporatetexts, setCorporatetexts] = useState([]);
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState(existingCompany || { content: { corporatetexts: [corporatetextId] } }); 
    const [corporatetextBullets, setCorporatetextBullets] = useState('');
    const [corporatetextText, setCorporatetextText] = useState('');
    const [corporatetextFor, setCorporatetextFor] = useState('');
    const [corporatetextWhat, setCorporatetextWhat] = useState('');
    const [headline, setHeadline] = useState('');
    const contextAI = company?.context?.contextCompany || '';
    const fineTuneAI = company?.context?.contextFinetune || '';
    const [successMessage, setSuccessMessage] = useState('');
const [errorMessage, setErrorMessage] = useState('');

 useEffect(() => {
        fetchCompanyData();
    }, []);

const fetchCompanyData = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }
        const response = await axios.get('/companies/get-company', {
            headers: { Authorization: `Bearer ${token}` }
        });
        const corporatetextsResponse = await axios.get('/content/corporatetexts/get-for-company', {
            headers: { Authorization: `Bearer ${token}` }
        });

        const filteredCorporatetexts = corporatetextsResponse.data.filter(corporatetext => corporatetext._id === corporatetextId);

           if (filteredCorporatetexts.length > 0) {
          setCorporatetextBullets(filteredCorporatetexts[0].corporatetextBullets || '');
                setCorporatetextText(filteredCorporatetexts[0].corporatetextText || '');
                setCorporatetextFor(filteredCorporatetexts[0].corporatetextFor || '');
                setCorporatetextWhat(filteredCorporatetexts[0].corporatetextWhat || '');
                setHeadline(filteredCorporatetexts[0].headline || '');

        }

        setCompany(response.data);
        setCorporatetexts(filteredCorporatetexts);
   

    } catch (error) {
    }
};


const handleSubmit = async (e) => {
    e.preventDefault();

    try {

if (!corporatetextText.trim()) {
    setErrorMessage(t('corporatetext_TextEmptyError'));
    setTimeout(() => {
        setErrorMessage('');
    }, 2000);
    return;
}

        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }

        let updatedCompany = {...company};
        updatedCompany.content = updatedCompany.content || {};
        updatedCompany.content.corporatetexts = updatedCompany.content.corporatetexts || [];

        if (!corporatetextId) {
            const userId = localStorage.getItem('userId');
            const newCorporatetext = {
                corporatetextBullets,
                corporatetextText,
                corporatetextFor,
                corporatetextWhat,
                headline,
                corporatetextTranslations: [],
                creator: userId,
                creationDate: new Date()
            };

            updatedCompany.content.corporatetexts.push(newCorporatetext);

        } else {
            const corporatetextIndex = updatedCompany.content.corporatetexts.findIndex(pr => pr._id === corporatetextId);
            if (corporatetextIndex !== -1) {
                updatedCompany.content.corporatetexts[corporatetextIndex] = {
                    ...updatedCompany.content.corporatetexts[corporatetextIndex],
                    corporatetextBullets,
                    corporatetextText,
                    corporatetextFor,
                    corporatetextWhat,
                    headline,
                };
            }
        }

        const response = await axios.put(`/companies/${updatedCompany._id}`, updatedCompany, {
            headers: { Authorization: `Bearer ${token}` }
        });

        setCompany(response.data);
    setSuccessMessage(t('save_success'));
        setTimeout(() => {
            setSuccessMessage('');
        }, 2000);
        fetchCompanyData();
    } catch (error) {
        console.error("An error occurred:", error);
    }
};


const processCorporatetextOpenAI = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    const token = localStorage.getItem('token');
    if (!token) {
        setIsLoading(false);
        return;
    }

   if (!corporatetextText) {
        setIsLoading(false);
        return;
    }

    try {
        let bulletsResponse = await axios.post('/content/process-corporatetexts-bullets', {
            corporatetextBullets: corporatetextText 
        }, { headers: { 'Authorization': `Bearer ${token}`,'Accept-Language': i18n.language } });

        let descriptionResponse = await axios.post('/content/process-corporatetexts-text', {
            contextAI: contextAI,
            corporatetextBullets: bulletsResponse.data.openAiResponse,              
            corporatetextWhat: corporatetextWhat,
            corporatetextFor: corporatetextFor,
            fineTuneAI: fineTuneAI
        }, { headers: { 'Authorization': `Bearer ${token}`,'Accept-Language': i18n.language } });

        if (bulletsResponse.data && descriptionResponse.data) {
            setCorporatetextBullets(bulletsResponse.data.openAiResponse);
            setCorporatetextText(descriptionResponse.data.openAiResponse);
        }

        if (descriptionResponse.data.openAiResponse) {
            const headlineResponse = await axios.post('/content/process-corporatetexts-headline', {
                corporatetextText: descriptionResponse.data.openAiResponse,
		fineTuneAI: fineTuneAI
            }, { headers: { 'Authorization': `Bearer ${token}`, 'Accept-Language': i18n.language } });


            if (headlineResponse.data) {
                setHeadline(headlineResponse.data.openAiResponse);
            } else {
            }
        }
    } catch (error) {
    } finally {
        setIsLoading(false);
    }
}, [
    isLoading, 
    corporatetextText, 
    contextAI, 
    corporatetextWhat, 
    corporatetextFor, 
    i18n.language   
]);



  return (
<>
            {isLoading && <div className="spinner-overlay"><div className="spinner"></div></div>}


<div className="content-container">

<div className="content-container-column col-two">

<div className="content-box col-two row-eight">
               <div className="inner-content-container">
         
{headline && (


            <>

                        <div className="inner-content-box col-five row-two">

<textarea className="content-box-main-headline" name="headline" value={headline} onChange={(e) => setHeadline(e.target.value)} placeholder={t('corporatetext_headline')} rows="5" cols="50" />
  </div>

                        <div className="inner-content-box col-five row-eight">

            <textarea className="content-box-main-textarea" value={corporatetextText} onChange={(e) => setCorporatetextText(e.target.value)} placeholder={t('corporatetext_instructions')} rows="5" cols="50" />
</div>
</>
)}


{
    !headline && (
<>
 <div className="inner-content-box col-five row-ten">
            <textarea className="content-box-main-textarea" value={corporatetextText} onChange={(e) => setCorporatetextText(e.target.value)} placeholder={t('corporatetext_instructions')} />
   </div>
</>
)}
<div className="inner-content-box col-two row-two">
            <textarea className="content-box-insert-textarea" value={corporatetextFor} onChange={(e) => setCorporatetextFor(e.target.value)} placeholder={t('for_instructions')} />
</div>

<div className="inner-content-box col-two row-two">
            <textarea className="content-box-insert-textarea" value={corporatetextWhat} onChange={(e) => setCorporatetextWhat(e.target.value)} placeholder={t('what_instructions')} />
</div>

<div className="inner-content-box">
<button className="editor-button" onClick={processCorporatetextOpenAI} disabled={isLoading}>
                        {headline ? t('re_generate_button') : t('generate_button')}
                    </button>
</div>
        </div>
       </div>
</div>
{successMessage && <div className="content-box blue-background"> {successMessage}</div>}
{errorMessage && <div className="content-box blue-background"> {errorMessage}</div>}

{!successMessage && ( 

<div className="content-container-column col-one">

	<div className="content-button-box row-one">
                    <button className="save" onClick={handleSubmit}>{t('save_button')}</button>
                    <button className="save" onClick={onCancel}>{t('cancel_button')}</button>
	</div>
 
	<div className="content-box row-six">
		<h2>{t('good_to_know')}</h2>
		<p className="small">{t('corporatetext_intro_text')}</p>
	</div>

</div>

)}
            

</div>
</>
    );
};

export default CompanyCorporatetextFunction;
