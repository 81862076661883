import React, { useEffect, useState, useCallback } from 'react';
import '../../Spinner.css';
import { useTranslation } from 'react-i18next';
import axios from '../../axios.js';
import { useHistory } from 'react-router-dom';

const CompanyDashboardBlogpostFunction = ({ existingCompany, blogpostId, users, formatDate, onCancel }) => {
    const [blogposts, setBlogposts] = useState([]);
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState(existingCompany || { content: { blogposts: [blogpostId] } });
    const [blogpostBullets, setBlogpostBullets] = useState('');
    const [blogpostText, setBlogpostText] = useState('');
    const [blogpostQuotes, setBlogpostQuotes] = useState('');
    const [blogpostFacts, setBlogpostFacts] = useState('');
    const [headline, setHeadline] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

 useEffect(() => {
        fetchCompanyData();
    }, []);

const fetchCompanyData = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }
        const response = await axios.get('/companies/get-company', {
            headers: { Authorization: `Bearer ${token}` }
        });
        const blogpostsResponse = await axios.get('/content/blogposts/get-for-company', {
            headers: { Authorization: `Bearer ${token}` }
        });

        const filteredBlogposts = blogpostsResponse.data.filter(blogpost => blogpost._id === blogpostId);

           if (filteredBlogposts.length > 0) {
          setBlogpostBullets(filteredBlogposts[0].blogpostBullets || '');
                setBlogpostText(filteredBlogposts[0].blogpostText || '');
                setBlogpostQuotes(filteredBlogposts[0].blogpostQuotes || '');
                setBlogpostFacts(filteredBlogposts[0].blogpostFacts || '');
                setHeadline(filteredBlogposts[0].headline || '');

        }

        setCompany(response.data);
        setBlogposts(filteredBlogposts);
   
    } catch (error) {
    }
};


const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }

        let updatedCompany = {...company};

        if (!blogpostId) {
            const userId = localStorage.getItem('userId');
            const newBlogpost = {
                blogpostBullets,
                blogpostText,
                blogpostQuotes,
                blogpostFacts,
                headline,
                blogpostTranslations: [],
                creator: userId,
                creationDate: new Date()
            };

            updatedCompany.content.blogposts = [...(updatedCompany.content?.blogposts || []), newBlogpost];

        } else {

            const blogpostIndex = updatedCompany.content.blogposts.findIndex(pr => pr._id === blogpostId);
            if (blogpostIndex !== -1) {
                updatedCompany.content.blogposts[blogpostIndex] = {
                    ...updatedCompany.content.blogposts[blogpostIndex],
                    blogpostBullets,
                    blogpostText,
                    blogpostQuotes,
                    blogpostFacts,
			headline,
                };
            }
        }

        const response = await axios.put(`/companies/${updatedCompany._id}`, updatedCompany, {
            headers: { Authorization: `Bearer ${token}` }
        });

        setCompany(response.data);

        setSuccessMessage(t('save_success'));
setTimeout(() => {
            setSuccessMessage('');
        }, 2000);
        fetchCompanyData();
    } catch (error) {
    }
};




  return (
<>


<textarea className="content-box-main-headline" name="headline" value={headline} onChange={(e) => setHeadline(e.target.value)} placeholder={t('blogpost_headline')} rows="3" cols="50" />
<textarea className="content-box-main-textarea" value={blogpostText} onChange={(e) => setBlogpostText(e.target.value)} placeholder={t('blogpost_text')} rows="10" cols="50" />


       


 <div className="content-box three-one">
                    <button className="save" onClick={handleSubmit}>{t('save_button')}</button>
                    <button className="save" onClick={onCancel}>{t('cancel_button')}</button>
                </div>
 
{successMessage && <div className="content-box blue-background"> {successMessage}</div>}




</>
    );
};

export default CompanyDashboardBlogpostFunction;
