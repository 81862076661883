import React, { useState, useEffect } from 'react';
import axios from '../axios.js';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';

const SubscriptionProPlansPage = ({ companyId }) => {
    const { subscriptionLevel } = useAuth();
    const [plans, setPlans] = useState([]);
    const [currentSubscription, setCurrentSubscription] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState('EUR'); 
    const [currencies, setCurrencies] = useState([]);
    const [isYearly, setIsYearly] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { t } = useTranslation();

const fetchPlanDetails = async () => {

    try {
        setLoading(true);
        const response = await axios.get('/subscription/plan-details');
        setPlans(response.data);

        const allCurrencies = response.data.flatMap(plan => 
            plan.prices.map(price => {
                return price.currency_code;
            })
        );

        const uniqueCurrencies = Array.from(new Set(allCurrencies));
        setCurrencies(uniqueCurrencies);

        if(uniqueCurrencies.length > 0 && !selectedCurrency) {
            setSelectedCurrency(uniqueCurrencies[0]);
        }

        setLoading(false);
    } catch (err) {
        setError(err.response?.data || 'Error fetching plans');
        setLoading(false);
    }
};

const fetchCurrentSubscription = async () => {
    try {
        const response = await axios.get(`/subscription/current/${companyId}`);

        if (response.data) {
            const subscriptionData = response.data;
            const itemPriceIds = subscriptionData.subscription_items.map(item => {
                return item.item_price_id;
            });

            const mappedData = {
                planNames: itemPriceIds,
                price: `${subscriptionData.mrr / 100} ${subscriptionData.currency_code}`,
                creationDate: new Date(subscriptionData.created_at * 1000).toLocaleDateString(),
                nextBillingCycle: new Date(subscriptionData.next_billing_at * 1000).toLocaleDateString(),
                billingCycle: subscriptionData.billing_period_unit
            };

            setCurrentSubscription(mappedData);
        } else {
        }
    } catch (err) {
    }
};




useEffect(() => {
    fetchPlanDetails().then(() => {
    });

    fetchCurrentSubscription().then(() => {
    });
}, [companyId, subscriptionLevel]); 



    const getPlanPrice = (plan) => {
        const billingCycle = isYearly ? 'yearly' : 'monthly';
        const price = plan.prices.find(p => p.period_unit === billingCycle);
        return price ? `${price.price / 100} ${price.currency_code}` : 'N/A';
    };

const getPlanPriceId = (plan) => {
    const billingCycle = isYearly ? 'year' : 'month';
    const price = plan.prices.find(p => p.period_unit === billingCycle && p.currency_code === selectedCurrency);
    return price ? price.id : null;
};


        if (loading) return <p>Loading...</p>;
    if (error) return <p className="error">{error}</p>;

   const getPrice = (plan) => {
    const billingCycle = isYearly ? 'year' : 'month'; 
    const priceInfo = plan.prices.find(price => 
        price.period_unit.startsWith(billingCycle) && price.currency_code === selectedCurrency
    );
    return priceInfo ? `${priceInfo.price / 100} ${priceInfo.currency_code}` : t('na');
};


const planValue = {
    free: 0,
    starter: 1,
    pro: 2,
    enterprise: 3
};

const extractPlanName = (planId) => {
    if (!planId) {
        return null;
    }
    const planNameRegex = /BuzzBeat-(.*?)(?:-|$)/; 
    const match = planId.match(planNameRegex);
    return match && match[1] ? match[1].toLowerCase() : null;
};

const sortPlans = (a, b) => {
    const planNameA = extractPlanName(a.id);
    const planNameB = extractPlanName(b.id);

    if (!planNameA || !planNameB) {
        return !planNameA ? 1 : -1;
    }

    const aValue = planValue[planNameA];
    const bValue = planValue[planNameB];

    return aValue - bValue;
};


 const getPlanValue = (planId) => {
        const planName = extractPlanName(planId);
        if (!planName) {
            return 0;
        }

        const value = planValue[planName];
        if (value === undefined) {
            return 0;
        }

        return value;
    };


const initiateSubscription = async (planId, billingCycle) => {
    try {
        setLoading(true);

        const selectedPlan = plans.find(plan => plan.id === planId);
        if (!selectedPlan) {
            throw new Error('Selected plan not found');
        }

        const priceId = getPlanPriceId(selectedPlan);
        const subscriptionItems = [{ item_price_id: priceId }];

        const currentPlanName = currentSubscription?.planNames?.[0];
        const currentPlanValue = currentPlanName ? getPlanValue(currentPlanName) : null;
        const newPlanValue = getPlanValue(priceId);
        const changeType = currentPlanValue != null ? (newPlanValue > currentPlanValue ? 'upgrade' : 'downgrade') : 'new';

        const payload = {
            companyId,
            subscriptionItems,
            changeType
        };

        const response = await axios.post('/subscription/create', payload);

        if (currentPlanValue != null && response.status === 200 && changeType !== 'new') {
            window.location.href = "https://www.buzzbeat.io/app/app/success-subscription-change";
        } else {
            window.location.href = response.data.hostedPageUrl;
        }
    } catch (error) {
        setError(error.response?.data?.message || error.message || 'Error initiating subscription');
        setLoading(false);
    }
};

const isFreeSubscription = () => {
    return !currentSubscription || 
           currentSubscription === 'free' ||
           (currentSubscription && currentSubscription.planNames.includes("BuzzBeat-Free"));
};

const currentPlanName = currentSubscription && currentSubscription.planNames.length > 0
    ? extractPlanName(currentSubscription.planNames[0])
    : null;







    return (
<div className="center">
<h1>{t('dont_miss_out_5days_headline')}</h1>
<p><br></br></p>
<p className="big">{t('dont_miss_out_5days_text')}</p>
<p><br></br></p>

            <div className="toggle-container">
                <span className={isYearly ? 'inactive' : 'active'}> {t('monthly')}</span>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={isYearly}
                        onChange={() => setIsYearly(!isYearly)}
                    />
                    <span className="slider round"></span>
                </label>
                <span className={isYearly ? 'active' : 'inactive'}> {t('yearly')}</span>
            </div>
 {currencies.length > 0 && (
                <div>
                    <select
                        id="currency-selector"
                        value={selectedCurrency}
                        onChange={(e) => setSelectedCurrency(e.target.value)}
                    >
                        {currencies.map((currency) => (
                            <option key={currency} value={currency}>
                                {currency}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {loading && <p> {t('loading')}</p>}
            {error && <p className="error">{error}</p>}
             {
  !loading && !error && (
    <div>
      {plans
        .filter(plan => plan.name === "Pro" || plan.id === "BuzzBeat-Pro") // Adjust this condition as needed
        .map(plan => (
          <div key={plan.id} className="plan">
            <h2>{plan.name}</h2>
            <p>{getPrice(plan)}</p>
            <button onClick={() => initiateSubscription(plan.id, isYearly ? 'yearly' : 'monthly')}>
              {t(currentSubscription ? 'change_button' : 'choose_button')}
            </button>
          </div>
        ))}
    </div>
  )
}




</div>
    );
};

export default SubscriptionProPlansPage;

