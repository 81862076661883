
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate, Routes, Route, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LanguageSwitcher from '../LanguageSwitcher';
import UniversityLandingPage from './UniversityLandingPage';
import HospitalLandingPage from './HospitalLandingPage';
import RealEstateLandingPage from './RealEstateLandingPage';
import SupplyChainLandingPage from './SupplyChainLandingPage';

import BuzzBeatFooterPage from '../public/BuzzBeatFooterPage';

import '../i18n';
import '../HomePage.css';

function LandingPages( openCookieConsentManager ) {
  const [logoSrc, setLogoSrc] = useState("../../assets/images/buzzbeat-logo-white.png");
  const [headerClass, setHeaderClass] = useState('headerbar-homepage');
  const [headerMobileClass, setMobileHeaderClass] = useState('mobile-headerbar-homepage');
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('language') || navigator.language.split('-')[0]);

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [currentLanguage, i18n]);

const changeLanguage = (language) => {
  setCurrentLanguage(language);
  localStorage.setItem('language', language);
  i18n.changeLanguage(language);
};


useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 0;
      setHeaderClass(show ? 'headerbar-homepage scrolled' : 'headerbar-homepage');
      setMobileHeaderClass(show ? 'mobile-headerbar-homepage scrolled' : 'mobile-headerbar-homepage');
      setLogoSrc(show ? "../../assets/images/buzzbeat-logo.png" : "../../assets/images/buzzbeat-logo-white.png");
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


 return (
<>
	<div className={headerMobileClass}></div>
 	 <div className={headerClass}>




<div className="headerbar-homepage-container">

  <div className="logo-homepage middle col-two">
      <img src={logoSrc} alt={t('buzzbeat_logo')}></img>
      </div>
<div></div>
<div className="col-eight"><h1>{t('headline_starting_page')}</h1></div>
 <div className="language-switcher">
       <LanguageSwitcher currentLanguage={currentLanguage} changeLanguage={changeLanguage} />
</div>


</div>
</div>


        <Routes>
 	  <Route path="/university" element={<UniversityLandingPage />} />
 	  <Route path="/hospital" element={<HospitalLandingPage />} />
 <Route path="/realestate" element={<RealEstateLandingPage />} />
 <Route path="/supplychain" element={<SupplyChainLandingPage />} />

        </Routes>


<div className="bottombar-homepage">

<BuzzBeatFooterPage openCookieConsentManager={openCookieConsentManager} />

</div>
</>
  );
}

export default LandingPages;



