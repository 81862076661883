import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';
import i18n from 'i18next';

const NewUserForm = ({ companyId, onSuccess, onError }) => {
    const [user, setUser] = useState({
        surname: '',
        name: '',
        email: '',
        infos: {
            roleInCompany: '',
            telephone: '',
            mobile: '',
            address: ''
        },
        role: 'user',
        company: companyId
    });
    const [invite, setInvite] = useState(false); 
    const { t } = useTranslation();
    const { fetchWithAuth, subscriptionLevel } = useAuth();
    const canAddUser = ['pro', 'enterprise'].includes(subscriptionLevel);
    const [roles, setRoles] = useState([]);

 useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await fetchWithAuth('/user/roles', {
                    method: 'GET'
                });
                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
                const fetchedRoles = await response.json();
                setRoles(fetchedRoles);
            } catch (error) {
                console.error("Failed to fetch roles", error);
            }
        };

        fetchRoles();
    }, [fetchWithAuth]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser(prevUser => {
            const updatedUser = { ...prevUser, [name]: value };
            return updatedUser;
        });
    };

    const handleInfosChange = (e) => {
        const { name, value } = e.target;
        setUser(prevUser => {
            const updatedUser = { ...prevUser, infos: { ...prevUser.infos, [name]: value } };
            return updatedUser;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        onError('');
        onSuccess('');

        const currentLanguage = i18n.language;

        try {
            const response = await fetchWithAuth('/companies/createuser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...user,
                    company: companyId,
                    invite,
                    language: currentLanguage
                })
            });

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const data = await response.json();
            onSuccess(t('invitation_sent_success'));
        } catch (error) {
            onError(error.message || t('invitation_failed'));
        }
    };



    return (


    <div>
 <h1>{t('add_new_user')}</h1>
<p><br></br></p>
      <form onSubmit={handleSubmit}>
     
		<div className="companydetails-item"> 
<div>{t('given_name')}:</div>
        <div>      <input type="text" name="name" onChange={handleChange} /></div>
</div>
         <div className="companydetails-item"> 
<div>{t('surname')}:</div>
        <div>       <input type="text" name="surname" onChange={handleChange} /></div>
</div>
          <div className="companydetails-item"> 
<div>{t('select_role_in_company')}:</div>
        <div>     <select name="roleInCompany" defaultValue="" onChange={handleInfosChange}>
                            <option value="">{t('select_role_in_company')}</option>
                            {roles.map((role) => (
                                <option key={role} value={role}>{t(role)}</option>
                            ))}
                        </select>
</div>
</div>
          <div className="companydetails-item"> 
<div>{t('telephone')}:</div>
        <div>      <input type="text" name="telephone" onChange={handleInfosChange} /></div>
</div>
         <div className="companydetails-item"> 
<div>{t('email')}</div>
        <div>     <input type="email" name="email" onChange={handleChange} /></div>
</div>
<div className="companydetails-item"> 

        <div> {t('send_invitation')}</div>  
<div><input type="checkbox" checked={invite} onChange={(e) => setInvite(e.target.checked)} /> </div>
</div>
        <div className="companydetails-item"> 

<div></div>
        <div>      <button className="editor-button">{t('add_user_button')}</button></div>

</div>


 </form>
            

        </div>
    );
};

export default NewUserForm;
