import React, { useEffect, useState, useCallback } from 'react';
import '../../Spinner.css';
import { useTranslation } from 'react-i18next';
import axios from '../../axios.js';
import { useHistory } from 'react-router-dom';

const CompanyUsecaseFunction = ({ existingCompany, usecaseId, users, formatDate, onCancel }) => {
    const [usecases, setUsecases] = useState([]);
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState(existingCompany || { content: { usecases: [usecaseId] } });
    const [usecaseBullets, setUsecaseBullets] = useState('');
    const [usecaseText, setUsecaseText] = useState('');
    const [usecaseQuotes, setUsecaseQuotes] = useState('');
    const [usecaseFacts, setUsecaseFacts] = useState('');
    const [headline, setHeadline] = useState('');
    const contextAI = company?.context?.contextCompany || '';
    const [successMessage, setSuccessMessage] = useState('');
const [errorMessage, setErrorMessage] = useState('');



 useEffect(() => {
        fetchCompanyData();
    }, []);

const fetchCompanyData = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }
        const response = await axios.get('/companies/get-company', {
            headers: { Authorization: `Bearer ${token}` }
        });
        const usecasesResponse = await axios.get('/content/usecases/get-for-company', {
            headers: { Authorization: `Bearer ${token}` }
        });

        const filteredUsecases = usecasesResponse.data.filter(usecase => usecase._id === usecaseId);

           if (filteredUsecases.length > 0) {
          setUsecaseBullets(filteredUsecases[0].usecaseBullets || '');
                setUsecaseText(filteredUsecases[0].usecaseText || '');
                setUsecaseQuotes(filteredUsecases[0].usecaseQuotes || '');
                setUsecaseFacts(filteredUsecases[0].usecaseFacts || '');
                setHeadline(filteredUsecases[0].headline || '');

        }

        setCompany(response.data);
        setUsecases(filteredUsecases);
   

    } catch (error) {
    }
};


const handleSubmit = async (e) => {
    e.preventDefault();

    try {

if (!usecaseText.trim()) {
    setErrorMessage(t('usecase_TextEmptyError'));
    setTimeout(() => {
        setErrorMessage('');
    }, 2000);
    return;
}

        const token = localStorage.getItem('token');
        if (!token) {
            return;
        }

        let updatedCompany = {...company};

        // Initialize the content object and usecases array if they don't exist
        updatedCompany.content = updatedCompany.content || {};
        updatedCompany.content.usecases = updatedCompany.content.usecases || [];

        if (!usecaseId) {
            // Adding a new usecase
            const userId = localStorage.getItem('userId');
            const newUsecase = {
                usecaseBullets,
                usecaseText,
                usecaseQuotes,
                usecaseFacts,
                headline,
                usecaseTranslations: [],
                creator: userId,
                creationDate: new Date()
            };

            updatedCompany.content.usecases.push(newUsecase);
        } else {
            // Updating an existing usecase
            const usecaseIndex = updatedCompany.content.usecases.findIndex(uc => uc._id === usecaseId);
            if (usecaseIndex !== -1) {
                updatedCompany.content.usecases[usecaseIndex] = {
                    ...updatedCompany.content.usecases[usecaseIndex],
                    usecaseBullets,
                    usecaseText,
                    usecaseQuotes,
                    usecaseFacts,
                    headline
                };
            }
        }

        // Send the updated company data to the server
        const response = await axios.put(`/companies/${updatedCompany._id}`, updatedCompany, {
            headers: { Authorization: `Bearer ${token}` }
        });

        setCompany(response.data);
    setSuccessMessage(t('save_success'));
        setTimeout(() => {
            setSuccessMessage('');
        }, 2000);
        fetchCompanyData();
    } catch (error) {
        console.error("An error occurred:", error);
        // Handle the error appropriately
    }
};


const processUsecaseOpenAI = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    const token = localStorage.getItem('token');
    if (!token) {
        setIsLoading(false);
        return;
    }

   if (!usecaseText) {
        setIsLoading(false);
        return;
    }

    try {
        let bulletsResponse = await axios.post('/content/process-usecases-bullets', {
            usecaseBullets: usecaseText 
        }, { headers: { 'Authorization': `Bearer ${token}`,'Accept-Language': i18n.language } });

        let descriptionResponse = await axios.post('/content/process-usecases-text', {
            contextAI: contextAI,
            usecaseBullets: bulletsResponse.data.openAiResponse,              
            usecaseFacts: usecaseFacts,
            usecaseQuotes: usecaseQuotes
        }, { headers: { 'Authorization': `Bearer ${token}`,'Accept-Language': i18n.language } });

        if (bulletsResponse.data && descriptionResponse.data) {
            setUsecaseBullets(bulletsResponse.data.openAiResponse);
            setUsecaseText(descriptionResponse.data.openAiResponse);
        }

        if (descriptionResponse.data.openAiResponse) {
            const headlineResponse = await axios.post('/content/process-usecases-headline', {
                usecaseText: descriptionResponse.data.openAiResponse
            }, { headers: { 'Authorization': `Bearer ${token}`, 'Accept-Language': i18n.language } });


            if (headlineResponse.data) {
                setHeadline(headlineResponse.data.openAiResponse);
            } else {
            }
        }
    } catch (error) {
    } finally {
        setIsLoading(false);
    }
}, [
    isLoading, 
    usecaseText, 
    contextAI, 
    usecaseFacts, 
    usecaseQuotes, 
    i18n.language   
]);



  return (
<>
{isLoading && <div className="spinner-overlay"><div className="spinner"></div></div>}

<div className="content-container">

<div className="content-container-column col-two">

<div className="content-box col-two row-eight">
               <div className="inner-content-container">
         
{headline && (
       <>
           <div className="inner-content-box col-five row-two">

<textarea className="content-box-main-headline" name="headline" value={headline} onChange={(e) => setHeadline(e.target.value)} placeholder={t('usecase_headline')} rows="5" cols="50" />
  </div>

                        <div className="inner-content-box col-five row-eight">

            <textarea className="content-box-main-textarea" value={usecaseText} onChange={(e) => setUsecaseText(e.target.value)} placeholder={t('usecase_instructions')} rows="5" cols="50" />
</div>
</>
)}


{
    !headline && (
<>
 <div className="inner-content-box col-five row-ten">
            <textarea className="content-box-main-textarea" value={usecaseText} onChange={(e) => setUsecaseText(e.target.value)} placeholder={t('usecase_instructions')} />
   </div>
</>
)}
<div className="inner-content-box col-two row-two">
            <textarea className="content-box-insert-textarea" value={usecaseQuotes} onChange={(e) => setUsecaseQuotes(e.target.value)} placeholder={t('usecase_quotes')} />
</div>

<div className="inner-content-box col-two row-two">
            <textarea className="content-box-insert-textarea" value={usecaseFacts} onChange={(e) => setUsecaseFacts(e.target.value)} placeholder={t('usecase_facts')} />
</div>

<div className="inner-content-box">
<button className="editor-button" onClick={processUsecaseOpenAI} disabled={isLoading}>
                        {headline ? t('re_generate_button') : t('generate_button')}
                    </button>
</div>
        </div>
       </div>
</div>
{successMessage && <div className="content-box blue-background"> {successMessage}</div>}
{errorMessage && <div className="content-box blue-background"> {errorMessage}</div>}


{!successMessage && ( 
<div className="content-container-column col-one">

 <div className="content-button-box row-one">
                    <button className="save" onClick={handleSubmit}>{t('save_button')}</button>
                    <button className="save" onClick={onCancel}>{t('cancel_button')}</button>
                </div>
 

            <div className="content-box row-four"><h2>{t('good_to_know')}</h2><p className="small">{t('usecase_intro_text')}</p></div>
</div>
)}
            

</div>
</>
    );
};

export default CompanyUsecaseFunction;
