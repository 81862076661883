import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';
 
function PublicContentReviewPage() {
  const { contentType, contentId } = useParams();
  const [email, setEmail] = useState('');
  const [contentDetails, setContentDetails] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState('Pending');
  const [comment, setComment] = useState('');
  const [error, setError] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const { t } = useTranslation();
  const { fetchWithAuth } = useAuth();
  const [backgroundImage, setBackgroundImage] = useState('');
  const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    const totalImages = 7;
    const randomIndex = Math.floor(Math.random() * totalImages) + 1;
    const imagePath = `${process.env.PUBLIC_URL}/assets/images/backgrounds/image${randomIndex}.jpg`;
    setBackgroundImage(imagePath);

    const handleScroll = () => setOffsetY(window.pageYOffset);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


useEffect(() => {
  if (!email || !isVerified) return;

  const fetchCurrentApprovalStatus = async () => {
    try {
      const url = `/approval/status/${contentId}?email=${encodeURIComponent(email)}`;
      const response = await fetchWithAuth(url, { headers: { 'Content-Type': 'application/json' } });

      if (response.ok) {
        const { approvalStatus: fetchedApprovalStatus } = await response.json();
        setApprovalStatus(fetchedApprovalStatus);
      } else {
        setError('Failed to fetch current approval status.');
      }
    } catch (err) {
      setError('Could not fetch current approval status.');
    }
  };


  fetchCurrentApprovalStatus();
}, [email, isVerified, contentId]);


  const formatContentType = (type) => {
    if (type === 'pressreleases') {
      return 'pressRelease';
    }
    return type;
  };

const handleEmailSubmit = async (e) => {
  e.preventDefault();
  if (!email) {
    setError("Please enter your email address.");
    return;
  }

  try {
    const response = await fetchWithAuth(`/approval/review/${contentType}/${contentId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
    });

    if (response.ok) {
      const data = await response.json();

      const fetchedContent = data.content.content[data.onModel].find(item => item._id === contentId);
      if (fetchedContent) {
        setContentDetails(fetchedContent);
        setIsVerified(true);
      } else {
        setError('Content not found.');
      }
    } else {
      throw new Error(`Failed to verify email and retrieve content. Status: ${response.status}`);
    }
  } catch (err) {
    setError(err.message);
    setIsVerified(false);
  }
};



const handleUpdate = async (e) => {
  e.preventDefault();
  try {
    const response = await fetchWithAuth(`/approval/update-approval/${contentId}/${email}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ approvalStatus, comment })
    });

    if (response.ok) {
      alert("Approval status and comment updated successfully.");
    } else {
      const errorResponse = await response.json();
      throw new Error(`Failed to update approval status and comments. Message: ${errorResponse.message}`);
    }
  } catch (err) {
    alert(`Error: ${err.message}`);
  }
};

const getFormattedContentTypeText = () => {
  let formattedContentType;
  switch (contentType) {
    case 'pressreleases':
      formattedContentType = 'pressRelease';
      break;
    case 'articles':
      formattedContentType = 'article';
      break;
    case 'blogposts':
      formattedContentType = 'blogpost';
      break;
    case 'corporatetexts':
      formattedContentType = 'corporatetext';
      break;
    case 'usecases':
      formattedContentType = 'usecase';
      break;
    default:
      formattedContentType = contentType;
      break;
  }

  return contentDetails[`${formattedContentType}Text`] || 'Content text not available';
};

  return (
    <>
     {!isVerified && (
        <>
      <div className="buzzbeat-starting-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="overlay-content" style={{ top: `${offsetY * 0.3}px` }}>
          
    <div className="login-container">

 <div className="login-form left">
<h1>{t('enter_email_for_access')}:</h1>
          <form onSubmit={handleEmailSubmit}>
           
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit">{t('verify_email')}</button>
          </form>
          {error && <p className="error">{error}</p>}
</div>
</div>
           </div>
      </div>

</>
      )}


      {isVerified && contentDetails && (
          <>

   <div className="buzzbeat-other-page" style={{ backgroundImage: `url(${backgroundImage})` }}></div>



      <div className="content-section-homepage">

        <div className="grid-layout">
        
  <div className="grid-content-item col-four left">


	
            		<h1>{contentDetails.headline}</h1>
	    		<p><br></br></p>
            		<p>{getFormattedContentTypeText()}</p>
  	    		<p><br></br></p>

</div>

  <div className="grid-content-item col-two left">

     		
	<h2>{t('content_review_request')}</h2>
			 <p><br></br></p>
           <form onSubmit={handleUpdate}>

           {approvalStatus === "Approved" ? (
                 <>

			 <h1>{t('approval_status')}: {t('approved')}</h1>
			 <p><br></br></p>

		</>
                ) : (
                  <>
                    <label htmlFor="approvalStatus">{t('approval_status')}:</label>
                    <select
                      id="approvalStatus"
                      value={approvalStatus}
                      onChange={(e) => setApprovalStatus(e.target.value)}
                    >
                      <option value="Approved">{t('approved')}</option>
                      <option value="Pending">{t('pending')}</option>
                      <option value="Not Approved">{t('not_approved')}</option>
                      <option value="Review">{t('review_again')}</option>
                      <option value="Commented">{t('commented')}</option>
                    </select>
                  </>
                )}



        
                  <textarea
		  placeholder={t('please_leave_comment')}
		  className="public-comment-textarea"
                  id="comment"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
              
              <button type="submit" className="editor-button">{t('update_approval')}</button>
            </form>
</div>
</div>
</div>


          </>
      )}
</>
  );
}

export default PublicContentReviewPage;
