import React, { useState, useEffect } from 'react';
import axios from '../axios.js';
import NewUserForm from './NewUserForm';
import { useTranslation } from 'react-i18next';
 
const CompanyDetailsPage = ({ existingCompany, onSuccess }) => {
 const initialState = {
    name: '',
    type: '',
    telephone: '',
    email: '',
    website: '',
    industry: '',
    foundedDate: '',
    numberOfEmployees: 0,
    revenue: 0,
    address: {
        street: '',
        city: '',
        state: '',
        zipCode: '',
        country: ''
    },
 context: {
	 context: '',
	 contextFinetune: '',
	 contextCompany: ''
    },
    contactPersons: []
};

    const [company, setCompany] = useState(initialState);
    const [successMessage, setSuccessMessage] = useState('');
    const [activeTab, setActiveTab] = useState('basicInfo');
    const [thisCompanyId, setThisCompanyId] = useState('');
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const storedCompanyId = localStorage.getItem('buzzBeatCompanyId');
        if (storedCompanyId) {
            setThisCompanyId(storedCompanyId);
        }
    }, []);


  useEffect(() => {
        if (!existingCompany) {
            fetchCompanyData();
        } else {
            setCompany(existingCompany);
        }
    }, [existingCompany]);

    const fetchCompanyData = async () => {
         try {
            const token = localStorage.getItem('token');
            if (!token) {
                return;
            }
            const response = await axios.get('/companies/get-company', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setCompany(response.data);
        } catch (error) {
        }
    };

const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split('.');
    let updatedValue = value;

    setCompany(prevState => {
        let newState = { ...prevState };
        let tempState = newState;

        for (let i = 0; i < keys.length - 1; i++) {
            if (!tempState[keys[i]]) {
                tempState[keys[i]] = {};
            }
            tempState = tempState[keys[i]];
        }

        tempState[keys[keys.length - 1]] = updatedValue;
        return newState;
    });
};


    const handleContactPersonChange = (index, e) => {
        const updatedContactPersons = [...company.contactPersons];
        updatedContactPersons[index] = {
            ...updatedContactPersons[index],
            [e.target.name]: e.target.value
        };
        setCompany({ ...company, contactPersons: updatedContactPersons });
    };

 const addContactPerson = () => {
        setCompany({
            ...company,
            contactPersons: [
                ...company.contactPersons,
                { name: '', surname: '', email: '', role: '', phoneNumber: '' }
            ]
        });
    };

const handleSubmit = async (e) => {
    e.preventDefault();

    const isContextFilled = company.context?.context?.trim() !== '';
    const isNameFilled = company.name?.trim() !== '';

    if (!isContextFilled || !isNameFilled) {
        if (!isContextFilled) {
            alert(t('context_must_filled'));
        }
        if (!isNameFilled) {
            alert(t('company_name_must_filled')); 
        }
        return;
    }

    const userId = localStorage.getItem('userId');
    const creationDate = new Date();

    let updatedCompany = {
        ...company,
        context: {
            creator: userId,
            creationDate,
            contextCompany: company.context.contextCompany,
            contextFinetune: company.context.contextFinetune
        },
        isSetupComplete: isContextFilled
    };

    updatedCompany.address = {
        street: company.address?.street || '',
        city: company.address?.city || '',
        state: company.address?.state || '',
        zipCode: company.address?.zipCode || '',
        country: company.address?.country || ''
    };

    try {
        const response = await axios.put(`/companies/${updatedCompany._id}`, updatedCompany, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        });

        if (response.data && response.data._id) {
            setSuccessMessage(t('company_update_successful')); 

const processingRequestConfig = {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}`, 'Accept-Language': i18n.language }
};

axios.post(`/content/process-context`, {
    companyId: updatedCompany._id, 
    contextCompany: updatedCompany.context.contextCompany,
    contextFinetune: updatedCompany.context.contextFinetune,
    name: company.name ?? '',
    type: company.type ?? '',
    email: company.email ?? '',
    website: company.website ?? '',
    industry: company.industry ?? '',
    foundedDate: company.foundedDate ?? '',
    address: {
        street: company.address?.street ?? '',
        city: company.address?.city ?? '',
        state: company.address?.state ?? '',
        zipCode: company.address?.zipCode ?? '',
        country: company.address?.country ?? ''
    },
}, processingRequestConfig)
.then(contextResponse => {
})
.catch(contextError => {
});

if (updatedCompany.context.contextFinetune?.trim()) {
    axios.post(`/content/process-finetune`, {
        companyId: updatedCompany._id, 
        contextFinetune: updatedCompany.context.contextFinetune,
    }, processingRequestConfig)
    .then(finetuneResponse => {

        if (finetuneResponse.data) {
            fetchCompanyData();
        } else {
        }
    })
    .catch(finetuneError => {
    });
} else {
}


setTimeout(async () => {
    setSuccessMessage('');

    if (typeof onSuccess === 'function') {
        onSuccess();
    } else {
    }

    try {
        await fetchCompanyData();
    } catch (finalError) {
    }
}, 2000);

        }
    } catch (error) {
    } finally {
    }
};



    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };


    return (
<>
  

<div className="content-container">
 {activeTab === 'basicInfo' && (

<div className="content-container-column col-two">
 <div className="content-box row-eight">	

 <div className="inner-content-container-flex ten-rows">



               
                 <div className="inner-content-box vertical-center col-two">{t('company_name')}:</div>
                <div className="inner-content-box vertical-center col-three"><input type="text" name="name" value={company.name || ''} onChange={handleChange} /></div>

                <div className="inner-content-box vertical-center col-two">{t('company_type')}:</div>
                <div className="inner-content-box vertical-center col-three"><input type="text" name="type" value={company.type || ''} onChange={handleChange} /></div>
              
 <div className="inner-content-box vertical-center col-two">{t('telephone')}:</div>
                <div className="inner-content-box vertical-center col-three"><input type="text" name="telephone" value={company.telephone || ''} onChange={handleChange} /></div>
              
<div className="inner-content-box vertical-center col-two">{t('your_email')}:</div>
                <div className="inner-content-box vertical-center col-three"><input type="email" name="email" value={company.email || ''} onChange={handleChange} /></div>
            
 <div className="inner-content-box vertical-center col-two">{t('website')}:</div>
                <div className="inner-content-box vertical-center col-three"><input type="text" name="website" value={company.website || ''} onChange={handleChange} /></div>
           
   <div className="inner-content-box vertical-center col-two">{t('industry')}:</div>
                <div className="inner-content-box vertical-center col-three"><input type="text" name="industry" value={company.industry || ''} onChange={handleChange} /></div>
           
  <div className="inner-content-box vertical-center col-two">{t('founded_date')}:</div>
                <div className="inner-content-box vertical-center col-three"><input type="date" name="foundedDate" value={company.foundedDate ? company.foundedDate.split('T')[0] : ''} onChange={handleChange} /></div>
            
   <div className="inner-content-box vertical-center col-two">{t('number_employees')}:</div>
                <div className="inner-content-box vertical-center col-three"><input type="number" name="numberOfEmployees" value={company.numberOfEmployees || 0} onChange={handleChange} /></div>
          
    <div className="inner-content-box vertical-center col-two">{t('revenue')}:</div>
                <div className="inner-content-box vertical-center col-three"><input type="number" name="revenue" value={company.revenue || 0} onChange={handleChange} /></div>
      </div>   
</div>
</div>

 )}

{activeTab === 'address' && (
<div className="content-container-column col-two">
 <div className="content-box row-eight">	

 <div className="inner-content-container-flex ten-rows">

                <div className="inner-content-box vertical-center col-two">{t('street')}:</div>
                <div className="inner-content-box vertical-center col-three"><input type="text" name="address.street" value={company.address?.street || ''} onChange={handleChange} /></div>

                <div className="inner-content-box vertical-center col-two">{t('city')}:</div>
                <div className="inner-content-box vertical-center col-three"><input type="text" name="address.city" value={company.address?.city || ''} onChange={handleChange} /></div>

                <div className="inner-content-box vertical-center col-two">{t('zip_code')}:</div>
                <div className="inner-content-box vertical-center col-three"><input type="text" name="address.zipCode" value={company.address?.zipCode || ''} onChange={handleChange} /></div>

                <div className="inner-content-box vertical-center col-two">{t('state')}:</div>
                <div className="inner-content-box vertical-center col-three"><input type="text" name="address.state" value={company.address?.state || ''} onChange={handleChange} /></div>


                <div className="inner-content-box vertical-center col-two">{t('country')}:</div>
                <div className="inner-content-box vertical-center col-three"><input type="text" name="address.country" value={company.address?.country || ''} onChange={handleChange} /></div>

               </div>   
</div>
</div>
 )}



{activeTab === 'context' && (
<div className="content-container-column col-two">

<div className="content-box row-eight">	
     <div className="inner-content-container">
	 <div className="inner-content-box col-five row-nine">

	    <textarea 
                className="editor-result-text"
                id="context"
                name="context.contextCompany"
                value={company?.context?.contextCompany}
                onChange={handleChange}
                placeholder={t('context_company_placeholder')}
                rows={16}
            />
        </div>

<div className="inner-content-box col-five row-three">

            <textarea className="content-box-insert-textarea" name="context.contextFinetune" value={company?.context?.contextFinetune} onChange={handleChange} placeholder={t('context_finetune_placeholder')} />
</div>



    </div>
</div>
</div>
)}



<div className="content-container-column col-one">

 <div className="content-box row-one">
         <button className="save" type="submit" onClick={handleSubmit}>{t('save_button')}</button>
</div>





{successMessage && <div className="content-box blue-background"> {successMessage}</div>}

 <div className="content-box row-two">

 <div className="tab-controls">

 <button onClick={() => handleTabChange('basicInfo')} className={activeTab === 'basicInfo' ? 'active' : ''}>{t('basic_info_button')}</button>
                <button onClick={() => handleTabChange('address')} className={activeTab === 'address' ? 'active' : ''}>{t('address_button')}</button>
                 <button onClick={() => handleTabChange('context')} className={activeTab === 'context' ? 'active' : ''}>{t('context_button')}</button>
</div>
</div>


{activeTab === 'context' && (
 <div className="content-box row-four">
	<p className="small">{t('context_infos')}</p>
</div>
)}

</div>

</div>
  

</>
    );
};

export default CompanyDetailsPage;