import axios from 'axios';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://app.buzzbeat.io';
const axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 403) {
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
