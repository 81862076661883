import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';
import i18n from 'i18next'; 

import ApprovalRequestDisplay from './ApprovalRequestDisplay'; 
import ApprovalRequestComments from './ApprovalRequestComments'; 
import LoadingIndicator from '../LoadingIndicator';

const ApprovalPage = () => {
    const { onModel, contentType, contentId } = useParams();
    const location = useLocation();
    const { companyId, userId } = location.state || {};
    const [contentData, setContentData] = useState(null);
    const [approvers, setApprovers] = useState([]);
    const [selectedApprover, setSelectedApprover] = useState('');
    const [selectedApprovers, setSelectedApprovers] = useState([]);
    const [customApproverEmail, setCustomApproverEmail] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [comment, setComment] = useState('');
    const [approvalRequest, setApprovalRequest] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editableContent, setEditableContent] = useState('');
    const [editableHeadline, setEditableHeadline] = useState('');
    const [isTextExpanded, setIsTextExpanded] = useState(false);
    const [resetApprovalStatus, setResetApprovalStatus] = useState(false);
    const [currentUserId, setCurrentUserId] = useState('');
    const { fetchWithAuth } = useAuth();
    const storedUserId = localStorage.getItem('userId');
    const { t } = useTranslation();



 useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        setCurrentUserId(storedUserId);
    }, []);

  const fetchData = async () => {
  setIsLoading(true);
  setError('');

  const localCompanyId = localStorage.getItem('buzzBeatCompanyId') || companyId;

  if (!localCompanyId) {
    setIsLoading(false);
    return;
  }

  try {
      const contentResponse = await fetchWithAuth(`/approval/content/${contentType}/${localCompanyId}/${contentId}`);

    if (!contentResponse.ok) {
    }
    const content = await contentResponse.json();
    setContentData(content);


    const companyUsersResponse = await fetchWithAuth(`/approval/get-company-users/${localCompanyId}`);
    if (!companyUsersResponse.ok) {
      throw new Error(`HTTP error! status: ${companyUsersResponse.status}`);
    }
    const { companyUsers } = await companyUsersResponse.json();
    setApprovers(companyUsers);

    const approvalRequestResponse = await fetchWithAuth(`/approval/get-approval-request/${contentId}`);
    if (!approvalRequestResponse.ok) {
      throw new Error(`HTTP error! status: ${approvalRequestResponse.status}`);
    }
    const approvalRequestData = await approvalRequestResponse.json();
    setApprovalRequest(approvalRequestData);
    setSelectedApprovers(approvalRequestData.internalApprovers.concat(approvalRequestData.externalApprovers));
  } catch (e) {
    setError(t('data_load_failed'));
  } finally {
    setIsLoading(false);
  }
};



useEffect(() => {
  fetchData();
}, [contentId, companyId, contentType, fetchWithAuth]);

const startEditing = () => {
  let contentText, contentHeadline;

  switch (contentType) {
    case 'articles':
      contentText = contentData.articleText;
      contentHeadline = contentData.headline;
      break;
    case 'pressreleases':
      contentText = contentData.pressReleaseText;
      contentHeadline = contentData.headline;
      break;
    case 'blogposts':
      contentText = contentData.blogpostText;
      contentHeadline = contentData.headline;
      break;
case 'usecases':
      contentText = contentData.usecaseText;
      contentHeadline = contentData.headline;
      break;
    case 'corporatetexts':
      contentText = contentData.corporatetextText;
      contentHeadline = contentData.headline;
      break;
    default:
      return;
  }

  setEditableContent(contentText);
  setEditableHeadline(contentHeadline);
  setIsEditing(true);
};

  const toggleTextExpansion = () => {
    setIsTextExpanded(!isTextExpanded);
  };


const handleApprovalSubmit = async () => {

    const internalApprovers = selectedApprovers
        .filter(approver => approver.userId)
        .map(approver => ({ userId: approver.userId }));

    const externalApprovers = selectedApprovers
        .filter(approver => approver.email)
        .map(approver => ({
            email: approver.email
        }));

let approvalData = {
        contentId,
        companyId, 
        creatorId: userId,
        onModel: contentType,
        internalApprovers,
        externalApprovers,
        ...(comment.trim() && { comment }) 
    };

    if (comment.trim() !== "") {
        approvalData.comment = comment;
    }

    try {
        const response = await fetchWithAuth('/approval/send-approval', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(approvalData)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        setComment('');
fetchData(); 
    } catch (e) {
        setError(t('approval_send_failed'));
    }
};

const handleAddApprover = async () => {
    let alreadyAdded = false;
    let approverToAdd = {};

    if (selectedApprover) {
        const approverDetails = approvers.find(user => user._id === selectedApprover);
        if (approverDetails) {
            alreadyAdded = selectedApprovers.some(approver => approver.userId === approverDetails._id);
            if (!alreadyAdded) {
                approverToAdd = { userId: approverDetails._id };
            } else {
            }
        } else {
        }
        setSelectedApprover('');

    } 

    else if (customApproverEmail) {
        alreadyAdded = selectedApprovers.some(approver => approver.email === customApproverEmail);
        if (!alreadyAdded) {
            approverToAdd = { email: customApproverEmail };
            setCustomApproverEmail('');
        } else {
        }
    }

    if (!alreadyAdded && (approverToAdd.userId || approverToAdd.email)) {
        setSelectedApprovers(prev => [...prev, approverToAdd]);

        try {
            const response = await fetchWithAuth(`/approval/add-approver/${contentId}`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(approverToAdd)
            });
            if (!response.ok) {
                throw new Error(t('approver_add_failed'));
            }
fetchData(); 
        } catch (error) {
        }
    }
};


const selectedApproverIds = selectedApprovers
    .filter(approver => approver.userId && approver.userId._id)
    .map(approver => approver.userId._id.toString());

    const availableApprovers = approvers.filter(approver =>
        !selectedApproverIds.includes(approver._id.toString())
    );

       const isReminderDue = (approvalDate) => {
        const threeDaysAgo = new Date(new Date().setDate(new Date().getDate() - 3));
        return approvalDate < threeDaysAgo;
    };


const handleSendApprovalRequest = async () => {
    try {

        const currentLanguage = i18n.language;

        const response = await fetchWithAuth(`/approval/send-approval-notifications/${contentId}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ language: currentLanguage })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

    setApprovalRequest(prev => ({ ...prev, approvalSentDate: new Date().toISOString() }));

    } catch (e) {
        setError('Failed to send approval notifications. Please try again later.');
    }
};

const handleReminder = async (approverEmail) => {
    try {

        const currentLanguage = i18n.language;

        const response = await fetchWithAuth(`/approval/send-reminder/${contentId}`, { 
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ email: approverEmail, language: currentLanguage })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

    } catch (e) {
       
    }
};




    const saveChanges = async () => {
        if (!storedUserId) {
            setError('No user session found. Please log in again.');
            return;
        }

        const payload = {
            updatedText: editableContent,
            updatedHeadline: editableHeadline,
            userId: storedUserId,
            resetApprovalStatus,
            contentType, 
        };

        try {
            const response = await fetchWithAuth(`/approval/update-content/${contentId}`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            setIsEditing(false);
            fetchData();
        } catch (error) {
            setError('Failed to update content. Please try again later.');
        }
    };




const handleAddComment = async () => {
    if (!approvalRequest || comment.trim() === "") {
        return;
    }

    try {
        const response = await fetchWithAuth('/approval/add-comment', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                approvalRequestId: approvalRequest._id,
                userId: userId,
                comment: comment.trim()
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        setComment('');
        fetchData();
    } catch (e) {
        setError('Failed to add comment. Please try again later.');
    }
};



const renderContent = () => {
  if (!contentData) return null;

  let contentText;
  switch (contentType) {
    case 'articles':
      contentText = contentData.articleText;
      break;
    case 'pressreleases':
      contentText = contentData.pressReleaseText;
      break;
    case 'blogposts':
      contentText = contentData.blogpostText; 
      break;
 case 'usecases':
      contentText = contentData.usecaseText; 
      break;
    case 'corporatetexts':
      contentText = contentData.corporatetextText; 
      break;
    default:
      contentText = 'Unsupported content type'; 
  }
  const headline = contentData.headline;


if (isLoading) {
    return <div> <LoadingIndicator />
</div>;
  }



return (
    





<div className="inner-content-container">
  

{approvalRequest && (
<>
    {isEditing ? (
		<div className="inner-content-box col-five row-two">
		        <textarea className="content-box-main-headline" value={editableHeadline} onChange={(e) => setEditableHeadline(e.target.value)} />
		</div>
      ) : (
		<div className="inner-content-box col-five row-two">
        		<h1>{headline}</h1>
		</div>
    )}
 


   {isEditing ? (
        	<div className="inner-content-box col-five row-ten">
			<textarea className="content-box-main-textarea" value={editableContent} onChange={(e) => setEditableContent(e.target.value)} />
                </div>
      ) : (
        <>
	        <div className="inner-content-box-scroll col-five row-two">
	       		<p class="contentText">{contentText}</p>

</div>

	        <div className="inner-content-box-scroll col-five row-eight">
	<ApprovalRequestDisplay approvalRequest={approvalRequest} handleReminder={handleReminder} handleSendApprovalRequest={handleSendApprovalRequest} fetchData={fetchData} />
</div>


        </>
      )}
 </>     
)}     



{!approvalRequest && (
<>

		<div className="inner-content-box col-five row-two">
        		<h1>{headline}</h1>
		</div>
 
	        <div className="inner-content-box-scroll col-five row-ten">
	       		<p class="contentText">{contentText}</p>
		</div> 
 </>     
)} 
    </div>

  );
};


    return (

<div className="content-container">



<div className="content-container-column col-two">

<div className="content-box row-eight">{renderContent()}</div>

</div>





<div className="content-container-column col-one">


{!approvalRequest && (
<>
<div className="content-button-box row-three">
      		<h2>{t('add')} {t('internal_approver')}</h2>
      			<select className="approval-selector" value={selectedApprover} onChange={(e) => setSelectedApprover(e.target.value)} id="approvers-select">
        			<option value="">{t('select_from_team')}</option>
        			{availableApprovers.map((user, index) => (
          			<option key={index} value={user._id}>{user.name} {user.surname}</option>
        			))}
     			</select>
      		<button onClick={handleAddApprover} className="tiny_button">{t('add')}</button>
 <p><br></br></p>
      <h2>{t('add')} {t('external_approver')}:</h2>
      <input className="approval-selector" type="email" placeholder="Enter email" value={customApproverEmail} onChange={(e) => setCustomApproverEmail(e.target.value)} />
      <button onClick={handleAddApprover} className="tiny_button">{t('add')}</button>
   
</div>
<div className="content-button-box row-two">
  <textarea className="comment" placeholder={t('you_can_leave_comment')} value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
  <button className="tiny_button" onClick={handleApprovalSubmit}>{t('create_approval')}</button> 
</div>

{selectedApprovers.length > 0 && (
<div className="content-box row-three">
		<h2>{t('selected_approvers')}:</h2>
		<ul>
  			{selectedApprovers.map((approver, index) => {const user = approvers.find(u => u._id === approver.userId);
    				return (
      					<li key={index}>{approver.userId && user ? `${user.name} ${user.surname} - ${t('internal_approver')}` : `${approver.email} - ${t('external_approver')}`} - {t('pending')}</li>
    					);
  			})}
		</ul>
</div>
)}
</>
 )}




{approvalRequest && (
<>
<div className="content-box row-one">

     {isEditing ? (
<>
	<button className="save" onClick={saveChanges}>{t('save_button')}</button>
        <button className="save" onClick={() => setIsEditing(false)}>{t('cancel_button')}</button>
</>
     ) : ( 

	<button className="save" onClick={startEditing}>{t('edit')}</button>

       )}
</div>

{contentData && isEditing && contentData.creator === currentUserId && (
<div className="content-box row-one">
            <input
              type="checkbox"
              checked={resetApprovalStatus}
              onChange={(e) => setResetApprovalStatus(e.target.checked)}
            />
            <label>{t('reset_approval_request')}</label>
          </div> 
 )}




<div className="content-box row-two">    
    <textarea className="editor-textarea" placeholder={t('you_can_leave_comment')} value={comment} onChange={(e) => setComment(e.target.value)} />
    <button className="tiny_button" onClick={approvalRequest ? handleAddComment : handleApprovalSubmit}>
        {approvalRequest ? t('comment') : t('create_approval')}
    </button> 
</div>


{contentData?.creator === currentUserId && (
  <>
    <div className="content-box row-five">    
      <h3>{t('add')} {t('internal_approver')}:</h3>
      <select value={selectedApprover} onChange={(e) => setSelectedApprover(e.target.value)} id="approvers-select">
        <option value="">{t('select_from_team')}</option>
        {availableApprovers.map((user, index) => (
          <option key={index} value={user._id}>{user.name} {user.surname}</option>
        ))}
      </select>
      <button onClick={handleAddApprover} className="tiny_button">{t('add')}</button>
<p><br></br></p>

 <div className="approvers-right">
      <h3>{t('add')} {t('external_approver')}:</h3>
      <input type="email" placeholder="Enter email" value={customApproverEmail} onChange={(e) => setCustomApproverEmail(e.target.value)} />
      <button onClick={handleAddApprover} className="tiny_button">{t('add')}</button>
    </div>

    </div>


  </>
)}




{isEditing && (
<div className="content-box row-four">    
<ApprovalRequestComments approvalRequest={approvalRequest} handleReminder={handleReminder} handleSendApprovalRequest={handleSendApprovalRequest} />
 </div>
)}


</>
)}

</div>



</div>



    );
};

export default ApprovalPage;