import React, { useState, useEffect } from 'react';
import axios from './axios.js';
import { useTranslation } from 'react-i18next';
import './Dashboard.css';
 
import DashboardUsecases from './content/usecases/DashboardUsecases';
import DashboardBlogposts from './content/blogposts/DashboardBlogposts';
import DashboardPressreleases from './content/pressreleases/DashboardPressreleases';
import DashboardArticles from './content/articles/DashboardArticles';
import DashboardCorporatetexts from './content/corporatetexts/DashboardCorporatetexts';
import DashboardSubscription from './subscription/DashboardSubscription';


const DashboardPageStarter = ({ companyId }) => {
 
  const { t } = useTranslation();

  return (
    <div className="dashboard-container">
<div className="square"><DashboardUsecases companyId={companyId} /></div>       
<div className="square"><DashboardBlogposts companyId={companyId} /></div>       
<div className="square"><DashboardPressreleases companyId={companyId} /></div>       

<div className="square"><DashboardArticles companyId={companyId} /></div>       
<div className="square"><DashboardCorporatetexts companyId={companyId} /></div>       
<div className="square"><DashboardSubscription companyId={companyId} /></div>       


    </div>
  );
};

export default DashboardPageStarter;
