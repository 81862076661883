// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-overlay {
  position: fixed;
  top: 0;
  left: 60px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000;
}

.spinner {
  border: 8px solid transparent;
  border-top: 8px solid #0028ff;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #0028ff;
  border-left: 8px solid transparent;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  background: radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 70%);
  animation: spin 1s linear infinite, pulseGlow 2s infinite alternate;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes pulseGlow {
  0% { box-shadow: 0 0 10px rgba(0,0,0,0.5); }
  100% { box-shadow: 0 0 20px rgba(0,0,0,1); }
}
`, "",{"version":3,"sources":["webpack://./src/Spinner.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,UAAU;EACV,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;EAC7B,6BAA6B;EAC7B,mCAAmC;EACnC,gCAAgC;EAChC,kCAAkC;EAClC,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,sFAAsF;EACtF,mEAAmE;AACrE;;AAEA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC;;AAEA;EACE,KAAK,oCAAoC,EAAE;EAC3C,OAAO,kCAAkC,EAAE;AAC7C","sourcesContent":[".spinner-overlay {\n  position: fixed;\n  top: 0;\n  left: 60px;\n  width: 100%;\n  height: 100%;\n  background: rgba(255, 255, 255, 0.8);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 10000000;\n}\n\n.spinner {\n  border: 8px solid transparent;\n  border-top: 8px solid #0028ff;\n  border-right: 8px solid transparent;\n  border-bottom: 8px solid #0028ff;\n  border-left: 8px solid transparent;\n  border-radius: 50%;\n  width: 200px;\n  height: 200px;\n  background: radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 70%);\n  animation: spin 1s linear infinite, pulseGlow 2s infinite alternate;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n\n@keyframes pulseGlow {\n  0% { box-shadow: 0 0 10px rgba(0,0,0,0.5); }\n  100% { box-shadow: 0 0 20px rgba(0,0,0,1); }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
