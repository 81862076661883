import axios from '../../axios.js';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../AuthContext'; 

const PressReleaseList = ({ pressReleases, handleEditPressRelease, onDelete, onCopy, onPressView, onPressViewTranslation, users, onTranslate, onPressApprove, onPressCollaborate, onToggleSubscription }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [showTranslations, setShowTranslations] = useState({}); 
  const [approvalStatuses, setApprovalStatuses] = useState({});
  const [collaborationStatuses, setCollaborationStatuses] = useState({});
  const { fetchWithAuth, subscriptionLevel } = useAuth();
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showCollaborationModal, setShowCollaborationModal] = useState(false);
  const [currentPressReleaseIndex, setCurrentPressReleaseIndex] = useState(null);
  const { t } = useTranslation();
  const freeAccess = ['free'].includes(subscriptionLevel);
  const starterAccess = ['starter'].includes(subscriptionLevel);
  const proAccess = ['pro'].includes(subscriptionLevel);
  const enterpriseAccess = ['enterprise'].includes(subscriptionLevel);



const ApprovalModal = ({ onClose, onApprove }) => (
<div className="modal-alert-backdrop">
            <div className="modal-alert">
<div className="modal-alert-item">
    <div><h1>{t('in_approval_only_in_approve')}</h1></div>
</div>
<div className="modal-alert-item">

    <div><button className="save" onClick={onApprove}>{t('go_to_approve')}</button>
    <button className="save" onClick={onClose}>{t('close')}</button></div>
</div>
  </div>
</div>
);


const CollaborationModal = ({ onClose, onCollaborate }) => (
<div className="modal-alert-backdrop">
            <div className="modal-alert">
<div className="modal-alert-item">
    <div><h1>{t('in_collaboration_only_in_approve')}</h1></div>
</div>
<div className="modal-alert-item">

    <div><button className="save" onClick={onCollaborate}>{t('go_to_collaborate')}</button>
    <button className="save" onClick={onClose}>{t('close')}</button></div>
</div>
  </div>
</div>
);



const fetchApprovalStatus = async (contentId) => {
    try {
        const response = await fetchWithAuth(`/approval/approval-status/${contentId}`);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.message) {
            return { overallApprovalStatus: 'Not Found', message: data.message };
        }
        return data;

    } catch (error) {
        return { overallApprovalStatus: 'Error', message: error.message || 'Unknown error' };
    }
};



useEffect(() => {
    pressReleases.forEach((pressRelease, index) => {
      fetchApprovalStatus(pressRelease._id).then((status) => {
        if (status) {
          setApprovalStatuses(prev => {
            const updatedStatuses = { ...prev, [pressRelease._id]: status };
            return updatedStatuses;
          });
        }
      });
    });
}, [pressReleases]);


const fetchCollaborationStatus = async (contentId) => {
    try {
        const response = await fetchWithAuth(`/collaboration/collaboration-status/${contentId}`);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.message) {
            return { overallCollaborationStatus: 'Not Found', message: data.message };
        }
        
        return data;

    } catch (error) {
          return { overallCollaborationStatus: 'Error', message: error.message || 'Unknown error' };
    }
};

  useEffect(() => {
    pressReleases.forEach((pressRelease, index) => {
      fetchCollaborationStatus(pressRelease._id).then((status) => {
        if (status) {
          setCollaborationStatuses(prev => {
            const updatedStatuses = { ...prev, [pressRelease._id]: status };
            return updatedStatuses;
          });
        }
      });
    });
  }, [pressReleases]);



const formatDate = (dateString) => {
    if (!dateString) return 'No Date Provided';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

const getComparable = (item, key, users) => {
 if (key === 'date' || key === 'creationDate') { 
    const date = new Date(item[key]);
    return isNaN(date.getTime()) ? 0 : date.getTime();
  }
  if (key === 'contact') {
    const user = users.find(u => u.email === item.contact);
    return user ? user.email : '';
  }
  return item[key];
};

const sortedPressReleases = React.useMemo(() => {
  let sortableItems = [...pressReleases];
  if (sortConfig.key !== null) {
    sortableItems.sort((a, b) => {
      const aValue = getComparable(a, sortConfig.key, users);
      const bValue = getComparable(b, sortConfig.key, users);
      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }
  return sortableItems;
}, [pressReleases, sortConfig, users]); 

  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '↑' : '↓';
    }
    return '↕'; 
  };

  const requestSort = (key) => {
    setSortConfig(currentSortConfig => {
      let direction = 'ascending';
      if (currentSortConfig.key === key && currentSortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      return { key, direction };
    });
  };

  useEffect(() => {
  }, [sortConfig, sortedPressReleases]);

  const displayTranslation = (pressRelease, language) => {
    const translation = pressRelease.pressReleaseTranslations.find(t => t.language === language);
    return translation ? translation.translatedPressReleaseHeadline : pressRelease.headline;
  };

 const toggleTranslationsVisibility = (pressReleaseId) => {
    setShowTranslations(prevState => ({
      ...prevState,
      [pressReleaseId]: !prevState[pressReleaseId]
    }));
  };


 
  return (
<>
{showApprovalModal && (
  <ApprovalModal
    onClose={() => setShowApprovalModal(false)}
    onApprove={() => {
      setShowApprovalModal(false);
      if (currentPressReleaseIndex !== null) onPressApprove(currentPressReleaseIndex);
    }}
  />
)}
{showCollaborationModal && (
  <CollaborationModal
    onClose={() => {
      setShowCollaborationModal(false);
    }}
    onCollaborate={() => {
      
      if (currentPressReleaseIndex !== null) {
        setShowCollaborationModal(false);
        onPressCollaborate(currentPressReleaseIndex);
      } else {
      }
    }}
  />
)}




<div className="content-box">

    <div className="presslist">

<div className="press-release-container">
  <div className="press-release-header">
    <div className="header-item">
      <h2>{t('press_release')} <span onClick={() => requestSort('headline')}>{getArrow('headline')}</span></h2>
    </div>
<div className="header-item">
      		 <h2>{t('creation_date')} <span onClick={() => requestSort('creationDate')}>{getArrow('creationDate')}</span></h2>
    		</div>
    <div className="header-item">
      <h2>{t('contact_person')} <span onClick={() => requestSort('contact')}>{getArrow('contact')}</span></h2>
    </div>
    <div className="header-item">
      <h2>{t('dateline')} <span onClick={() => requestSort('date')}>{getArrow('date')}</span></h2>
    </div>
    <div className="header-item"></div>
<div className="header-item icons"></div>
  </div>


  {sortedPressReleases && sortedPressReleases.map((pressRelease, index) => (

<React.Fragment key={`pressRelease-${pressRelease._id}-${index}`}>

    <div className="press-release-item">

            <div>{pressRelease.headline || 'Untitled Press Release'}</div>
            <div>{formatDate(pressRelease.creationDate)}</div>

            <div className="hundred">{users.find(user => user.email === pressRelease.contact)?.email || 'No contact'}</div>
            <div>{formatDate(pressRelease.date)}</div>

 <div className="toggle-translations">
{pressRelease.pressReleaseTranslations && (proAccess || enterpriseAccess) && pressRelease.pressReleaseTranslations.length > 0 && (

                <button 
                  className="toggle-translations-btn"
                  onClick={() => toggleTranslationsVisibility(pressRelease._id)} 
                  title="Toggle Translations"
                >
                  {showTranslations[pressRelease._id] ? t('hide_translations') : t('show_translations')}
                </button>

            )}
 </div>

{(proAccess || enterpriseAccess) && (

             <div className="icons">

{collaborationStatuses[pressRelease._id] ? (
  <button
    className={
      !approvalStatuses[pressRelease._id] ||
      approvalStatuses[pressRelease._id]?.overallApprovalStatus === 'Error' ? 'enabledButton' : 'disabledButton'
    }
    onClick={() => {
      // Check for approval status before deciding action
      if (!approvalStatuses[pressRelease._id] ||
          approvalStatuses[pressRelease._id]?.overallApprovalStatus === 'Error') {
        onPressCollaborate(index);
      } else {
        setCurrentPressReleaseIndex(index)
        setShowApprovalModal(true);
      }
    }}
    title={t('collaborate')}
  >
    <img src={
      collaborationStatuses[pressRelease._id].overallCollaborationStatus === 'Collaborated' ? "../../assets/images/collaborate_done.png" :
      ['Pending', 'rewrite', 'Commented'].includes(collaborationStatuses[pressRelease._id].overallCollaborationStatus) ? "../../assets/images/collaborate_open.png" :
      "../../assets/images/collaborate.png"
    } alt={t('collaborate')} />
  </button>
) : (
  <button onClick={() => onPressCollaborate(index)} title={t('collaborate')} className="enabledButton">
    <img src="../../assets/images/collaborate.png" alt={t('collaborate')} />
  </button>
)}




{approvalStatuses[pressRelease._id] ? (
  <button
    className={
      collaborationStatuses[pressRelease._id]?.overallCollaborationStatus === 'Collaborated' ||
      !collaborationStatuses[pressRelease._id] ||
      collaborationStatuses[pressRelease._id]?.overallCollaborationStatus === 'Error' ? 'enabledButton' : 'disabledButton'
    }
    onClick={() => {
      if (collaborationStatuses[pressRelease._id]?.overallCollaborationStatus === 'Collaborated' ||
          !collaborationStatuses[pressRelease._id] ||
          collaborationStatuses[pressRelease._id]?.overallCollaborationStatus === 'Error') {
        onPressApprove(index);
      } else {
        setCurrentPressReleaseIndex(index)
        setShowCollaborationModal(true);
      }
    }}
    title={t('approve')}
  >
    <img src={
      approvalStatuses[pressRelease._id].overallApprovalStatus === 'Approved' ? "../../assets/images/approval_done.png" :
      ['Pending', 'Not Approved', 'Review', 'Commented'].includes(approvalStatuses[pressRelease._id].overallApprovalStatus) ? "../../assets/images/approval_open.png" :
      "../../assets/images/approval.png"
    } alt={t('approve')} />
  </button>
) : (
  <button onClick={() => onPressApprove(index)} title={t('approve')} className="enabledButton">
    <img src="../../assets/images/approval.png" alt={t('approve')} />
  </button>
)}




                <button onClick={() => onTranslate(pressRelease._id)} title={t('translate')}><img src="../../assets/images/translate.png" alt={t('translate')} /></button>
                <button onClick={() => onCopy(index)} title={t('copy')}><img src="../../assets/images/copy.png" alt={t('copy')} /></button>
<button onClick={() => onPressView(pressRelease._id)} title={t('view')}><img src="../../assets/images/view.png" alt={t('view')} /></button>

        
<button
    className={
        (approvalStatuses[pressRelease._id]?.overallApprovalStatus && approvalStatuses[pressRelease._id]?.overallApprovalStatus !== 'Error' || collaborationStatuses[pressRelease._id]?.overallCollaborationStatus && collaborationStatuses[pressRelease._id]?.overallCollaborationStatus !== 'Error')
        ? 'disabledButton' : 'enabledButton'
    }
    onClick={() => {

        setCurrentPressReleaseIndex(pressRelease._id);

        const approvalStatusValue = approvalStatuses[pressRelease._id]?.overallApprovalStatus;
        const collaborationStatusValue = collaborationStatuses[pressRelease._id]?.overallCollaborationStatus;

        const hasApprovalStatus = !!approvalStatusValue && approvalStatusValue !== 'Error';
        const hasCollaborationStatus = !!collaborationStatusValue && collaborationStatusValue !== 'Error';


        if (hasApprovalStatus && hasCollaborationStatus) {
        setCurrentPressReleaseIndex(index)
            setShowApprovalModal(true); 
        } else if (hasApprovalStatus) {
        setCurrentPressReleaseIndex(index)
            setShowApprovalModal(true);
        } else if (hasCollaborationStatus) {        
setCurrentPressReleaseIndex(index)
            setShowCollaborationModal(true);
        } else {
handleEditPressRelease(pressRelease._id)
        }
    }}
    title={t('edit')}
>
    <img src="../../assets/images/edit.png" alt={t('edit')} />
</button>




<button
    className={
        (approvalStatuses[pressRelease._id]?.overallApprovalStatus && approvalStatuses[pressRelease._id]?.overallApprovalStatus !== 'Error' || collaborationStatuses[pressRelease._id]?.overallCollaborationStatus && collaborationStatuses[pressRelease._id]?.overallCollaborationStatus !== 'Error')
        ? 'disabledButton' : 'enabledButton'
    }
    onClick={() => {
        const approvalStatusValue = approvalStatuses[pressRelease._id]?.overallApprovalStatus;
        const collaborationStatusValue = collaborationStatuses[pressRelease._id]?.overallCollaborationStatus;

        const hasApprovalStatus = !!approvalStatusValue && approvalStatusValue !== 'Error';
        const hasCollaborationStatus = !!collaborationStatusValue && collaborationStatusValue !== 'Error';


        if (hasApprovalStatus && hasCollaborationStatus) {
        setCurrentPressReleaseIndex(index)
            setShowApprovalModal(true); 
        } else if (hasApprovalStatus) {
        setCurrentPressReleaseIndex(index)
            setShowApprovalModal(true);
        } else if (hasCollaborationStatus) {
        setCurrentPressReleaseIndex(index)
            setShowCollaborationModal(true);
        } else {
onDelete(index)
        }
    }}
    title={t('delete')}
>
    <img src="../../assets/images/delete.png" alt={t('delete')} />
</button>


            </div>
)}

{(freeAccess || starterAccess) && (
             <div className="icons">

  <button className="disabledButton" onClick={onToggleSubscription} title={t('collaborate')}><img src="../../assets/images/collaborate.png" alt={t('collaborate')} /></button>
  <button className="disabledButton" onClick={onToggleSubscription} title={t('approve')}><img src="../../assets/images/approval.png" alt={t('approve')} /></button>
  <button className="disabledButton" onClick={onToggleSubscription} title={t('translate')}><img src="../../assets/images/translate.png" alt={t('translate')} /></button>
  <button onClick={() => onCopy(index)} title={t('copy')}><img src="../../assets/images/copy.png" alt={t('copy')} /></button>
  <button onClick={() => onPressView(pressRelease._id)} title={t('view')}><img src="../../assets/images/view.png" alt={t('view')} /></button>
  <button onClick={() => handleEditPressRelease(pressRelease._id)} title={t('edit')} ><img src="../../assets/images/edit.png" alt={t('edit')} /></button>
  <button onClick={() => onDelete(index)} title={t('delete')}><img src="../../assets/images/delete.png" alt={t('delete')} /></button>

     </div>
)}
 </div>
<div>

{pressRelease.pressReleaseTranslations && pressRelease.pressReleaseTranslations.map((translation, tIndex) => (
  <React.Fragment key={`translation-${pressRelease._id}-${tIndex}`}>
   {showTranslations[pressRelease._id] && (

  <div className="translations" key={`translation-${index}-${tIndex}`}>
          <div className="first-translations"><p>{translation.language}:</p></div>
          <div className="second-translations"><p>{translation.translatedPressReleaseHeadline}</p></div>
          <div className="third-translations"> 
            <div className="icons">
             <button onClick={() => onCopy(translation)} title={t('copy_translation')}>
      <img src="../../assets/images/copy.png" alt={t('copy')} />
    </button>
             <button onClick={() => onPressViewTranslation(translation)} title={t('view_translation')}>
                <img src="../../assets/images/view.png" alt={t('view')} />
            </button>           
              <button onClick={() => onDelete({ pressReleaseIndex: index, translationIndex: tIndex })} title={t('delete_translation')}>
      <img src="../../assets/images/delete.png" alt={t('delete')} />
    </button>
            </div>
          </div>
        </div>
)}
  </React.Fragment>
      ))}



</div>
          </React.Fragment>
))}
      </div>
    </div>
</div>
</>
  );
};

export default PressReleaseList;
