import React, { useState, useCallback } from 'react';
import axios from '../axios.js';
import '../Spinner.css';
import { useTranslation } from 'react-i18next';

const CompanyMissionFunction = ({ company, handleChange }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const [successMessage, setSuccessMessage] = useState('');

    const contextAI = company?.context?.contextCompany || '';
    const finetuneAI = company?.context?.contextFinetune || '';

    const missionBullets = company?.content?.mission?.missionBullets || '';
    const missionText = company?.content?.mission?.missionText || '';
const [errorMessage, setErrorMessage] = useState('');

const handleSubmit = async (e) => {
    e.preventDefault();
    const userId = localStorage.getItem('userId');
    const updatedCompany = { ...company };

    if (updatedCompany.content?.mission) {
        updatedCompany.content.mission.creator = userId;
    }

    try {

if (!missionText.trim()) {
    setErrorMessage(t('saving_error'));
    setTimeout(() => {
        setErrorMessage('');
    }, 2000);
    return;
}

        const response = await axios.put(`/companies/${updatedCompany._id}`, updatedCompany);
        setSuccessMessage(t('translation_update_success'));
        setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
    }
};


const handleRefresh = () => {
        window.location.reload();
    };

const processMissionOpenAI = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
        const token = localStorage.getItem('token');
        if (!token) {
            setIsLoading(false);
            return;
        }

        if (!contextAI) {
            setIsLoading(false);
            return;
        }

        let bulletsResponse = await axios.post('/content/process-mission-bullets', {
            missionBullets: missionText,
            contextAI: contextAI,         
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept-Language': i18n.language 
            }
        });

        let descriptionResponse = await axios.post('/content/process-mission-text', {
            missionBullets: bulletsResponse.data.openAiResponse, 
            finetuneAI: finetuneAI
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept-Language': i18n.language 
            }
        });

        // Ensure company.content and company.content.mission are initialized
        if (!company.content || typeof company.content !== 'object') {
            company.content = {};
        }
        if (!company.content.mission || typeof company.content.mission !== 'object') {
            company.content.mission = {};
        }

        handleChange({
            target: {
                name: 'content.mission',
                value: {
                    ...company.content.mission,
                    missionText: descriptionResponse.data.openAiResponse,
                    missionBullets: bulletsResponse.data.openAiResponse
                }
            }
        });

    } catch (error) {
    } finally {
        setIsLoading(false);
    }
}, [isLoading, missionText, contextAI, finetuneAI, handleChange, i18n.language]);



    return (
    
<>
    {isLoading && <div className="spinner-overlay"><div className="spinner"></div></div>}


<div className="content-container-column col-two">

 <div className="content-box row-eight">	
               <div className="inner-content-container">

 <div className="inner-content-box col-five row-eleven">


 <textarea 
                className="editor-result-text"
                id="missionText"
                name="content.mission.missionText"
                value={missionText}
                onChange={handleChange}
                placeholder={t('mission_instructions')}
                rows={16}
            />


   </div>


<div className="inner-content-box col-two">

           
            <button className="editor-button" onClick={processMissionOpenAI} disabled={isLoading}>
    {t('generate_button')}
            </button>


 </div>

</div>
</div>
</div>


<div className="content-container-column col-one">

{successMessage && <div className="content-box row-ten blue-background"> {successMessage}</div>}
{errorMessage && <div className="content-box row-ten blue-background"> {errorMessage}</div>}


 <div className="content-button-box row-one">


          <button className="save" type="submit" onClick={handleSubmit}>{t('save_button')}</button>
            <button className="save"  type="button" onClick={handleRefresh}>{t('cancel_button')}</button>

</div>



  
 <div className="content-box row-four">
<h2>{t('good_to_know')}</h2>
<p className="small">{t('mission_intro_text')}</p>
        </div>
   </div>

</>
);
};

export default CompanyMissionFunction;

