import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate, NavLink } from 'react-router-dom';
import axios from '../../axios.js';

import CompanyDashboardUsecaseFunction from './companyDashboardUsecaseFunction';
import DashboardUsecaseList from './DashboardUsecaseList';

import UsecaseModal from './usecaseViewModal';
import LoadingIndicator from '../../LoadingIndicator';

const DashboardUsecases = ({ existingCompany, onApprove, onCollaborate, onToggleSubscription }) => {
    const [company, setCompany] = useState(existingCompany || { content: { usecases: [] } });
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [usecaseId, setUsecaseId] = useState([]);
    const [usecases, setUsecases] = useState([]);
    const [users, setUsers] = useState([]);
    const { t } = useTranslation();
    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUsecase, setSelectedUsecase] = useState(null);
    const [isTranslationModalOpen, setIsTranslationModalOpen] = useState(false);
    const [editingUsecaseId, setEditingUsecaseId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isUsecaseFunctionModalOpen, setIsUsecaseFunctionModalOpen] = useState(false);

 useEffect(() => {
        fetchCompanyData();
    }, []);

useEffect(() => {
  if (usecases.length > 0) {
  }
}, [usecases]);

 useEffect(() => {
        const fetchUsers = async () => {
            const token = localStorage.getItem('token');
            const companyID = company?._id;
            if (token && companyID) {
                try {
                    const response = await axios.get(`/content/get-for-company/${companyID}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setUsers(response.data || []);
                } catch (error) {
                }
            }
        };

        fetchUsers();
    }, [company]); 


const handleEditUsecase = (usecaseId) => {
  resetState();
  setEditingUsecaseId(usecaseId);
  handleOpenUsecaseFunctionModal();
};





const resetState = () => {
    setIsAddingNew(false);
    setEditingIndex(-1);
    setUsecaseId([]);
    setEditingUsecaseId(null);
    fetchCompanyData(); 
};






const fetchCompanyData = async () => {
  try {
    setIsLoading(true);

    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    if (!token) {
      return;
    }

    const response = await axios.get('/companies/get-company', {
      headers: { Authorization: `Bearer ${token}` }
    });

    const usecasesResponse = await axios.get('/content/usecases/get-for-company', {
      headers: { Authorization: `Bearer ${token}` }
    });

    const sortedUsecases = usecasesResponse.data.sort((a, b) => {
      return new Date(b.creationDate) - new Date(a.creationDate);
    });

    const filteredAndLimitedUsecases = sortedUsecases.filter(usecase => usecase.creator === userId).slice(0, 3);

    setCompany(response.data);
    setUsecases(filteredAndLimitedUsecases);
    setIsLoading(false);

  } catch (error) {
    setIsLoading(false);
  }
};

    const onCompanyUpdate = (updatedCompany) => {
        setCompany(updatedCompany);
    };

 const handleFinishAddingOrEditing = () => {
        handleCloseUsecaseFunctionModal();
        resetState();
        fetchCompanyData();
    };

const handleCancelAddingOrEditing = () => {
        handleCloseUsecaseFunctionModal();

        resetState();
fetchCompanyData();
    };






  const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };


const handleCopyUsecase = (data) => {
  let formattedData;

  if (typeof data === 'number') {

    const usecase = usecases[data];
    formattedData = `
${usecase.headline || 'Untitled usecase'}

${usecase.usecaseText || ''}

Contact:
${usecase.contact || 'No contact information'}
`;
  } else if (typeof data === 'object' && data.translatedUsecaseHeadline) {

    formattedData = `
Headline: ${data.translatedUsecaseHeadline || 'Untitled Translation'}
Text: ${data.translatedUsecaseText || ''}
`;
  } else {
    return;
  }

  navigator.clipboard.writeText(formattedData)
    .then(() => alert("Details copied!"))
    .catch(err => console.error("Failed to copy: ", err));
};

const handleViewUsecase = (usecaseId) => {
    const selectedUsecase = company.content.usecases.find(usecase => usecase._id === usecaseId);
    if (selectedUsecase) {
        setSelectedUsecase(selectedUsecase);
        setIsModalOpen(true);
        setIsTranslationModalOpen(false);
    } else {
    }
};


    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedUsecase(null);
    };


 const handleOpenUsecaseFunctionModal = () => {
        setIsUsecaseFunctionModalOpen(true);
    };

    const handleCloseUsecaseFunctionModal = () => {
        setIsUsecaseFunctionModalOpen(false);
    };





 let usecaseFunctionModalContent = isUsecaseFunctionModalOpen ? (
<div className="modal-backdrop-edit">
	<div className="modal-edit-content">
        <CompanyDashboardUsecaseFunction
            company={company}
            onFinish={handleFinishAddingOrEditing}
            usecaseId={editingUsecaseId}
            isAddingNew={isAddingNew}
            users={users}
            formatDate={formatDate}
            onCancel={handleCancelAddingOrEditing}
        />
</div>
</div>
    ) : null;


let content = (
            <DashboardUsecaseList 
    	    	onToggleSubscription={onToggleSubscription}

                usecases={usecases} 
                handleEditUsecase={handleEditUsecase}
                onCopy={handleCopyUsecase} 
                onPressView={handleViewUsecase}
                users={users}
            />
    
    );


if (isLoading) {
    return <div> <LoadingIndicator />
</div>;
  }

    return (
        <>
            {usecaseFunctionModalContent}

 <div className="dashboard-content-top">
                <div>{t('usecases')}</div>
		<div> <NavLink to="usecases" className={({ isActive }) => isActive ? 'active' : ''}>{t('view_all')}</NavLink></div> 
	    </div>

     <UsecaseModal 
                isOpen={isModalOpen} 
                onClose={handleCloseModal} 
                usecase={selectedUsecase} 
            />

   


            {content}
        </>
    );
};

export default DashboardUsecases;