import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';
 
import BarGraph from '../BarGraph';

const DashboardApprovalsPerTypeCountPage = ({ companyId }) => {
    const [error, setError] = useState('');
    const [approvalRequest, setApprovalRequest] = useState(null);
    const [approvals, setApprovals] = useState([]);
    const [openPressReleaseApprovals, setOpenPressReleaseApprovals] = useState(0);
    const [openArticleApprovals, setOpenArticleApprovals] = useState(0);
    const [openBlogpostApprovals, setOpenBlogpostApprovals] = useState(0);
    const [openUsecaseApprovals, setOpenUsecaseApprovals] = useState(0);
    const { fetchWithAuth } = useAuth();
    const [openCorporatetextApprovals, setOpenCorporatetextApprovals] = useState(0);

    const { t } = useTranslation();
    const navigate = useNavigate();

useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    const fetchCounts = async () => {
        try {
            const countsEndpoint = `/approval/approvals/${companyId}/counts?userId=${storedUserId}`;
            const response = await fetchWithAuth(countsEndpoint);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();

           setOpenPressReleaseApprovals(data.openPressReleaseApprovals);
           setOpenArticleApprovals(data.openArticleApprovals);
           setOpenBlogpostApprovals(data.openBlogpostApprovals);
           setOpenUsecaseApprovals(data.openUsecaseApprovals);

           setOpenCorporatetextApprovals(data.openCorporatetextApprovals);


        } catch (error) {
            setError('Failed to load data. Please try again later.');
        }
    };

    fetchCounts();
}, [companyId, navigate]);


    const handleEditClick = (approvalRequest) => {
        navigate(`/app/app/giv-approval/${approvalRequest.onModel}/${approvalRequest.contentId}`, { state: { companyId } });
    };

   const graphData = [
        { name: t('your_open_press_releases'), value: openPressReleaseApprovals },
        { name: t('your_open_articles'), value: openArticleApprovals },
        { name: t('your_open_blogposts'), value: openBlogpostApprovals },
        { name: t('your_open_usecases'), value: openUsecaseApprovals },
        { name: t('your_open_corporatetexts'), value: openCorporatetextApprovals },
    ];

    return (
        <div>
            <div className="dashboard-content-top">
                <div>{t('your_open_approvals_by_category')}</div>
                <div><NavLink to="give-approval">{t('open_by_category_link')}</NavLink></div>
            </div>
            <div className="dashboard-content-table">
                      <div className="dashboard-stats">
        <div className="bargraph"><BarGraph data={graphData} /></div>

                </div>
            </div>
        </div>
    );
};

export default DashboardApprovalsPerTypeCountPage;
